import React from 'react';
import { ApolloProvider } from '@apollo/client';
import apolloClient from './apollo/apolloclient';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import App from './containers/App/index';
import 'assets/vendors/style';
import 'styles/keepsporting.less';
import ability, { AbilityContext } from './acl/index';

const NextApp = () => (
    <ApolloProvider client={apolloClient}>
        <AbilityContext.Provider value={ability}>
            <BrowserRouter>
                <Switch>
                    <Route path="/" component={App} />
                </Switch>
            </BrowserRouter>
        </AbilityContext.Provider>
    </ApolloProvider>
);

export default NextApp;
