import { DeleteOutlined, FilePdfOutlined } from '@ant-design/icons';
import { useMutation, useQuery } from '@apollo/client';
import { Row, Col, PageHeader, Button, Drawer, Form, Select, Input, message, Card, Empty, Grid, Tag, Avatar, Modal } from 'antd';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { defaultCatchException } from '../../../../apollo/callbacks';
import { GQL_ASSET_DELETE } from '../../../../apollo/mutation/assets';
import { SUB_EVENT_ATTACHMENTS } from '../../../../apollo/query/sub-event';
import BadResponse from '../../../../components/BadResponse';
import CircularProgress from '../../../../components/CircularProgress';
import LanguageTag from '../../../../components/Tags/LanguageTag';
import UploadAsset from '../../../../components/UploadAsset';
import { validateFormFiles } from '../../../../components/UploadAsset/validators';
import { AssetContextType } from '../../../../constants/Asset';
import { useS3Upload } from '../../../../hooks/useS3Upload';
import { requiredRule } from '../../../../util/form';
import IntlMessages from '../../../../util/IntlMessages';
import SubEventBreadcrumbs from '../breadcrumbs';

const AttachmentUploadForm = ({ selectedLanguage, onFinish, form, intl }) => {
    return (
        <>
            <Form
                form={form}
                onFinish={onFinish}
                className="gx-mt-4"
                layout="vertical"
                initialValues={{
                    category: 'rules',
                }}
            >
                <Form.Item name="category" rules={[requiredRule(intl)]} label={<IntlMessages id="common.type" />}>
                    <Select>
                        <Select.Option value={'rules'}>
                            <IntlMessages id="common.rules" />
                        </Select.Option>
                        <Select.Option value={'ranking'}>
                            <IntlMessages id="common.rankings" />
                        </Select.Option>
                        <Select.Option value={'generic'}>
                            <IntlMessages id="common.generic_attachment" />
                        </Select.Option>
                    </Select>
                </Form.Item>
                <Form.Item
                    label={
                        <span>
                            {intl.formatMessage({ id: 'common.title' })} <LanguageTag language={selectedLanguage} />
                        </span>
                    }
                    name={['label', selectedLanguage]}
                    rules={[requiredRule(intl)]}
                >
                    <Input placeholder={intl.formatMessage({ id: 'common.title' }, { lang: intl.formatMessage({ id: `common.${selectedLanguage}` }) })} />
                </Form.Item>
                <Form.Item
                    name="file"
                    className="gx-mt-4"
                    label={<IntlMessages id="common.attachment" />}
                    rules={[requiredRule(intl), ...validateFormFiles({ mimeTypes: ['application/pdf'] }, intl)]}
                >
                    <UploadAsset placeholder={<IntlMessages id="common.attachment" />} allowMultipleFiles={false} />
                </Form.Item>
            </Form>
        </>
    );
};

const { useBreakpoint } = Grid;

const AttachmentItem = ({ asset, language, loading, onDelete }) => {
    return (
        <div className="gx-position-relative">
            <Button
                loading={loading}
                size="small"
                className="gx-btn-danger"
                style={{
                    position: 'absolute',
                    top: 0,
                    right: 0,
                }}
                onClick={() => onDelete(asset)}
            >
                <DeleteOutlined />
            </Button>
            <div className="gx-text-center">
                <Avatar className="gx-d-inline-block gx-mb-3" shape="square" size={100} icon={<FilePdfOutlined />} />

                <a className="gx-d-block" href={asset.url} target="_blank" rel="noreferrer">
                    <Tag>
                        <IntlMessages id={`common.${asset.category === 'generic' ? 'generic_attachment' : asset.category}`} />
                    </Tag>
                    {asset.label[language]}
                </a>
            </div>
        </div>
    );
};

const AttachmentsCard = ({ subEvent, onDelete }) => {
    const { assets, event } = subEvent;

    const screens = useBreakpoint();

    return (
        <Card>
            {assets && assets.length > 0 ? (
                assets.map((asset, index) => (
                    <Card.Grid style={{ width: !screens.xxl ? '33.33%' : '25%', textAlign: 'center' }} key={index}>
                        <AttachmentItem onDelete={onDelete} asset={asset} language={event.main_language} />
                    </Card.Grid>
                ))
            ) : (
                <Empty />
            )}
        </Card>
    );
};

const AttachmentPage = ({ subEvent, intl, refetch }) => {
    const [form] = Form.useForm();

    const [loading, setLoading] = useState(false);

    const [uploadAssets] = useS3Upload(
        AssetContextType.EVENT_ATTACHMENT,
        {
            id: subEvent.id,
            name: 'subevent',
        },
        false
    );

    const [deleteAsset, { loading: deleteLoading }] = useMutation(GQL_ASSET_DELETE);

    const history = useHistory();

    const [drawerVisibility, setDrawerVisibility] = useState(false);
    const [selectedLanguage, setSelectedLanguage] = useState(subEvent.event.main_language);

    const languages = subEvent.event.languages?.map((l) => l.iso);

    const closeHandler = () => {
        history.goBack();
    };

    const onDeleteAsset = (attachment) => {
        Modal.confirm({
            title: intl.formatMessage({ id: 'common.confirm' }),
            content: intl.formatMessage({ id: 'common.confirm_delete' }),
            okText: intl.formatMessage({ id: 'common.yes' }),
            cancelText: intl.formatMessage({ id: 'common.no' }),
            onOk: () => {
                deleteAsset({
                    variables: {
                        id: attachment.id,
                    },
                })
                    .then(({ data, errors }) => {
                        if (!errors) {
                            message.success(intl.formatMessage({ id: 'common.operation_successful' }));
                            refetch();
                        } else {
                            message.error(intl.formatMessage({ id: 'common.default_mutation_error' }));
                        }
                    })
                    .catch((error) => {
                        defaultCatchException(error, intl);
                    });
            },
        });
    };

    const onFinish = (values) => {
        setLoading(true);

        uploadAssets(values.file, {
            category: values.category,
            label: values.label,
        })
            .then((data) => {
                message.success(intl.formatMessage({ id: 'common.operation_successful' }));
                refetch();
                setDrawerVisibility(false);
                form.resetFields();
                setLoading(false);
            })
            .catch((error) => {
                defaultCatchException(error, intl);
                setLoading(false);
            });
    };

    return (
        <>
            <Row>
                <Col md={24}>
                    <SubEventBreadcrumbs subEvent={subEvent} section={<IntlMessages id={`common.attachments`} />} />
                </Col>
            </Row>
            <Row>
                <Col xs={24}>
                    <PageHeader
                        title={<IntlMessages id={`common.attachments`} />}
                        onBack={() => history.goBack()}
                        extra={[
                            <Button key="1" onClick={() => closeHandler()}>
                                <IntlMessages id="common.cancel" />
                            </Button>,
                            <Button key="2" htmlType="submit" type="primary" onClick={() => setDrawerVisibility(true)} loading={loading}>
                                {intl.formatMessage({ id: 'common.upload' }).toUpperCase()}
                            </Button>,
                        ]}
                    />

                    <AttachmentsCard subEvent={subEvent} intl={intl} onDelete={onDeleteAsset} loading={deleteLoading} />
                </Col>

                <Drawer
                    width={500}
                    title={<IntlMessages id="common.load_attachment" />}
                    placement="right"
                    closable={false}
                    onClose={() => setDrawerVisibility(false)}
                    visible={drawerVisibility}
                    extra={[
                        <Select
                            value={selectedLanguage}
                            onSelect={(value) => {
                                setSelectedLanguage(value);
                            }}
                            style={{ width: 100 }}
                            size="small"
                        >
                            {languages.map((value, key) => {
                                return (
                                    <Select.Option key={key} value={value}>
                                        {intl.formatMessage({ id: `common.${value}` })}
                                    </Select.Option>
                                );
                            })}
                        </Select>,
                    ]}
                    footer={[
                        <div className="gx-text-right gx-mt-2">
                            <Button key="back" onClick={() => setDrawerVisibility(false)}>
                                <IntlMessages id="common.cancel" />
                            </Button>
                            ,
                            <Button
                                key="submit"
                                type="primary"
                                onClick={() => {
                                    form.submit();
                                }}
                            >
                                <IntlMessages id="common.upload" />
                            </Button>
                        </div>,
                    ]}
                >
                    <AttachmentUploadForm intl={intl} form={form} onFinish={onFinish} selectedLanguage={selectedLanguage} />
                </Drawer>
            </Row>
        </>
    );
};

/**
 * TODO aggiungere delete!
 * @param {*} param0
 * @returns
 */
const AttachmentSubEvent = ({ match }) => {
    const intl = useIntl();

    const { data, loading, error, refetch } = useQuery(SUB_EVENT_ATTACHMENTS, { variables: { id: match.params.id }, fetchPolicy: 'no-cache' });

    if (loading) {
        return <CircularProgress />;
    }

    if (error) {
        return <BadResponse title={intl.formatMessage({ id: '500.ooops' })} subtitle={intl.formatMessage({ id: '500.something_wrong' })} />;
    }

    return <AttachmentPage {...data} refetch={refetch} intl={intl} />;
};

export default AttachmentSubEvent;
