import React, { useState } from 'react';
import BadResponse from '../../../../../components/BadResponse';
import { EVENT_PAYMENTS, PAYMENTS_SUMMARY } from '../../../../../apollo/query/event';
import { useMutation, useQuery } from '@apollo/client';
import CircularProgress from '../../../../../components/CircularProgress';
import IntlMessages from '../../../../../util/IntlMessages';
import CardDescriptions, { CardDescriptionsItem } from '../../../../../components/Descriptions/CardDescription';
import { DeleteOutlined, WarningOutlined } from '@ant-design/icons';
import { Row, Col, Button, Modal, message, Typography, Checkbox } from 'antd';
import { defaultCatchException, defaultMutationHandler } from '../../../../../apollo/callbacks';
import { DELETE_EVENT_PAYMENTS, VERIFY_EFI_PAYMENTS } from '../../../../../apollo/mutation/event';
import { formatCurrency } from '../../../../../util/string';
import { PAYMENTS } from '../../../../../constants/App';
import { useIntl } from 'react-intl';
import { usePersistedState } from '../../../../../hooks/usePersistedState';

const VerifyEfiPaymentModal = ({ show, event, provider, onCancel }) => {
    const intl = useIntl();
    const [verifyEfiPayments, { loading: mutationLoading }] = useMutation(VERIFY_EFI_PAYMENTS, {
        refetchQueries: [
            {
                query: PAYMENTS_SUMMARY,
                variables: { event_id: event.id },
            },
        ],
        awaitRefetchQueries: true,
    });
    return (
        <Modal
            open={show}
            okText={<IntlMessages id="common.confirm" />}
            onCancel={onCancel}
            cancelText={<IntlMessages id="common.cancel" />}
            okButtonProps={{
                loading: mutationLoading,
                disabled: mutationLoading,
            }}
            onOk={() => {
                verifyEfiPayments({
                    variables: {
                        event_id: event.id,
                        payment_type: provider.type,
                    },
                })
                    .then(
                        defaultMutationHandler({
                            successCallback: ({ verifyEfiPayments }) => {
                                const messageText = intl.formatMessage(
                                    {
                                        id: 'common.verify_unpaid_transactions_result',
                                    },
                                    verifyEfiPayments
                                );

                                message.success(messageText);
                                onCancel();
                            },
                            errorMessage: intl.formatMessage({ id: 'common.default_mutation_error' }),
                        })
                    )
                    .catch((e) => {
                        onCancel();
                        defaultCatchException(e, intl);
                    });
            }}
        >
            <Typography.Title level={5}>
                <IntlMessages id="common.verify_unpaid_transactions" />
            </Typography.Title>
            <IntlMessages id="common.verify_unpaid_transactions_text" />
        </Modal>
    );
};

const DeleteEfiPaymentsModal = ({ show, event, provider, onCancel }) => {
    const intl = useIntl();

    const [sendEmailToPayer, setSendEmailToPayer] = useState(false);

    const [filters] = usePersistedState('eventPayments', { term: '' });
    const [paginationState] = usePersistedState('eventPaymentsPagination', { current: 1, pageSize: 10 });
    const [sortingState] = usePersistedState('eventPaymentsSorting', { key: 'created_at', direction: -1 });

    const [deleteEventPayments, { loading: mutationLoading }] = useMutation(DELETE_EVENT_PAYMENTS, {
        refetchQueries: [
            {
                query: PAYMENTS_SUMMARY,
                variables: { event_id: event.id },
            },
            {
                query: EVENT_PAYMENTS,
                variables: {
                    filter: {
                        event_id: event.id,
                        ...filters,
                    },
                    pagination: {
                        page: paginationState.current,
                        limit: paginationState.pageSize,
                    },
                    sorting: sortingState,
                },
            },
        ],
        awaitRefetchQueries: true,
    });
    return (
        <Modal
            open={show}
            okText={<IntlMessages id="common.confirm" />}
            onCancel={onCancel}
            cancelText={<IntlMessages id="common.cancel" />}
            okButtonProps={{
                loading: mutationLoading,
                disabled: mutationLoading,
            }}
            onOk={() => {
                deleteEventPayments({
                    variables: {
                        event_id: event.id,
                        payment_type: provider.type,
                        notify: sendEmailToPayer,
                    },
                })
                    .then(
                        defaultMutationHandler({
                            successCallback: ({ deleteEventPayments }) => {
                                const messageText = intl.formatMessage(
                                    {
                                        id: 'common.multi_payments_deleted_plural',
                                    },
                                    { count: deleteEventPayments.success }
                                );

                                message.success(messageText);
                                onCancel();
                            },
                            errorMessage: intl.formatMessage({ id: 'common.default_mutation_error' }),
                        })
                    )
                    .catch((e) => {
                        onCancel();
                        defaultCatchException(e, intl);
                    });
            }}
        >
            <Typography.Title level={5}>
                <IntlMessages id={`common.payment_type_${provider.type}`} />: <IntlMessages id="common.delete_payments" />
            </Typography.Title>

            <Typography.Paragraph>
                <IntlMessages id="common.payments_delete_modal_info" />
            </Typography.Paragraph>

            <div className="gx-mt-4">
                <Checkbox
                    checked={sendEmailToPayer}
                    onChange={(e) => {
                        setSendEmailToPayer(e.target.checked);
                    }}
                >
                    <IntlMessages id="common.player_delete_send_mail_checkbox" />
                </Checkbox>
            </div>
        </Modal>
    );
};

const EfiPaymentProvider = ({ event, provider, formatPaymentCurrency }) => {
    const [showVerifyBoletoModal, setShowVerifyBoletoModal] = React.useState(false);
    const [showDeleteEfiPaymentModal, setShowDeleteEfiPaymentModal] = React.useState(false);

    return (
        <>
            <CardDescriptions title={<IntlMessages id={`common.payment_type_${provider.type}`} />} column={1} className="gx-mx-0">
                <CardDescriptionsItem label={<IntlMessages id="common.total" />}>
                    {formatPaymentCurrency(provider.total)}
                    <span className="gx-pl-1">({provider.count})</span>
                </CardDescriptionsItem>
                <CardDescriptionsItem label={<IntlMessages id="common.paid" />}>{formatPaymentCurrency(provider.paid)}</CardDescriptionsItem>
                {provider.not_paid > 0 ? (
                    <CardDescriptionsItem label={<IntlMessages id="common.not_paid" />} className="has-action">
                        <strong>{formatPaymentCurrency(provider.not_paid)}</strong>
                        <span className="gx-pl-1">({provider.not_paid_count})</span>

                        <Button
                            size="small"
                            className="gx-mb-0 gx-my-2"
                            key="payment-provider-verify-efi-payments"
                            icon={<WarningOutlined className="gx-pr-1" />}
                            onClick={() => {
                                setShowVerifyBoletoModal(true);
                            }}
                        >
                            <IntlMessages id="common.verify_unpaid_transactions" />
                        </Button>
                        <Button
                            danger={true}
                            size="small"
                            className="gx-mb-0 gx-my-2"
                            key="payment-provider-delete-efi-payments"
                            icon={<DeleteOutlined className="gx-pr-1" />}
                            onClick={() => {
                                setShowDeleteEfiPaymentModal(true);
                            }}
                        >
                            <IntlMessages id="common.delete_payments" />
                        </Button>
                    </CardDescriptionsItem>
                ) : (
                    <CardDescriptionsItem label={<IntlMessages id="common.not_paid" />}>{formatPaymentCurrency(provider.not_paid)}</CardDescriptionsItem>
                )}
                <CardDescriptionsItem label={<IntlMessages id="common.discount" />}>{formatPaymentCurrency(provider.discount)}</CardDescriptionsItem>
            </CardDescriptions>
            <VerifyEfiPaymentModal
                event={event}
                provider={provider}
                show={showVerifyBoletoModal}
                onCancel={() => {
                    setShowVerifyBoletoModal(false);
                }}
            />
            <DeleteEfiPaymentsModal
                event={event}
                provider={provider}
                show={showDeleteEfiPaymentModal}
                onCancel={() => {
                    setShowDeleteEfiPaymentModal(false);
                }}
            />
        </>
    );
};

const CommonPaymentProvider = ({ provider, formatPaymentCurrency }) => {
    return (
        <CardDescriptions title={<IntlMessages id={`common.payment_type_${provider.type}`} />} column={1} className="gx-mx-0">
            <CardDescriptionsItem label={<IntlMessages id="common.total" />}>
                {formatPaymentCurrency(provider.total)}
                <span className="gx-pl-1">({provider.count})</span>
            </CardDescriptionsItem>
            <CardDescriptionsItem label={<IntlMessages id="common.paid" />}>{formatPaymentCurrency(provider.paid)}</CardDescriptionsItem>
            <CardDescriptionsItem label={<IntlMessages id="common.not_paid" />}>
                {formatPaymentCurrency(provider.not_paid)} <span className="gx-pl-1">({provider.not_paid_count})</span>
            </CardDescriptionsItem>
            <CardDescriptionsItem label={<IntlMessages id="common.discount" />}>{formatPaymentCurrency(provider.discount)}</CardDescriptionsItem>
        </CardDescriptions>
    );
};

const PaymentProvider = ({ provider, ...rest }) => {
    switch (provider.type) {
        case PAYMENTS.BOLETO:
        case PAYMENTS.CARD_GERENCIANET:
            return <EfiPaymentProvider provider={provider} {...rest} />;
        default:
            return <CommonPaymentProvider provider={provider} {...rest} />;
    }
};

const PaymentsSummary = ({ intl, event }) => {
    const { data, loading, error } = useQuery(PAYMENTS_SUMMARY, {
        variables: { event_id: event.id },
    });

    if (error) {
        return <BadResponse title={intl.formatMessage({ id: '500.ooops' })} subtitle={intl.formatMessage({ id: '500.something_wrong' })} />;
    }

    if (loading) {
        return <CircularProgress />;
    }

    const formatPaymentCurrency = (value) => {
        return formatCurrency(value / 100, 2, event.payments.location.currency.iso);
    };

    const { paymentsSummary } = data;

    const { providers } = paymentsSummary;

    return (
        <div className="gx-m-4">
            <Row>
                <Col xs={24} sm={24} md={24} lg={12}>
                    {providers.map((provider) => {
                        return <PaymentProvider key={provider.id} provider={provider} formatPaymentCurrency={formatPaymentCurrency} event={event} />;
                    })}
                </Col>
                <Col xs={24} sm={24} md={24} lg={12}>
                    <CardDescriptions
                        title={
                            <strong>
                                <IntlMessages id="common.total" />
                            </strong>
                        }
                        column={1}
                        className="gx-mx-0"
                    >
                        <CardDescriptionsItem label={<IntlMessages id="common.total" />}>
                            {formatPaymentCurrency(paymentsSummary.total)}
                            <span className="gx-pl-1">({paymentsSummary.count})</span>
                        </CardDescriptionsItem>
                        <CardDescriptionsItem label={<IntlMessages id="common.paid" />}>{formatPaymentCurrency(paymentsSummary.paid)}</CardDescriptionsItem>
                        <CardDescriptionsItem label={<IntlMessages id="common.not_paid" />}>
                            {formatPaymentCurrency(paymentsSummary.not_paid)} ({paymentsSummary.not_paid_count})
                        </CardDescriptionsItem>
                        <CardDescriptionsItem label={<IntlMessages id="common.discount" />}>
                            {formatPaymentCurrency(paymentsSummary.discount)}
                        </CardDescriptionsItem>
                    </CardDescriptions>
                </Col>
            </Row>
        </div>
    );
};

export default PaymentsSummary;
