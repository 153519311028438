import React from 'react';
import { Tag } from 'antd';
import { TagOutlined } from '@ant-design/icons';
import { useIntl } from 'react-intl';

const LanguageTag = ({ language }) => {
    const intl = useIntl();

    return (
        <Tag className={'gx-text-uppercase gx-fs-sm gx-mb-0'}>
            <TagOutlined />
            &nbsp;
            {intl.formatMessage({ id: `common.${language}` })}
        </Tag>
    );
};

export default LanguageTag;
