export const Roles = {
    SUPERADMIN: 'superadmin',
    ADMIN: 'admin',
    MANAGER: 'manager',
};

export const ResponseErrorType = {
    GRAPHQL_ERROR: 1,
    NETWORK_ERROR: 2,
};

export const EVENT_TYPES = {
    COMPETITION: 1,
    WORKOUT: 2,
    COURSE: 3,
    CAMP: 4,
    TRAVEL: 5,
    //LIVE: 6
};

//form translations
export const SUPPORTED_LANGUAGES = {
    IT: 'it',
    EN: 'en',
    PT: 'pt',
    ES: 'es',
    DE: 'de',
};

//i18n static translations
export const STATIC_TRANSLATIONS = {
    SUPPORTED_LANGUAGES: {
        IT: 'it',
        EN: 'en',
        PT: 'pt',
        ES: 'es',
    },
    DEFAULT_LANGUAGE: 'it',
};
