import { Badge, Popover, Button, Row, Col, List, Checkbox } from 'antd';
import React, { useState, memo, useEffect } from 'react';
import { includes } from 'lodash';
import { PRIMARY_COLOR } from '../../constants/ThemeSetting';
import IntlMessages from '../../util/IntlMessages';

const FilterContent = ({ onChange, selectedOptions = [], options }) => {
    return (
        <List
            size="small"
            footer={
                <Row>
                    <Col xs={12}>
                        <Button
                            type="link"
                            size="small"
                            onClick={(e) => {
                                e.stopPropagation();
                                onChange(options.map((o) => o.value));
                            }}
                        >
                            <span className="gx-text-uppercase" style={{ color: PRIMARY_COLOR }}>
                                <IntlMessages id="common.select_all" />
                            </span>
                        </Button>
                    </Col>
                    <Col xs={12} className="gx-text-right">
                        <Button
                            type="link"
                            size="small"
                            onClick={(e) => {
                                e.stopPropagation();
                                onChange([]);
                            }}
                        >
                            <span className="gx-text-uppercase">
                                <IntlMessages id="common.cancel" />
                            </span>
                        </Button>
                    </Col>
                </Row>
            }
            dataSource={options}
            renderItem={(option) => (
                <List.Item>
                    <Checkbox
                        className="checkbox-secondary"
                        checked={includes(selectedOptions, option.value)}
                        onChange={(e) => {
                            if (e.target.checked) {
                                onChange([...selectedOptions, option.value]);
                            } else {
                                onChange(selectedOptions.filter((o) => o !== option.value));
                            }
                        }}
                    >
                        {option.label}
                    </Checkbox>
                </List.Item>
            )}
        />
    );
};

const DropDownCheckboxFilter = ({ options = [], label, values = [], onChange }) => {
    const [selectedOptions, setSelectedOptions] = useState([...values]);

    useEffect(() => {
        if (values.length === 0 && selectedOptions.length !== 0) {
            setSelectedOptions([]);
        }
        //values viene modificato (??) options no
    }, [options]);

    const popoverDefaultProps = {
        onVisibleChange: (visible) => {
            if (!visible) {
                onChange(selectedOptions);
            }
        },
        placement: 'bottom',
        trigger: 'click',
    };

    return (
        <Popover
            {...popoverDefaultProps}
            content={
                <FilterContent
                    options={options}
                    onChange={(opts) => {
                        setSelectedOptions(opts);
                    }}
                    selectedOptions={selectedOptions}
                />
            }
            title={null}
        >
            <Badge offset={[-15, 0]} style={{ backgroundColor: PRIMARY_COLOR }} count={selectedOptions.length}>
                <Button type="default">{label || <IntlMessages id="common.search" />}</Button>
            </Badge>
        </Popover>
    );
};

export default DropDownCheckboxFilter;
