import { get, find } from 'lodash';
import { MEDICAL_CERTIFICATE } from '../../../../constants/App';

export const shouldUploadMedicalCartificate = (player, subEvent) => {
    const value = player.athlete_card ? player.athlete_card.value : null;

    if (value) {
        const athleteCardOptions = get(subEvent, 'predefined_fields.athlete_card.options', []);

        const opt = find(athleteCardOptions, (o) => value === o.value);
        if (opt) {
            return opt.certificate_required;
        }
    }

    return get(subEvent, 'certificate.type') > 1;
};

export const subEventHasMedicalCartificate = (subEvent) => {
    return subEvent.certificate?.type !== MEDICAL_CERTIFICATE.TYPE.NONE;
};

export const subEventHasAthleteCard = (subEvent) => {
    return subEvent.predefined_fields?.athlete_card?.active === true;
};
