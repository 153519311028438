import React, { useState, useEffect } from 'react';
import {
    CheckCircleOutlined,
    CloseCircleOutlined,
    EditOutlined,
    EyeOutlined,
    FileExcelOutlined,
    FileOutlined,
    MailOutlined,
    UnorderedListOutlined,
} from '@ant-design/icons';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { PageHeader, Space, Row, Col, Card, Table, Input, Button, Collapse, Badge, Tooltip, Dropdown, Modal, message, Drawer } from 'antd';
import { useIntl } from 'react-intl';
import BadResponse from '../../../../components/BadResponse';
import { paginationOptions } from '../../../../constants/PaginationOptions';
import { usePersistedState } from '../../../../hooks/usePersistedState';
import { formatDate, formatDateTime } from '../../../../util/date';
import IntlMessages from '../../../../util/IntlMessages';
import { useDebounce } from 'use-debounce';
import { debounceSearchFilterDelay } from '../../../../util/config';
import CircularProgress from '../../../../components/CircularProgress';
import { SUB_EVENT_CONTAINER_DATA, SUB_EVENT_PLAYERS, EXPORT_SUB_EVENT_SUBSCRIPTIONS } from '../../../../apollo/query/sub-event';
import { PLAYER_ATHLETE_CARD_VALIDATIONS, PLAYER_SUBSCRIPTION_CONFIRMATIONS } from '../../../../apollo/mutation/sub-event';
import { Link, Redirect, useHistory } from 'react-router-dom';
import { getAdminPath, getMediaURL } from '../../../../util/router';
import DevButton from '../../../../components/Dev/DevButton';
import SubEventBreadcrumbs from '../breadcrumbs';
import { countActiveFilters } from '../../../../util/object';
import { PRIMARY_COLOR } from '../../../../constants/ThemeSetting';
import { MEDICAL_CERTIFICATE } from '../../../../constants/App';
import { AthleteCardValidationIcon } from '../../../../components/Icons/AthleteCardValidation';
import DropDownCheckboxFilter from '../../../../components/DropdownCheckboxFilter';
import { defaultCatchException, defaultMutationHandler } from '../../../../apollo/callbacks';
import { PLAYER_CERTIFICATE_CONFIRMS } from '../../../../apollo/mutation/event';
import { getSafeISOLanguage } from '../../../../lngProvider';
import PlayersSummary from './components/summary';
import { useActiveBreakpoint } from '../../../../hooks/useActiveBreakpoint';
import { includes } from 'lodash';
import { downloadBase64File } from '../../../../util/file';
import { Can } from '../../../../acl';

const getColumns = ({ subEvent }) => {
    const columns = [
        {
            title: <IntlMessages id="common.name" />,
            dataIndex: ['first_name'],
            width: 200,
            responsive: ['xs'],
            render(_, row) {
                const fullName = `${row.first_name} ${row.last_name}`;
                return row.retired || row.deleted_at ? <span className="gx-text-strikethrough">{fullName}</span> : fullName;
            },
        },
        {
            title: <IntlMessages id="common.first_name" />,
            dataIndex: ['first_name'],
            fixed: 'left',
            responsive: ['sm', 'md'],
            render(value, row) {
                return row.retired || row.deleted_at ? <span className="gx-text-strikethrough">{value}</span> : value;
            },
        },
        {
            title: <IntlMessages id="common.last_name" />,
            dataIndex: ['last_name'],
            fixed: 'left',
            responsive: ['sm', 'md'],
            render(value, row) {
                return row.retired || row.deleted_at ? <span className="gx-text-strikethrough">{value}</span> : value;
            },
        },
        {
            title: <IntlMessages id="common.email" />,
            align: 'center',
            dataIndex: ['email'],
            render(value, row) {
                return row.retired || row.deleted_at ? <span className="gx-text-strikethrough">{value}</span> : value;
            },
        },
    ];

    if (subEvent.certificate.type !== MEDICAL_CERTIFICATE.TYPE.NONE) {
        columns.push({
            title: <IntlMessages id="common.certificate" />,
            dataIndex: 'certificate',
            align: 'center',
            width: 150,
            render(certificate) {
                if (!certificate) {
                    return null;
                }

                return (
                    <Space>
                        {certificate.upload_at &&
                            certificate.path &&
                            (certificate.expire_at ? (
                                <Tooltip
                                    title={
                                        <span>
                                            <IntlMessages id="common.expire_at" /> {formatDate(certificate.expire_at)}
                                        </span>
                                    }
                                >
                                    <a target="_blank" href={getMediaURL(certificate.path)} rel="noreferrer">
                                        <FileOutlined />
                                    </a>
                                </Tooltip>
                            ) : (
                                <a target="_blank" href={getMediaURL(certificate.path)} rel="noreferrer">
                                    <FileOutlined />
                                </a>
                            ))}
                        {certificate.organization_confirm ? (
                            <CheckCircleOutlined className="gx-text-success" />
                        ) : (
                            <CloseCircleOutlined className="gx-text-danger" />
                        )}
                    </Space>
                );
            },
        });
    }

    if (subEvent.predefined_fields?.athlete_card && subEvent.predefined_fields?.athlete_card.active) {
        columns.push({
            title: <IntlMessages id="common.athlete_card" />,
            dataIndex: 'athlete_card',
            align: 'center',
            width: 150,
            render(athleteCard) {
                if (!athleteCard) {
                    return null;
                }

                const content = (
                    <Space>
                        <span>
                            <Tooltip
                                title={
                                    <span>
                                        <IntlMessages id="common.number" />
                                        <span className="gx-ml-2">{athleteCard.number || 'N.D.'}</span>
                                    </span>
                                }
                            >
                                <FileOutlined /> {athleteCard.label}
                            </Tooltip>
                        </span>
                        <AthleteCardValidationIcon card={athleteCard} />
                    </Space>
                );

                return (
                    <Space>
                        {athleteCard.file_upload_at && athleteCard.file_path ? (
                            <a target="_blank" href={getMediaURL(athleteCard.file_path)} rel="noreferrer">
                                {content}
                            </a>
                        ) : (
                            content
                        )}
                    </Space>
                );
            },
        });
    }

    //add customs fields

    columns.push(
        {
            title: <IntlMessages id="common.confirm" />,
            align: 'center',
            dataIndex: ['confirmed'],
            width: 100,
            render(value, row) {
                return value ? (
                    <Space>
                        <Tooltip title={<IntlMessages id="common.player_confirmed" />}>
                            <CheckCircleOutlined className="gx-text-success" />
                        </Tooltip>
                        {row.confirm_email ? (
                            <Tooltip title={<IntlMessages id="common.confirmation_email_sent" />}>
                                <MailOutlined />
                            </Tooltip>
                        ) : null}
                    </Space>
                ) : (
                    <Tooltip title={<IntlMessages id="common.player_not_confirmed" />}>
                        <CloseCircleOutlined className="gx-text-danger" />
                    </Tooltip>
                );
            },
        },
        {
            title: <IntlMessages id="common.paid" />,
            dataIndex: 'paid_at',
            width: 100,
            align: 'center',
            render(value) {
                return value ? <CheckCircleOutlined className="gx-text-success" /> : <CloseCircleOutlined className="gx-text-danger" />;
            },
        },
        {
            title: <IntlMessages id="common.createdAt" />,
            dataIndex: 'created_at',
            align: 'center',
            render(value) {
                return formatDateTime(value);
            },
        },
        {
            title: '',
            dataIndex: 'id',
            align: 'center',
            width: 140,
            responsive: ['sm'],
            render(id) {
                return [
                    <Link
                        key="view-athlete"
                        to={getAdminPath(`event/${subEvent.event.id}/sub-event/${subEvent.id}/subscriber/view/${id}`)}
                        className="ant-btn gx-mb-0"
                    >
                        <EyeOutlined />
                    </Link>,
                    <Link
                        key="update-athlete"
                        to={getAdminPath(`event/${subEvent.event.id}/sub-event/${subEvent.id}/subscriber/update/${id}`)}
                        className="ant-btn ant-btn-primary gx-mb-0"
                    >
                        <EditOutlined />
                    </Link>,
                ];
            },
        }
    );

    return columns;
};

const getDropdownActionMenu = ({ onClickConfirmations, onClickConfirmCertificate }) => {
    return [
        {
            key: '1',
            label: (
                <a onClick={onClickConfirmations}>
                    <IntlMessages id="common.confirm_subscription" />
                </a>
            ),
        },
        onClickConfirmCertificate
            ? {
                key: '2',
                label: (
                    <a onClick={onClickConfirmCertificate}>
                        <IntlMessages id="common.confirm_medical_certificate" />
                    </a>
                ),
            }
            : null,
    ].filter(Boolean);
};

const getFilterButtonsSpaceProps = (activeBreakpoint) => {
    if (includes(['XS', 'SM'], activeBreakpoint)) {
        return {
            direction: 'vertical',
            size: 'small',
            className: 'gx-mt-2',
        };
    } else {
        return {
            direction: 'horizontal',
            size: 'large',
        };
    }
};

const EventPlayersTable = ({ subEvent, intl }) => {
    const DEFAULT_FILTERS = { term: '', confirmed: [], retired: [] };

    const activeBreakpoint = useActiveBreakpoint();

    const history = useHistory();

    const { event } = subEvent;

    const [filters, setFilters] = usePersistedState(`subEvent.${subEvent.id}.eventPlayers`, DEFAULT_FILTERS);
    const [paginationState, setPaginationState] = usePersistedState(`subEvent.${subEvent.id}.eventPlayersPagination`, { current: 1, pageSize: 10 });
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);

    const updateFilters = (key, value) => {
        if (paginationState.current !== 1) {
            setPaginationState({ ...paginationState, current: 1 });
        }
        setFilters({ ...filters, [key]: value });
    };

    const [debouncedTerm] = useDebounce(filters?.term, debounceSearchFilterDelay);

    const [subscriptionConfimations, { loading: confirmationLoading }] = useMutation(PLAYER_SUBSCRIPTION_CONFIRMATIONS);
    const [playerCertificateConfirms, { loading: certificateConfirmsLoading }] = useMutation(PLAYER_CERTIFICATE_CONFIRMS);
    const [athleteCardValidations, { loading: athleteCardValidationLoading }] = useMutation(PLAYER_ATHLETE_CARD_VALIDATIONS);

    const { data, loading, error, previousData, refetch } = useQuery(SUB_EVENT_PLAYERS, {
        variables: {
            filter: {
                event_id: event.id,
                sub_event_id: subEvent.id,
                ...filters,
                term: debouncedTerm,
            },
            pagination: {
                page: paginationState.current,
                limit: paginationState.pageSize,
            },
        },
        onError: (error) => {
            setFilters(DEFAULT_FILTERS);
        },
    });

    const onSubscriptionConfirmation = () => {
        Modal.confirm({
            title: intl.formatMessage({ id: 'common.confirm_subscription' }),
            okText: intl.formatMessage({ id: 'common.confirm' }),
            cancelText: intl.formatMessage({ id: 'common.cancel' }),
            okButtonProps: {
                loading: confirmationLoading,
            },
            onOk: () => {
                subscriptionConfimations({
                    variables: {
                        input: selectedRowKeys.map((id) => ({
                            id: id,
                            confirmed: true,
                        })),
                    },
                })
                    .then(
                        defaultMutationHandler({
                            successCallback: ({ subscriptionConfirmations }) => {
                                message.success(intl.formatMessage({ id: 'common.multi_players_confirmed_plural' }, { count: subscriptionConfirmations }));
                                setSelectedRowKeys([]);
                                refetch();
                            },
                            errorMessage: intl.formatMessage({ id: 'common.default_mutation_error' }),
                        })
                    )
                    .catch((e) => defaultCatchException(e, intl));
            },
        });
    };

    const onClickConfirmCertificate = () => {
        Modal.confirm({
            title: intl.formatMessage({ id: 'common.confirm_medical_certificate' }),
            okText: intl.formatMessage({ id: 'common.confirm' }),
            cancelText: intl.formatMessage({ id: 'common.cancel' }),
            okButtonProps: {
                loading: certificateConfirmsLoading,
            },
            onOk: () => {
                playerCertificateConfirms({
                    variables: {
                        input: selectedRowKeys,
                    },
                })
                    .then(
                        defaultMutationHandler({
                            successCallback: ({ playerCertificateConfirms }) => {
                                message.success(intl.formatMessage({ id: 'common.multi_players_certificate_confirmed' }, { count: playerCertificateConfirms }));
                                setSelectedRowKeys([]);
                                refetch();
                            },
                            errorMessage: intl.formatMessage({ id: 'common.default_mutation_error' }),
                        })
                    )
                    .catch((e) => defaultCatchException(e, intl));
            },
        });
    };

    const onValidateAthleteCards = () => {
        Modal.confirm({
            title: intl.formatMessage({ id: 'common.check_all_athlete_cards' }),
            okText: intl.formatMessage({ id: 'common.confirm' }),
            cancelText: intl.formatMessage({ id: 'common.cancel' }),
            okButtonProps: {
                loading: athleteCardValidationLoading,
            },
            onOk: () => {
                athleteCardValidations({
                    variables: {
                        sub_event_id: subEvent.id,
                    },
                })
                    .then(
                        defaultMutationHandler({
                            successCallback: ({ athleteCardValidations }) => {
                                message.success(intl.formatMessage({ id: 'common.operation_successful' }));
                                refetch();
                            },
                            errorMessage: intl.formatMessage({ id: 'common.default_mutation_error' }),
                        })
                    )
                    .catch((e) => defaultCatchException(e, intl));
            },
        });
    };

    useEffect(() => {
        if (data && data.eventPlayers) {
            const totalPages = data.eventPlayers.pagination?.pages;
            const currentPage = data.eventPlayers.pagination?.page;
            if (currentPage > totalPages) {
                setPaginationState({ ...paginationState, current: 1 });
            }
        }
    }, [data]);

    if (error) {
        return <BadResponse title={intl.formatMessage({ id: '500.ooops' })} subtitle={intl.formatMessage({ id: '500.something_wrong' })} />;
    }

    //reuse previous data while loading
    let tableData = previousData
        ? previousData.eventPlayers
        : {
            items: [],
            pagination: {},
        };

    if (!loading && data) {
        tableData = data.eventPlayers;
    }

    const hasSubEventCartificate = subEvent.certificate.type !== MEDICAL_CERTIFICATE.TYPE.NONE;

    return (
        <Row>
            <Col xs={24}>
                <Collapse className="gx-panel gx-mb-3" defaultActiveKey={countActiveFilters(filters) > 0 ? ['1'] : null}>
                    <Collapse.Panel
                        header={
                            <Space className="gx-w-100 gx-justify-content-between">
                                <div className="">
                                    <IntlMessages id="common.filters" />
                                    <Badge className="gx-ml-2" count={countActiveFilters(filters, 0)} style={{ backgroundColor: PRIMARY_COLOR }} />
                                </div>
                            </Space>
                        }
                        size="small"
                        className="gx-panel"
                        key="1"
                        bordered={false}
                        extra={[
                            <Button
                                size="small"
                                key="reset-btn"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    setFilters(DEFAULT_FILTERS);
                                }}
                                className="gx-mb-0"
                            >
                                <CloseCircleOutlined className="gx-pr-1" />
                                <IntlMessages id="common.clear_filters" />
                            </Button>,
                        ]}
                    >
                        <Row>
                            <Col xs={24} md={24} lg={10} xl={8}>
                                <Input.Search
                                    style={{ minWidth: 300 }}
                                    value={filters?.term}
                                    onChange={(e) => {
                                        const { value } = e.target;
                                        updateFilters('term', value);
                                    }}
                                    placeholder={`${intl.formatMessage({ id: 'common.name' })}, ${intl.formatMessage({ id: 'common.email' })}`}
                                />
                            </Col>
                            <Col xs={24} md={24} lg={6} style={{ maxWidth: '100%' }}>
                                <Space {...getFilterButtonsSpaceProps(activeBreakpoint)}>
                                    <DropDownCheckboxFilter
                                        label={<IntlMessages id="common.confirm_subscription" />}
                                        options={[
                                            { label: <IntlMessages id="common.player_confirmed" />, value: true },
                                            { label: <IntlMessages id="common.player_not_confirmed" />, value: false },
                                        ]}
                                        values={filters?.confirmed}
                                        onChange={(values) => {
                                            updateFilters('confirmed', values);
                                        }}
                                    />

                                    {hasSubEventCartificate && (
                                        <DropDownCheckboxFilter
                                            label={<IntlMessages id="common.medical_certificate" />}
                                            options={[
                                                { label: <IntlMessages id="common.uploaded_and_confirmed" />, value: true },
                                                { label: <IntlMessages id="common.uploaded_and_not_confirmed" />, value: false },
                                            ]}
                                            values={filters?.certificate_confirmed}
                                            onChange={(values) => {
                                                updateFilters('certificate_confirmed', values);
                                            }}
                                        />
                                    )}

                                    {subEvent.predefined_fields?.athlete_card && subEvent.predefined_fields?.athlete_card.active && (
                                        <DropDownCheckboxFilter
                                            label={<IntlMessages id="common.athlete_card" />}
                                            options={[
                                                { label: <IntlMessages id={`common.fidal_validation_state_1`} />, value: 1 },
                                                { label: <IntlMessages id={`common.fidal_validation_state_2`} />, value: 2 },
                                                { label: <IntlMessages id={`common.fidal_validation_state_3`} />, value: 3 },
                                            ]}
                                            values={filters?.athlete_card_confirmed}
                                            onChange={(values) => {
                                                updateFilters('athlete_card_confirmed', values);
                                            }}
                                        />
                                    )}

                                    <DropDownCheckboxFilter
                                        label={<IntlMessages id="common.retired" />}
                                        options={[
                                            { label: <IntlMessages id="common.yes" />, value: true },
                                            //{ label: <IntlMessages id="common.no" />, value: false },
                                        ]}
                                        values={filters?.retired}
                                        onChange={(values) => {
                                            updateFilters('retired', values);
                                        }}
                                    />

                                    <DropDownCheckboxFilter
                                        label={<IntlMessages id="common.deleted" />}
                                        options={[{ label: <IntlMessages id="common.yes" />, value: true }]}
                                        values={filters?.deleted}
                                        onChange={(values) => {
                                            updateFilters('deleted', values);
                                        }}
                                    />
                                </Space>
                            </Col>
                        </Row>
                    </Collapse.Panel>
                </Collapse>
            </Col>

            <Col xs={24}>
                <Card
                    className="gx-card"
                    title={[
                        <Dropdown
                            disabled={selectedRowKeys.length === 0}
                            key="actions"
                            menu={{
                                items: getDropdownActionMenu({
                                    onClickConfirmations: onSubscriptionConfirmation,
                                    onClickConfirmCertificate: hasSubEventCartificate ? onClickConfirmCertificate : null,
                                }),
                            }}
                            trigger={'click'}
                            placement="bottom"
                            arrow
                        >
                            <Button className="gx-mb-0">
                                <IntlMessages id="common.actions_on_selected" />
                                &nbsp;({selectedRowKeys.length})
                            </Button>
                        </Dropdown>,
                    ]}
                    extra={
                        <Space>
                            {subEvent.predefined_fields?.athlete_card && subEvent.predefined_fields?.athlete_card.active && (
                                <Button loading={athleteCardValidationLoading} type="dashed" onClick={() => onValidateAthleteCards()}>
                                    <IntlMessages id="common.check_all_athlete_cards" />
                                </Button>
                            )}
                        </Space>
                    }
                >
                    <Table
                        scroll={{ x: 1000 }}
                        rowKey="id"
                        rowSelection={{
                            type: 'checkbox',
                            selectedRowKeys,
                            onChange: (selectedRowKeys, selectedRows) => {
                                setSelectedRowKeys(selectedRowKeys);
                            },
                        }}
                        onRow={(row) => {
                            return {
                                onClick: (e) => {
                                    if (includes(['XS', 'SM'], activeBreakpoint)) {
                                        return history.push(getAdminPath(`event/${subEvent.event.id}/sub-event/${subEvent.id}/subscriber/view/${row.id}`));
                                    }
                                },
                            };
                        }}
                        loading={loading}
                        dataSource={tableData.items}
                        columns={getColumns({ subEvent })}
                        pagination={{
                            ...paginationOptions,
                            total: tableData.pagination.total_items,
                            current: tableData.pagination.page,
                            pageSize: tableData.pagination.limit,
                            onChange: (current, pageSize) => {
                                setPaginationState({ current, pageSize });
                            },
                        }}
                    />
                </Card>
            </Col>
        </Row>
    );
};

const ExportSubscriptionsButton = ({ subEvent, type }) => {
    const intl = useIntl();

    const [exportSubscriptions, { loading }] = useLazyQuery(EXPORT_SUB_EVENT_SUBSCRIPTIONS, {
        fetchPolicy: 'no-cache',
    });

    return (
        <Button
            loading={loading}
            className={`${type !== 'content' ? 'gx-btn-warning' : ''}`}
            onClick={() => {
                exportSubscriptions({
                    variables: {
                        id: subEvent.id,
                        type: type,
                        locale: getSafeISOLanguage(intl.locale),
                    },
                })
                    .then(({ data }) => {
                        if (data?.exportSubEventSubscriptions) {
                            const { filename, content, url } = data.exportSubEventSubscriptions;

                            if (url) {
                                const link = document.createElement('a');
                                link.href = url;
                                link.download = true;
                                link.click();
                                link.remove();
                            } else if (filename && content) {
                                downloadBase64File(filename, content);
                            } else {
                                message.error(intl.formatMessage({ id: 'common.default_mutation_error' }));
                            }
                        } else {
                            message.error(intl.formatMessage({ id: 'common.default_mutation_error' }));
                        }
                    })
                    .catch((e) => defaultCatchException(e, intl));
            }}
        >
            <FileExcelOutlined /> <IntlMessages id="common.export_xls" />
            {type !== 'content' && <span>&nbsp;OLD</span>}
        </Button>
    );
};

const SubEventPlayers = ({ match }) => {
    const { params } = match;

    const [showPlayersSummary, setShowPlayersSummary] = useState(false);

    const intl = useIntl();

    const activeBreakpoint = useActiveBreakpoint();

    const { data, loading, error } = useQuery(SUB_EVENT_CONTAINER_DATA, {
        variables: {
            id: params.id,
        },
    });

    if (error) {
        return <BadResponse title={intl.formatMessage({ id: '500.ooops' })} subtitle={intl.formatMessage({ id: '500.something_wrong' })} />;
    }

    if (loading) {
        return <CircularProgress />;
    }

    if (!data || !data.subEvent) {
        return <Redirect to={getAdminPath(`event/${params.event_id}/sub-event/${params.id}`)} />;
    }

    const { subEvent } = data;

    return (
        <>
            <Row>
                <Col md={24}>
                    <SubEventBreadcrumbs subEvent={subEvent} section={<IntlMessages id={`common.players`} />} />
                </Col>
            </Row>

            <Row>
                <Col xs={24}>
                    <PageHeader
                        title={
                            <span>
                                <IntlMessages id="common.players" />
                                :&nbsp;{subEvent.name}
                            </span>
                        }
                        extra={
                            <Space>
                                <ExportSubscriptionsButton subEvent={subEvent} type="content" />
                                <Can I="manage" a="Organization">
                                    <ExportSubscriptionsButton subEvent={subEvent} type="url" />
                                </Can>
                                <Button
                                    icon={<UnorderedListOutlined className="gx-mr-2" />}
                                    type="primary"
                                    onClick={() => {
                                        setShowPlayersSummary(true);
                                    }}
                                >
                                    <IntlMessages id="common.players_summary" />
                                </Button>
                            </Space>
                        }
                    />
                </Col>
            </Row>

            <EventPlayersTable subEvent={data?.subEvent} intl={intl} />

            <Drawer
                title={<IntlMessages id="common.players_summary" />}
                placement="right"
                width={!includes(['XS', 'SM', 'MD'], activeBreakpoint) ? 800 : null}
                closable={true}
                onClose={() => {
                    setShowPlayersSummary(false);
                }}
                open={showPlayersSummary}
            >
                <PlayersSummary subEvent={data.subEvent} intl={intl} />
            </Drawer>
            <DevButton path={__filename} />
        </>
    );
};

export default SubEventPlayers;
