import { InfoCircleOutlined, BranchesOutlined, DeleteOutlined, DownOutlined } from '@ant-design/icons';
import { useMutation, useQuery } from '@apollo/client';
import { PageHeader, Breadcrumb, Row, Col, Card, Statistic, Tooltip, Tag, Dropdown, message, Modal, Button } from 'antd';
import React, { useContext } from 'react';
import { useIntl } from 'react-intl';
import { Link, useHistory } from 'react-router-dom';
import { SUB_EVENT_DASHBOARD } from '../../../../apollo/query/sub-event';
import BadResponse from '../../../../components/BadResponse';
import BreadcrumbHomeLink from '../../../../components/BreadcrumbHomeLink';
import CircularProgress from '../../../../components/CircularProgress';
import CardDescriptions, { CardDescriptionsItem } from '../../../../components/Descriptions/CardDescription';
import PlayersAffluenceChart from '../../../../components/PlayersAffluenceChart';
import { MEDICAL_CERTIFICATE } from '../../../../constants/App';
import IntlMessages from '../../../../util/IntlMessages';
import { getAdminPath } from '../../../../util/router';
import { formatCurrency } from '../../../../util/string';
import { getDashboardStats, PlayersCard } from '../../event/dashboard';
import { defaultCatchException, defaultMutationHandler } from '../../../../apollo/callbacks';
import { DELETE_SUB_EVENT, DUPLICATE_SUB_EVENT } from '../../../../apollo/mutation/sub-event';
import { MenuContext } from '../../../../contexts/MenuContext';

const getCertificateType = (certificate) => {
    if (!certificate || certificate.type === MEDICAL_CERTIFICATE.TYPE.NONE) {
        return <IntlMessages id="common.none" />;
    } else if (certificate.type === MEDICAL_CERTIFICATE.TYPE.HEALTH) {
        return <IntlMessages id="common.certificate_health" />;
    } else if (certificate.type === MEDICAL_CERTIFICATE.TYPE.COMPETITIVE) {
        return <IntlMessages id="common.certificate_competitive" />;
    }
    return null;
};

const SubEventDashboard = ({ match }) => {
    const { params } = match;

    const intl = useIntl();

    const history = useHistory();

    const { menu, setMenu } = useContext(MenuContext);

    const [duplicateSubEvent, { loading: duplicateEventLoading }] = useMutation(DUPLICATE_SUB_EVENT);
    const [deleteSubEvent, { loading: deleteEventLoading }] = useMutation(DELETE_SUB_EVENT);

    const { data, loading, error } = useQuery(SUB_EVENT_DASHBOARD, {
        variables: {
            event_id: params.event_id,
            id: params.id,
        },
    });

    if (error) {
        return <BadResponse title={intl.formatMessage({ id: '500.ooops' })} subtitle={intl.formatMessage({ id: '500.something_wrong' })} />;
    }

    if (loading) {
        return <CircularProgress />;
    }

    const { subEvent, playersAffluence } = data;

    if (!subEvent) {
        return <BadResponse title={intl.formatMessage({ id: '500.ooops' })} subtitle={intl.formatMessage({ id: '500.something_wrong' })} />;
    }

    const { event } = subEvent;

    const stats = getDashboardStats(data);

    const dropDownButtonItems = [
        {
            key: '2',
            label: (
                <a
                    onClick={(e) => {
                        e.preventDefault();
                        Modal.confirm({
                            title: intl.formatMessage(
                                { id: `common.duplicate_modal_title_type_${event.type}` },
                                {
                                    race: subEvent.name,
                                }
                            ),
                            content: intl.formatMessage(
                                { id: `common.duplicate_modal_text_type_${event.type}` },
                                {
                                    race: subEvent.name,
                                }
                            ),
                            okText: intl.formatMessage({ id: 'common.yes' }),
                            cancelText: intl.formatMessage({ id: 'common.no' }),
                            okButtonProps: {
                                loading: duplicateEventLoading,
                            },
                            onOk: () => {
                                duplicateSubEvent({ variables: { id: subEvent.id } })
                                    .then(
                                        defaultMutationHandler({
                                            successMessage: intl.formatMessage({ id: 'common.operation_successful' }),
                                            successCallback: (data) => {
                                                if (data && data.duplicateSubEvent) {
                                                    setMenu({
                                                        ...menu,
                                                        event: {
                                                            ...menu.event,
                                                            sub_events: [
                                                                ...menu.event.sub_events,
                                                                { id: data.duplicateSubEvent.id, name: data.duplicateSubEvent.name },
                                                            ],
                                                        },
                                                    });
                                                }
                                            },
                                            errorCallback: (errors) => {
                                                message.error(intl.formatMessage({ id: 'common.default_mutation_error' }));
                                            },
                                        })
                                    )
                                    .catch((e) => defaultCatchException(e, intl));
                            },
                        });
                    }}
                >
                    <BranchesOutlined />
                    &nbsp;
                    <IntlMessages id="common.duplicate_competition" />
                </a>
            ),
        },
        {
            key: '3',
            label: (
                <a
                    onClick={(e) => {
                        e.preventDefault();
                        Modal.confirm({
                            title: intl.formatMessage({ id: `common.delete_modal_title_type_${event.type}` }, { name: subEvent.name }),
                            content: intl.formatMessage({ id: `common.delete_modal_text_type_${event.type}` }),
                            okText: intl.formatMessage({ id: 'common.yes' }),
                            cancelText: intl.formatMessage({ id: 'common.no' }),
                            okButtonProps: {
                                loading: deleteEventLoading,
                            },
                            onOk: () => {
                                deleteSubEvent({ variables: { id: subEvent.id } })
                                    .then(
                                        defaultMutationHandler({
                                            successMessage: intl.formatMessage({ id: 'common.operation_successful' }),
                                            successCallback: () => {
                                                history.push(getAdminPath(`event/${event.id}/dashboard`));
                                                setMenu({
                                                    ...menu,
                                                    event: {
                                                        ...menu.event,
                                                        sub_events: menu.event.sub_events.filter((s) => s.id !== subEvent.id),
                                                    },
                                                });
                                            },
                                            errorCallback: (errors) => {
                                                if (errors && errors[0] && errors[0].message && intl.messages[errors[0].message]) {
                                                    message.error(intl.formatMessage({ id: errors[0].message }));
                                                } else {
                                                    message.error(intl.formatMessage({ id: 'common.default_mutation_error' }));
                                                }
                                            },
                                        })
                                    )
                                    .catch((e) => defaultCatchException(e, intl));
                            },
                        });
                    }}
                >
                    <DeleteOutlined />
                    &nbsp;
                    <IntlMessages id="common.delete" />
                </a>
            ),
        },
    ].filter((i) => Boolean(i.label));

    return (
        <>
            <Row>
                <Col md={24}>
                    <Breadcrumb className="gx-pb-3">
                        <Breadcrumb.Item>
                            <BreadcrumbHomeLink />
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>
                            <Link to={getAdminPath('dashboard')}>
                                <IntlMessages id="common.events" />
                            </Link>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>
                            <Link to={getAdminPath(`event/${event.id}/dashboard`)}>{event.name}</Link>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>{intl.formatMessage({ id: `common.event_type_${event.type}` })}</Breadcrumb.Item>
                        <Breadcrumb.Item href="#">{subEvent.name}</Breadcrumb.Item>
                    </Breadcrumb>
                </Col>
            </Row>
            <PageHeader
                title={
                    <span>
                        <IntlMessages id={`common.event_type_${event.type}`} />
                        &nbsp;{subEvent.name}
                    </span>
                }
                extra={[
                    <Dropdown menu={{ items: dropDownButtonItems }} placement="bottomRight" arrow>
                        <Button icon={<DownOutlined className="gx-pr-2" />} className="gx-mb-0">
                            <IntlMessages id="common.actions" />
                        </Button>
                    </Dropdown>,
                ]}
            />

            <Row gutter={16}>
                <Col xs={24}>
                    <Card
                        className="gx-card gx-w-100"
                        title={
                            <Tooltip title={<IntlMessages id="common.week_aggregation_tip" />}>
                                <IntlMessages id="common.athlete_affluence_by_week" /> <InfoCircleOutlined />
                            </Tooltip>
                        }
                    >
                        <PlayersAffluenceChart data={playersAffluence || []} />
                    </Card>
                </Col>
                <Col xs={24} md={12} lg={6}>
                    <PlayersCard event={event} stats={stats} loading={loading} />
                </Col>
                <Col xs={24} md={12} lg={6}>
                    <Card className="gx-card" title={<IntlMessages id="common.confirmed_players" />}>
                        <Statistic value={stats.playersConfirmed} precision={0} />
                    </Card>
                </Col>
                <Col xs={24} md={12} lg={6}>
                    <Card className="gx-card" title={<IntlMessages id="common.paying_subscribers" />}>
                        <Statistic value={stats.playersPaid} precision={0} />
                    </Card>
                </Col>
                <Col xs={24} md={12} lg={6}>
                    <Card className="gx-card" title={<IntlMessages id="common.revenues" />}>
                        <Statistic value={formatCurrency(stats.playersTotalPaid / 100, 2, event.payments?.location.currency.iso)} precision={2} />
                    </Card>
                </Col>
            </Row>

            <Row>
                <Col xs={24}>
                    <Card className="gx-card" title={<IntlMessages id="common.configuration" />}>
                        <CardDescriptions column={3} className="gx-ml-1">
                            <CardDescriptionsItem label={<IntlMessages id="common.name" />}>{subEvent.name}</CardDescriptionsItem>
                            <CardDescriptionsItem label={<IntlMessages id="common.has_teams" />}>
                                {subEvent.has_teams ? (
                                    <span>
                                        <Tag>
                                            <IntlMessages id="common.yes" />
                                        </Tag>
                                        <small>
                                            <IntlMessages id="common.team_components_number" />: {subEvent.team_players}
                                        </small>
                                    </span>
                                ) : (
                                    <IntlMessages id="common.no" />
                                )}
                            </CardDescriptionsItem>

                            <CardDescriptionsItem label={<IntlMessages id="common.medical_certificate" />}>
                                {getCertificateType(subEvent.certificate)}
                            </CardDescriptionsItem>

                            <CardDescriptionsItem label={<IntlMessages id="common.subscriptions_limit" />}>
                                {subEvent.subscriptions_limit || '-'}
                            </CardDescriptionsItem>

                            <CardDescriptionsItem label={<IntlMessages id="common.hide_players_from_starting_list" />}>
                                {subEvent.hide_starting_list ? <IntlMessages id="common.yes" /> : <IntlMessages id="common.no" />}
                            </CardDescriptionsItem>

                            <CardDescriptionsItem label={<IntlMessages id="common.manual_confirmation" />}>
                                {subEvent.manual_confirmation ? <IntlMessages id="common.yes" /> : <IntlMessages id="common.no" />}
                            </CardDescriptionsItem>
                        </CardDescriptions>
                    </Card>
                </Col>
            </Row>
        </>
    );
};

export default SubEventDashboard;
