import React from 'react';
import { imageTypeProps } from '../../constants/Asset';
import { find } from 'lodash';

const EventImage = ({ event, type, ...rest }) => {
    const imageProps = imageTypeProps[type];
    if (!imageProps) return <p className="gx-text-danger">Image type not found</p>;

    const placeholder = <img src={imageProps.placeholder} {...rest} />;

    if (!event.assets || event.assets.length === 0) {
        return placeholder;
    }

    const image = find(event.assets, (asset) => {
        return asset.context_type === type;
    });

    if (!image) {
        return placeholder;
    }

    return <img src={image.url} {...rest} />;
};

export default EventImage;
