import { useReactiveVar } from '@apollo/client';
import { Alert } from 'antd';
import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { clearErrors, serverErrorsVar } from '../../apollo/local/error';

/**
 * da deprecare??
 * @param {*} msg
 */
const cleanMessage = (msg) => {
    if (msg) {
        if (msg.indexOf('Error:') > -1) {
            msg = msg.replace('Error:', '');
        }
        msg = msg.trim();
    }
    return msg;
};

const ErrorsList = ({ items }) => {
    const intl = useIntl();

    const printMessage = (msg) => {
        const cleanMsg = cleanMessage(msg);

        if (intl.messages[cleanMsg]) {
            return intl.formatMessage({ id: cleanMsg });
        } else if (intl.messages[msg?.replace('server_error', 'common')]) {
            return intl.formatMessage({ id: msg?.replace('server_error', 'common') });
        } else {
            return intl.formatMessage({ id: 'common.default_mutation_error' });
        }
    };

    return (
        <ul className="ant-list">
            {items.map((e, i) => {
                return (
                    <li className="ant-list-item" key={`e${i}`}>
                        {printMessage(e.messageKey)}
                    </li>
                );
            })}
        </ul>
    );
};

const BadResponseErrorAlert = ({ className = '' }) => {
    const serverErrors = useReactiveVar(serverErrorsVar);

    const location = useLocation();

    useEffect(() => {
        //da testare, ritorno su pagina errore cancella errore precederente
        clearErrors();
    }, [location]);

    if (!serverErrors.hasErrors) return null;

    return (
        <Alert
            onClose={() => {
                clearErrors();
            }}
            className={className}
            closable={true}
            type="error"
            message={<ErrorsList {...serverErrors} />}
        />
    );
};

export default BadResponseErrorAlert;
