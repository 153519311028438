import './Input.less';
import * as React from 'react';

export default function TextInput({ label, value, onChange, placeholder = '', 'data-test-id': dataTestId }) {
    return (
        <div className="Input__wrapper">
            <label className="Input__label">{label}</label>
            <input
                type="text"
                className="Input__input"
                placeholder={placeholder}
                value={value}
                onChange={(e) => {
                    onChange(e.target.value);
                }}
                data-test-id={dataTestId}
            />
        </div>
    );
}
