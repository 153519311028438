import { Roles } from '../constants/Enums';
import { includes } from 'lodash';

/**
 * Determines if the user has emp access based on their role.
 * @param {Object} me - The user object.
 * @param {string} me.role - The role of the user.
 * @returns {boolean} - Whether or not the user has admin access.
 */
export const hasAdminAccess = (me) => {
    return includes([Roles.SUPERADMIN, Roles.ADMIN, Roles.MANAGER], me.role);
};

export const isSuperAdmin = (me) => {
    return hasRole(me, Roles.SUPERADMIN);
};

export const hasRole = (me, role) => {
    if (!me || !me.role) {
        return false;
    }

    return me.role === role;
};
