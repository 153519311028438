import { useMutation } from '@apollo/client';
import { Button, Modal, Alert, Typography, message } from 'antd';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { Can } from '../../../../acl';
import { defaultCatchException } from '../../../../apollo/callbacks';
import { PUBLISH_EVENT, UNPUBLISH_EVENT } from '../../../../apollo/mutation/event';
import IntlMessages from '../../../../util/IntlMessages';
import { EVENT } from '../../../../constants/App';

const PublishModalAlertContent = ({ messages }) => {
    return (
        <ul>
            {messages.map((message, index) => (
                <li key={index}>
                    <IntlMessages id={message.message} values={{ ...message.payload }} />
                </li>
            ))}
        </ul>
    );
};

const PublishModal = ({ state, onCancel, onClickPublish, loading }) => {
    if (!state) {
        return null;
    }

    return (
        <Modal
            title={<IntlMessages id="common.publish_competition" />}
            visible={state !== null}
            width={600}
            onCancel={onCancel}
            okButtonProps={{ loading: loading, disabled: state.errors.length > 0 }}
            onOk={() => {
                if (state.errors.length === 0) {
                    return onClickPublish(true);
                }
            }}
            okText={<IntlMessages id="common.publish" />}
        >
            {state.errors.length > 0 || state.warnings.length > 0 ? (
                <Typography.Paragraph>
                    <IntlMessages id="events.publish_confirm" />
                </Typography.Paragraph>
            ) : (
                <Typography.Paragraph>
                    <IntlMessages id="events.publish_confirm_without_warning" />
                </Typography.Paragraph>
            )}
            {state.errors?.length > 0 && (
                <Alert
                    showIcon
                    type="error"
                    message={<IntlMessages id="events.publish_confirm_errors" />}
                    description={<PublishModalAlertContent messages={state.errors} />}
                />
            )}

            {state.warnings?.length > 0 && (
                <Alert
                    showIcon
                    type="warning"
                    message={<IntlMessages id="events.publish_confirm_warnings" />}
                    description={<PublishModalAlertContent messages={state.warnings} />}
                />
            )}
        </Modal>
    );
};

const UnpublishModal = ({ state, onCancel, onClickUnpublish, loading }) => {
    if (!state) {
        return null;
    }

    return (
        <Modal
            title={<IntlMessages id="common.unpublish_competition" />}
            visible={state !== null}
            width={600}
            onCancel={onCancel}
            okButtonProps={{ loading: loading }}
            onOk={() => {
                return onClickUnpublish(true);
            }}
        >
            <Typography.Paragraph>
                <IntlMessages id="common.unpublish_competition_text" />
            </Typography.Paragraph>
        </Modal>
    );
};

//add a modal
//add a form
const PublishButton = ({ event, onSuccess, className = '' }) => {
    const intl = useIntl();

    const [publishState, setPublishState] = useState(null);

    const [publishEvent, { loading }] = useMutation(PUBLISH_EVENT);
    const [unpublishEvent, { loading: unpublishLoading }] = useMutation(UNPUBLISH_EVENT);

    const onClickPublish = (confirm) => {
        publishEvent({ variables: { id: event.id, confirm: confirm } })
            .then(({ data, errors }) => {
                if (errors && errors.length > 0) {
                    defaultCatchException(errors[0], intl);
                } else if (!confirm) {
                    setPublishState(data.publishEvent);
                } else if (confirm) {
                    setPublishState(null);
                    message.success(intl.formatMessage({ id: 'common.competition_published' }));
                    onSuccess();
                }
            })
            .catch((error) => {
                defaultCatchException(error, intl);
            });
    };

    const onClickUnpublish = () => {
        unpublishEvent({ variables: { id: event.id } })
            .then(({ data, errors }) => {
                if (errors && errors.length > 0) {
                    defaultCatchException(errors[0], intl);
                } else {
                    setPublishState(null);
                    message.success(intl.formatMessage({ id: 'common.competition_unpublished' }));
                    onSuccess();
                }
            })
            .catch((error) => {
                defaultCatchException(error, intl);
            });
    };

    if (event.publish?.state === EVENT.PUBLISH_STATE.PUBLISHED) {
        return (
            <Can I="manage" a="Event">
                <Button className={`ant-btn ant-btn-secondary ${className}`} onClick={() => onClickPublish(false)}>
                    <IntlMessages id="common.unpublish_competition" />
                </Button>

                <UnpublishModal
                    loading={unpublishLoading}
                    state={publishState}
                    onClickUnpublish={onClickUnpublish}
                    onCancel={() => {
                        setPublishState(null);
                    }}
                />
            </Can>
        );
    } else {
        return (
            <Can I="manage" a="Event">
                <Button className={`ant-btn ant-btn-secondary ${className}`} onClick={() => onClickPublish(false)}>
                    <IntlMessages id="common.publish" />
                </Button>

                <PublishModal
                    loading={loading}
                    state={publishState}
                    onClickPublish={onClickPublish}
                    onCancel={() => {
                        setPublishState(null);
                    }}
                />
            </Can>
        );
    }
};

export default PublishButton;
