import { startsWith } from 'lodash';
const { ADMIN_ROUTE_PREFIX } = require('../constants/App');

export const getAdminPath = (relativePath, queryObject = null) => {
    const queryString = queryObject ? `?${new URLSearchParams(queryObject).toString()}` : '';

    return `/${ADMIN_ROUTE_PREFIX}${relativePath ? `/${relativePath}` : ''}${queryString}`;
};

export const getFrontendURL = (relativePath = null) => {
    return `${process.env.REACT_APP_FRONTEND_URL}${relativePath ? `/${relativePath}` : ''}`;
};

export const pushNextOrFallback = (history, fallback) => {
    if (history.location.search) {
        const qParams = new URLSearchParams(history.location.search);
        const nextURL = qParams.get('next');

        if (nextURL) {
            history.push(nextURL);
            return;
        }
    }

    if (fallback) {
        history.push(fallback);
    } else {
        history.push('/');
    }
};

export const requestByEmailHref = (text = '', tracker = null) => {
    if (tracker) {
        tracker();
    }

    const senderMail = process.env.REACT_APP_MAIL;
    return `mailto:${senderMail}?subject=${encodeURIComponent(text)}`;
};

export const getProfileImage = (userId, format = '40x40', addTime = false) => {
    let baseUrl = `${process.env.REACT_APP_MEDIA_URL}`;

    let path;
    if (userId) {
        path = baseUrl + '/profiles/' + idToPath(userId) + '/' + userId + '.jpg';
    } else {
        path = `${baseUrl}/profiles/404.jpg`;
    }

    path = addSizeToFilename(path, format);

    if (addTime) {
        path += '?t=' + Date.now();
    }

    return path;
};

export const addSizeToFilename = (image, format) => {
    image = image || '';
    if (!image.length) return false;

    var filename = image.substring(0, image.lastIndexOf('.'));
    var extension = image.substring(image.lastIndexOf('.'));
    const path = filename + '-' + format + extension;
    return path;
};

export const idToPath = (id) => {
    return (id % 100) + '/' + (Math.floor(id / 100) % 100);
};

//remove sub-event => get event base route
export const parentRoute = (uri) => {
    if (!uri) return '/';
    const u = uri.split('/').slice(0, -1).join('/');
    return u;
};

const isEventRoute = (uri) => {
    const re = new RegExp(`^/${ADMIN_ROUTE_PREFIX}/event/[0-9a-z]+`);
    return re.test(uri);
};

const isSubEventRoute = (uri) => {
    const re = new RegExp(`^/${ADMIN_ROUTE_PREFIX}/event/[0-9a-z]+/sub-event/[0-9a-z]+`);
    return re.test(uri);
};

const eventBaseSubRoute = (uri) => {
    if (!uri) return '/';

    const re = new RegExp(`^/${ADMIN_ROUTE_PREFIX}/event/[0-9a-z]+/sub-event/[0-9a-z]+`);

    const result = uri.match(re);

    if (result && result.length > 0) {
        return result[0];
    }

    return '/';
};

export const eventBaseRoute = (uri) => {
    if (!uri) return '/';

    const re = new RegExp(`^/${ADMIN_ROUTE_PREFIX}/event/[0-9a-z]+`);

    const result = uri.match(re);

    if (result && result.length > 0) {
        return result[0];
    }

    return '/';
};

export const getOpenedMenuByUri = (uri) => {
    if (!uri) return '/';

    if (isSubEventRoute(uri)) {
        return eventBaseSubRoute(uri);
    } else if (isEventRoute(uri)) {
        return eventBaseRoute(uri);
    }

    return '/';
};

export const getMediaURL = (path) => {
    if (startsWith(path, 'media/')) {
        path = path.replace('media/', '/');
    }

    return `${process.env.REACT_APP_MEDIA_URL}${path}`;
};
