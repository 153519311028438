import React, { useEffect, useState } from 'react';
import { Menu } from 'antd';
import { THEME_TYPE_LITE } from '../../constants/ThemeSetting';
import { Can } from '../../acl';
import { HomeOutlined, TeamOutlined } from '@ant-design/icons';
import { Link, useLocation } from 'react-router-dom';
import { getAdminPath, getOpenedMenuByUri } from '../../util/router';
import IntlMessages from '../../util/IntlMessages';
import { globalSettingsVar } from '../../apollo/local/cache';

const SidebarMenu = ({ themeType, drawerWrapped }) => {
    const adminPath = getAdminPath();

    const [menuSelectedKeys, setMenuSelectedKeys] = useState([]);

    const location = useLocation();

    useEffect(() => {
        setMenuSelectedKeys([getOpenedMenuByUri(location.pathname)]);
    }, [location]);

    const linkProps = drawerWrapped ? { onClick: () => globalSettingsVar({ ...globalSettingsVar(), navCollapsed: false }) } : {};

    return (
        <Menu
            selectedKeys={menuSelectedKeys}
            openKeys={menuSelectedKeys}
            onOpenChange={(openKeys) => {
                if (!openKeys || openKeys.length === 0) {
                    setMenuSelectedKeys([]);
                } else {
                    setMenuSelectedKeys(openKeys);
                }
            }}
            theme={themeType === THEME_TYPE_LITE ? 'lite' : 'dark'}
            mode="inline"
            style={{ overflowX: 'hidden' }}
        >
            <Menu.Item key={`${adminPath}/management/dashboard`} icon={<HomeOutlined />}>
                <Can I="read" a="Event">
                    <Link to={`${adminPath}/management/dashboard`} {...linkProps}>
                        <IntlMessages id="common.admin_competitions" />
                    </Link>
                </Can>
            </Menu.Item>
            <Menu.Item key={`${adminPath}/management/profiles`} icon={<TeamOutlined />}>
                <Can I="read" a="Profile">
                    <Link to={`${adminPath}/management/profiles`} {...linkProps}>
                        <IntlMessages id="common.user_profiles" />
                    </Link>
                </Can>
            </Menu.Item>
        </Menu>
    );
};

export default SidebarMenu;
