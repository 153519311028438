import { keys, map, isArray, isString } from 'lodash';

export const keyByValue = (obj, value) => {
    let swappedObj = {};
    keys(obj).forEach((k) => {
        swappedObj[obj[k]] = k;
    });

    return swappedObj[value];
};

export const countActiveFilters = (filtersState, offset = 0) => {
    return (
        keys(filtersState).filter((k) => (isArray(filtersState[k]) && filtersState[k].length > 0) || (!isArray(filtersState[k]) && filtersState[k])).length -
        offset
    );
};

export const cleanObject = (obj) => {
    let cleaned = {};
    map(obj, (value, key) => {
        if (value && key !== '__typename') {
            cleaned[key] = value;
        }
    });

    return cleaned;
};

export function cleanObjectDeep(input) {
    let newish = { ...input };

    for (let prop in newish) {
        if (prop === '__typename') {
            delete newish[prop];
        } else if (isArray(newish[prop])) {

            if (newish[prop].length === 0) {
                continue;
            }

            //is array of strings
            if (isString(newish[prop][0])) {
                newish[prop] = newish[prop].concat();
            } else {
                newish[prop] = [...newish[prop]];
                for (let next in newish[prop]) {
                    newish[prop][next] = cleanObjectDeep(newish[prop][next]);
                }
            }
        } else if (newish[prop] !== null && typeof newish[prop] === 'object') {
            //null is object :/
            newish[prop] = cleanObjectDeep(newish[prop]);
        }
    }

    return newish;
}
