import {
    BankOutlined,
    DeleteOutlined,
    FilePdfOutlined,
    InfoCircleOutlined,
    MailOutlined,
    RocketOutlined,
    UnorderedListOutlined,
    UserOutlined,
} from '@ant-design/icons';
import { useMutation, useQuery } from '@apollo/client';
import {
    Modal,
    PageHeader,
    Row,
    Card,
    Col,
    Breadcrumb,
    message,
    Tag,
    Button,
    List,
    Form,
    DatePicker,
    Input,
    Space,
    Alert,
    Popover,
    Empty,
    Tooltip,
    Checkbox,
} from 'antd';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { Link, Redirect, useHistory } from 'react-router-dom';
import { EVENT_PAYMENT } from '../../../../apollo/query/event';
import BadResponse from '../../../../components/BadResponse';
import BreadcrumbHomeLink from '../../../../components/BreadcrumbHomeLink';
import CircularProgress from '../../../../components/CircularProgress';
import CommunicationCard from '../../../../components/CommunicationCard';
import CardDescriptions, { CardDescriptionsItem } from '../../../../components/Descriptions/CardDescription';
import PaymentTypeIcon from '../../../../components/Payment/PaymentTypeIcon';
import UserAvatar from '../../../../components/UserAvatar';
import IntlMessages from '../../../../util/IntlMessages';
import { getAdminPath, getMediaURL } from '../../../../util/router';
import { formatCurrency, parseJSONSafe } from '../../../../util/string';
import {
    SUBSCRIPTION_PAYMENT_REMINDERS,
    SUBSCRIPTION_PAYMENT_CONFIRM,
    RESET_BOLETO_PAYMENT,
    DELETE_EVENT_PAYMENT,
    RESTORE_EVENT_PAYMENT,
} from '../../../../apollo/mutation/event';
import { defaultCatchException, defaultMutationCallback, defaultMutationHandler } from '../../../../apollo/callbacks';
import { DATE_FORMAT, PAYMENTS } from '../../../../constants/App';
import moment from 'moment';
import { requiredRule } from '../../../../util/form';
import { formatDate, formatDateTime } from '../../../../util/date';
import DevButton from '../../../../components/Dev/DevButton';
import { faBarcode } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import BadResponseErrorAlert from '../../../../components/BadResponseErrorAlert';
import { Can } from '../../../../acl';
import { useActiveBreakpoint } from '../../../../hooks/useActiveBreakpoint';
import { includes } from 'lodash';

const PaymentType = ({ transaction }) => {
    const getColorFromState = (status) => {
        switch (status) {
            case 'new':
                return 'blue';
            case 'waiting':
                return 'warning';
            case 'unpaid':
            case 'canceled':
                return 'error';
            case 'paid':
                return 'success';
            default:
                return 'blue';
        }
    };

    if (!transaction) {
        return null;
    }

    const payoff = parseJSONSafe(transaction.payoff);

    if (transaction.type === PAYMENTS.BOLETO) {
        let stateInfoComponent = null;
        let chargeLink = null;

        if (payoff && payoff.notifications && payoff.notifications.length > 0) {
            stateInfoComponent = (
                <Popover
                    title={<IntlMessages id={`common.state`} />}
                    content={
                        payoff && payoff.notifications ? (
                            <span>
                                <ul class="ant-list">
                                    {payoff.notifications.map((notification) => {
                                        const color = getColorFromState(notification.current_status);
                                        return (
                                            <li className="ant-list-item">
                                                <span className="gx-pr-2">{formatDateTime(notification.created_at)}</span>
                                                <Tag color={color}>
                                                    <IntlMessages id={`common.${notification.current_status}`} />
                                                </Tag>
                                            </li>
                                        );
                                    })}
                                </ul>
                            </span>
                        ) : (
                            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                        )
                    }
                >
                    <InfoCircleOutlined className="gx-pl-1" />
                </Popover>
            );
        }

        if (payoff && payoff.charge?.link) {
            chargeLink = (
                <Tooltip title={<IntlMessages id="common.view" />}>
                    <a href={payoff.charge.link} target="_blank" className="gx-pl-2" rel="noreferrer">
                        <FilePdfOutlined />
                    </a>
                </Tooltip>
            );
        }

        return (
            <span>
                <IntlMessages id={`common.payment_type_${transaction.type}`} />
                {stateInfoComponent}
                {chargeLink}
            </span>
        );
    } else {
        return (
            <span>
                <IntlMessages id={`common.payment_type_${transaction.type}`} />
            </span>
        );
    }
};

const ConfirmBankPaymentModal = ({ visible, payment, onCancel, intl, refetch }) => {
    const [form] = Form.useForm();

    const [subscriptionPaymentConfirm, { loading: confirmationLoading }] = useMutation(SUBSCRIPTION_PAYMENT_CONFIRM);

    //TODO sviluppare lato server
    const onSubmit = (values) => {
        subscriptionPaymentConfirm({
            variables: {
                id: payment.id,
                input: {
                    date: values.date.format('YYYY-MM-DD'),
                    transaction_id: values.transaction_id,
                },
            },
        })
            .then(
                defaultMutationHandler({
                    successMessage: intl.formatMessage({ id: 'common.operation_successful' }),
                    successCallback: () => {
                        refetch();
                        form.resetFields();
                        onCancel();
                    },
                    errorMessage: intl.formatMessage({ id: 'common.default_mutation_error' }),
                })
            )
            .catch((e) => defaultCatchException(e, intl));
    };

    return (
        <Modal
            title={<IntlMessages id="common.confirm_bank_payment" />}
            okText={<IntlMessages id="common.confirm" />}
            okButtonProps={{ loading: confirmationLoading }}
            visible={visible}
            onOk={() => form.submit()}
            onCancel={onCancel}
        >
            <IntlMessages id="common.confirm_bank_payment_modal_text" />

            <Form
                form={form}
                className="gx-mt-4"
                layout="vertical"
                initialValues={{
                    date: moment(),
                }}
                onFinish={onSubmit}
            >
                <Form.Item label={<IntlMessages id="common.date" />} name="date" rules={[requiredRule(intl)]}>
                    <DatePicker format={DATE_FORMAT} />
                </Form.Item>

                <Form.Item label={<IntlMessages id="common.transfer_code" />} name="transaction_id" rules={[requiredRule(intl)]}>
                    <Input />
                </Form.Item>
            </Form>
        </Modal>
    );
};

const ResetBoletoModal = ({ visible, payment, boletoPayoff, onCancel, intl, refetch }) => {
    const [resetBoletoPayment, { loading: resetBoletoLoading }] = useMutation(RESET_BOLETO_PAYMENT);

    const [sendEmailToPayer, setSendEmailToPayer] = useState(false);

    let expireDate = moment().add(-1, 'days').format(DATE_FORMAT);

    if (boletoPayoff && boletoPayoff.charge?.expire_at) {
        expireDate = moment(boletoPayoff.charge?.expire_at, 'YYYY-MM-DD').format(DATE_FORMAT);
    }

    return (
        <Modal
            visible={visible}
            title={<IntlMessages id="common.reset_boleto_modal_title" />}
            onCancel={onCancel}
            okButtonProps={{ loading: resetBoletoLoading }}
            onOk={() => {
                resetBoletoPayment({
                    variables: {
                        id: payment.id,
                        notify_payer: sendEmailToPayer,
                    },
                })
                    .then(
                        defaultMutationHandler({
                            successCallback: () => {
                                message.success(intl.formatMessage({ id: 'common.operation_successful' }));
                                refetch();
                            },
                            errorMessage: intl.formatMessage({ id: 'common.default_mutation_error' }),
                        })
                    )
                    .catch((e) => defaultCatchException(e, intl));
            }}
        >
            <IntlMessages id="common.reset_boleto_modal_text" values={{ value: expireDate }} />

            <div className="gx-mt-4">
                <Checkbox
                    checked={sendEmailToPayer}
                    onChange={(e) => {
                        setSendEmailToPayer(e.target.checked);
                    }}
                >
                    <IntlMessages id="common.send_email_to_payer" />
                </Checkbox>
            </div>
        </Modal>
    );
};

//TODO se già cancellato delete hard altrimenti soft
const DeletePaymentModal = ({ visible, payment, onCancel, intl, refetch }) => {
    const [deleteEventPayment, { loading: deleteLoading }] = useMutation(DELETE_EVENT_PAYMENT);

    const [sendEmailToPayer, setSendEmailToPayer] = useState(false);

    return (
        <Modal
            visible={visible}
            title={<IntlMessages id="common.payment_delete_modal_title" />}
            onCancel={onCancel}
            okButtonProps={{ loading: deleteLoading }}
            onOk={() => {
                deleteEventPayment({
                    variables: {
                        id: payment.id,
                        notify: sendEmailToPayer,
                    },
                })
                    .then(
                        defaultMutationHandler({
                            successCallback: () => {
                                message.success(intl.formatMessage({ id: 'common.operation_successful' }));
                                refetch();
                                onCancel();
                            },
                        })
                    )
                    .catch((e) => defaultCatchException(e, intl));
            }}
        >
            <BadResponseErrorAlert className="gx-mb-3" />

            <IntlMessages id="common.payment_delete_modal_text_v2_soft" />

            <div className="gx-mt-4">
                <Checkbox
                    checked={sendEmailToPayer}
                    onChange={(e) => {
                        setSendEmailToPayer(e.target.checked);
                    }}
                >
                    <IntlMessages id="common.player_delete_send_mail_checkbox" />
                </Checkbox>
            </div>
        </Modal>
    );
};

const ViewEventPayment = ({ match }) => {
    const { params } = match;

    const intl = useIntl();
    const history = useHistory();

    const activeBreakpoint = useActiveBreakpoint();

    const [isConfirmBankPaymentModalVisible, setConfirmBankPaymentModalVisible] = React.useState(false);
    const [isResetBoletoModalVisible, setResetBoletoModalVisible] = React.useState(false);
    const [isDeletePaymentModalVisible, setDeletePaymentModalVisible] = React.useState(false);

    const [subscriptionPaymentReminders, { loading: confirmationLoading }] = useMutation(SUBSCRIPTION_PAYMENT_REMINDERS);
    const [restoreEventPayment, { loading: restoreLoading }] = useMutation(RESTORE_EVENT_PAYMENT);

    const { data, loading, error, refetch } = useQuery(EVENT_PAYMENT, {
        variables: {
            id: params.id,
            event_id: params.event_id,
        },
    });

    if (error) {
        return <BadResponse title={intl.formatMessage({ id: '500.ooops' })} subtitle={intl.formatMessage({ id: '500.something_wrong' })} />;
    }

    const onRemindPayment = (payment) => {
        Modal.confirm({
            title: intl.formatMessage({ id: 'common.payment_remainder_modal_title' }),
            content: intl.formatMessage({ id: 'common.payment_remainder_modal_text' }),
            okText: intl.formatMessage({ id: 'common.confirm' }),
            cancelText: intl.formatMessage({ id: 'common.cancel' }),
            okButtonProps: {
                loading: confirmationLoading,
            },
            onOk: () => {
                subscriptionPaymentReminders({
                    variables: {
                        input: [payment.id],
                    },
                })
                    .then(
                        defaultMutationHandler({
                            successCallback: () => {
                                message.success(intl.formatMessage({ id: 'common.multi_remainder_email_sent' }));
                                refetch();
                            },
                            errorMessage: intl.formatMessage({ id: 'common.default_mutation_error' }),
                        })
                    )
                    .catch((e) => defaultCatchException(e, intl));
            },
        });
    };

    const onRestorePayment = () => {
        Modal.confirm({
            title: intl.formatMessage({ id: 'common.restore' }),
            okText: intl.formatMessage({ id: 'common.restore' }),
            cancelText: intl.formatMessage({ id: 'common.cancel' }),
            okButtonProps: {
                loading: restoreLoading,
            },
            onOk() {
                restoreEventPayment({
                    variables: {
                        id: eventPayment.id,
                    },
                })
                    .then((response) =>
                        defaultMutationCallback(response, () => {
                            refetch();
                        })
                    )
                    .catch((e) => defaultCatchException(e, intl));
            },
        });
    };

    if (loading) {
        return <CircularProgress />;
    }

    if (!data || !data.eventPayment) {
        return <Redirect to={getAdminPath(`event/${params.event_id}/payments/${params.event_id}`)} />;
    }

    const { event, eventPayment } = data;

    const { currency } = eventPayment.paymentLocation;

    const subscriptionPaid = Boolean(eventPayment.transaction?.paid_at);

    const boletoPayoff = parseJSONSafe(eventPayment?.transaction?.payoff);

    const canResetBoleto = () => {
        // funzionalità disabilitata
        return false;

        if (eventPayment.transaction?.type !== PAYMENTS.BOLETO) {
            return false;
        }

        const hasBoletoLink = boletoPayoff?.charge?.link;

        const expireAt = boletoPayoff?.charge.expire_at;

        let isExpired = false;

        if (expireAt) {
            const format = 'YYYY-MM-DD';
            isExpired = moment(expireAt, format).isBefore(moment().format(format));
        }

        return !subscriptionPaid && hasBoletoLink && isExpired;
    };

    return (
        <>
            <Row>
                <Col md={24}>
                    <Breadcrumb className="gx-pb-3">
                        <Breadcrumb.Item>
                            <BreadcrumbHomeLink />
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>{event.name}</Breadcrumb.Item>
                        <Breadcrumb.Item>
                            <IntlMessages id="common.payment" />
                        </Breadcrumb.Item>
                    </Breadcrumb>
                </Col>
            </Row>

            <Row>
                <Col xs={24}>
                    <PageHeader
                        onBack={() => history.goBack()}
                        title={
                            <span>
                                <IntlMessages id="common.payment" />
                                &nbsp;<small>#{eventPayment.tickets_id}</small>
                            </span>
                        }
                        extra={[
                            <a
                                className="ant-btn"
                                key="sheet"
                                href={`${process.env.REACT_APP_FRONTEND_URL}/tickets/${eventPayment.tickets_id}?pk=${eventPayment.private_key}`}
                                target="_blank"
                                rel="noreferrer"
                            >
                                <UserOutlined /> <IntlMessages id="common.payment_sheet" />
                            </a>,
                            <Link key="list" className="ant-btn" to={getAdminPath(`event/${event.id}/payments`)}>
                                <UnorderedListOutlined /> <IntlMessages id="common.payments" />
                            </Link>,
                            <Button
                                disabled={eventPayment.deleted_at}
                                icon={<DeleteOutlined className="gx-pr-1" />}
                                className="gx-btn-danger"
                                onClick={() => {
                                    setDeletePaymentModalVisible(true);
                                }}
                            >
                                <IntlMessages id="common.delete" />
                            </Button>,
                        ]}
                    />
                </Col>
                {eventPayment.deleted_at && (
                    <Col xs={24}>
                        <Alert
                            message={
                                <span>
                                    <IntlMessages id="common.payment_deleted_alert" values={{ date: formatDateTime(eventPayment.deleted_at) }} />
                                    <br />
                                    <Tag className="gx-bg-warning">
                                        <IntlMessages id={`common.${eventPayment.order_state}`} />
                                    </Tag>
                                </span>
                            }
                            type="warning"
                            showIcon
                            className="gx-mb-3"
                            action={
                                <Can I="manage" a="Organization">
                                    <Button
                                        onClick={() => {
                                            onRestorePayment(eventPayment);
                                        }}
                                        icon={<RocketOutlined className="gx-pr-1" />}
                                        size="small"
                                        danger
                                    >
                                        <IntlMessages id="common.restore" />
                                    </Button>
                                </Can>
                            }
                        />
                    </Col>
                )}
                <Col xs={24} md={6}>
                    <Card title={<IntlMessages id="common.payer" />} className="gx-card">
                        {eventPayment.payer ? <span>{eventPayment.payer.full_name}</span> : '-'}
                    </Card>
                </Col>
                <Col xs={24} md={6}>
                    <Card title={<IntlMessages id="common.players_number" />} className="gx-card">
                        {eventPayment.players ? <span>{eventPayment.players?.length}</span> : '-'}
                    </Card>
                </Col>
                <Col xs={24} md={6}>
                    <Card
                        title={<IntlMessages id="common.payment" />}
                        className={`gx-card ${subscriptionPaid ? 'gx-card-success' : 'gx-card-warning'}`}
                        extra={[<PaymentTypeIcon transaction={eventPayment.transaction} />]}
                    >
                        {eventPayment.transaction ? (
                            <span>
                                <IntlMessages id={`common.payment_type_${eventPayment.transaction.type}`} />
                                <br />
                                <IntlMessages id={`common.${!Boolean(eventPayment.transaction.paid_at) ? 'not_' : ''}paid`} />
                                <br />
                            </span>
                        ) : (
                            <span>
                                <IntlMessages id={`common.not_paid`} />
                                <br />
                            </span>
                        )}
                    </Card>
                </Col>
                <Col xs={24} md={6}>
                    <Card title={<IntlMessages id="common.total" />} className="gx-card">
                        {eventPayment.transaction ? <span>{formatCurrency(eventPayment.transaction.total / 100, 2, currency.iso)}</span> : '-'}
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col xs={24} md={12}>
                    <Card
                        title={<IntlMessages id="common.payment" />}
                        className="gx-card"
                        actions={[
                            eventPayment.receipt ? (
                                <a
                                    key="receipt"
                                    href={getMediaURL(
                                        `/competitions/receipt/${eventPayment.receipt.keepsporting_organization_id}/${eventPayment.receipt.filename}`
                                    )}
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <IntlMessages id="common.receipt" />
                                </a>
                            ) : null,
                            !subscriptionPaid && eventPayment.transaction?.type === PAYMENTS.BANK ? (
                                <Button
                                    className="gx-mb-0"
                                    key="bank_payment"
                                    icon={<BankOutlined className="gx-pr-1" />}
                                    onClick={() => setConfirmBankPaymentModalVisible(true)}
                                >
                                    <IntlMessages id="common.confirm_payment" />
                                </Button>
                            ) : null,

                            canResetBoleto() ? (
                                <Button
                                    className="gx-mb-0"
                                    key="boleto_payment"
                                    icon={<FontAwesomeIcon className="gx-fs-lg gx-pr-1" icon={faBarcode} />}
                                    onClick={() => setResetBoletoModalVisible(true)}
                                >
                                    <IntlMessages id="common.reset_boleto" />
                                </Button>
                            ) : null,
                        ].filter(Boolean)}
                    >
                        <CardDescriptions column={includes(['XS', 'LG'], activeBreakpoint) ? 1 : 2} className="gx-mx-0">
                            <CardDescriptionsItem label={<IntlMessages id="common.tickets_id" />}>{eventPayment.tickets_id}</CardDescriptionsItem>
                            <CardDescriptionsItem label={<IntlMessages id="common.payer" />}>{eventPayment.payer.full_name}</CardDescriptionsItem>
                            <CardDescriptionsItem label={<IntlMessages id="common.email" />}>{eventPayment.payer.email}</CardDescriptionsItem>
                            {eventPayment.payer.phone && (
                                <CardDescriptionsItem label={<IntlMessages id="common.phone" />}>{eventPayment.payer.phone}</CardDescriptionsItem>
                            )}
                            {eventPayment.payer.gender && (
                                <CardDescriptionsItem label={<IntlMessages id="common.gender" />}>{eventPayment.payer.gender}</CardDescriptionsItem>
                            )}
                            <CardDescriptionsItem label={<IntlMessages id="common.subscription_paid" />}>
                                {subscriptionPaid ? (
                                    <Space>
                                        <Tag color="success">
                                            <IntlMessages id="common.yes" /> | <IntlMessages id="common.date" />
                                            :&nbsp;
                                            <span>{formatDate(eventPayment.transaction.paid_at)}</span>
                                        </Tag>
                                    </Space>
                                ) : (
                                    <Tag color="warning">
                                        <IntlMessages id="common.no" />
                                    </Tag>
                                )}
                            </CardDescriptionsItem>

                            {eventPayment.transaction && (
                                <>
                                    <CardDescriptionsItem label={<IntlMessages id="common.payment_type" />}>
                                        <PaymentType transaction={eventPayment.transaction} />
                                    </CardDescriptionsItem>
                                    {eventPayment.bank_code && (
                                        <CardDescriptionsItem label={<IntlMessages id="common.bank_code" />}>{eventPayment.bank_code}</CardDescriptionsItem>
                                    )}
                                    <CardDescriptionsItem label={<IntlMessages id="common.payment_id" />}>
                                        #{eventPayment.transaction.payment_id}
                                    </CardDescriptionsItem>
                                    <CardDescriptionsItem column={1} label={<IntlMessages id="common.price" />}>
                                        {formatCurrency(eventPayment.transaction.price / 100, 2, currency.iso)}
                                    </CardDescriptionsItem>
                                    <CardDescriptionsItem column={1} label={<IntlMessages id="common.additions" />}>
                                        {formatCurrency(eventPayment.transaction.additions / 100, 2, currency.iso)}
                                    </CardDescriptionsItem>
                                    <CardDescriptionsItem column={1} label={<IntlMessages id="common.subtotal" />}>
                                        {formatCurrency(eventPayment.transaction.subtotal / 100, 2, currency.iso)}
                                    </CardDescriptionsItem>
                                    <CardDescriptionsItem column={1} label={<IntlMessages id="common.fee" />}>
                                        {formatCurrency(eventPayment.transaction.fee / 100, 2, currency.iso)}
                                        <br />
                                        {eventPayment.transaction.fee_to_org ? (
                                            <IntlMessages id="common.fee_to_org" />
                                        ) : (
                                            <IntlMessages id="common.fee_to_athlete" />
                                        )}
                                    </CardDescriptionsItem>
                                    <CardDescriptionsItem column={1} label={<IntlMessages id="common.discount" />}>
                                        {formatCurrency(eventPayment.transaction.discount / 100, 2, currency.iso)}
                                    </CardDescriptionsItem>
                                    <CardDescriptionsItem column={1} label={<IntlMessages id="common.total" />}>
                                        {formatCurrency(eventPayment.transaction.total / 100, 2, currency.iso)}
                                    </CardDescriptionsItem>
                                </>
                            )}
                        </CardDescriptions>
                    </Card>
                    <CommunicationCard
                        communications={eventPayment?.communications}
                        actions={[
                            !subscriptionPaid ? (
                                <Button
                                    className="gx-mb-0"
                                    key="payment_reminder"
                                    icon={<MailOutlined className="gx-pr-1" />}
                                    onClick={() => onRemindPayment(eventPayment)}
                                >
                                    <IntlMessages id="common.remainder_email" />
                                </Button>
                            ) : null,
                        ].filter(Boolean)}
                    />
                </Col>
                <Col xs={24} md={12}>
                    <Card title={<IntlMessages id="common.players" />} className="gx-card">
                        <List
                            dataSource={eventPayment.players}
                            renderItem={(player) => {
                                const viewUrl = getAdminPath(`event/${event.id}/sub-event/${player.sub_event.id}/subscriber/view/${player.id}`);
                                return (
                                    <List.Item
                                        actions={[
                                            <Link key="list-loadmore-more" to={viewUrl}>
                                                <IntlMessages id="common.view" />
                                            </Link>,
                                            <Link
                                                to={getAdminPath(`event/${event.id}/sub-event/${player.sub_event.id}/subscriber/update/${player.id}`)}
                                                key="list-loadmore-edit"
                                            >
                                                <IntlMessages id="common.edit" />
                                            </Link>,
                                        ]}
                                    >
                                        <List.Item.Meta
                                            avatar={<UserAvatar user={player} />}
                                            title={
                                                <Link to={viewUrl}>
                                                    {player.full_name} <small>({player.email})</small>
                                                </Link>
                                            }
                                            description={<span>{player.sub_event.name}</span>}
                                        />
                                        <div></div>
                                    </List.Item>
                                );
                            }}
                        />
                    </Card>
                </Col>
            </Row>
            <ConfirmBankPaymentModal
                payment={eventPayment}
                visible={isConfirmBankPaymentModalVisible}
                onCancel={() => setConfirmBankPaymentModalVisible(false)}
                refetch={refetch}
                intl={intl}
            />

            <ResetBoletoModal
                payment={eventPayment}
                boletoPayoff={boletoPayoff}
                visible={isResetBoletoModalVisible}
                onCancel={() => setResetBoletoModalVisible(false)}
                refetch={refetch}
                intl={intl}
            />

            <DeletePaymentModal
                payment={eventPayment}
                visible={isDeletePaymentModalVisible}
                onCancel={() => setDeletePaymentModalVisible(false)}
                refetch={refetch}
                intl={intl}
            />
            <DevButton path={__filename} />
        </>
    );
};

export default ViewEventPayment;
