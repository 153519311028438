import React from 'react';
import { Row, Col, Typography, Select, Form, Input, Button, Card } from 'antd';
import IntlMessages from '../../../../util/IntlMessages';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { CUSTOM_FIELD_TYPES } from '../../../../constants/App';
import { keys } from 'lodash';
import LanguageTag from '../../../../components/Tags/LanguageTag';
import { requiredRule } from '../../../../util/form';

const CustomFields = ({ intl, values, selectedLanguage }) => {
    return (
        <Row className="gx-row-form-child">
            <Col xs={24} md={24}>
                <Card
                    title={
                        <span>
                            <IntlMessages id="common.custom_fields" />
                        </span>
                    }
                    className="gx-card"
                >
                    <Form.List name={['customs']} initialValue={[]}>
                        {(fields, { add, remove }) => (
                            <>
                                {fields.map(({ key, name, ...restField }) => {
                                    return (
                                        <div className="gx-bg-light-grey gx-p-3 gx-mb-3 gx-border gx-border-primary" style={{ borderStyle: 'dashed' }}>
                                            <Row>
                                                <Form.Item name={[name, 'name']} noStyle>
                                                    <Input type="hidden" />
                                                </Form.Item>
                                                <Col xs={24} md={20}>
                                                    <Form.Item
                                                        {...restField}
                                                        label={intl.formatMessage({ id: 'common.type' })}
                                                        initialValue={null}
                                                        name={[name, 'custom_type']}
                                                    >
                                                        <Select>
                                                            {keys(CUSTOM_FIELD_TYPES).map((key) => {
                                                                const value = CUSTOM_FIELD_TYPES[key];
                                                                return (
                                                                    <Select.Option key={value} value={value}>
                                                                        <IntlMessages id={`common.${value}_field`} />
                                                                    </Select.Option>
                                                                );
                                                            })}
                                                        </Select>
                                                    </Form.Item>
                                                </Col>

                                                <Col xs={24} md={4} className="gx-text-right">
                                                    <MinusCircleOutlined className="gx-text-danger" onClick={() => remove(name)} />
                                                </Col>

                                                <Col xs={24} md={14}>
                                                    <Form.Item
                                                        {...restField}
                                                        label={
                                                            <span>
                                                                {intl.formatMessage({ id: 'common.custom_field_label' })}{' '}
                                                                <LanguageTag language={selectedLanguage} />
                                                            </span>
                                                        }
                                                        name={[name, 'label', selectedLanguage]}
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: intl.formatMessage({
                                                                    id: 'common.field_required',
                                                                }),
                                                            },
                                                        ]}
                                                    >
                                                        <Input
                                                            placeholder={intl.formatMessage(
                                                                { id: 'common.custom_field_label_placeholder' },
                                                                { lang: intl.formatMessage({ id: `common.${selectedLanguage}` }) }
                                                            )}
                                                        />
                                                    </Form.Item>
                                                </Col>
                                                <Col xs={24} md={6} className="_gx-pl-0">
                                                    <Form.Item
                                                        {...restField}
                                                        initialValue={false}
                                                        name={[name, 'required']}
                                                        label={<IntlMessages id="common.mandatory" />}
                                                    >
                                                        <Select>
                                                            <Select.Option value={true}>
                                                                <IntlMessages id="common.yes" />
                                                            </Select.Option>
                                                            <Select.Option value={false}>
                                                                <IntlMessages id="common.no" />
                                                            </Select.Option>
                                                        </Select>
                                                    </Form.Item>
                                                </Col>

                                                {values && values[name]?.custom_type === CUSTOM_FIELD_TYPES.SELECT && (
                                                    <Col xs={24} md={24}>
                                                        <Typography.Paragraph className="gx-text-uppercase">
                                                            <IntlMessages id="common.options" /> <LanguageTag language={selectedLanguage} />
                                                        </Typography.Paragraph>
                                                        <Form.List name={[name, 'options']} initialValue={[{ label: '', value: '' }]}>
                                                            {(fieldOptions, { add: addOption, remove: removeOption }) => (
                                                                <>
                                                                    {fieldOptions.map((option) => {
                                                                        return (
                                                                            <Row>
                                                                                <Col xs={20} md={10} className="gx-pl-0">
                                                                                    <Form.Item
                                                                                        rules={[requiredRule(intl)]}
                                                                                        name={[option.name, 'label', selectedLanguage]}
                                                                                    >
                                                                                        <Input
                                                                                            placeholder={`${intl.formatMessage({ id: 'common.option' })} ${
                                                                                                option.name + 1
                                                                                            }`}
                                                                                        />
                                                                                    </Form.Item>
                                                                                    <Form.Item name={[option.name, 'value']} noStyle>
                                                                                        <Input type="hidden" />
                                                                                    </Form.Item>
                                                                                </Col>
                                                                                <Col xs={4}>
                                                                                    <MinusCircleOutlined onClick={() => removeOption(option.name)} />
                                                                                </Col>
                                                                            </Row>
                                                                        );
                                                                    })}
                                                                    <Form.Item>
                                                                        <Button type="dashed" onClick={() => addOption()} icon={<PlusOutlined />}>
                                                                            <IntlMessages id="common.add_option" />
                                                                        </Button>
                                                                    </Form.Item>
                                                                </>
                                                            )}
                                                        </Form.List>
                                                    </Col>
                                                )}
                                            </Row>
                                        </div>
                                    );
                                })}
                                <Form.Item>
                                    <Button
                                        type="dashed"
                                        onClick={() =>
                                            add({
                                                custom_type: CUSTOM_FIELD_TYPES.INPUT_TEXT,
                                            })
                                        }
                                        block
                                        icon={<PlusOutlined />}
                                    >
                                        <IntlMessages id="common.add_custom_field" />
                                    </Button>
                                </Form.Item>
                            </>
                        )}
                    </Form.List>
                </Card>
            </Col>
        </Row>
    );
};
export default CustomFields;
