import React, { useEffect, useState } from 'react';
import IntlMessages from '../../../../util/IntlMessages';
import { Row, Col, Breadcrumb, PageHeader, Collapse, Space, Badge, Button, Input, Table, Card, Checkbox, message } from 'antd';
import BreadcrumbHomeLink from '../../../../components/BreadcrumbHomeLink';
import { usePersistedState } from '../../../../hooks/usePersistedState';
import { useDebounce } from 'use-debounce';
import { useMutation, useQuery } from '@apollo/client';
import { PAGINATED_EVENTS } from '../../../../apollo/query/event';
import { debounceSearchFilterDelay } from '../../../../util/config';
import { useIntl } from 'react-intl';
import { countActiveFilters } from '../../../../util/object';
import { CloseCircleOutlined } from '@ant-design/icons';
import BadResponse from '../../../../components/BadResponse';
import { paginationOptions } from '../../../../constants/PaginationOptions';
import { PRIMARY_COLOR } from '../../../../constants/ThemeSetting';
import { formatDate } from '../../../../util/date';
import { DATE_FORMAT } from '../../../../constants/App';
import { EventPublicationIcon } from '../../../../components/Icons/EventPublicationIcon';
import { UPDATE_EVENT_MANAGEMENT } from '../../../../apollo/mutation/event';
import { defaultCatchException, defaultMutationCallback } from '../../../../apollo/callbacks';
import { GQL_CHANGE_ORGANIZATION } from '../../../../apollo/mutation/user';
import { getAdminPath } from '../../../../util/router';
import { useHistory } from 'react-router-dom';

const getColumns = ({ updateEventManagement, changeOrganization, refetch }, history, intl) => {
    return [
        {
            title: <IntlMessages id="common.name" />,
            dataIndex: 'name',
            key: 'name',
            render(name, record) {
                if (!record.organization_id) {
                    return name;
                }
                return (
                    <a
                        onClick={(e) => {
                            e.preventDefault();
                            changeOrganization({ variables: { id: record.organization_id } })
                                .then((response) =>
                                    defaultMutationCallback(response, () => {
                                        history.push(getAdminPath(`event/${record.id}/payments-summary`));
                                    })
                                )
                                .catch((e) => defaultCatchException(e, intl));
                        }}
                        href="#"
                    >
                        <EventPublicationIcon event={record} />
                        <span className="gx-pl-2">{name}</span>
                    </a>
                );
            },
        },
        {
            title: <IntlMessages id="common.location" />,
            dataIndex: 'location',
            key: 'location',
            width: 200,
            render(location) {
                if (!location) return null;
                return (
                    <span>
                        {location.name} ({location.state})
                    </span>
                );
            },
        },
        {
            title: <IntlMessages id="common.date" />,
            dataIndex: 'date_start',
            key: 'date_start',
            width: 150,
            sorter: true,
            sortDirections: ['ascend', 'descend', 'ascend'],
            defaultSortOrder: 'descend',
            render(dateStart) {
                return formatDate(dateStart, DATE_FORMAT, null, true);
            },
        },
        {
            title: <IntlMessages id="common.competition_stored" />,
            dataIndex: 'management',
            key: 'management',
            className: 'gx-text-center',
            width: 100,
            render(management, record) {
                return (
                    <Checkbox
                        onChange={(e) => {
                            updateEventManagement({
                                variables: {
                                    id: record.id,
                                    is_archived: e.target.checked,
                                },
                            })
                                .then((response) =>
                                    defaultMutationCallback(response, () => {
                                        refetch();
                                        message.success(intl.formatMessage({ id: 'common.operation_successful' }));
                                    })
                                )
                                .catch((e) => defaultCatchException(e, intl));
                        }}
                        checked={management?.is_archived}
                    />
                );
            },
        },
    ];
};

const ManagementDashboard = () => {
    const intl = useIntl();
    const history = useHistory();
    const DEFAULT_FILTERS = { term: '' };

    const [filters, setFilters] = useState(DEFAULT_FILTERS);
    const [paginationState, setPaginationState] = usePersistedState('adminEventsPagination', { current: 1, pageSize: 10 });
    const [sortingState, setSortingState] = usePersistedState('adminEventsSorting', { key: 'created_at', direction: -1 });

    const updateFilters = (key, value) => {
        if (paginationState.current !== 1) {
            setPaginationState({ ...paginationState, current: 1 });
        }
        setFilters({ ...filters, [key]: value });
    };

    const [debouncedTerm] = useDebounce(filters?.term, debounceSearchFilterDelay);

    const [updateEventManagement] = useMutation(UPDATE_EVENT_MANAGEMENT);
    const [changeOrganization] = useMutation(GQL_CHANGE_ORGANIZATION);

    const { data, loading, error, previousData, refetch } = useQuery(PAGINATED_EVENTS, {
        variables: {
            filter: {
                ...filters,
                show_all: true,
                term: debouncedTerm,
            },
            pagination: {
                page: paginationState.current,
                limit: paginationState.pageSize,
            },
            sorting: sortingState,
        },
        onError: (error) => {
            setFilters(DEFAULT_FILTERS);
        },
    });

    useEffect(() => {
        if (data && data.paginatedEvents) {
            const totalPages = data.paginatedEvents.pagination?.pages;
            const currentPage = data.paginatedEvents.pagination?.page;
            if (currentPage > totalPages) {
                setPaginationState({ ...paginationState, current: 1 });
            }
        }
    }, [data]);

    if (error) {
        return <BadResponse title={intl.formatMessage({ id: '500.ooops' })} subtitle={intl.formatMessage({ id: '500.something_wrong' })} />;
    }

    //reuse previous data while loading
    let tableData = previousData
        ? previousData.paginatedEvents
        : {
              items: [],
              pagination: {},
          };

    if (!loading && data) {
        tableData = data.paginatedEvents;
    }

    return (
        <>
            <Row>
                <Col md={24}>
                    <Breadcrumb className="gx-pb-3">
                        <Breadcrumb.Item>
                            <BreadcrumbHomeLink />
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>
                            <IntlMessages id="common.management" />
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>
                            <IntlMessages id="common.admin_competitions" />
                        </Breadcrumb.Item>
                    </Breadcrumb>
                </Col>
            </Row>

            <Row>
                <Col xs={24}>
                    <PageHeader
                        title={
                            <span>
                                <IntlMessages id="common.admin_competitions" />
                            </span>
                        }
                    />
                </Col>
                <Col xs={24}>
                    <Collapse className="gx-panel gx-mb-3" defaultActiveKey={countActiveFilters(filters) > 0 ? ['1'] : null}>
                        <Collapse.Panel
                            header={
                                <Space className="gx-w-100 gx-justify-content-between">
                                    <div className="">
                                        <IntlMessages id="common.filters" />
                                        <Badge className="gx-ml-2" count={countActiveFilters(filters, 0)} style={{ backgroundColor: PRIMARY_COLOR }} />
                                    </div>
                                </Space>
                            }
                            size="small"
                            className="gx-panel"
                            key="1"
                            bordered={false}
                            extra={[
                                <Button
                                    size="small"
                                    key="reset-btn"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        setFilters(DEFAULT_FILTERS);
                                    }}
                                    className="gx-mb-0"
                                >
                                    <CloseCircleOutlined className="gx-pr-1" />
                                    <IntlMessages id="common.clear_filters" />
                                </Button>,
                            ]}
                        >
                            <Row>
                                <Col xs={24} md={6}>
                                    <Space size="large">
                                        <Input.Search
                                            value={filters?.term}
                                            onChange={(e) => {
                                                const { value } = e.target;
                                                updateFilters('term', value);
                                            }}
                                            style={{ minWidth: 300 }}
                                            placeholder={intl.formatMessage({ id: 'common.search' })}
                                        />
                                    </Space>
                                </Col>
                            </Row>
                        </Collapse.Panel>
                    </Collapse>
                </Col>
                <Col xs={24}>
                    <Card className="gx-card">
                        <Table
                            rowKey="id"
                            onChange={(pagination, filters, sorter, { action }) => {
                                if (action === 'sort') {
                                    setSortingState({ key: sorter.field, direction: sorter.order === 'ascend' ? 1 : -1 });
                                }
                            }}
                            loading={loading}
                            dataSource={tableData.items}
                            columns={getColumns({ updateEventManagement, changeOrganization, refetch }, history, intl)}
                            pagination={{
                                ...paginationOptions,
                                total: tableData.pagination.total_items,
                                current: tableData.pagination.page,
                                pageSize: tableData.pagination.limit,
                                onChange: (current, pageSize) => {
                                    setPaginationState({ current, pageSize });
                                },
                            }}
                        />
                    </Card>
                </Col>
            </Row>
        </>
    );
};

export default ManagementDashboard;
