import { keys, uniq, map } from 'lodash';

export const getInitials = (str, maxChars = null, uppercase = true) => {
    const inits = str
        .split(' ')
        .map((n) => {
            if (!n[0]) return '';
            return uppercase ? `${n[0].toUpperCase()}` : `${n[0]}`;
        })
        .join('');

    if (maxChars) {
        return inits.slice(0, maxChars);
    }

    return inits;
};

export const formatPercent = (value, digit = 2) => {
    const formatter = new Intl.NumberFormat('it-IT', {
        minimumFractionDigits: digit,
        maximumFractionDigits: digit,
    });
    return formatter.format(value) + `%`;
};

export const formatCurrency = (number, digits = 0, currency = 'EUR') => {
    return new Intl.NumberFormat('it-IT', {
        style: 'currency',
        currency: currency,
        minimumFractionDigits: digits,
        maximumFractionDigits: digits,
    }).format(number);
};

export const getLocaleFromMultiLanguage = (obj, locale, defaultLocale = 'it') => {
    if (!obj) return '';

    if (obj[locale]) {
        return obj[locale];
    }

    if (obj[defaultLocale]) {
        return obj[defaultLocale];
    }

    const validKey = keys(obj).filter((key) => {
        return key !== '__typename' && obj[key];
    });

    if (validKey.length > 0) {
        return obj[validKey[0]];
    }

    return '';
};

export const formatLocation = (location) => {
    if (!location) return '';

    const locationsNames = [location.name].concat(map(location.parents, 'name'));

    return uniq(locationsNames).join(', ');
};

export const parseJSONSafe = (json) => {
    try {
        return JSON.parse(json);
    } catch (e) {
        return null;
    }
};
