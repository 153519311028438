import { Avatar } from 'antd';
import React from 'react';
import { PRIMARY_COLOR } from '../../constants/ThemeSetting';
import { getProfileImage } from '../../util/router';

const UserAvatar = ({ user }) => {
    if (!user) return null;

    const { first_name, last_name, keepsporting_user_id } = user;

    if (keepsporting_user_id) {
        const imagePath = getProfileImage(keepsporting_user_id);
        return <Avatar src={imagePath} />;
    } else {
        const initials = `${first_name[0]}${last_name[0]}`.toUpperCase();
        return <Avatar style={{ color: '#fff', backgroundColor: PRIMARY_COLOR }}>{initials}</Avatar>;
    }
};

export default UserAvatar;
