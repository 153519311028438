import { gql } from '@apollo/client';

export const GQL_QUERY_ME = gql`
    query me {
        me {
            id
            first_name
            last_name
            full_name
            username
            keepsporting_id
            current_organization {
                id
                keepsporting_id
            }
            role
            ability {
                action
                subject
                fields
                conditions
            }
            auth {
                verified
            }
            assignable_roles
        }
    }
`;

export const GQL_INIT_FRONTEND = gql`
    query frontEndMe {
        me {
            id
            first_name
            last_name
            full_name
            username
            fiscal_code
            phone_number
            role
            auth {
                verified
            }
            customer {
                id
                first_name
                last_name
            }
            ability {
                action
                subject
                fields
                conditions
            }
            current_organization {
                id
                name
                keepsporting_id
                logo {
                    url
                }
            }
        }
    }
`;

export const GQL_INIT_QUERY = gql`
    query initQuery {
        me {
            id
            first_name
            last_name
            full_name
            username
            current_organization {
                id
                name
                keepsporting_id
                timezone
            }
            role
            locale
            ability {
                action
                subject
                fields
                conditions
            }
            assignable_roles
        }
    }
`;

export const GQL_GET_USER = gql`
    query user($id: String!) {
        user(id: $id) {
            id
            first_name
            last_name
            full_name
            username
            role
            auth {
                disabled
                verified
            }
            role_reference_id
        }
        me {
            assignable_roles
        }
    }
`;

export const GQL_LOCAL_ME = gql`
    query localMe {
        me @client {
            id
            first_name
            last_name
            full_name
            role
            current_organization {
                id
                keepsporting_id
            }
        }
    }
`;

export const GQL_PAGINATED_USERS = gql`
    query paginatedUsers($sorting: SortingInput, $pagination: PaginationInput, $filter: FilterUserInput) {
        paginatedUsers(sorting: $sorting, pagination: $pagination, filter: $filter) {
            items {
                id
                first_name
                last_name
                username
                fiscal_code
                phone_number
            }
            pagination {
                page
                pages
                current_items
                total_items
                size
            }
        }
    }
`;

export const GQL_SEARCH_USERS = gql`
    query users($sorting: SortingInput, $filter: FilterUserInput, $limit: Int) {
        users(sorting: $sorting, filter: $filter, limit: $limit) {
            id
            first_name
            last_name
            username
        }
    }
`;

export const GQL_ASSIGNABLE_ROLES = gql`
    query localInitQuery {
        me @client {
            assignable_roles
        }
    }
`;
