import React from 'react';
import { Col, Row, Layout, Space } from 'antd';
import { footerText, appVersion } from 'util/config';
import { changeLocale } from '../../apollo/local/cache';
import { getSafeISOLanguage } from '../../lngProvider';
import { useIntl } from 'react-intl';

const Footer = () => {
    //English  |  Español  | Italiano  |  Português

    const intl = useIntl();

    const locale = getSafeISOLanguage(intl.locale);

    const onClickLocale = (e, locale) => {
        e.preventDefault();
        e.stopPropagation();
        changeLocale(locale);
    };

    return (
        <Layout.Footer>
            <Row>
                <Col md={12}>
                    <div className="gx-layout-footer-content">
                        <span className="gx-pr-1">{footerText}</span>
                        <small>v{appVersion}</small>
                    </div>
                </Col>
                <Col md={12}>
                    <div className="gx-layout-footer-content gx-text-right">
                        <Space>
                            <span>
                                <a className={`${locale === 'en' ? 'gx-font-weight-medium' : ''}`} rel="nofollow" onClick={(e) => onClickLocale(e, 'en')}>
                                    English
                                </a>
                            </span>
                            |
                            <span>
                                <a className={`${locale === 'es' ? 'gx-font-weight-medium' : ''}`} rel="nofollow" onClick={(e) => onClickLocale(e, 'es')}>
                                    Español
                                </a>
                            </span>
                            |
                            <span>
                                <a className={`${locale === 'it' ? 'gx-font-weight-medium' : ''}`} rel="nofollow" onClick={(e) => onClickLocale(e, 'it')}>
                                    Italiano
                                </a>
                            </span>
                            |
                            <span>
                                <a className={`${locale === 'pt' ? 'gx-font-weight-medium' : ''}`} rel="nofollow" onClick={(e) => onClickLocale(e, 'pt')}>
                                    Português
                                </a>
                            </span>
                        </Space>
                    </div>
                </Col>
            </Row>
        </Layout.Footer>
    );
};

export default Footer;
