import React from 'react';
import { List, Card } from 'antd';
import IntlMessages from '../../util/IntlMessages';
import { CalendarOutlined } from '@ant-design/icons';
import { formatDateTime } from '../../util/date';

const CommunicationCard = ({ communications, actions = null }) => {
    const hasCommunications = communications && communications.length > 0;

    if (!hasCommunications) return null;

    return (
        <Card title={<IntlMessages id="common.communications" />} className="gx-card" actions={actions}>
            <List
                bordered
                itemLayout="horizontal"
                dataSource={communications}
                renderItem={(communication) => (
                    <List.Item
                        actions={[
                            <a href={communication.url} target="_blank" rel="noreferrer">
                                <IntlMessages id="common.view" />
                            </a>,
                        ]}
                    >
                        <List.Item.Meta
                            title={
                                <a href={communication.url} target="_blank" rel="noreferrer">
                                    <IntlMessages id={`common.communication_type_${communication.type}`} />
                                </a>
                            }
                            description={
                                <span>
                                    <CalendarOutlined className="gx-mr-2" />
                                    {formatDateTime(communication.sent_at)}
                                </span>
                            }
                        />
                    </List.Item>
                )}
            />
        </Card>
    );
};

export default CommunicationCard;
