import { gql } from '@apollo/client';
import { MULTILANGUAGE_FRAGMENT } from '../common';

export const PAGINATED_EVENTS = gql`
    query paginatedEvents($sorting: SortingInput, $pagination: PaginationInput, $filter: FilterEventInput) {
        paginatedEvents(sorting: $sorting, pagination: $pagination, filter: $filter) {
            items {
                id
                name
                type
                location {
                    id
                    name
                    state
                }
                publish {
                    state
                }
                sport {
                    name
                }
                date_start
                assets {
                    id
                    context_type
                    url
                }
                management {
                    is_archived
                }
                organization_id
                created_at
            }
            pagination {
                page
                pages
                current_items
                total_items
                size
            }
        }
    }
`;

export const EVENT_CONTAINER_DATA = gql`
    query event($id: String!) {
        event(id: $id) {
            id
            name
            type
            created_at
            sub_events {
                id
                name
            }
            sport {
                name
            }
            payments {
                location {
                    currency {
                        iso
                        symbol
                    }
                }
            }
        }
    }
`;

export const EVENT_DASHBOARD = gql`
    query event($id: String!) {
        event(id: $id) {
            id
            name
            type
            sport {
                id
                name
            }
            location {
                id
                name
            }
            sub_events {
                id
                name
                timetables {
                    subscription_start
                    subscription_end
                    event_start
                    event_end
                }
                assets(type: "image", fallback: true) {
                    id
                    file_name
                    context_type
                    url
                }
            }
            publish {
                state
            }
            payments {
                location {
                    id
                    currency {
                        symbol
                        iso
                    }
                }
            }
        }
        playersAggregations(event_id: $id) {
            count
            retired
            confirmed
            paid
        }
        paymentsAggregations(event_id: $id) {
            transactions {
                type
                count
                total
            }
        }
        playersAffluence(event_id: $id) {
            x
            y
        }
    }
`;

export const EVENT_PAYMENTS = gql`
    query eventPayments($sorting: SortingInput, $pagination: PaginationInput, $filter: FilterPaymentsInput!) {
        eventPayments(sorting: $sorting, pagination: $pagination, filter: $filter) {
            items {
                id
                payer {
                    full_name
                    email
                }
                players {
                    id
                    sub_event_id
                    full_name
                }
                paymentLocation {
                    id
                    currency {
                        symbol
                        iso
                    }
                }
                transaction {
                    type
                    total
                    paid_at
                }
                order_state
                created_at
                deleted_at
            }
            pagination {
                page
                pages
                current_items
                total_items
                size
            }
        }
    }
`;

export const EVENT_PAYMENT = gql`
    query eventPayment($id: String!, $event_id: String!) {
        eventPayment(id: $id) {
            id
            tickets_id
            payer {
                full_name
                email
            }
            paymentLocation {
                id
                currency {
                    symbol
                    iso
                }
            }
            transaction {
                type
                price
                fee
                fee_to_org
                additions
                discount
                subtotal
                total
                paid_at
                payment_id
                payoff
            }
            players {
                id
                keepsporting_user_id
                full_name
                first_name
                last_name
                email
                sub_event {
                    id
                    name
                }
            }
            receipt {
                id
                keepsporting_organization_id
                filename
            }
            communications {
                url
                type
                sent_at
            }
            bank_code
            order_state
            private_key
            created_at
            deleted_at
        }
        event(id: $event_id) {
            id
            name
            type
            created_at
            sub_events {
                id
                name
            }
            sport {
                name
            }
        }
    }
`;

export const EVENT_PAYMENTS_REPORT = gql`
    query event($id: String!) {
        event(id: $id) {
            id
            name
            type
            created_at
            sub_events {
                id
                name
            }
            sport {
                name
            }
            payments {
                location {
                    id
                    currency {
                        symbol
                        iso
                    }
                    transfer_fee {
                        threshold
                        fee
                    }
                }
                extra_fees {
                    id
                    date
                    description
                    amount
                    created_at
                }
                transfers_to_org {
                    id
                    date
                    code
                    amount
                    created_at
                }
            }
        }
        paymentsReportAggregations(event_id: $id) {
            payments
            players
            price
            fees {
                to_player
                to_org
                total
            }
            subtotal
            discount
            additions
            total
            bank_payment_to_org_account
            data_types {
                players
                payments
                price
                fees
                subtotal
                additions
                discount
                total
                type
            }
        }
    }
`;

export const EVENT_DESCRIPTION = gql`
    query event($id: String!) {
        event(id: $id) {
            id
            name
            main_language
            languages {
                iso
                main
            }
            description {
                ...multilanguageFragment
            }
        }
    }
    ${MULTILANGUAGE_FRAGMENT}
`;

export const EVENT_GALLERY = gql`
    query event($id: String!) {
        event(id: $id) {
            id
            name
            assets(type: "image") {
                id
                file_name
                context_type
                url
            }
        }
    }
`;

export const EVENT_INFORMATIONS = gql`
    query event($id: String!) {
        event(id: $id) {
            id
            name
            type
            sport {
                keepsporting_id
                name
            }
            languages {
                iso
                main
            }
            main_language
            date_start
            hide_date
            location {
                id
            }
            wiclax_live_standings
            fotop
            editable_contacts {
                type
                description {
                    ...multilanguageFragment
                }
                value
            }
        }
    }
    ${MULTILANGUAGE_FRAGMENT}
`;

export const EVENT_CONTRACT = gql`
    query event($id: String!) {
        me {
            id
            locale
            role
        }
        event(id: $id) {
            id
            name
            type
            contract {
                generated
                signed
                signed_at
                downloaded
                signer_name
                organization_address
                url
            }
            payments {
                fee_to_org
                sp_fee_to_org
                ks_fee {
                    fixed
                    percent
                    sp_fixed
                    sp_percent
                    min
                }
                location {
                    id
                    name
                    slug
                    currency {
                        symbol
                        iso
                    }
                }
                providers {
                    slug
                    active
                    expiration_span
                }
            }
            organization {
                id
                name
                address
            }
        }
        paymentLocations {
            id
            name
            slug
        }
        currentOrganization {
            id
            bank_account {
                state
                data {
                    bank_holder
                    iban
                    pix
                    location
                }
            }
        }
    }
`;

export const INSERT_EVENT = gql`
    query fetchInsertEvent {
        me @client {
            id
            locale
            current_organization {
                id
                timezone
            }
        }
        paymentLocations(active: true) {
            id
            name
            slug
        }
    }
`;

export const EVENT_DISCOUNTS = gql`
    query event($id: String!) {
        activeEvents: events(filter: { date_start: "future" }) {
            id
            name
        }
        event(id: $id) {
            id
            name
            type
            created_at
            sub_events {
                id
                name
            }
            sport {
                name
            }
            payments {
                location {
                    id
                    currency {
                        symbol
                        iso
                    }
                }
            }
            discount {
                subscriptions {
                    fixed
                    percent
                    threshold
                    event_id
                    expire_in_days
                }
            }
        }
    }
`;

export const PAGINATED_EVENT_DISCOUNTS = gql`
    query eventDiscounts($sorting: SortingInput, $pagination: PaginationInput, $filter: FilterDiscountsInput!) {
        eventDiscounts(sorting: $sorting, pagination: $pagination, filter: $filter) {
            items {
                id
                type
                code
                fixed
                percent
                used_at
                used_times
                expires_at
                created_at
            }
            pagination {
                page
                pages
                current_items
                total_items
                size
            }
        }
    }
`;

export const EXPORT_EVENT_PAYMENTS = gql`
    query exportPayments($id: String!, $locale: String) {
        exportPayments(id: $id, locale: $locale)
    }
`;

export const EXPORT_EVENT_DISCOUNTS = gql`
    query exportDiscounts($id: String!, $locale: String) {
        exportDiscounts(id: $id, locale: $locale)
    }
`;

export const PAYMENTS_SUMMARY = gql`
    query paymentsSummary($event_id: String!) {
        paymentsSummary(event_id: $event_id) {
            providers {
                type
                count
                total
                paid
                not_paid
                not_paid_count
                discount
            }
            count
            total
            paid
            not_paid
            not_paid_count
            discount
        }
    }
`;
