import { gql } from '@apollo/client';

export const PAGINATED_PROFILES = gql`
    query paginateProfiles($sorting: SortingInput, $pagination: PaginationInput, $filter: FilterProfilesInput) {
        profiles(sorting: $sorting, pagination: $pagination, filter: $filter) {
            items {
                email
                first_name
                last_name
                gender
                language
                keepsporting_user_id
                location {
                    id
                    name
                    state
                    primary_administrative_area {
                        id
                        name
                    }
                    secondary_administrative_area {
                        id
                        name
                    }
                }
                sports {
                    id
                    name
                }
                types
                last_activity_at
            }
            pagination {
                page
                pages
                current_items
                total_items
                size
            }
        }
    }
`;

export const GQL_EXPORT_PROFILES = gql`
    query exportProfiles($filter: FilterProfilesInput) {
        exportProfiles(filter: $filter) {
            content
            filename
        }
    }
`;
