import { isNumber, isNaN } from 'lodash';

export const roundNumber = (num, decimal = 2) => {
    return Math.round(num * Math.pow(10, decimal)) / Math.pow(10, decimal);
};

export const safeRoundedNonNegativeNumber = (value, decimal = 2) => {
    if (!isNumber(value) || isNaN(value)) return 0;
    if (value < 0) return 0;
    return roundNumber(value, decimal);
};

export const floatToInt = (value) => {
    return roundNumber(value * 100, 0);
};

export const intToFloat = (value) => {
    return roundNumber(value / 100, 2);
};

export const boolToInt = (value) => {
    return value ? 1 : 0;
};

export const intToBool = (value) => {
    return value === 1;
};
