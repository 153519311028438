import { useMutation } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { localStorageClass } from '../apollo/local/storage';
import { GQL_AUTH_TOKEN_LOGIN } from '../apollo/mutation/user';
import { getAdminPath, getFrontendURL } from '../util/router';
import { Result, Button, Row, Col } from 'antd';
import IntlMessages from '../util/IntlMessages';
import { useIntl } from 'react-intl';
import CircularProgress from '../components/CircularProgress';

const PAGE_STATE = {
    LOADING: 1,
    ERROR: 2,
    SUCCESS: 3,
};

const SignInContainer = ({ children }) => {
    return (
        <div className="gx-container-fluid bg-blue mh-100vh">
            <div className="gx-container frontend-container">
                <Row className="gx-mt-5 gx-p-5">
                    <Col span={24} className="gx-text-center">
                        <img src="/assets/images/keepsporting_logo.png" />
                    </Col>
                </Row>
                {children}
            </div>
        </div>
    );
};

/**
 * @route /signin-emp/:organization/:token
 * @param {*} param0
 * @returns
 */
const EmpLogin = ({ token, organization, intl }) => {
    const history = useHistory();

    const [authTokenLogin] = useMutation(GQL_AUTH_TOKEN_LOGIN, { variables: { organization: parseInt(organization), token: token } });

    const [pageState, setPageState] = useState(PAGE_STATE.LOADING);

    useEffect(() => {
        authTokenLogin()
            .then(({ data, errors }) => {
                if (data && data.authTokenLogin) {
                    localStorageClass.login(data.authTokenLogin);
                    history.push(getAdminPath('dashboard'));
                } else {
                    console.log('authTokenLogin.then.error', errors);
                    setPageState(PAGE_STATE.ERROR);
                }
            })
            .catch((error) => {
                console.log('authTokenLogin.catch', error);
                setPageState(PAGE_STATE.ERROR);
            });
    }, []);

    if (pageState === PAGE_STATE.LOADING) {
        return <CircularProgress style={{ height: '50vh' }} />;
    }

    if (pageState === PAGE_STATE.ERROR) {
        return (
            <Result
                status="500"
                title={intl.formatMessage({ id: 'errors.goto_frontend_to_login_title' })}
                subTitle={intl.formatMessage({ id: 'errors.goto_frontend_to_login_description' })}
                extra={
                    <a href={getFrontendURL()} target="_blank" rel="noreferrer">
                        <Button type="primary">
                            <IntlMessages id="common.login_page" />
                        </Button>
                    </a>
                }
            />
        );
    }

    return (
        <Result
            status="success"
            title={intl.formatMessage({ id: 'success.goto_admin_title' })}
            subTitle={intl.formatMessage({ id: 'success.goto_admin_description' })}
            extra={[
                <Link to={getAdminPath()} className="ant-btn ant-btn-primary" key="console">
                    {intl.formatMessage({ id: 'comon.admin' })}
                </Link>,
            ]}
        />
    );
};

const SignInEmp = ({ match }) => {
    const { params } = match;

    const intl = useIntl();

    const { token, organization } = params;

    if (!token || !organization) {
        return (
            <SignInContainer>
                <Result
                    status="500"
                    title={intl.formatMessage({ id: 'errors.goto_frontend_to_login_title' })}
                    subTitle={intl.formatMessage({ id: 'errors.goto_frontend_to_login_description' })}
                    extra={
                        <a href={getFrontendURL()}>
                            <Button type="primary">
                                <IntlMessages id="404.home" />
                            </Button>
                        </a>
                    }
                />
            </SignInContainer>
        );
    }

    return (
        <SignInContainer>
            <EmpLogin organization={organization} token={token} intl={intl} />
        </SignInContainer>
    );
};

export default SignInEmp;
