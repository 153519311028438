import React from 'react';
import BadResponse from '../../../../../components/BadResponse';
import { PLAYERS_SUMMARY } from '../../../../../apollo/query/sub-event';
import { useMutation, useQuery } from '@apollo/client';
import CircularProgress from '../../../../../components/CircularProgress';
import IntlMessages from '../../../../../util/IntlMessages';
import { subEventHasAthleteCard, subEventHasMedicalCartificate } from '../common';
import CardDescriptions, { CardDescriptionsItem } from '../../../../../components/Descriptions/CardDescription';
import { MailOutlined } from '@ant-design/icons';
import { Button, Modal, message } from 'antd';
import { defaultCatchException, defaultMutationHandler } from '../../../../../apollo/callbacks';
import { PLAYER_CERTIFICATE_REMINDERS } from '../../../../../apollo/mutation/event';

const PlayersSummary = ({ intl, subEvent }) => {
    const [playerCertificateReminders, { loading: remindersLoading }] = useMutation(PLAYER_CERTIFICATE_REMINDERS);

    const onRemindCertificate = (playersCount) => {
        const content =
            playersCount > 1
                ? intl.formatMessage({ id: 'common.certificate_remainder_modal_text_plural' }, { count: playersCount })
                : intl.formatMessage({ id: 'common.certificate_remainder_modal_text' });

        Modal.confirm({
            title: intl.formatMessage({ id: 'common.certificate_remainder_modal_title' }),
            content: content,
            okText: intl.formatMessage({ id: 'common.confirm' }),
            cancelText: intl.formatMessage({ id: 'common.cancel' }),
            okButtonProps: {
                loading: remindersLoading,
            },
            onOk: () => {
                playerCertificateReminders({
                    variables: {
                        sub_event_id: subEvent.id,
                    },
                })
                    .then(
                        defaultMutationHandler({
                            successCallback: ({ playerCertificateReminders }) => {
                                const messageText =
                                    playerCertificateReminders > 1
                                        ? intl.formatMessage({ id: 'common.certificate_multi_remainder_email_sent_plural', count: playerCertificateReminders })
                                        : intl.formatMessage({ id: 'common.certificate_multi_remainder_email_sent' });
                                message.success(messageText);
                            },
                            errorMessage: intl.formatMessage({ id: 'common.default_mutation_error' }),
                        })
                    )
                    .catch((e) => defaultCatchException(e, intl));
            },
        });
    };

    const { data, loading, error } = useQuery(PLAYERS_SUMMARY, {
        variables: { sub_event_id: subEvent.id },
    });

    if (error) {
        return <BadResponse title={intl.formatMessage({ id: '500.ooops' })} subtitle={intl.formatMessage({ id: '500.something_wrong' })} />;
    }

    if (loading) {
        return <CircularProgress />;
    }

    const hasMedicalCertificate = subEventHasMedicalCartificate(subEvent);
    const hasAthleteCard = subEventHasAthleteCard(subEvent);

    return (
        <div className="gx-m-4">
            <CardDescriptions column={1} className="gx-mx-0">
                <CardDescriptionsItem label={<IntlMessages id="common.total" />}>{data.playersSummary.total}</CardDescriptionsItem>

                <CardDescriptionsItem label={<IntlMessages id="common.confirmed_plural" />}>{data.playersSummary.confirmed}</CardDescriptionsItem>
                <CardDescriptionsItem label={<IntlMessages id="common.not_confirmed_plural" />}>{data.playersSummary.not_confirmed}</CardDescriptionsItem>

                <CardDescriptionsItem label={<IntlMessages id="common.retireds" />}>{data.playersSummary.retired}</CardDescriptionsItem>
                <CardDescriptionsItem label={<IntlMessages id="common.should_confirm" />}>
                    <strong>{data.playersSummary.should_confirm}</strong>
                </CardDescriptionsItem>
                {hasMedicalCertificate && [
                    <CardDescriptionsItem key="certificate_upload" label={<IntlMessages id="common.certificate_upload" />}>
                        {data.playersSummary.certificate_upload}
                    </CardDescriptionsItem>,
                    <CardDescriptionsItem key="certificate_confirm" label={<IntlMessages id="common.certificate_confirm" />}>
                        {data.playersSummary.certificate_confirm}
                    </CardDescriptionsItem>,
                    data.playersSummary.should_upload_certificate > 0 ? (
                        <CardDescriptionsItem
                            key="should_upload_certificate"
                            label={<IntlMessages id="common.should_upload_certificate" />}
                            className="has-action"
                        >
                            <strong>{data.playersSummary.should_upload_certificate}</strong>

                            <Button
                                size="small"
                                className="gx-mb-0 gx-ml-2"
                                key="certificate_reminder"
                                icon={<MailOutlined className="gx-pr-1" />}
                                onClick={() => onRemindCertificate(data.playersSummary.should_upload_certificate)}
                            >
                                <IntlMessages id="common.remainder_email" />
                            </Button>
                        </CardDescriptionsItem>
                    ) : (
                        <CardDescriptionsItem key="should_upload_certificate" label={<IntlMessages id="common.should_upload_certificate" />}>
                            <strong>{data.playersSummary.should_upload_certificate}</strong>
                        </CardDescriptionsItem>
                    ),

                    <CardDescriptionsItem key="should_confirm_certificate" label={<IntlMessages id="common.should_confirm_certificate" />}>
                        <strong>{data.playersSummary.should_confirm_certificate}</strong>
                    </CardDescriptionsItem>,
                ]}
                {hasAthleteCard && [
                    <CardDescriptionsItem key="athlete_card_uploaded" label={<IntlMessages id="common.athlete_card_uploaded" />}>
                        {data.playersSummary.athlete_card_upload}
                    </CardDescriptionsItem>,
                    <CardDescriptionsItem key="athlete_card_confirm" label={<IntlMessages id="common.athlete_card_confirm" />}>
                        {data.playersSummary.athlete_card_confirm}
                    </CardDescriptionsItem>,
                    <CardDescriptionsItem key="should_upload_athlete_card" label={<IntlMessages id="common.should_upload_athlete_card" />}>
                        <strong> {data.playersSummary.should_upload_athlete_card}</strong>
                    </CardDescriptionsItem>,
                    <CardDescriptionsItem key="should_confirm_athlete_card" label={<IntlMessages id="common.should_confirm_athlete_card" />}>
                        <strong>{data.playersSummary.should_confirm_athlete_card}</strong>
                    </CardDescriptionsItem>,
                ]}
            </CardDescriptions>
        </div>
    );
};

export default PlayersSummary;
