import React, { useState } from 'react';
import { Button, Upload } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import IntlMessages from '../../util/IntlMessages';
import { injectIntl } from 'react-intl';

/**
 * do not add ...rest to UPLOAD component it broke file preview
 * @param {*} param0
 * @returns
 */
const UploadAsset = ({ accept, placeholder, allowMultipleFiles, onChange, initialFilesList }) => {
    const [filesList, setFilesList] = useState(initialFilesList);

    return (
        <Upload
            accept={accept}
            multiple={allowMultipleFiles}
            fileList={filesList}
            beforeUpload={(file, files) => {
                const newFilesList = allowMultipleFiles ? [...filesList, ...files] : [...files];
                setFilesList(newFilesList);
                onChange(newFilesList);
                return false;
            }}
            onRemove={(file) => {
                const index = filesList.indexOf(file);
                const newFileList = filesList.slice();
                newFileList.splice(index, 1);
                setFilesList(newFileList);

                onChange(newFileList);
            }}
        >
            <Button icon={<UploadOutlined />}>
                &nbsp;
                {placeholder ?? <IntlMessages id="common.upload_file" />}
            </Button>
        </Upload>
    );
};

UploadAsset.defaultProps = {
    allowMultipleFiles: false,
    accept: '*',
    initialFilesList: [],
};

export default injectIntl(UploadAsset);
