import { Breadcrumb, Col, PageHeader, Row, Form, Input, Card, Table, Space, Button, Alert } from 'antd';
import React, { useState } from 'react';
import IntlMessages from '../../../../util/IntlMessages';
import BreadcrumbHomeLink from '../../../../components/BreadcrumbHomeLink';
import CircularProgress from '../../../../components/CircularProgress';
import BadResponse from '../../../../components/BadResponse';
import { useIntl } from 'react-intl';
import { useMutation, useQuery } from '@apollo/client';
import { CREATE_PRODUCT_DATA } from '../../../../apollo/query/product';
import DevButton from '../../../../components/Dev/DevButton';
import { getSafeISOLanguage } from '../../../../lngProvider';
import { SaveOutlined } from '@ant-design/icons';
import { requiredRule } from '../../../../util/form';
import { CREATE_PRODUCT } from '../../../../apollo/mutation/product';
import { defaultCatchException, defaultMutationHandler } from '../../../../apollo/callbacks';
import { useHistory } from 'react-router-dom';
import { getAdminPath } from '../../../../util/router';
import MultilanguageFormInput from '../../../../components/MultilanguageInput';

const CreateEventProduct = ({ match }) => {

    const eventId = match.params.id;

    const intl = useIntl();

    const history = useHistory();

    const [selectedRowKeys, setSelectedRowKeys] = useState([]);

    const [showAttributesError, setShowAttributesError] = useState(false);

    const [createProduct, { loading: loadingMutation }] = useMutation(CREATE_PRODUCT);

    const { data, loading, error } = useQuery(CREATE_PRODUCT_DATA, {
        fetchPolicy: 'no-cache',
        variables: {
            eventId,
        },
        context: {
            headers: {
                'x-locale': getSafeISOLanguage(intl.locale),
            },
        },
    });

    if (error) {
        return <BadResponse title={intl.formatMessage({ id: '500.ooops' })} subtitle={intl.formatMessage({ id: '500.something_wrong' })} />;
    }

    if (loading) {
        return <CircularProgress />;
    }

    const onFinish = (values) => {
        console.log('onFinish', values);

        const attributesError = selectedRowKeys.length === 0;

        if (attributesError) {
            setShowAttributesError(attributesError);

            return;
        }

        const input = {
            ...values,
            event_id: eventId,
            attributes: selectedRowKeys,
        };

        createProduct({
            variables: {
                input,
            },
        })
            .then(
                defaultMutationHandler({
                    successCallback: ({ createProduct }) => {
                        //message.success(intl.formatMessage({ id: 'common.multi_remainder_email_sent_plural' }, { count: subscriptionPaymentReminders }));
                        if (createProduct) {
                            setSelectedRowKeys([]);
                            history.push(getAdminPath(`event/${eventId}/products/${createProduct}/update`));
                        }
                        //refetch();
                    },
                    errorMessage: intl.formatMessage({ id: 'common.default_mutation_error' }),
                })
            )
            .catch((e) => defaultCatchException(e, intl));
    };

    return (
        <>
            <Form layout="vertical" onFinish={onFinish}>
                <Row>
                    <Col md={24}>
                        <Breadcrumb className="gx-pb-3">
                            <Breadcrumb.Item>
                                <BreadcrumbHomeLink />
                            </Breadcrumb.Item>
                            <Breadcrumb.Item>{data?.event?.name}</Breadcrumb.Item>
                            <Breadcrumb.Item>
                                <IntlMessages id="products.create" />
                            </Breadcrumb.Item>
                        </Breadcrumb>
                    </Col>
                </Row>

                <Row>
                    <Col xs={24}>
                        <PageHeader
                            onBack={() => history.goBack()}
                            title={
                                <span>
                                    <IntlMessages id="products.create" />
                                </span>
                            }
                            extra={
                                <Space>
                                    <Button loading={loadingMutation} key="create" icon={<SaveOutlined className="gx-pr-1" />} type="primary" htmlType="submit">
                                        <IntlMessages id="common.save" />
                                    </Button>
                                </Space>
                            }
                        />
                    </Col>

                    <Col xs={24} md={12}>
                        <Card title={<IntlMessages id="common.general_data" />} className="gx-card">
                            <Form.Item
                                rules={[
                                    requiredRule(intl),
                                    {
                                        min: 2,
                                        type: 'string',
                                        message: intl.formatMessage(
                                            {
                                                id: 'common.validation_min_chars',
                                            },
                                            {
                                                min: 2,
                                            }
                                        ),
                                    },
                                    {
                                        max: 125,
                                        type: 'string',
                                        message: intl.formatMessage(
                                            {
                                                id: 'common.validation_max_chars',
                                            },
                                            {
                                                max: 125,
                                            }
                                        ),
                                    },
                                ]}
                                label={<IntlMessages id="common.name" />}
                                name="name"
                            >
                                <Input />
                            </Form.Item>

                            <MultilanguageFormInput
                                eventLanguages={data.event.languages}
                                input={<Input.TextArea />}
                                formItemProps={{
                                    label: <IntlMessages id="common.description" />,
                                    name: 'description',
                                    rules: [requiredRule(intl)],
                                }}
                            />
                        </Card>
                    </Col>

                    <Col xs={24} md={12}>
                        <Card title={<IntlMessages id="products.select_attributes" />} className="gx-card">
                            {showAttributesError && (
                                <Alert message={<IntlMessages id="products.select_attributes_error" />} type="error" showIcon className="gx-mb-3" />
                            )}

                            <Table
                                rowKey={(record) => record.id}
                                rowSelection={{
                                    type: 'checkbox',
                                    selectedRowKeys,
                                    onChange: (selectedRowKeys, selectedRows) => {
                                        if (selectedRowKeys.length > 0) {
                                            setShowAttributesError(false);
                                        }

                                        setSelectedRowKeys(selectedRowKeys);
                                    },
                                }}
                                dataSource={data.attributes || []}
                                columns={[
                                    {
                                        title: <IntlMessages id="common.name" />,
                                        dataIndex: 'name',
                                        key: 'name',
                                    },
                                    // {
                                    //     title: <IntlMessages id="common.type" />,
                                    //     dataIndex: 'type',
                                    //     key: 'type',
                                    // },
                                    {
                                        title: <IntlMessages id="common.options" />,
                                        dataIndex: 'options',
                                        key: 'options',
                                        render: (options) => {
                                            return options.map((option) => option.label).join(', ');
                                        },
                                    },
                                ]}
                            />
                        </Card>
                    </Col>
                </Row>
            </Form>
            <DevButton path={__filename} />
        </>
    );
};

export default CreateEventProduct;
