import React from 'react';
import { Card } from 'antd';
import IntlMessages from '../../../../util/IntlMessages';
import { formatCurrency, formatPercent } from '../../../../util/string';
import CardDescriptions, { CardDescriptionsItem } from '../../../../components/Descriptions/CardDescription';
import { paymentSlugToInt, processProviderPrice } from '../../../../util/payments';
import { safeRoundedNonNegativeNumber } from '../../../../util/number';

const PriceCommissions = ({ event, prices, currency }) => {
    const { payments } = event;

    return (
        <Card title={<IntlMessages id="common.commissions" />} className="gx-card">
            <CardDescriptions column={1} className="ks-descriptions">
                <CardDescriptionsItem>
                    {payments.fee_to_org ? <IntlMessages id="common.fee_to_org" /> : <IntlMessages id="common.fee_to_athlete" />}
                </CardDescriptionsItem>
                <CardDescriptionsItem label={<IntlMessages id="common.ks_percent_fee" />}>{formatPercent(payments.ks_fee.percent / 100)}</CardDescriptionsItem>
                <CardDescriptionsItem label={<IntlMessages id="common.ks_fixed_fee" />}>
                    {formatCurrency(payments.ks_fee.fixed / 100, 2, currency.iso)}
                </CardDescriptionsItem>
                {payments.ks_fee.min ? (
                    <CardDescriptionsItem label={<IntlMessages id="common.ks_min_fee" />}>{formatPercent(payments.ks_fee.min / 100)}</CardDescriptionsItem>
                ) : null}
            </CardDescriptions>

            {payments.providers.map((provider, index) => {
                const { ksFee, providerFee, price } = processProviderPrice(provider, payments, prices);

                return (
                    <CardDescriptions
                        title={<IntlMessages id={`common.payment_type_${paymentSlugToInt(provider.slug)}`} />}
                        key={index}
                        column={1}
                        className="ks-descriptions"
                    >
                        {provider.selling_point && (
                            <>
                                <CardDescriptionsItem>
                                    {payments.sp_fee_to_org ? <IntlMessages id="common.fee_to_org" /> : <IntlMessages id="common.fee_to_athlete" />}
                                </CardDescriptionsItem>
                                <CardDescriptionsItem label={<IntlMessages id="common.ks_percent_fee" />}>
                                    {formatPercent(payments.ks_fee.sp_percent / 100)}
                                </CardDescriptionsItem>
                                <CardDescriptionsItem label={<IntlMessages id="common.ks_fixed_fee" />}>
                                    {formatCurrency(payments.ks_fee.sp_fixed / 100, 2, currency.iso)}
                                </CardDescriptionsItem>
                            </>
                        )}
                        <CardDescriptionsItem label={<IntlMessages id="common.base_price" />}>
                            {formatCurrency(safeRoundedNonNegativeNumber(prices?.base / 100), 2, currency.iso)}
                        </CardDescriptionsItem>
                        <CardDescriptionsItem label={<IntlMessages id="common.ks_fee" />}>{formatCurrency(ksFee, 2, currency.iso)}</CardDescriptionsItem>
                        <CardDescriptionsItem label={<IntlMessages id="common.provider_fee" />}>
                            {formatCurrency(providerFee, 2, currency.iso)}
                        </CardDescriptionsItem>
                        <CardDescriptionsItem label={<IntlMessages id="common.customer_price" />}>
                            {formatCurrency(price, 2, currency.iso)}
                        </CardDescriptionsItem>
                    </CardDescriptions>
                );
            })}
        </Card>
    );
};

export default PriceCommissions;
