import { gql } from '@apollo/client';
import { MULTILANGUAGE_FRAGMENT } from '../common';

export const PAGINATE_PRODUCTS = gql`
    query products($sorting: SortingInput, $pagination: PaginationInput, $filter: FilterProductsInput!) {
        products(sorting: $sorting, pagination: $pagination, filter: $filter) {
            items {
                id
                active
                name
                variants {
                    sku
                }
                created_at
                deleted_at
            }
            pagination {
                page
                pages
                current_items
                total_items
                size
            }
        }
    }
`;

export const CREATE_PRODUCT_DATA = gql`
    query createProductData($eventId: String!) {
        event(id: $eventId) {
            id
            name
            languages {
                iso
                main
            }
            main_language
        }
        attributes {
            id
            name
            type
            options {
                value
                label
            }
        }
    }
`;

export const UPDATE_PRODUCT_DATA = gql`
    query updateProductData($eventId: String!, $productId: String!) {
        event(id: $eventId) {
            id
            name
            languages {
                iso
                main
            }
            main_language
        }
        product(id: $productId) {
            id
            active
            name
            description {
                ...multilanguageFragment
            }
            variants {
                sku
                stock
                sold_quantity
                available_quantity
                price
                attributes {                    
                    attribute_name_locale
                    option_name_locale                    
                    option_value
                }
            }
        }
    }
    ${MULTILANGUAGE_FRAGMENT}
`;
