import React, { useState } from 'react';
import { Row, Col, Breadcrumb, PageHeader, Form, Button, Select, DatePicker, Input, Card, Skeleton, Typography, Alert, Divider, Checkbox } from 'antd';
import IntlMessages from '../../../../util/IntlMessages';
import { useIntl } from 'react-intl';
import { useMutation, useQuery } from '@apollo/client';
import { EVENT_INFORMATIONS } from '../../../../apollo/query/event';
import BreadcrumbHomeLink from '../../../../components/BreadcrumbHomeLink';
import BadResponse from '../../../../components/BadResponse';
import { Can } from '../../../../acl';
import { useHistory } from 'react-router-dom';
import { EVENT_TYPES, SUPPORTED_LANGUAGES } from '../../../../constants/Enums';
import { DATE_FORMAT, EVENT } from '../../../../constants/App';
import { map, omit } from 'lodash';
import BadResponseErrorAlert from '../../../../components/BadResponseErrorAlert';
import SportSelect from '../../../../components/SportSelect';
import moment from 'moment';
import { includes, keys } from 'lodash';
import { MinusCircleOutlined, PlusOutlined, RocketOutlined } from '@ant-design/icons';
import { cleanObject, keyByValue } from '../../../../util/object';
import { UPDATE_EVENT } from '../../../../apollo/mutation/event';
import { defaultMutationCallback, defaultCatchException } from '../../../../apollo/callbacks';
import LocationSelect from '../../../../components/LocationSelect';
import { getAdminPath, pushNextOrFallback } from '../../../../util/router';

const EventInformationsAdminCard = ({ intl }) => {
    return (
        <Card
            title={
                <span>
                    <RocketOutlined />
                    <IntlMessages id="common.admin" />
                </span>
            }
            className="gx-card gx-bg-amber-light"
        >
            <Divider>
                <Typography.Title level={5}>WICLAX</Typography.Title>
            </Divider>
            <Form.List name="wiclax_live_standings">
                {(fields, { add, remove }) => (
                    <>
                        {fields.map(({ key, name, ...restField }) => (
                            <>
                                <Row>
                                    <Col xs={20}>
                                        <Form.Item
                                            {...restField}
                                            name={[name, 'url']}
                                            label="URL"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: intl.formatMessage({
                                                        id: 'common.field_required',
                                                    }),
                                                },
                                            ]}
                                        >
                                            <Input
                                                placeholder={
                                                    'Esempio: https://www.dtiming.it/wp-content/glive/g-live.html?f=/wp-content/glive-results/augusta-2023/7^%20Cronoscalata%20di%20Sant%27Augusta.clax'
                                                }
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={4}>
                                        <Button className="gx-mt-4 gx-btn-danger" onClick={() => remove(name)} icon={<MinusCircleOutlined />}></Button>
                                    </Col>
                                </Row>
                                <Divider />
                            </>
                        ))}
                        <Form.Item>
                            <Button className="gx-mt-4" type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                Aggiungi link classifica
                            </Button>
                        </Form.Item>
                    </>
                )}
            </Form.List>

            <Divider>
                <Typography.Title level={5}>FOTOP</Typography.Title>
            </Divider>

            <Form.List name="fotop">
                {(fields, { add, remove }) => (
                    <>
                        {fields.map(({ key, name, ...restField }) => (
                            <>
                                <Row>
                                    <Col xs={20}>
                                        <Form.Item
                                            {...restField}
                                            name={[name, 'url']}
                                            label="URL"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: intl.formatMessage({
                                                        id: 'common.field_required',
                                                    }),
                                                },
                                            ]}
                                        >
                                            <Input placeholder={'Esempio: https://keepsporting.fotop.com.br/fotos/eventos?evento={fotop_id}'} />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={4}>
                                        <Button className="gx-mt-4 gx-btn-danger" onClick={() => remove(name)} icon={<MinusCircleOutlined />}></Button>
                                    </Col>
                                </Row>
                                <Divider />
                            </>
                        ))}
                        <Form.Item>
                            <Button className="gx-mt-4" type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                Aggiungi link foto
                            </Button>
                        </Form.Item>
                    </>
                )}
            </Form.List>
        </Card>
    );
};

const EventInformationsForm = ({ event, intl }) => {
    const [form] = Form.useForm();

    const languages = event.languages?.map((l) => l.iso);
    const [availableLanguages, setAvailableLanguages] = useState(languages);
    const [selectedLanguage, setSelectedLanguage] = useState(event.main_language);
    const [contactErrors, setContactErrors] = useState([]);

    const history = useHistory();

    const [updateEvent, { loading: loadingMutation }] = useMutation(UPDATE_EVENT);

    const validateContacts = ({ contacts }) => {
        let errors = [];
        if (!contacts || contacts.length === 0) {
            return errors;
        }

        contacts.forEach((contact) => {
            const filteredKeys = keys(contact.description).filter((k) => !!contact.description[k]);
            if (!includes(filteredKeys, event.main_language)) {
                errors.push(contact.type);
            }
        });

        return errors;
    };

    const handleOk = (values) => {
        const errors = validateContacts(values);

        if (errors.length === 0) {
            setContactErrors([]);

            updateEvent({
                variables: {
                    id: event.id,
                    event: {
                        ...values,
                        date: values.date.format('YYYY-MM-DD'),
                        sport_id: parseInt(values.sport_id),
                        main_language: values.main_language.toUpperCase(),
                        languages: values.languages.map((l) => l.toUpperCase()),
                        wiclax_live_standings: (values.wiclax_live_standings || []).map((link) => link.url),
                        fotop: (values.fotop || []).map((link) => link.url),
                    },
                },
            })
                .then((response) => defaultMutationCallback(response, () => closeHandler()))
                .catch((e) => defaultCatchException(e, intl));
        } else {
            setContactErrors(errors);
        }
    };

    const closeHandler = () => {
        pushNextOrFallback(history, getAdminPath(`event/${event.id}/dashboard`));
    };

    const handleError = () => {};

    return (
        <Form
            onFinish={handleOk}
            onFinishFailed={handleError}
            layout="vertical"
            labelCol={{ span: 20 }}
            initialValues={{
                name: event.name,
                type: parseInt(event.type),
                date: moment(event.date_start).utc(),
                sport_id: event.sport.keepsporting_id,
                languages: event.languages?.map((l) => l.iso),
                main_language: event.main_language,
                location_id: event.location?.id,
                contacts: event.editable_contacts?.map((contact) => ({
                    type: contact.type,
                    value: contact.value,
                    description: cleanObject(contact.description),
                })),
                wiclax_live_standings: event.wiclax_live_standings?.map((link) => ({ url: link })),
                fotop: event.fotop?.map((link) => ({ url: link })),
                hide_date: event.hide_date || false,
            }}
            onValuesChange={(changedValues, allValues) => {
                if (changedValues.languages) {
                    setAvailableLanguages(changedValues.languages);
                }
            }}
            form={form}
            autoComplete="off"
        >
            <Row>
                <Col xs={24}>
                    <PageHeader
                        title={
                            <span>
                                <IntlMessages id="common.base_data" />
                            </span>
                        }
                        onBack={() => history.goBack()}
                        extra={[
                            <Button key="1" onClick={() => closeHandler()}>
                                <IntlMessages id="common.cancel" />
                            </Button>,
                            <Button key="2" htmlType="submit" type="primary" loading={loadingMutation}>
                                {intl.formatMessage({ id: 'common.update' }).toUpperCase()}
                            </Button>,
                        ]}
                    />
                </Col>

                <Col xs={24}>
                    <BadResponseErrorAlert />
                </Col>

                <Col xs={24} md={12}>
                    <Card
                        className="gx-card"
                        title={intl.formatMessage({
                            id: 'common.general_data',
                        })}
                    >
                        <Row>
                            <Col xs={20}>
                                <Form.Item
                                    label={intl.formatMessage({
                                        id: 'common.competition_name',
                                    })}
                                    name="name"
                                    rules={[
                                        {
                                            required: true,
                                            message: intl.formatMessage({
                                                id: 'common.field_required',
                                            }),
                                        },
                                        {
                                            min: 5,
                                            message: intl.formatMessage(
                                                {
                                                    id: 'common.validation_min_chars',
                                                },
                                                {
                                                    min: 5,
                                                }
                                            ),
                                        },
                                        {
                                            max: 125,
                                            message: intl.formatMessage(
                                                {
                                                    id: 'common.validation_max_chars',
                                                },
                                                {
                                                    max: 125,
                                                }
                                            ),
                                        },
                                    ]}
                                >
                                    <Input showCount />
                                </Form.Item>
                            </Col>

                            <Col xs={20}>
                                <Form.Item
                                    label={intl.formatMessage({
                                        id: 'common.type',
                                    })}
                                    name="type"
                                    rules={[
                                        {
                                            required: true,
                                            message: intl.formatMessage({
                                                id: 'common.field_required',
                                            }),
                                        },
                                    ]}
                                >
                                    <Select>
                                        {map(EVENT_TYPES, (value, key) => {
                                            return (
                                                <Select.Option key={key} value={value}>
                                                    {intl.formatMessage({ id: `common.event_type_${value}` })}
                                                </Select.Option>
                                            );
                                        })}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col xs={20}>
                                <Form.Item
                                    label={intl.formatMessage({
                                        id: 'events.location',
                                    })}
                                    name="location_id"
                                    trigger="onSelect"
                                    rules={[
                                        {
                                            required: true,
                                            message: intl.formatMessage({
                                                id: 'common.field_required',
                                            }),
                                        },
                                    ]}
                                >
                                    <LocationSelect />
                                </Form.Item>
                            </Col>
                            <Col xs={20}>
                                <Form.Item
                                    label={intl.formatMessage({
                                        id: 'events.date_start',
                                    })}
                                    name="date"
                                    rules={[
                                        {
                                            required: true,
                                            message: intl.formatMessage({
                                                id: 'common.field_required',
                                            }),
                                        },
                                    ]}
                                >
                                    <DatePicker format={DATE_FORMAT} />
                                </Form.Item>
                            </Col>

                            <Col xs={20}>
                                <Form.Item
                                    label={intl.formatMessage({
                                        id: 'events.hide_frontend_date',
                                    })}
                                    name="hide_date"
                                    valuePropName="checked"
                                >
                                    <Checkbox />
                                </Form.Item>
                            </Col>

                            <Col xs={20}>
                                <Form.Item
                                    label={intl.formatMessage({
                                        id: 'common.sport',
                                    })}
                                    name="sport_id"
                                    trigger="onSelect"
                                    rules={[
                                        {
                                            required: true,
                                            message: intl.formatMessage({
                                                id: 'common.field_required',
                                            }),
                                        },
                                    ]}
                                >
                                    <SportSelect />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Card>
                    <Card title={<IntlMessages id="common.languages" />} className="gx-card">
                        <Col xs={20}>
                            <Form.Item
                                label={intl.formatMessage({
                                    id: 'common.main_language',
                                })}
                                name="main_language"
                                rules={[
                                    {
                                        required: true,
                                        message: intl.formatMessage({
                                            id: 'common.field_required',
                                        }),
                                    },
                                ]}
                                help={<div className="gx-pt-2">{intl.formatMessage({ id: 'common.main_lang_cant_change' })}</div>}
                            >
                                <Select disabled={true}>
                                    {languages.map((value, index) => {
                                        return (
                                            <Select.Option key={index} value={value}>
                                                {intl.formatMessage({ id: `common.${value}` })}
                                            </Select.Option>
                                        );
                                    })}
                                </Select>
                            </Form.Item>

                            <Form.Item
                                label={intl.formatMessage({
                                    id: 'common.languages',
                                })}
                                name="languages"
                                rules={[
                                    {
                                        required: true,
                                        message: intl.formatMessage({
                                            id: 'common.field_required',
                                        }),
                                    },
                                ]}
                            >
                                <Select mode="multiple">
                                    {map(omit(SUPPORTED_LANGUAGES, event.main_language), (value, key) => {
                                        return (
                                            <Select.Option disabled={value === event.main_language} key={key} value={value}>
                                                {intl.formatMessage({ id: `common.${value}` })}
                                            </Select.Option>
                                        );
                                    })}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Card>
                </Col>
                <Col xs={24} md={12}>
                    <Card
                        title={<IntlMessages id="common.contacts" />}
                        className="gx-card"
                        extra={[
                            <Select
                                value={selectedLanguage}
                                onSelect={(value) => {
                                    setSelectedLanguage(value);
                                }}
                                style={{ width: 100 }}
                                size="small"
                            >
                                {availableLanguages.map((value, key) => {
                                    return (
                                        <Select.Option key={key} value={value}>
                                            {intl.formatMessage({ id: `common.${value}` })}
                                        </Select.Option>
                                    );
                                })}
                            </Select>,
                        ]}
                    >
                        {contactErrors.length > 0 && (
                            <Alert
                                onClose={() => {
                                    setContactErrors([]);
                                }}
                                className="gx-py-2"
                                closable={true}
                                type="error"
                                message={
                                    <span>
                                        {intl.formatMessage({ id: 'common.contacts_without_main_language' })}:&nbsp;
                                        <strong>
                                            {contactErrors
                                                .map((value) => intl.formatMessage({ id: `common.${keyByValue(EVENT.CONTACTS, value)?.toLowerCase()}` }))
                                                .join(', ')}
                                        </strong>
                                    </span>
                                }
                            />
                        )}
                        <Form.List name="contacts">
                            {(fields, { add, remove }) => (
                                <>
                                    {fields.map(({ key, name, ...restField }) => (
                                        <>
                                            <Row>
                                                <Col xs={20}>
                                                    <Form.Item
                                                        {...restField}
                                                        name={[name, 'type']}
                                                        label={intl.formatMessage({ id: 'common.type' })}
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: intl.formatMessage({
                                                                    id: 'common.field_required',
                                                                }),
                                                            },
                                                        ]}
                                                    >
                                                        <Select className="gx-w-100" placeholder={intl.formatMessage({ id: 'common.type' })}>
                                                            {map(EVENT.CONTACTS, (value, key) => {
                                                                return (
                                                                    <Select.Option value={value} key={value}>
                                                                        <IntlMessages id={`common.${key.toLowerCase()}`} />
                                                                    </Select.Option>
                                                                );
                                                            })}
                                                        </Select>
                                                    </Form.Item>
                                                    <Form.Item
                                                        {...restField}
                                                        className="gx-w-100"
                                                        label={intl.formatMessage({ id: 'common.description' })}
                                                        name={[name, 'description', selectedLanguage]}
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: intl.formatMessage({
                                                                    id: 'common.field_required',
                                                                }),
                                                            },
                                                        ]}
                                                    >
                                                        <Input placeholder={intl.formatMessage({ id: 'common.example_organization_contact' })} />
                                                    </Form.Item>
                                                    <Form.Item
                                                        {...restField}
                                                        name={[name, 'value']}
                                                        label={intl.formatMessage({ id: 'common.value' })}
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: intl.formatMessage({
                                                                    id: 'common.field_required',
                                                                }),
                                                            },
                                                        ]}
                                                    >
                                                        <Input placeholder={intl.formatMessage({ id: 'common.example_mail' })} />
                                                    </Form.Item>
                                                </Col>
                                                <Col xs={4}>
                                                    <Button
                                                        className="gx-mt-4 gx-btn-danger"
                                                        onClick={() => remove(name)}
                                                        icon={<MinusCircleOutlined />}
                                                    ></Button>
                                                </Col>
                                            </Row>
                                            <Divider />
                                        </>
                                    ))}
                                    <Form.Item>
                                        <Button className="gx-mt-4" type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                            <IntlMessages id="common.add_contact" />
                                        </Button>
                                    </Form.Item>
                                </>
                            )}
                        </Form.List>
                    </Card>

                    <Can I="manage" a="Organization">
                        <EventInformationsAdminCard intl={intl} />
                    </Can>
                </Col>
            </Row>
        </Form>
    );
};

const EventInformations = ({ match }) => {
    const { params } = match;

    const intl = useIntl();
    const { data, loading, error } = useQuery(EVENT_INFORMATIONS, { variables: { id: params.id } });

    if (error) {
        return <BadResponse title={intl.formatMessage({ id: '500.ooops' })} subtitle={intl.formatMessage({ id: '500.something_wrong' })} />;
    }

    return (
        <>
            <Row>
                <Col md={24}>
                    <Breadcrumb className="gx-pb-3">
                        <Breadcrumb.Item>
                            <BreadcrumbHomeLink />
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>{data?.event.name}</Breadcrumb.Item>
                        <Breadcrumb.Item>
                            <IntlMessages id="common.base_data" />
                        </Breadcrumb.Item>
                    </Breadcrumb>
                </Col>
            </Row>

            <Row>
                <Col xs={24}>{!loading ? <EventInformationsForm loading={loading} event={data?.event} intl={intl} /> : <Skeleton />}</Col>
            </Row>
        </>
    );
};

export default EventInformations;
