import React, { useEffect, useState } from 'react';
import IntlMessages from '../../../../util/IntlMessages';
import { Row, Col, Breadcrumb, PageHeader, Collapse, Space, Badge, Button, Input, Table, Card, Tag, Select } from 'antd';
import BreadcrumbHomeLink from '../../../../components/BreadcrumbHomeLink';
import { usePersistedState } from '../../../../hooks/usePersistedState';
import { useDebounce } from 'use-debounce';
import { useQuery } from '@apollo/client';
import { debounceSearchFilterDelay } from '../../../../util/config';
import { useIntl } from 'react-intl';
import { countActiveFilters } from '../../../../util/object';
import { CloseCircleOutlined, CheckCircleOutlined } from '@ant-design/icons';
import BadResponse from '../../../../components/BadResponse';
import { paginationOptions } from '../../../../constants/PaginationOptions';
import { PRIMARY_COLOR, SUCCESS_COLOR } from '../../../../constants/ThemeSetting';
import { formatDate } from '../../../../util/date';
import { DATE_FORMAT } from '../../../../constants/App';
import { useHistory } from 'react-router-dom';
import { GQL_EXPORT_PROFILES, PAGINATED_PROFILES } from '../../../../apollo/query/profiles';
import SportSelect from '../../../../components/SportSelect';
import { SUPPORTED_LANGUAGES } from '../../../../constants/Enums';
import CountrySelect from '../../../../components/LocationSelect/CountrySelect';
import { ExportButton } from '../../../../components/FileButton';

const getColumns = () => {
    return [
        {
            title: <IntlMessages id="common.email" />,
            dataIndex: 'email',
            key: 'email',
        },
        {
            title: <IntlMessages id="common.name" />,
            dataIndex: 'first_name',
            key: 'first_name',
        },
        {
            title: <IntlMessages id="common.last_name" />,
            dataIndex: 'last_name',
        },
        {
            title: <IntlMessages id="common.gender" />,
            dataIndex: 'gender',
            align: 'center',
        },
        {
            title: <IntlMessages id="common.keepsporting_user" />,
            dataIndex: 'keepsporting_user_id',
            key: 'keepsporting_user_id',
            align: 'center',
            width: 100,
            render: (value) => {
                return value ? <CheckCircleOutlined style={{ color: SUCCESS_COLOR }} /> : <CloseCircleOutlined />;
            },
        },
        {
            title: <IntlMessages id="common.language" />,
            dataIndex: 'language',
            key: 'language',
        },
        {
            title: <IntlMessages id="common.sport" />,
            dataIndex: 'sports',
            key: 'sports',
            render(sports) {
                if (!sports || sports.length === 0) return null;
                return sports.map((sport) => (
                    <Tag className="gx-mb-0" key={sport.id}>
                        {sport.name}
                    </Tag>
                ));
            },
        },
        {
            title: <IntlMessages id="common.secondary_administrative_area" />,
            dataIndex: ['location', 'secondary_administrative_area'],
            key: 'secondary_administrative_area',
            render(location) {
                if (!location) return null;
                return <span>{location.name}</span>;
            },
        },
        {
            title: <IntlMessages id="common.primary_administrative_area" />,
            dataIndex: ['location', 'primary_administrative_area'],
            key: 'primary_administrative_area',
            render(location) {
                if (!location) return null;
                return <span>{location.name}</span>;
            },
        },
        {
            title: <IntlMessages id="common.country" />,
            dataIndex: ['location', 'state'],
            key: 'country',
            render(country) {
                if (!country) return null;
                return <span>{country}</span>;
            },
        },
        {
            title: <IntlMessages id="common.type" />,
            dataIndex: 'types',
            key: 'types',
            render(types) {
                if (!types || types.length === 0) return null;
                return types.map((type) => (
                    <Tag className="gx-mb-0" key={type}>
                        {type}
                    </Tag>
                ));
            },
        },
        {
            title: <IntlMessages id="common.last_activity_at" />,
            dataIndex: 'last_activity_at',
            key: 'last_activity_at',
            render(last_activity_at) {
                return formatDate(last_activity_at, DATE_FORMAT, null, true);
            },
        },
    ];
};

const Profiles = () => {
    const intl = useIntl();
    const history = useHistory();
    const DEFAULT_FILTERS = { term: '' };

    const [filters, setFilters] = useState(DEFAULT_FILTERS);
    const [paginationState, setPaginationState] = usePersistedState('adminEventsPagination', { current: 1, pageSize: 10 });
    const [sortingState, setSortingState] = usePersistedState('adminEventsSorting', { key: 'created_at', direction: -1 });

    const updateFilters = (key, value) => {
        if (paginationState.current !== 1) {
            setPaginationState({ ...paginationState, current: 1 });
        }
        setFilters({ ...filters, [key]: value });
    };

    const [debouncedTerm] = useDebounce(filters?.term, debounceSearchFilterDelay);

    const { data, loading, error, previousData } = useQuery(PAGINATED_PROFILES, {
        variables: {
            filter: {
                ...filters,
                term: debouncedTerm,
            },
            pagination: {
                page: paginationState.current,
                limit: paginationState.pageSize,
            },
            sorting: sortingState,
        },
        onError: (error) => {
            setFilters(DEFAULT_FILTERS);
        },
    });

    useEffect(() => {
        if (data && data.profiles) {
            const totalPages = data.profiles.pagination?.pages;
            const currentPage = data.profiles.pagination?.page;
            if (currentPage > totalPages) {
                setPaginationState({ ...paginationState, current: 1 });
            }
        }
    }, [data]);

    if (error) {
        return <BadResponse title={intl.formatMessage({ id: '500.ooops' })} subtitle={intl.formatMessage({ id: '500.something_wrong' })} />;
    }

    //reuse previous data while loading
    let tableData = previousData
        ? previousData.profiles
        : {
              items: [],
              pagination: {},
          };

    if (!loading && data) {
        tableData = data.profiles;
    }

    return (
        <>
            <Row>
                <Col md={24}>
                    <Breadcrumb className="gx-pb-3">
                        <Breadcrumb.Item>
                            <BreadcrumbHomeLink />
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>
                            <IntlMessages id="common.management" />
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>
                            <IntlMessages id="common.user_profiles" />
                        </Breadcrumb.Item>
                    </Breadcrumb>
                </Col>
            </Row>

            <Row>
                <Col xs={24}>
                    <PageHeader
                        title={
                            <span>
                                <IntlMessages id="common.user_profiles" />
                            </span>
                        }
                        extra={
                            <Space>
                                <ExportButton
                                    key="print"
                                    buttonClassName="gx-mx-2 gx-mb-0"
                                    query={{
                                        query: GQL_EXPORT_PROFILES,
                                        variables: {
                                            filter: filters,
                                        },
                                        fetchPolicy: 'no-cache',
                                    }}
                                    context="exportProfiles"
                                />
                            </Space>
                        }
                    />
                </Col>
                <Col xs={24}>
                    <Collapse className="gx-panel gx-mb-3" defaultActiveKey={countActiveFilters(filters) > 0 ? ['1'] : null}>
                        <Collapse.Panel
                            header={
                                <Space className="gx-w-100 gx-justify-content-between">
                                    <div className="">
                                        <IntlMessages id="common.filters" />
                                        <Badge className="gx-ml-2" count={countActiveFilters(filters, 0)} style={{ backgroundColor: PRIMARY_COLOR }} />
                                    </div>
                                </Space>
                            }
                            size="small"
                            className="gx-panel"
                            key="1"
                            bordered={false}
                            extra={[
                                <Button
                                    size="small"
                                    key="reset-btn"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        setFilters(DEFAULT_FILTERS);
                                    }}
                                    className="gx-mb-0"
                                >
                                    <CloseCircleOutlined className="gx-pr-1" />
                                    <IntlMessages id="common.clear_filters" />
                                </Button>,
                            ]}
                        >
                            <Row gutter={[16, 16]}>
                                <Col xs={24} md={6} lg={6} xl={5}>
                                    <Input.Search
                                        className="gx-mb-0"
                                        value={filters?.term}
                                        onChange={(e) => {
                                            const { value } = e.target;
                                            updateFilters('term', value);
                                        }}
                                        style={{ minWidth: 200 }}
                                        placeholder={intl.formatMessage({ id: 'common.search' })}
                                    />
                                </Col>
                                <Col xs={24} md={6} lg={6} xl={4}>
                                    <SportSelect
                                        style={{ minWidth: 200, width: '100%' }}
                                        allowClear={true}
                                        value={filters?.sport_id?.toString()}
                                        onSelect={(value) => {
                                            if (Boolean(value)) {
                                                updateFilters('sport_id', Number(value));
                                            }
                                        }}
                                        onChange={(value) => {
                                            if (!value) {
                                                updateFilters('sport_id', null);
                                            }
                                        }}
                                        placeholder={intl.formatMessage({ id: 'common.sport' })}
                                    />
                                </Col>

                                <Col xs={24} md={6} lg={6} xl={4}>
                                    <Select
                                        style={{ minWidth: 200, width: '100%' }}
                                        allowClear
                                        value={filters?.is_keepsporting_user}
                                        onChange={(value) => {
                                            updateFilters('is_keepsporting_user', value >= 0 ? value : null);
                                        }}
                                        placeholder={intl.formatMessage({ id: 'common.keepsporting_user' })}
                                    >
                                        <Select.Option value={1}>È utente KS</Select.Option>
                                        <Select.Option value={0}>Non è un utente KS</Select.Option>
                                    </Select>
                                </Col>
                                <Col xs={24} md={6} lg={6} xl={4}>
                                    <Select
                                        style={{ minWidth: 200, width: '100%' }}
                                        mode="multiple"
                                        allowClear={true}
                                        value={filters?.languages}
                                        placeholder={intl.formatMessage({ id: 'common.language' })}
                                        onChange={(value) => {
                                            updateFilters('languages', value);
                                        }}
                                    >
                                        {Object.values(SUPPORTED_LANGUAGES).map((lang) => {
                                            return (
                                                <Select.Option key={lang} value={lang}>
                                                    <IntlMessages id={`common.${lang}`} />
                                                </Select.Option>
                                            );
                                        })}
                                    </Select>
                                </Col>
                                <Col xs={24} md={6} lg={6} xl={4}>
                                    <CountrySelect
                                        style={{ minWidth: 200, width: '100%' }}
                                        value={filters?.country_iso}
                                        allowClear={true}
                                        onSelect={(value) => {
                                            if (Boolean(value)) {
                                                updateFilters('country_iso', value);
                                            } else {
                                                updateFilters('country_iso', null);
                                            }
                                        }}
                                        placeholder={intl.formatMessage({ id: 'common.country' })}
                                    />
                                </Col>
                            </Row>
                        </Collapse.Panel>
                    </Collapse>
                </Col>
                <Col xs={24}>
                    <Card className="gx-card">
                        <Table
                            rowKey="id"
                            onChange={(pagination, filters, sorter, { action }) => {
                                if (action === 'sort') {
                                    setSortingState({ key: sorter.field, direction: sorter.order === 'ascend' ? 1 : -1 });
                                }
                            }}
                            loading={loading}
                            dataSource={tableData.items}
                            columns={getColumns(history, intl)}
                            pagination={{
                                ...paginationOptions,
                                total: tableData.pagination.total_items,
                                current: tableData.pagination.page,
                                pageSize: tableData.pagination.limit,
                                onChange: (current, pageSize) => {
                                    setPaginationState({ current, pageSize });
                                },
                            }}
                        />
                    </Card>
                </Col>
            </Row>
        </>
    );
};

export default Profiles;
