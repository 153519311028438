import React, { useContext } from 'react';
import { isObject, keys } from 'lodash';
import { Divider } from 'antd';

const DescriptionContext = React.createContext();

export const CardDescriptionsItem = ({ label, children, column = null, className = '' }) => {
    const context = useContext(DescriptionContext);

    column = column ? column : context.column;

    let colClasses = '';

    if (isObject(column)) {
        colClasses = keys(column)
            .map((k) => `ant-col-${k}-${24 / column[k]}`)
            .join(' ');
    } else {
        colClasses = `ant-col-xs-${24 / column}`;
    }

    return (
        <div className={`ks-descriptions-item ant-col ${colClasses} ${className}`}>
            <div className="label">{label}</div>
            <div className="value">{children || '-'}</div>
        </div>
    );
};

const CardDescriptions = ({ title = '', column = 2, children, className = '' }) => {
    return (
        <div className={`ks-card-descriptions ant-row ${className}`}>
            {title && (
                <div className="ks-card-descriptions-title gx-mt-3">
                    <div>
                        {title}
                        <Divider />
                    </div>
                </div>
            )}
            <DescriptionContext.Provider value={{ column: column }}>{children}</DescriptionContext.Provider>
        </div>
    );
};

export default CardDescriptions;
