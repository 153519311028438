import React from 'react';
import { useHistory } from 'react-router';
import { Avatar, Popover, Skeleton, Divider } from 'antd';
import IntlMessages from '../../util/IntlMessages';
import { Link } from 'react-router-dom';
import { GQL_QUERY_ME } from '../../apollo/query/user';
import { useQuery } from '@apollo/client';
import { localStorageClass } from '../../apollo/local/storage';
import { getAdminPath, getProfileImage } from '../../util/router';
import { getInitials } from '../../util/string';
import { PRIMARY_COLOR } from '../../constants/ThemeSetting';
import { LogoutOutlined, RocketOutlined } from '@ant-design/icons';
import { Can } from '../../acl';

const UserOptions = () => {
    const history = useHistory();
    return (
        <ul className="gx-user-popover">
            <Can I="manage" a="Organization">
                <li>
                    <Link className="gx-d-block" to={getAdminPath('management')}>
                        <RocketOutlined />
                        <IntlMessages id="common.management" />
                    </Link>
                </li>
                <li>
                    <Divider />
                </li>
            </Can>
            <li>
                <a
                    className="gx-d-block"
                    onClick={(e) => {
                        e.stopPropagation();
                        localStorageClass.logout();
                        history.push(getAdminPath());
                    }}
                >
                    <LogoutOutlined className="gx-pr-1" /> <IntlMessages id="common.logout" />
                </a>
            </li>
        </ul>
    );
};

const UserProfile = () => {
    //TODO riorganizzare query startup, no cache per evitare duplicazione chiamate
    const { data, loading, error } = useQuery(GQL_QUERY_ME, {
        fetchPolicy: 'no-cache',
    });

    if (loading) return <Skeleton.Avatar />;

    if (error || !data) return <div></div>;

    const { me } = data;
    const initials = getInitials(`${me.first_name} ${me.last_name}`);

    return (
        <div>
            <Popover className="gx-d-block" placement="bottomRight" content={<UserOptions />} trigger="click">
                <span className="gx-avatar-name gx-mr-1">
                    <small>{me.full_name}</small>
                </span>

                <Avatar
                    style={{
                        backgroundColor: PRIMARY_COLOR,
                    }}
                    className={'gx-size-40 gx-pointer gx-mr-1'}
                    alt={me.full_name}
                    src={getProfileImage(me.keepsporting_id)}
                >
                    {initials.toUpperCase()}
                </Avatar>

                <i className="icon icon-chevron-down gx-fs-xxs" />
            </Popover>
        </div>
    );
};

export default UserProfile;
