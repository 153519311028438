import React, { memo, useEffect } from 'react';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import { ConfigProvider } from 'antd';
import { IntlProvider } from 'react-intl';

import AppLocale from 'lngProvider';
import AdminApp from './AdminApp';

import {
    LAYOUT_TYPE_BOXED,
    LAYOUT_TYPE_FRAMED,
    LAYOUT_TYPE_FULL,
    NAV_STYLE_ABOVE_HEADER,
    NAV_STYLE_BELOW_HEADER,
    NAV_STYLE_DARK_HORIZONTAL,
    NAV_STYLE_DEFAULT_HORIZONTAL,
    NAV_STYLE_INSIDE_HEADER_HORIZONTAL,
} from '../../constants/ThemeSetting';

import { useReactiveVar } from '@apollo/client';
import { layoutSettingsVar } from '../../apollo/local/cache';
import { localStorageClass } from '../../apollo/local/storage';
import { getAdminPath } from '../../util/router';
import NotFound from '../NotFound';
import UnderMaintenance from '../UnderMaintenance';
import SignInEmp from '../SignInEmp';
import { MenuProvider } from '../../contexts/MenuContext';
import { getInitLanguage } from '../../lngProvider/index';

const RestrictedAdminRoute = ({ component: Component, location, localStorageClass, ...rest }) => {
    return (
        <Route
            {...rest}
            render={(props) =>
                localStorageClass.isLogged() ? (
                    <Component {...props} />
                ) : (
                    <Redirect
                        to={{
                            pathname: '/signin-emp',
                            state: { from: location },
                        }}
                    />
                )
            }
        />
    );
};

const App = ({ ability }) => {
    let { locale, navStyle, layoutType } = useReactiveVar(layoutSettingsVar);

    const location = useLocation();

    const setLayoutType = (layoutType) => {
        if (layoutType === LAYOUT_TYPE_FULL) {
            document.body.classList.remove('boxed-layout');
            document.body.classList.remove('framed-layout');
            document.body.classList.add('full-layout');
        } else if (layoutType === LAYOUT_TYPE_BOXED) {
            document.body.classList.remove('full-layout');
            document.body.classList.remove('framed-layout');
            document.body.classList.add('boxed-layout');
        } else if (layoutType === LAYOUT_TYPE_FRAMED) {
            document.body.classList.remove('boxed-layout');
            document.body.classList.remove('full-layout');
            document.body.classList.add('framed-layout');
        }
    };

    const setNavStyle = (navStyle) => {
        if (
            navStyle === NAV_STYLE_DEFAULT_HORIZONTAL ||
            navStyle === NAV_STYLE_DARK_HORIZONTAL ||
            navStyle === NAV_STYLE_INSIDE_HEADER_HORIZONTAL ||
            navStyle === NAV_STYLE_ABOVE_HEADER ||
            navStyle === NAV_STYLE_BELOW_HEADER
        ) {
            document.body.classList.add('full-scroll');
            document.body.classList.add('horizontal-layout');
        } else {
            document.body.classList.remove('full-scroll');
            document.body.classList.remove('horizontal-layout');
        }
    };

    const initLocale = getInitLanguage(locale);

    const currentAppLocale = AppLocale[initLocale];

    useEffect(() => {
        setLayoutType(layoutType);
        setNavStyle(navStyle);

        document.documentElement.lang = initLocale;
    });

    useEffect(() => {
        //ReactGA.pageview(window.location.pathname + window.location.search);
    }, [location]);

    return (
        <MenuProvider>
            <ConfigProvider locale={currentAppLocale.antd}>
                <IntlProvider locale={currentAppLocale.locale} messages={currentAppLocale.messages}>
                    <Switch>
                        <Route path="/under-maintenance" component={UnderMaintenance} />

                        <Route key="signin-emp" exact path="/signin-emp/:organization?/:token?" component={SignInEmp} />

                        <RestrictedAdminRoute
                            key="restricted-routes"
                            path={getAdminPath()}
                            localStorageClass={localStorageClass}
                            location={location}
                            ability={ability}
                            component={AdminApp}
                        />
                        <Route path="*">
                            <NotFound redirectToURL={getAdminPath()} />
                        </Route>
                    </Switch>
                </IntlProvider>
            </ConfigProvider>
        </MenuProvider>
    );
};

export default memo(App);
