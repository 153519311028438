import { HomeOutlined } from '@ant-design/icons';
import React from 'react';
import { Link } from 'react-router-dom';
import { Breadcrumb } from 'antd';

export const BreadcrumbHomeLink = () => {
    return (
        <Link to="dashboard">
            <HomeOutlined />
        </Link>
    );
};

const BreadcrumbBuilder = ({ items = [], className = 'gx-pb-3' }) => {
    return (
        <Breadcrumb className={className}>
            <Breadcrumb.Item>
                <BreadcrumbHomeLink />
            </Breadcrumb.Item>
            {items.map((item, index) => {
                return (
                    <Breadcrumb.Item className={item.link ? 'gx-text-primary' : null} key={index}>
                        <Link to={item.link || '#'}>{item.text}</Link>
                    </Breadcrumb.Item>
                );
            })}
        </Breadcrumb>
    );
};

export default BreadcrumbBuilder;
