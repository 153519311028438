import { gql } from '@apollo/client';

export const GQL_LOGIN = gql`
    mutation Login($username: String!, $password: String!, $frontend: Boolean! = false) {
        login(username: $username, password: $password, frontend: $frontend) {
            token
            refresh_token
            expires_at
        }
    }
`;

export const GQL_AUTH_TOKEN_LOGIN = gql`
    mutation authTokenLogin($token: String!, $organization: Int!) {
        authTokenLogin(token: $token, organization: $organization) {
            token
            refresh_token
            expires_at
        }
    }
`;

//String format used in fetch request (not gql!)
export const GQL_REFRESH_TOKEN = `
  mutation refreshToken($token: String!){
    refreshToken(token: $token){
      token
      refresh_token
      expires_at
    }
  }
`;

export const GQL_USER_UPDATE = gql`
    mutation updateUser($user: UpdateUserInput!) {
        updateUser(user: $user)
    }
`;

export const GQL_USER_DELETE = gql`
    mutation deleteUser($id: String!) {
        deleteUser(id: $id)
    }
`;

export const GQL_USER_ADD_NEW = gql`
    mutation createUser($user: CreateUserInput!) {
        createUser(user: $user)
    }
`;

export const GQL_ADMIN_USER_ADD_NEW = gql`
    mutation adminCreateUser($user: AdminCreateUserInput!) {
        adminCreateUser(user: $user)
    }
`;

export const GQL_ADMIN_USER_UPDATE = gql`
    mutation adminUpdateUser($id: String!, $user: AdminUpdateUserInput!) {
        adminUpdateUser(id: $id, user: $user)
    }
`;

export const GQL_CHANGE_ORGANIZATION = gql`
    mutation changeOrganization($id: String!) {
        changeOrganization(id: $id)
    }
`;
