import React from 'react';
import { Layout } from 'antd';
import { Link } from 'react-router-dom';
import UserProfile from '../../containers/Topbar/UserProfile';
import { NAV_STYLE_DRAWER, NAV_STYLE_FIXED, NAV_STYLE_MINI_SIDEBAR, TAB_SIZE } from '../../constants/ThemeSetting';
import { layoutSettingsVar, globalSettingsVar } from '../../apollo/local/cache';
import { useReactiveVar } from '@apollo/client';
import { getAdminPath } from '../../util/router';

const { Header } = Layout;

const Topbar = () => {
    var { navStyle } = useReactiveVar(layoutSettingsVar);
    var { width, sideBarCollapsed } = useReactiveVar(globalSettingsVar);
    const logoClassName = sideBarCollapsed ? 'gx-d-lg-block' : 'gx-d-lg-none';

    return (
        <Header style={{ padding: 0 }}>
            {navStyle === NAV_STYLE_DRAWER || ((navStyle === NAV_STYLE_FIXED || navStyle === NAV_STYLE_MINI_SIDEBAR) && width < TAB_SIZE) ? (
                <div className="gx-linebar gx-mr-3">
                    <i
                        className="gx-icon-btn icon icon-menu"
                        onClick={(e) => {
                            e.stopPropagation();
                            globalSettingsVar({
                                ...globalSettingsVar(),
                                navCollapsed: true,
                            });
                        }}
                    />
                </div>
            ) : null}

            <Link to={getAdminPath()} className={` ${logoClassName} gx-pointer`}>
                <img alt="Keepsporing" className="gx-d-none gx-d-md-block" src="/assets/images/keepsporting_logo.png" height={34} />
                <img alt="Keepsporing" className="gx-d-block gx-d-md-none" src="/assets/images/keepsporting-footer.png" height={28} />
            </Link>

            <ul className="gx-header-notifications gx-ml-auto">
                <li className="gx-notify">
                    <UserProfile />
                </li>
            </ul>
        </Header>
    );
};

export default Topbar;
