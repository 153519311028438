import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { PageHeader, Breadcrumb, Row, Col, Card, Table, Input, Button, Tabs, Form, InputNumber, Typography, Alert, message, Select, Space, Modal } from 'antd';
import { useIntl } from 'react-intl';
import { EVENT_DISCOUNTS, EXPORT_EVENT_DISCOUNTS, PAGINATED_EVENT_DISCOUNTS } from '../../../../apollo/query/event';
import BadResponse from '../../../../components/BadResponse';
import BreadcrumbHomeLink from '../../../../components/BreadcrumbHomeLink';
import { paginationOptions } from '../../../../constants/PaginationOptions';
import { formatDateTime } from '../../../../util/date';
import IntlMessages from '../../../../util/IntlMessages';
import { formatCurrency, formatPercent } from '../../../../util/string';
import CircularProgress from '../../../../components/CircularProgress';
import DevButton from '../../../../components/Dev/DevButton';
import { requiredRule } from '../../../../util/form';
import { roundNumber } from '../../../../util/number';
import { defaultCatchException } from '../../../../apollo/callbacks';
import { DELETE_DISCOUNTS, DELETE_SUBSCRIPTION_DISCOUNT, GENERATE_DISCOUNTS, UPDATE_SUBSCRIPTION_DISCOUNT } from '../../../../apollo/mutation/event';
import { ExclamationCircleOutlined, FileExcelOutlined } from '@ant-design/icons';
import React, { useState } from 'react';
import { getSafeISOLanguage } from '../../../../lngProvider';
import { usePersistedState } from '../../../../hooks/usePersistedState';
import { includes } from 'lodash';

const ExportDiscountsButton = ({ event }) => {
    const intl = useIntl();

    const [exportDiscounts, { loading }] = useLazyQuery(EXPORT_EVENT_DISCOUNTS, {
        fetchPolicy: 'no-cache',
    });

    return (
        <Button
            loading={loading}
            onClick={() => {
                exportDiscounts({
                    variables: {
                        id: event.id,
                        locale: getSafeISOLanguage(intl.locale),
                    },
                })
                    .then(({ data }) => {
                        if (data?.exportDiscounts) {
                            const link = document.createElement('a');
                            link.href = data.exportDiscounts;
                            link.download = true;
                            link.click();
                            link.remove();
                        } else {
                            message.error(intl.formatMessage({ id: 'common.default_mutation_error' }));
                        }
                    })
                    .catch((e) => defaultCatchException(e, intl));
            }}
        >
            <FileExcelOutlined /> <IntlMessages id="common.export_xls" />
        </Button>
    );
};

const DiscountsTable = ({ event, type }) => {
    const intl = useIntl();

    const [deleteDiscounts, { loading: loadingDeleteMutation }] = useMutation(DELETE_DISCOUNTS);

    const [paginationState, setPaginationState] = useState({ current: 1, pageSize: 10 });

    const { data, loading, error, previousData, refetch } = useQuery(PAGINATED_EVENT_DISCOUNTS, {
        variables: {
            filter: {
                event_id: event.id,
                type: type,
            },
            pagination: {
                page: paginationState.current,
                limit: paginationState.pageSize,
            },
        },
    });

    const onDeleteDiscount = (id) => {
        Modal.confirm({
            title: intl.formatMessage({ id: 'common.warning' }),
            icon: <ExclamationCircleOutlined />,
            content: intl.formatMessage({
                id: 'common.delete_discount_code_text',
            }),
            okText: intl.formatMessage({ id: 'common.delete' }),
            cancelText: intl.formatMessage({ id: 'common.no' }),
            okType: 'danger',
            okButtonProps: {
                loading: loadingDeleteMutation,
            },
            onOk() {
                deleteDiscounts({ variables: { ids: [id] } })
                    .then(() => {
                        refetch();
                    })
                    .catch((e) => {
                        defaultCatchException(e, intl);
                    });
            },
        });
    };

    if (error) {
        return <BadResponse title={intl.formatMessage({ id: '500.ooops' })} subtitle={intl.formatMessage({ id: '500.something_wrong' })} />;
    }

    //reuse previous data while loading
    let tableData = previousData
        ? previousData.eventDiscounts
        : {
              items: [],
              pagination: {},
          };

    if (!loading && data) {
        tableData = data.eventDiscounts;
    }

    const getColumns = () => {
        return [
            {
                title: <IntlMessages id="common.code" />,
                dataIndex: 'code',
                fixed: 'left',
                render(value) {
                    return <Typography.Text copyable>{value}</Typography.Text>;
                },
            },
            {
                title: <IntlMessages id="common.type" />,
                dataIndex: 'type',
                render: (type) => {
                    return <IntlMessages id={`common.${type}_discount`} />;
                },
            },
            {
                title: <IntlMessages id="common.percent" />,
                dataIndex: 'percent',
                render(value) {
                    return formatPercent(value / 100);
                },
            },
            {
                title: <IntlMessages id="common.fixed" />,
                dataIndex: 'fixed',
                render(value) {
                    return formatCurrency(value / 100, 2, event.payments.location.currency.iso);
                },
            },
            type === 'manual'
                ? {
                      title: <IntlMessages id="common.used_at" />,
                      dataIndex: 'used_at',
                      render(value) {
                          return value ? formatDateTime(value) : null;
                      },
                  }
                : null,
            type === 'manual'
                ? {
                      title: <IntlMessages id="common.expire_at" />,
                      dataIndex: 'expires_at',
                      render(value) {
                          return value ? formatDateTime(value) : null;
                      },
                  }
                : null,
            type === 'reusable'
                ? {
                      title: <IntlMessages id="common.count" />,
                      align: 'left',
                      width: 120,
                      dataIndex: 'used_times',
                      render(value) {
                          return value || 0;
                      },
                  }
                : null,

            {
                title: <IntlMessages id="common.createdAt" />,
                dataIndex: 'created_at',
                render(value) {
                    return formatDateTime(value);
                },
            },
            {
                title: <IntlMessages id="common.actions" />,
                dataIndex: 'id',
                render(id) {
                    return (
                        <Button
                            className="gx-mb-0"
                            onClick={() => {
                                onDeleteDiscount(id);
                            }}
                        >
                            <IntlMessages id="common.delete" />
                        </Button>
                    );
                },
            },
        ].filter(Boolean);
    };

    return (
        <div>
            <Typography.Text className="gx-text-uppercase">
                <IntlMessages id="common.discount_codes" />
            </Typography.Text>

            <Table
                loading={loading}
                dataSource={tableData.items}
                columns={getColumns()}
                rowKey="id"
                scroll={{
                    x: 1300,
                }}
                pagination={{
                    ...paginationOptions,
                    total: tableData.pagination.total_items,
                    current: tableData.pagination.page,
                    pageSize: tableData.pagination.limit,
                    onChange: (current, pageSize) => {
                        setPaginationState({ current, pageSize });
                    },
                }}
                className="gx-mt-3"
            />
        </div>
    );
};

const AutomaticGeneratedDiscounts = ({ event, activeEvents, refetch }) => {
    const intl = useIntl();

    const [form] = Form.useForm();

    const [updateSubscriptionDiscount, { loading }] = useMutation(UPDATE_SUBSCRIPTION_DISCOUNT);
    const [deleteSubscriptionDiscount, { loading: deleteLoading }] = useMutation(DELETE_SUBSCRIPTION_DISCOUNT);

    const onFinish = (values) => {
        if (!values.fixed && !values.percent) {
            message.error(intl.formatMessage({ id: 'common.error_percent_fixed_values_both_zero' }));
            return;
        }

        updateSubscriptionDiscount({
            variables: {
                id: event.id,
                input: {
                    percent: roundNumber(values.percent * 100),
                    fixed: roundNumber(values.fixed * 100),
                    threshold: values.threshold,
                    event_id: values.event_id,
                    expire_in_days: values.expire_in_days,
                },
            },
        })
            .then(() => {
                refetch();
                message.success(intl.formatMessage({ id: 'common.edit_successful' }));
            })
            .catch((e) => defaultCatchException(e, intl));
    };

    const discountValues = event.discount?.subscriptions
        ? {
              ...event.discount.subscriptions,
              fixed: roundNumber(event.discount.subscriptions.fixed / 100, 2),
              percent: roundNumber(event.discount.subscriptions.percent / 100, 2),
          }
        : null;

    return (
        <div>
            {event.discount?.subscriptions?.event_id && (
                <div className="gx-mt-0 gx-mb-4">
                    <Alert showIcon type="warning" description={<div></div>} message={<IntlMessages id="common.discount_active" />} />
                </div>
            )}
            <Form
                form={form}
                layout="vertical"
                onFinish={onFinish}
                initialValues={{
                    threshold: 1,
                    expire_in_days: 0,
                    ...discountValues,
                }}
            >
                <Row>
                    <Col xs={24} md={6}>
                        <Form.Item name="percent" label={<IntlMessages id="common.percent_discount" />}>
                            <InputNumber min={0} className="gx-w-100" addonAfter="%" />
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={6}>
                        <Form.Item name="fixed" label={<IntlMessages id="common.fixed_discount" />}>
                            <InputNumber min={0} className="gx-w-100" addonAfter={event.payments.location.currency.symbol} />
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={6}>
                        <Form.Item name="threshold" rules={[requiredRule(intl)]} label={<IntlMessages id="common.subscriptions_races_threshold" />}>
                            <InputNumber min={1} className="gx-w-100" />
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={6}>
                        <Form.Item name="expire_in_days" rules={[requiredRule(intl)]} label={<IntlMessages id="common.subscriptions_races_threshold" />}>
                            <Select>
                                {[0, 1, 3, 5, 10, 15, 30, 60, 90].map((v) => {
                                    return (
                                        <Select.Option key={v} value={v}>
                                            {v > 0 ? (
                                                <span>
                                                    {v} <IntlMessages id="common.day_short" />
                                                </span>
                                            ) : (
                                                <IntlMessages id="common.no_expiration" />
                                            )}
                                        </Select.Option>
                                    );
                                })}
                            </Select>
                        </Form.Item>
                    </Col>

                    <Col xs={24} md={10}>
                        <Form.Item name="event_id" rules={[requiredRule(intl)]} label={<IntlMessages id="common.active_events" />}>
                            <Select disabled={activeEvents.length === 0}>
                                {activeEvents.map((event) => {
                                    return (
                                        <Select.Option key={event.id} value={event.id}>
                                            {event.name}
                                        </Select.Option>
                                    );
                                })}
                            </Select>
                        </Form.Item>
                    </Col>

                    <Col xs={24} md={14} className="gx-text-right">
                        <Space className="gx-mt-4">
                            <Button
                                loading={deleteLoading}
                                className="gx-mb-0"
                                disabled={!event.discount?.subscriptions?.event_id}
                                onClick={() => {
                                    deleteSubscriptionDiscount({
                                        variables: {
                                            id: event.id,
                                        },
                                    })
                                        .then(() => {
                                            refetch();
                                            form.resetFields();
                                            message.success(intl.formatMessage({ id: 'common.edit_successful' }));
                                        })
                                        .catch((e) => defaultCatchException(e, intl));
                                }}
                            >
                                <IntlMessages id="common.cancel" />
                            </Button>
                            <Form.Item className="gx-mb-0 ">
                                <Button className="gx-mb-0" loading={loading} disabled={activeEvents.length === 0} htmlType="submit" type="primary">
                                    <IntlMessages id="common.generate" />
                                </Button>
                            </Form.Item>
                        </Space>
                    </Col>
                </Row>
            </Form>
        </div>
    );
};

const ReusableDiscountGeneration = ({ event, refetch }) => {
    const intl = useIntl();

    const [form] = Form.useForm();

    const [generateDiscounts, { loading }] = useMutation(GENERATE_DISCOUNTS);

    const onFinish = (values) => {
        if (!values.fixed && !values.percent) {
            message.error(intl.formatMessage({ id: 'common.error_percent_fixed_values_both_zero' }));
            return;
        }

        generateDiscounts({
            variables: {
                id: event.id,
                input: {
                    percent: roundNumber(values.percent * 100),
                    fixed: roundNumber(values.fixed * 100),
                    code: values.code.trim(),
                    event_id: event.id,
                    type: 'reusable',
                    quantity: 1,
                },
            },
        })
            .then(() => {
                form.resetFields();
                message.success(intl.formatMessage({ id: 'common.edit_successful' }));
                refetch();
            })
            .catch((e) => defaultCatchException(e, intl));
    };

    return (
        <div>
            <Form form={form} layout="vertical" onFinish={onFinish}>
                <Row>
                    <Col xs={24} md={4}>
                        <Form.Item name="percent" label={<IntlMessages id="common.percent_discount" />}>
                            <InputNumber min={0} className="gx-w-100" addonAfter="%" />
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={4}>
                        <Form.Item name="fixed" label={<IntlMessages id="common.fixed_discount" />}>
                            <InputNumber min={0} className="gx-w-100" addonAfter={event.payments.location.currency.symbol} />
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={4}>
                        <Form.Item name="code" rules={[requiredRule(intl)]} label={<IntlMessages id="common.discount_code" />}>
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={4}>
                        <Form.Item>
                            <Button loading={loading} htmlType="submit" type="primary" style={{ marginTop: '1.8rem' }}>
                                <IntlMessages id="common.generate" />
                            </Button>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </div>
    );
};

const DisposableDiscountGeneration = ({ event, refetch }) => {
    const intl = useIntl();

    const [form] = Form.useForm();

    const [generateDiscounts, { loading }] = useMutation(GENERATE_DISCOUNTS);

    const onFinish = (values) => {
        if (!values.fixed && !values.percent) {
            message.error(intl.formatMessage({ id: 'common.error_percent_fixed_values_both_zero' }));
            return;
        }

        generateDiscounts({
            variables: {
                id: event.id,
                input: {
                    percent: roundNumber(values.percent * 100),
                    fixed: roundNumber(values.fixed * 100),
                    expires_in_days: values.expires_in_days,
                    event_id: event.id,
                    type: 'manual',
                    quantity: values.quantity,
                },
            },
        })
            .then(() => {
                form.resetFields();
                message.success(intl.formatMessage({ id: 'common.edit_successful' }));
                refetch();
            })
            .catch((e) => defaultCatchException(e, intl));
    };

    return (
        <div>
            <Form
                form={form}
                layout="vertical"
                onFinish={onFinish}
                initialValues={{
                    expires_in_days: 0,
                    quantity: 1,
                }}
            >
                <Row>
                    <Col xs={24} md={4}>
                        <Form.Item name="percent" label={<IntlMessages id="common.percent_discount" />}>
                            <InputNumber min={0} className="gx-w-100" addonAfter="%" />
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={4}>
                        <Form.Item name="fixed" label={<IntlMessages id="common.fixed_discount" />}>
                            <InputNumber min={0} className="gx-w-100" addonAfter={event.payments.location.currency.symbol} />
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={4}>
                        <Form.Item name="quantity" rules={[requiredRule(intl)]} label={<IntlMessages id="common.quantity" />}>
                            <InputNumber min={1} className="gx-w-100" />
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={4}>
                        <Form.Item name="expires_in_days" rules={[requiredRule(intl)]} label={<IntlMessages id="common.discount_expire_in" />}>
                            <Select>
                                <Select.Option value={0}>
                                    <IntlMessages id="common.no_expiration" />
                                </Select.Option>
                                {[1, 2, 3, 5, 10, 15, 30, 60, 90].map((d) => {
                                    return (
                                        <Select.Option key={d} value={d}>
                                            {d} {intl.formatMessage({ id: d === 1 ? 'common.day' : 'common.day_short' }).toLowerCase()}
                                        </Select.Option>
                                    );
                                })}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={4}>
                        <Form.Item>
                            <Button loading={loading} htmlType="submit" type="primary" style={{ marginTop: '1.8rem' }}>
                                <IntlMessages id="common.generate" />
                            </Button>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </div>
    );
};

const DiscountFormAlert = ({ titleKey, className = '' }) => (
    <Alert
        style={{
            textTransform: 'none',
            fontWeight: 400,
            whiteSpace: 'normal',
        }}
        className={className}
        showIcon
        message={<IntlMessages id={titleKey} />}
    />
);

const DiscountForms = ({ event, activeEvents, refetch }) => {
    const [tabKey, setTabKey] = usePersistedState(`discounts_tab_key_${event.id}`, '1');

    return (
        <Tabs
            activeKey={!includes(['1', '2', '3'], tabKey) ? '1' : tabKey}
            onChange={(key) => {
                setTabKey(key);
            }}
        >
            <Tabs.TabPane tab={<IntlMessages id="common.multiple_subscriptions_discount" />} key="1">
                <Card className="gx-card" title={<DiscountFormAlert titleKey="common.multiple_subscriptions_discount_help" className="gx-mb-0" />}>
                    <AutomaticGeneratedDiscounts event={event} activeEvents={activeEvents} refetch={refetch} />
                </Card>
            </Tabs.TabPane>

            <Tabs.TabPane tab={<IntlMessages id="common.reusable_discount_form_title" />} key="2">
                <Card className="gx-card" title={<DiscountFormAlert titleKey="common.reusable_discount_form_help" />}>
                    <ReusableDiscountGeneration event={event} refetch={refetch} />
                    <DiscountsTable event={event} type="reusable" />
                </Card>
            </Tabs.TabPane>

            <Tabs.TabPane tab={<IntlMessages id="common.manual_discount_code" />} key="3">
                <Card className="gx-card" title={<DiscountFormAlert titleKey="common.competition_discount_tip_new" />}>
                    <DisposableDiscountGeneration event={event} refetch={refetch} />
                    <DiscountsTable event={event} type="manual" />
                </Card>
            </Tabs.TabPane>
        </Tabs>
    );
};

const EventDiscounts = ({ match }) => {
    const { params } = match;

    const intl = useIntl();

    const { data, loading, error, refetch } = useQuery(EVENT_DISCOUNTS, {
        variables: {
            id: params.id,
        },
    });

    let event = {};
    let activeEvents = [];

    if (error) {
        return <BadResponse title={intl.formatMessage({ id: '500.ooops' })} subtitle={intl.formatMessage({ id: '500.something_wrong' })} />;
    }

    if (loading) {
        return <CircularProgress />;
    }

    event = data.event;
    activeEvents = data.activeEvents;

    return (
        <>
            <Row>
                <Col md={24}>
                    <Breadcrumb className="gx-pb-3">
                        <Breadcrumb.Item>
                            <BreadcrumbHomeLink />
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>{event.name}</Breadcrumb.Item>
                        <Breadcrumb.Item>
                            <IntlMessages id="common.discounts" />
                        </Breadcrumb.Item>
                    </Breadcrumb>
                </Col>
            </Row>
            <Row>
                <Col xs={24}>
                    <PageHeader
                        title={
                            <span>
                                <IntlMessages id="common.discounts" />
                                &nbsp;{event.name}
                            </span>
                        }
                        extra={[<ExportDiscountsButton event={event} />]}
                    />
                </Col>
            </Row>
            <Row>
                <Col xs={24}>
                    <DiscountForms event={event} activeEvents={activeEvents} refetch={refetch} />
                </Col>
            </Row>
            <DevButton path={__filename} />
        </>
    );
};

export default EventDiscounts;
