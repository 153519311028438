import { Tag, Breadcrumb, Col, Form, PageHeader, Row, Space, Card, Input, Button, Table, InputNumber, Checkbox, message, Modal } from 'antd';
import React from 'react';
import { useIntl } from 'react-intl';
import IntlMessages from '../../../../util/IntlMessages';
import { DeleteOutlined, SaveOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import BadResponse from '../../../../components/BadResponse';
import { useMutation, useQuery } from '@apollo/client';
import { UPDATE_PRODUCT_DATA } from '../../../../apollo/query/product';
import { getSafeISOLanguage } from '../../../../lngProvider';
import CircularProgress from '../../../../components/CircularProgress';
import BreadcrumbHomeLink from '../../../../components/BreadcrumbHomeLink';
import { DELETE_PRODUCT, UPDATE_PRODUCT } from '../../../../apollo/mutation/product';
import { requiredRule } from '../../../../util/form';
import { defaultCatchException, defaultMutationCallback, defaultMutationHandler } from '../../../../apollo/callbacks';
import MultilanguageFormInput from '../../../../components/MultilanguageInput';
import { getAdminPath } from '../../../../util/router';

const VariantTable = ({ products }) => {
    const columns = [
        {
            title: <IntlMessages id="products.sku" />,
            dataIndex: 'sku',
            key: 'sku',            
        },
        {
            title: <IntlMessages id="products.attributes" />,
            dataIndex: 'attributes',
            key: 'attributes',            
            render: (attributes) => {
                return (attributes || []).map((attribute) => {
                    return (
                        <span>
                            {attribute.attribute_name_locale}: <Tag>{attribute.option_name_locale}</Tag>
                        </span>
                    );
                })
            },
        },
        {
            title: <IntlMessages id="products.stock" />,
            dataIndex: 'stock',
            key: 'stock',
            width: 200,
            render: (text, record) => {
                return (
                    <Form.Item name={['variants', record.sku, 'stock']} className="gx-mb-0">
                        <InputNumber />
                    </Form.Item>
                );
            },
        },
        {
            title: <IntlMessages id="products.sold_quantity" />,
            dataIndex: 'sold_quantity',
            key: 'sold_quantity',
            width: 100,
            render: (text, record) => {
                return <span>{record.sold_quantity || 0}</span>;
            }
        },
        {
            title: <IntlMessages id="products.available_quantity" />,
            dataIndex: 'available_quantity',
            key: 'available_quantity',
            width: 100,
        },
        {
            title: <IntlMessages id="common.price" />,
            dataIndex: 'price',
            key: 'price',
            width: 200,
            render: (text, record) => {
                return (
                    <Form.Item
                        name={['variants', record.sku, 'price']}
                        className="gx-mb-0">
                        <InputNumber />
                    </Form.Item>
                );
            },
        },
    ];

    return <Table columns={columns} dataSource={products.variants} rowKey="id" pagination={false} />;
};

const hasSoldQuantity = (variants) => {
    return (variants || []).some((variant) => variant.sold_quantity > 0);
};

const UpdateEventProduct = ({ match }) => {

    const eventId = match.params.id;
    const productId = match.params.product_id;

    const history = useHistory();

    const intl = useIntl();

    const [updateProduct, { loading: loadingMutation }] = useMutation(UPDATE_PRODUCT);
    const [deleteProduct, { loading: deleteProductLoading }] = useMutation(DELETE_PRODUCT);

    const { data, loading, error, refetch } = useQuery(UPDATE_PRODUCT_DATA, {
        fetchPolicy: 'no-cache',
        variables: {
            eventId,
            productId,
        },
        context: {
            headers: {
                'x-locale': getSafeISOLanguage(intl.locale),
            },
        },
    });

    if (error) {
        return <BadResponse title={intl.formatMessage({ id: '500.ooops' })} subtitle={intl.formatMessage({ id: '500.something_wrong' })} />;
    }

    if (loading) {
        return <CircularProgress />;
    }

    const onFinish = (values) => {

        updateProduct({
            variables: {
                input: {
                    id: productId,
                    active: values.active,
                    name: values.name,
                    description: values.description,
                    variants: Object.keys(values.variants).map((sku) => ({
                        sku,
                        stock: values.variants[sku].stock || 0,
                        price: values.variants[sku].price || 0,
                    })),
                },
            },
        })
            .then((response) =>
                defaultMutationCallback(response, () => {
                    message.success(intl.formatMessage({ id: 'common.edit_successful' }));
                    refetch();
                })
            )
            .catch((e) => defaultCatchException(e, intl));
    };

    return (
        <Form
            layout="vertical"
            onFinish={onFinish}
            initialValues={{
                active: data?.product?.active,
                name: data?.product?.name,
                description: data?.product?.description,
                variants: (data?.product?.variants || []).reduce((acc, variant) => {
                    acc[variant.sku] = {
                        stock: variant.stock || 0,
                        price: variant.price ? parseFloat(variant.price / 100) : 0,
                    };

                    return acc;
                }, {}),
            }}
        >
            <Row>
                <Col md={24}>
                    <Breadcrumb className="gx-pb-3">
                        <Breadcrumb.Item>
                            <BreadcrumbHomeLink />
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>{data?.event?.name}</Breadcrumb.Item>
                        <Breadcrumb.Item>
                            <IntlMessages id="products.update" />
                        </Breadcrumb.Item>
                    </Breadcrumb>
                </Col>
            </Row>

            <Row>
                <Col xs={24}>
                    <PageHeader
                        onBack={() => history.goBack()}
                        title={
                            <span>
                                <IntlMessages id="products.update" />
                            </span>
                        }
                        extra={
                            <Space>
                                <Button
                                    disabled={deleteProductLoading || hasSoldQuantity(data?.product?.variants)}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        Modal.confirm({
                                            title: intl.formatMessage({ id: `products.delete_modal_title` }),
                                            content: intl.formatMessage({ id: `products.delete_modal_text` }),
                                            okText: intl.formatMessage({ id: 'common.yes' }),
                                            cancelText: intl.formatMessage({ id: 'common.no' }),
                                            okButtonProps: {
                                                loading: deleteProductLoading,
                                            },
                                            onOk: () => {
                                                deleteProduct({ variables: { id: productId } })
                                                    .then(
                                                        defaultMutationHandler({
                                                            successMessage: intl.formatMessage({ id: 'common.operation_successful' }),
                                                            successCallback: () => {
                                                                history.push(getAdminPath(`event/${eventId}/products`));
                                                            },
                                                            errorCallback: (errors) => {
                                                                if (errors && errors[0] && errors[0].message && intl.messages[errors[0].message]) {
                                                                    message.error(intl.formatMessage({ id: errors[0].message }));
                                                                } else {
                                                                    message.error(intl.formatMessage({ id: 'common.default_mutation_error' }));
                                                                }
                                                            },
                                                        })
                                                    )
                                                    .catch((e) => defaultCatchException(e, intl));
                                            },
                                        });
                                    }}
                                    icon={<DeleteOutlined className='gx-mr-1' />}
                                    key="delete"
                                    danger>
                                    <IntlMessages id="common.delete" />
                                </Button>
                                <Button loading={loadingMutation} key="update" icon={<SaveOutlined className="gx-pr-1" />} type="primary" htmlType="submit">
                                    <IntlMessages id="common.save" />
                                </Button>
                            </Space>
                        }
                    />
                </Col>

                <Col xs={24} md={8} lg={6} xl={6} >
                    <Card title={<IntlMessages id="common.general_data" />} className="gx-card">
                        <Form.Item valuePropName="checked" name="active">
                            <Checkbox>
                                <IntlMessages id="common.active" />
                            </Checkbox>
                        </Form.Item>

                        <Form.Item rules={[
                            requiredRule(intl),
                            {
                                min: 2,
                                type: 'string',
                                message: intl.formatMessage(
                                    {
                                        id: 'common.validation_min_chars',
                                    },
                                    {
                                        min: 2,
                                    }
                                ),
                            },
                            {
                                max: 125,
                                type: 'string',
                                message: intl.formatMessage(
                                    {
                                        id: 'common.validation_max_chars',
                                    },
                                    {
                                        max: 125,
                                    }
                                ),
                            },
                        ]} label={<IntlMessages id="common.name" />} name="name">
                            <Input />
                        </Form.Item>

                        <MultilanguageFormInput
                            eventLanguages={data.event.languages}
                            input={<Input.TextArea />}
                            formItemProps={{
                                label: <IntlMessages id="common.description" />,
                                name: 'description',
                                rules: [requiredRule(intl)],
                            }}
                        />
                    </Card>
                </Col>

                <Col xs={24} md={16} lg={18} xl={18}>
                    <Card title={<IntlMessages id="products.variants" />} className="gx-card">
                        <VariantTable products={data.product} />
                    </Card>
                </Col>
            </Row>
        </Form>
    );
};

export default UpdateEventProduct;
