//import { EmojiNode } from "./nodes/EmojiNode";

import EditorNodes from './nodes';
import lexicalTheme from './theme';

// Catch any errors that occur during Lexical updates and log them
// or throw them as needed. If you don't throw them, Lexical will
// try to recover gracefully without losing user data.
function onError(error) {
    console.error(error);
    throw error;
}

const editorConfig = {
    theme: lexicalTheme,
    nodes: [...EditorNodes],
    onError: onError,
};

const getEditorConfig = (initState = null) => {
    const defaultEditorState =
        '{"root":{"children":[{"children":[],"direction":null,"format":"","indent":0,"type":"paragraph","version":1}],"direction":null,"format":"","indent":0,"type":"root","version":1}}';

    return {
        ...editorConfig,
        editorState: initState || defaultEditorState,
    };
};

export { editorConfig, getEditorConfig };
