import React, { useState, useMemo } from 'react';
import { Row, Breadcrumb, Skeleton, Col, Form, Button, PageHeader, Card, Select, message } from 'antd';
import BadResponse from '../../../../components/BadResponse';
import { EVENT_DESCRIPTION } from '../../../../apollo/query/event';
import BreadcrumbHomeLink from '../../../../components/BreadcrumbHomeLink';
import { useIntl } from 'react-intl';
import { useMutation, useQuery } from '@apollo/client';
import IntlMessages from '../../../../util/IntlMessages';
import BadResponseErrorAlert from '../../../../components/BadResponseErrorAlert';
import { useHistory } from 'react-router-dom';
import { UPDATE_EVENT } from '../../../../apollo/mutation/event';
import { defaultCatchException, defaultMutationCallback } from '../../../../apollo/callbacks';
import { keys, isString } from 'lodash';
import LexicalEditor from '../../../../components/LexicalEditor';

const EventDescriptionForm = ({ intl, event, refetch }) => {
    const history = useHistory();
    const languages = event.languages?.map((l) => l.iso);
    const [selectedLanguage, setSelectedLanguage] = useState(event.main_language);

    const [updateEvent, { loading: loadingMutation }] = useMutation(UPDATE_EVENT);

    const handleOk = (values) => {
        let description = {};
        keys(values.description).forEach((language) => {
            if (values.description[language]) {
                const editorState = values.description[language];
                const editorStringState = !isString(editorState) ? JSON.stringify(editorState) : editorState;
                description[language] = editorStringState;
            }
        });

        updateEvent({
            variables: {
                id: event.id,
                event: { description },
            },
        })
            .then((response) => defaultMutationCallback(response, () => closeHandler(response.data)))
            .catch((e) => defaultCatchException(e, intl));
    };
    const handleError = () => {};
    const closeHandler = () => {
        message.success(intl.formatMessage({ id: 'common.edit_successful' }));
    };

    return (
        <Form
            onFinish={handleOk}
            onFinishFailed={handleError}
            layout="vertical"
            labelCol={{ span: 20 }}
            initialValues={{
                description: event?.description,
            }}
            autoComplete="off"
        >
            <Row>
                <Col xs={24}>
                    <PageHeader
                        title={
                            <span>
                                <IntlMessages id="common.description" />
                            </span>
                        }
                        onBack={() => history.goBack()}
                        extra={[
                            <Button key="2" htmlType="submit" type="primary" loading={loadingMutation}>
                                {intl.formatMessage({ id: 'common.update' }).toUpperCase()}
                            </Button>,
                        ]}
                    />
                </Col>

                <Col xs={24}>
                    <BadResponseErrorAlert />
                </Col>

                <Col xs={24} sm={24} md={24} lg={24}>
                    <Card
                        title={<IntlMessages id="common.description" />}
                        className="gx-card"
                        extra={[
                            <Select
                                value={selectedLanguage}
                                onSelect={(value) => {
                                    setSelectedLanguage(value);
                                }}
                                style={{ width: 100 }}
                                size="small"
                            >
                                {languages.map((value, key) => {
                                    return (
                                        <Select.Option key={key} value={value}>
                                            {intl.formatMessage({ id: `common.${value}` })}
                                        </Select.Option>
                                    );
                                })}
                            </Select>,
                        ]}
                    >
                        {event.languages.map((language) => {
                            return (
                                <Form.Item
                                    style={{ display: language.iso === selectedLanguage ? 'block' : 'none' }}
                                    name={['description', language.iso]}
                                    labelCol={{ span: 0 }}
                                    wrapperCol={{ span: 24 }}
                                >
                                    <LexicalEditor
                                        placeholder={intl.formatMessage({
                                            id: 'common.insert_description',
                                        })}
                                        debug={process.env.REACT_APP_NODE_ENV === 'development'}
                                    />
                                </Form.Item>
                            );
                        })}
                    </Card>
                </Col>
            </Row>
        </Form>
    );
};

const EventDescription = ({ match }) => {
    const { params } = match;

    const intl = useIntl();
    const { data, loading, error, refetch } = useQuery(EVENT_DESCRIPTION, { variables: { id: params.id } });

    if (error) {
        return <BadResponse title={intl.formatMessage({ id: '500.ooops' })} subtitle={intl.formatMessage({ id: '500.something_wrong' })} />;
    }

    return (
        <>
            <Row>
                <Col md={24}>
                    <Breadcrumb className="gx-pb-3">
                        <Breadcrumb.Item>
                            <BreadcrumbHomeLink />
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>{data?.event.name}</Breadcrumb.Item>
                        <Breadcrumb.Item>
                            <IntlMessages id="common.description" />
                        </Breadcrumb.Item>
                    </Breadcrumb>
                </Col>
            </Row>

            <Row>
                <Col xs={24}>{!loading ? <EventDescriptionForm loading={loading} event={data?.event} intl={intl} refetch={refetch} /> : <Skeleton />}</Col>
            </Row>
        </>
    );
};

export default EventDescription;
