import { Button, Result } from 'antd';
import React from 'react';
import { injectIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import IntlMessages from '../../util/IntlMessages';
import { getAdminPath } from '../../util/router';

const NotFound = ({ intl, redirectToURL, subTitle }) => {
    return (
        <Result
            status="404"
            title="404"
            subTitle={subTitle || intl.formatMessage({ id: '404.page_not_exists' })}
            extra={
                <Link to={redirectToURL}>
                    <Button type="primary">
                        <IntlMessages id="404.home" />
                    </Button>
                </Link>
            }
        />
    );
};

NotFound.defaultProps = {
    redirectToURL: getAdminPath(),
    subTitle: '',
};

export default injectIntl(NotFound);
