import { useMutation } from '@apollo/client';
import { GQL_ASSETS_UPLOAD_AUTHORIZATION, GQL_ASSET_CREATE } from '../apollo/mutation/assets';
import { uploadFileToPresignedURL } from '../components/UploadAsset/upload-asset.definitions';

export function uploadSingleFile(file, context, entity, justOne, payload, requestAuthorizationMutation, createAsset) {
    return new Promise((resolve, reject) => {
        const params = {
            file_name: file.name,
            meta_type: file.type,
            context_type: context,
        };

        requestAuthorizationMutation({ variables: { params } })
            .then((response) => {
                const { data, errors } = response;

                if (errors) {
                    reject(errors);
                } else {
                    const jsonPresignedPost = JSON.parse(data.uploadAuthorization);

                    uploadFileToPresignedURL(file, jsonPresignedPost)
                        .then((response) => {
                            const args = {
                                bucket_name: jsonPresignedPost.fields.bucket,
                                key: jsonPresignedPost.fields.key,
                                file_name: file.name,
                                file_type: file.type,
                                file_size: file.size,
                                context_type: context,
                                entity_id: entity.id,
                                entity_name: entity.name,
                                just_one: justOne,
                                ...payload,
                            };
                            createAsset({ variables: { args } })
                                .then((res) => {
                                    const { data, errors } = res;

                                    if (errors) {
                                        reject(errors);
                                    } else {
                                        resolve(data);
                                    }
                                })
                                .catch((error) => {
                                    reject(error);
                                });
                        })
                        .catch((error) => {
                            reject(error);
                        });
                }
            })
            .catch((error) => {
                reject(error);
            });
    });
}

export const useS3Upload = (context, entity, justOne = false) => {
    const [requestAuthorization] = useMutation(GQL_ASSETS_UPLOAD_AUTHORIZATION);
    const [createAsset] = useMutation(GQL_ASSET_CREATE);

    const uploadPromise = (files, payload = {}) => {
        return Promise.all(files.map((file) => uploadSingleFile(file, context, entity, justOne, payload, requestAuthorization, createAsset)));
    };

    return [uploadPromise];
};
