import { gql } from '@apollo/client';

export const MULTILANGUAGE_FRAGMENT = gql`
    fragment multilanguageFragment on MultilanguageObject {
        it
        en
        es
        de
        pt
    }
`;

export const MULTILANGUAGE_RICHTEXT_FRAGMENT = gql`
    fragment multilanguageFragment on MultilanguageRichtextObject {
        it {
            blocks {
                key
                type
                text
                depth
            }
        }
        en {
            blocks {
                key
                type
                text
                depth
            }
        }
        es {
            blocks {
                key
                type
                text
                depth
            }
        }
        de {
            blocks {
                key
                type
                text
                depth
            }
        }
        pt {
            blocks {
                key
                type
                text
                depth
            }
        }
    }
`;
