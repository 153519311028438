import React, { useState } from 'react';
import { Row, Col, Breadcrumb, PageHeader, List, Skeleton, Empty, Card } from 'antd';
import IntlMessages from '../../../util/IntlMessages';
import BreadcrumbHomeLink from '../../../components/BreadcrumbHomeLink';
import { ClockCircleOutlined, EnvironmentOutlined, FireOutlined, LinkOutlined, PlusOutlined } from '@ant-design/icons';
import { Can } from '../../../acl';
import { Link } from 'react-router-dom';
import { getAdminPath } from '../../../util/router';
import { PAGINATED_EVENTS } from '../../../apollo/query/event';
import { useQuery } from '@apollo/client';
import { useIntl } from 'react-intl';
import { DATE_FORMAT } from '../../../constants/App';
import { formatDate } from '../../../util/date';
import { IconText } from '../../../components/Icons/IconText';
import EventImage from '../../../components/Image/EventImage';
import { AssetContextType } from '../../../constants/Asset';
import { EventPublicationIcon } from '../../../components/Icons/EventPublicationIcon';

const EventList = ({ loading, dataSource, onChangePage, intl }) => {
    if (loading) {
        return <Skeleton />;
    }

    return (
        <Card className="gx-card">
            <List
                itemLayout="vertical"
                size="large"
                pagination={{
                    onChange: (page) => {
                        //console.log(page);
                        onChangePage(page);
                    },
                    total: dataSource?.pagination.total_items || 1,
                    current: dataSource?.pagination.page || 1,
                    pageSize: dataSource?.pagination.size || 10,
                }}
                dataSource={dataSource?.items || []}
                locale={{
                    emptyText: <Empty description={intl.formatMessage({ id: 'events.no_data' })} image={Empty.PRESENTED_IMAGE_SIMPLE} />,
                }}
                renderItem={(item) => (
                    <List.Item
                        key={item.title}
                        actions={[
                            <IconText key="date_start" icon={ClockCircleOutlined} text={formatDate(item.date_start, DATE_FORMAT, null, true)} />,
                            <IconText key="sport" icon={FireOutlined} text={item.sport.name} />,
                            <IconText key="loaction" icon={EnvironmentOutlined} text={item.location.name} />,
                        ]}
                        extra={
                            <Link to={`/admin/event/${item.id}/dashboard`}>
                                <EventImage className="gx-d-block" width={400} alt={item.title} event={item} type={AssetContextType.EVENT_IMAGE_BIG} />
                            </Link>
                        }
                    >
                        <List.Item.Meta
                            avatar={<EventPublicationIcon event={item} />}
                            title={
                                <Link className="gx-fs-xl" to={`/admin/event/${item.id}/dashboard`}>
                                    {item.name} <LinkOutlined />
                                </Link>
                            }
                            description={null}
                        />
                        {item.description}
                    </List.Item>
                )}
            />
        </Card>
    );
};

const Dashboard = () => {
    const intl = useIntl();

    const [paginationState, setPaginationState] = useState({
        page: 1,
        limit: 10,
    });

    const { data, error, loading } = useQuery(PAGINATED_EVENTS, {
        variables: {
            pagination: paginationState,
            sorting: {
                key: 'date_start',
                direction: -1,
            },
        },
    });

    const onChangePage = (page) => {
        setPaginationState({
            ...paginationState,
            page: page,
        });
    };

    return (
        <>
            <Row>
                <Col md={24}>
                    <Breadcrumb className="gx-pb-3">
                        <Breadcrumb.Item>
                            <BreadcrumbHomeLink showWorkspaceName={false} />
                        </Breadcrumb.Item>
                        <Breadcrumb.Item href="#">
                            <IntlMessages id="common.dashboard" />
                        </Breadcrumb.Item>
                    </Breadcrumb>
                </Col>
            </Row>

            <Row>
                <Col xs={24}>
                    <PageHeader
                        title={<IntlMessages id="common.events" />}
                        extra={[
                            <Can I="create" a="Event" key="1">
                                <Link className="ant-btn ant-btn-primary circle-add-button" to={getAdminPath('events/insert')}>
                                    <PlusOutlined />
                                    &nbsp;
                                    <IntlMessages id="common.create" />
                                </Link>
                            </Can>,
                        ]}
                    />
                </Col>
                <Col md={24}>
                    <EventList loading={loading} onChangePage={onChangePage} dataSource={data?.paginatedEvents} intl={intl} />
                </Col>
            </Row>
        </>
    );
};

export default Dashboard;
