import {
    ClockCircleOutlined,
    EnvironmentOutlined,
    FireOutlined,
    LinkOutlined,
    PlusOutlined,
    InfoCircleOutlined,
    HomeOutlined,
    DownOutlined,
    BranchesOutlined,
    DeleteOutlined,
} from '@ant-design/icons';
import { useMutation, useQuery } from '@apollo/client';
import { PageHeader, Breadcrumb, Row, Col, Card, Statistic, Empty, List, Tooltip, Skeleton, Button, Dropdown, Modal, message, Space } from 'antd';
import { useIntl } from 'react-intl';
import { Link, useHistory } from 'react-router-dom';
import { Can } from '../../../../acl';
import { EVENT_DASHBOARD } from '../../../../apollo/query/event';
import BadResponse from '../../../../components/BadResponse';
import BreadcrumbHomeLink from '../../../../components/BreadcrumbHomeLink';
import { IconText } from '../../../../components/Icons/IconText';
import IntlMessages from '../../../../util/IntlMessages';
import { getAdminPath } from '../../../../util/router';
import EventImage from '../../../../components/Image/EventImage';
import { AssetContextType } from '../../../../constants/Asset';
import PublishButton from './publishButton';
import { formatCurrency } from '../../../../util/string';
import { useState } from 'react';
import CardDescriptions, { CardDescriptionsItem } from '../../../../components/Descriptions/CardDescription';
import { formatDateTime } from '../../../../util/date';
import PlayersAffluenceChart from '../../../../components/PlayersAffluenceChart';
import { EVENT } from '../../../../constants/App';
import { kebabCase } from 'lodash';
import { DELETE_EVENT, DUPLICATE_EVENT } from '../../../../apollo/mutation/event';
import { defaultCatchException, defaultMutationHandler } from '../../../../apollo/callbacks';

export const getDashboardStats = ({ paymentsAggregations, playersAggregations }) => {
    let stats = {
        playersCount: 0,
        playersConfirmed: 0,
        playersRetired: 0,
        paymentsCount: 0,
        revenues: 0,
        revenuesDetails: [],
    };

    if (paymentsAggregations || playersAggregations) {
        stats = {
            playersCount: playersAggregations.count,
            playersConfirmed: playersAggregations.confirmed,
            playersRetired: playersAggregations.retired,
            playersPaid: playersAggregations.paid,
            playersTotalPaid: playersAggregations.total_paid,
            paymentsCount: paymentsAggregations.transactions.reduce((acc, row) => {
                return (acc += row.count);
            }, 0),
            revenues: paymentsAggregations.transactions.reduce((acc, row) => {
                return (acc += row.total);
            }, 0),
            revenuesDetails: paymentsAggregations.transactions,
        };
    }

    return stats;
};

export const RevenueCard = ({ event, stats, loading }) => {
    const [showDetails, setShowDetails] = useState(false);

    return (
        <Card
            className="gx-card"
            title={<IntlMessages id="common.revenues" />}
            extra={[
                <a
                    onClick={() => {
                        setShowDetails(!showDetails);
                    }}
                >
                    {showDetails ? <IntlMessages id="common.total" /> : <IntlMessages id="common.details" />}
                </a>,
            ]}
        >
            {!showDetails ? (
                <Statistic value={formatCurrency(stats.revenues / 100, 2, event.payments?.location.currency.iso)} precision={2} loading={loading} />
            ) : (
                <CardDescriptions column={2} className="gx-ml-0">
                    {(stats.revenuesDetails || []).map((row, index) => {
                        return (
                            <CardDescriptionsItem key={index} label={<IntlMessages id={`common.payment_type_${row.type}`} />}>
                                {formatCurrency(row.total / 100, 2, event.payments?.location.currency.iso)}
                            </CardDescriptionsItem>
                        );
                    })}
                </CardDescriptions>
            )}
        </Card>
    );
};

export const PlayersCard = ({ event, stats, loading }) => {
    const [showDetails, setShowDetails] = useState(false);

    return (
        <Card
            className="gx-card"
            title={<IntlMessages id="common.players" />}
            extra={[
                <a
                    onClick={() => {
                        setShowDetails(!showDetails);
                    }}
                >
                    {showDetails ? <IntlMessages id="common.total" /> : <IntlMessages id="common.details" />}
                </a>,
            ]}
        >
            {!showDetails ? (
                <Statistic value={stats.playersCount} precision={0} loading={loading} />
            ) : (
                <CardDescriptions column={2} className="gx-ml-0">
                    <CardDescriptionsItem label={<IntlMessages id="common.paid" />}>{stats.playersPaid}</CardDescriptionsItem>
                    <CardDescriptionsItem label={<IntlMessages id="common.not_paid" />}>{stats.playersCount - stats.playersPaid}</CardDescriptionsItem>
                    <CardDescriptionsItem label={<IntlMessages id="common.retired" />}>{stats.playersRetired}</CardDescriptionsItem>
                </CardDescriptions>
            )}
        </Card>
    );
};

const EventDashboard = ({ match }) => {
    const { params } = match;

    const intl = useIntl();
    const history = useHistory();
    const { data, loading, error, refetch } = useQuery(EVENT_DASHBOARD, { variables: { id: params.id } });
    const [duplicateEvent, { loading: duplicateEventLoading }] = useMutation(DUPLICATE_EVENT);
    const [deleteEvent, { loading: deleteEventLoading }] = useMutation(DELETE_EVENT);

    let event = {};

    let stats = {
        playersCount: 0,
        playersConfirmed: 0,
        playersRetired: 0,
        paymentsCount: 0,
        revenues: 0,
        revenuesDetails: [],
    };

    let playersAffluence = [];

    if (error) {
        return <BadResponse title={intl.formatMessage({ id: '500.ooops' })} subtitle={intl.formatMessage({ id: '500.something_wrong' })} />;
    }

    if (!loading) {
        event = data.event;
        stats = getDashboardStats(data);
        playersAffluence = data.playersAffluence;
    }

    const dropDownButtonItems = [
        {
            key: '1',
            label:
                event.publish?.state === EVENT.PUBLISH_STATE.PUBLISHED ? (
                    <a
                        key="event-page"
                        className="gx-mb-0"
                        href={`${process.env.REACT_APP_FRONTEND_URL}/cr/${event.id}/${kebabCase(event.name)}`}
                        target="_blank"
                        rel="noreferrer"
                    >
                        <HomeOutlined />
                        &nbsp;
                        <IntlMessages id="common.competition_page" />
                    </a>
                ) : null,
        },
        {
            key: '2',
            label: (
                <a
                    onClick={(e) => {
                        e.preventDefault();
                        Modal.confirm({
                            title: intl.formatMessage(
                                { id: 'common.duplicate_competition_modal_title' },
                                {
                                    competition: event.name,
                                }
                            ),
                            content: intl.formatMessage(
                                { id: 'common.duplicate_competition_modal_text' },
                                {
                                    competition: event.name,
                                }
                            ),
                            okText: intl.formatMessage({ id: 'common.yes' }),
                            cancelText: intl.formatMessage({ id: 'common.no' }),
                            okButtonProps: {
                                loading: duplicateEventLoading,
                            },
                            onOk: () => {
                                duplicateEvent({ variables: { id: event.id } })
                                    .then(
                                        defaultMutationHandler({
                                            successMessage: intl.formatMessage({ id: 'common.operation_successful' }),
                                            errorCallback: (errors) => {
                                                message.error(intl.formatMessage({ id: 'common.default_mutation_error' }));
                                            },
                                        })
                                    )
                                    .catch((e) => defaultCatchException(e, intl));
                            },
                        });
                    }}
                >
                    <BranchesOutlined />
                    &nbsp;
                    <IntlMessages id="common.duplicate_competition" />
                </a>
            ),
        },
        {
            key: '3',
            label: (
                <a
                    onClick={(e) => {
                        e.preventDefault();
                        Modal.confirm({
                            title: intl.formatMessage({ id: 'common.competition_delete_modal_title' }, { name: event.name }),
                            content: intl.formatMessage({ id: 'common.competition_delete_modal_text' }),
                            okText: intl.formatMessage({ id: 'common.yes' }),
                            cancelText: intl.formatMessage({ id: 'common.no' }),
                            okButtonProps: {
                                loading: deleteEventLoading,
                            },
                            onOk: () => {
                                deleteEvent({ variables: { id: event.id } })
                                    .then(
                                        defaultMutationHandler({
                                            successMessage: intl.formatMessage({ id: 'common.operation_successful' }),
                                            successCallback: () => {
                                                history.push(getAdminPath('dashboard'));
                                            },
                                            errorCallback: (errors) => {
                                                if (errors && errors[0] && errors[0].message && intl.messages[errors[0].message]) {
                                                    message.error(intl.formatMessage({ id: errors[0].message }));
                                                } else {
                                                    message.error(intl.formatMessage({ id: 'common.default_mutation_error' }));
                                                }
                                            },
                                        })
                                    )

                                    .catch((e) => defaultCatchException(e, intl));
                            },
                        });
                    }}
                >
                    <DeleteOutlined />
                    &nbsp;
                    <IntlMessages id="common.delete" />
                </a>
            ),
        },
    ].filter((i) => Boolean(i.label));

    return (
        <>
            <Row>
                <Col md={24}>
                    <Breadcrumb className="gx-pb-3">
                        <Breadcrumb.Item>
                            <BreadcrumbHomeLink />
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>{event.name}</Breadcrumb.Item>
                        <Breadcrumb.Item>
                            <IntlMessages id="common.dashboard" />
                        </Breadcrumb.Item>
                    </Breadcrumb>
                </Col>
            </Row>

            <Row>
                <Col xs={24}>
                    <PageHeader
                        title={
                            <span>
                                <IntlMessages id="common.event" />
                                &nbsp;{event.name}
                            </span>
                        }
                        extra={
                            <Space wrap>
                                <Dropdown menu={{ items: dropDownButtonItems }} placement="bottomLeft" arrow>
                                    <Button icon={<DownOutlined className="gx-pr-2" />} className="gx-mb-0">
                                        <IntlMessages id="common.other" />
                                    </Button>
                                </Dropdown>

                                <PublishButton
                                    className="gx-mb-0"
                                    event={event}
                                    onSuccess={() => {
                                        refetch();
                                    }}
                                    key="2"
                                />
                                {!loading ? (
                                    <Can I="create" a="Event" key="1">
                                        <Link className="ant-btn ant-btn-primary gx-mb-0" to={`/admin/event/${event.id}/sub-event/insert`}>
                                            <PlusOutlined event={event} />
                                            &nbsp;
                                            <IntlMessages id={'common.create_sub_event'} />
                                        </Link>
                                    </Can>
                                ) : (
                                    <Skeleton.Button active={true} size="large" shape="round" key="1" />
                                )}
                            </Space>
                        }
                    />
                </Col>
                <Col md={24}>
                    <Row gutter={16}>
                        <Col xs={24}>
                            <Card
                                className="gx-card gx-w-100"
                                title={
                                    <Tooltip title={<IntlMessages id="common.week_aggregation_tip" />}>
                                        <IntlMessages id="common.athlete_affluence_by_week" /> <InfoCircleOutlined />
                                    </Tooltip>
                                }
                            >
                                <PlayersAffluenceChart data={playersAffluence} />
                            </Card>
                        </Col>

                        <Col xs={24} md={12} lg={6}>
                            <PlayersCard event={event} stats={stats} loading={loading} />
                        </Col>
                        <Col xs={24} md={12} lg={6}>
                            <Card className="gx-card" title={<IntlMessages id="common.confirmed_players" />}>
                                <Statistic value={stats.playersConfirmed} precision={0} />
                            </Card>
                        </Col>
                        <Col xs={24} md={12} lg={6}>
                            <Card className="gx-card" title={<IntlMessages id="common.payments" />}>
                                <Statistic value={stats.paymentsCount} precision={0} />
                            </Card>
                        </Col>
                        <Col xs={24} md={12} lg={6}>
                            <RevenueCard event={event} stats={stats} loading={loading} />
                        </Col>
                    </Row>
                    <Row gutter={[16, 16]}>
                        <Col xs={24}>
                            <Card title={<IntlMessages id={`common.events_type_${event.type}`} />} className="gx-card">
                                <List
                                    itemLayout="vertical"
                                    size="large"
                                    pagination={null}
                                    dataSource={event.sub_events || []}
                                    locale={{
                                        emptyText: <Empty description={intl.formatMessage({ id: 'events.no_data' })} image={Empty.PRESENTED_IMAGE_SIMPLE} />,
                                    }}
                                    renderItem={(item) => {
                                        const link = getAdminPath(`event/${event.id}/sub-event/${item.id}/dashboard`);
                                        return (
                                            <List.Item
                                                key={item.title}
                                                actions={[
                                                    <IconText
                                                        key="date_start"
                                                        icon={ClockCircleOutlined}
                                                        text={formatDateTime(item.timetables?.event_start)}
                                                    />,
                                                    <IconText key="sport" icon={FireOutlined} text={event.sport?.name} />,
                                                    <IconText key="loaction" icon={EnvironmentOutlined} text={event.location?.name} />,
                                                ]}
                                                extra={
                                                    <Link to={link}>
                                                        <EventImage
                                                            className="gx-d-block"
                                                            width={400}
                                                            alt={item.title}
                                                            event={item}
                                                            type={AssetContextType.EVENT_IMAGE_BIG}
                                                        />
                                                    </Link>
                                                }
                                            >
                                                <List.Item.Meta
                                                    title={
                                                        <Link className="gx-fs-xl" to={link}>
                                                            {item.name} <LinkOutlined />
                                                        </Link>
                                                    }
                                                    description={null}
                                                />
                                                {item.description}
                                            </List.Item>
                                        );
                                    }}
                                />
                            </Card>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </>
    );
};

export default EventDashboard;
