import { Breadcrumb, Skeleton } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';
import BreadcrumbHomeLink from '@/components/BreadcrumbHomeLink';
import { getAdminPath } from '@/util/router';
import IntlMessages from '@/util/IntlMessages';

const SubEventBreadcrumbs = ({ subEvent, section }) => {
    if (!subEvent) return null; //<Skeleton active />

    return (
        <Breadcrumb className="gx-pb-3">
            <Breadcrumb.Item>
                <BreadcrumbHomeLink />
            </Breadcrumb.Item>
            <Breadcrumb.Item>
                <Link to={getAdminPath('dashboard')}>
                    <IntlMessages id="common.events" />
                </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
                <Link to={getAdminPath(`event/${subEvent.event.id}/dashboard`)}>{subEvent.event.name}</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
                <IntlMessages id={`common.event_type_${subEvent.event.type}`} />
            </Breadcrumb.Item>
            <Breadcrumb.Item href="#">
                <Link to={getAdminPath(`event/${subEvent.event.id}/sub-event/${subEvent.id}/dashboard`)}>{subEvent.name}</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item href="#">{section}</Breadcrumb.Item>
        </Breadcrumb>
    );
};

export default SubEventBreadcrumbs;
