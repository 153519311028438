import { includes } from 'lodash';
import itLang from './entries/it-IT';
import enLang from './entries/en-US';
import ptLang from './entries/pt-BR';
import esLang from './entries/es-ES';

import 'moment/locale/it';
import moment from 'moment';
import { STATIC_TRANSLATIONS, SUPPORTED_LANGUAGES } from '../constants/Enums';

const AppLocale = {
    it: itLang,
    en: enLang,
    pt: ptLang,
    es: esLang,
};

moment.locale('it');

const isSupportedLanguage = (language) => {
    return includes(SUPPORTED_LANGUAGES, language);
};

export const KS_EMP_LOCALE_STORAGE_KEY = 'KS_EMP_LOCALE';

export const getSafeISOLanguage = (language) => {
    if (!language) return STATIC_TRANSLATIONS.DEFAULT_LANGUAGE;

    language = language.split('-')[0];

    if (isSupportedLanguage(language)) {
        return language;
    }

    return STATIC_TRANSLATIONS.DEFAULT_LANGUAGE;
};

export const getBrowserLanguage = () => {
    return navigator.language || navigator.userLanguage;
};

export const getInitLanguage = (apolloCacheLocale = null) => {
    const selectedLocale = localStorage.getItem(KS_EMP_LOCALE_STORAGE_KEY);

    if (apolloCacheLocale && selectedLocale !== apolloCacheLocale && isSupportedLanguage(apolloCacheLocale)) {
        localStorage.setItem(KS_EMP_LOCALE_STORAGE_KEY, apolloCacheLocale);
        return apolloCacheLocale;
    }

    if (!selectedLocale || !isSupportedLanguage(selectedLocale)) {
        const language = getSafeISOLanguage(getBrowserLanguage());
        localStorage.setItem(KS_EMP_LOCALE_STORAGE_KEY, language);
        return language;
    } else {
        return selectedLocale;
    }
};

export default AppLocale;
