import { useEffect } from 'react';
import { LAYOUT_BREAKPOINT } from '../constants/ThemeSetting';
import { reverse } from 'lodash';
import { Grid } from 'antd';
import { useState } from 'react';
const { useBreakpoint } = Grid;

export const getActiveBreakpoint = (screens) => {
    const breakpoints = reverse(Object.keys(LAYOUT_BREAKPOINT));
    return breakpoints.find((breakpoint) => screens[breakpoint.toLocaleLowerCase()]) || undefined;
};

export const useActiveBreakpoint = () => {
    const [activeBreakpoint, setActiveBreakpoint] = useState(undefined);

    const screens = useBreakpoint();

    useEffect(() => {
        const activeBreakpoint = getActiveBreakpoint(screens);

        if (activeBreakpoint !== undefined) {
            setActiveBreakpoint(activeBreakpoint);
        }
    }, [screens]);

    return activeBreakpoint;
};
