export const DATE_FORMAT = 'DD/MM/YYYY';

export const DATETIME_FORMAT = 'DD/MM/YYYY HH:mm';

export const TIME_FORMAT = 'HH:mm';

export const ADMIN_ROUTE_PREFIX = 'admin';

export const PASSWORD_MIN_LENGTH = 5;
export const PASSWORD_MAX_LENGTH = 50;

export const EVENT = {
    PUBLISH_STATE: {
        NOT_PUBLISHED: 1,
        PUBLISHED: 2,
    },
    CONTACTS: {
        FAX: 1,
        EMAIL: 2,
        TEL: 3,
        CELL: 4,
        SKYPE: 5,
        WHATSAPP: 6,
        OTHER: 7,
        WEB: 8,
    },
};

export const MENU_TYPE = {
    MAIN: 'main',
    EVENT: 'event',
    MANAGEMENT: 'management',
};

export const MEDICAL_CERTIFICATE = {
    TYPE: {
        NONE: 1,
        HEALTH: 2,
        COMPETITIVE: 3,
    },
};

export const CUSTOM_FIELD_TYPES = {
    TEXTAREA: 'note',
    INPUT_TEXT: 'free_text',
    SELECT: 'select_choice',
    CHECKBOX: 'check_box',
};

export const PAYMENTS = {
    BANK: 1,
    STRIPE: 2,
    PAYPAL: 3,
    MANUAL: 4, //deprecated
    BOLETO: 5,
    FREE: 6,
    PAYU: 7,
    CARD_GERENCIANET: 8,
    CASH: 9,
    PAGADITO: 10,
};

export const ORGANIZATION_BANK_ACCOUNT_STATE = {
    SAVE: 'save',
    WAITING_REVIEW: 'waiting_review',
    REVIEW: 'review',
};

export const TRACKING_MODE = {
    MANUAL: 'MANUAL',
    SUBSCRIPTION_CONFIRM: 'SUBSCRIPTION_CONFIRM',
};
