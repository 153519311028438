import { clearErrors, serverErrorsVar } from './local/error';
import { first, has, isArray, includes } from 'lodash';
import { message } from 'antd';

const getMessageKey = (error) => {
    if (includes(['UNAUTHENTICATED'], error.extensions.code)) {
        //messaggio errori per codice
        return `server_error.${error.extensions.code.toLowerCase()}`;
    } else if (has(error, 'extensions.response.message')) {
        //messaggio errori per errore interno
        const msg = error.extensions.response.message;
        if (isArray(msg)) {
            return first(msg);
        }
        return msg;
    } else if (error.message) {
        //messaggio non gestito
        return error.message;
    } else {
        //messaggio generico
        return 'common.default_mutation_error';
    }
};

const getStatusCode = (errorData) => {
    if (errorData.response) {
        return errorData.response.statusCode;
    } else if (errorData.exception) {
        return errorData.exception.statusCode;
    } else {
        return 400;
    }
};

export const normalizeServerErrors = (errors) => {
    return errors
        .map((error) => {
            const errorData = error.extensions;

            if (!errorData) return null;

            return {
                code: errorData.code,
                status: getStatusCode(errorData),
                messageKey: getMessageKey(error),
            };
        })
        .filter((e) => e !== null);
};

export const defaultMutationCallback = ({ data, errors }, successCallback) => {
    if (errors) {
        const items = normalizeServerErrors(errors);

        serverErrorsVar({
            hasErrors: true,
            items: items,
        });
    } else {
        successCallback && successCallback();
        clearErrors();
    }

    return { data, errors };
};

export const defaultMutationHandler =
    ({ errorCallback, errorMessage, successCallback, successMessage }) =>
    ({ data, errors }) => {
        if (errors) {
            const items = normalizeServerErrors(errors);

            serverErrorsVar({
                hasErrors: true,
                items: items,
            });

            errorMessage && message.error(errorMessage);

            errorCallback && errorCallback(errors, data);
        } else {
            successCallback && successCallback(data);
            successMessage && message.success(successMessage);
            clearErrors();
        }

        return { data, errors };
    };

/**
 * Network error
 * @param {*} e
 * @param {*} intl
 */
export const defaultCatchException = (e, intl) => {
    console.log('catchExeption', e);
    message.error(intl.formatMessage({ id: 'common.default_mutation_error' }));
};
