import React from 'react';
import moment from 'moment';
import { each, range, keyBy } from 'lodash';
import { Bar, BarChart, ResponsiveContainer, XAxis, YAxis, CartesianGrid } from 'recharts';
import { PRIMARY_COLOR } from '../../constants/ThemeSetting';

function mongoWeekYearToWeekStartDate(weekYear) {
    const format = 'DD-MM-YYYY';
    const wy = weekYear.split('-');
    const week = parseInt(wy[0]);
    const year = parseInt(wy[1]);
    if (week == 0) {
        return moment(year + '-01-01').format(format);
    }

    if (week == 1) {
        return moment(year + '-01-01')
            .day(7)
            .format(format);
    }

    if (week > 1) {
        const firstSunday = moment(year + '-01-01')
            .day(7)
            .format('DD');
        let dayOfYear = parseInt(firstSunday) + 7 * (week - 1);
        if (dayOfYear >= 365) {
            return moment(year + '-12-31').format(format);
        }
        return moment(year, 'YYYY').dayOfYear(dayOfYear).format(format);
    }
}

function addPadding(values, padding) {
    const prefix = range(padding).map((i) => {
        return {
            x: moment(values[0]?.x, 'DD-MM-YYYY')
                .subtract(i + 1, 'weeks')
                .format('DD-MM-YYYY'),
            y: 0,
        };
    });

    const suffix = range(padding).map((i) => {
        return {
            x: moment(values[values.length - 1]?.x, 'DD-MM-YYYY')
                .add(i + 1, 'weeks')
                .format('DD-MM-YYYY'),
            y: 0,
        };
    });

    return [...prefix, ...values, ...suffix];
}

function chartAdapter(data) {
    let values = [];

    if (!data || data.length === 0) {
        return values;
    }

    let firstItem = data[0];
    let lastItem = data[data.length - 1];

    let items = [];

    const [firstWeek, firstYear] = firstItem.x.split('-').map((i) => parseInt(i));
    const [lastWeek, lastYear] = lastItem.x.split('-').map((i) => parseInt(i));

    for (let y = firstYear; y <= lastYear; y++) {
        let startRange = 0;
        let endRange = 52;
        if (y === firstYear) {
            startRange = firstWeek;
        }

        if (y === lastYear) {
            endRange = lastWeek;
        }

        each(range(startRange, endRange + 1), (week) => {
            items.push(week + '-' + y);
        });
    }

    let dataMap = keyBy(data, 'x');

    each(items, (i) => {
        if (!dataMap[i]) {
            values.push({
                x: '',
                y: 0,
            });
        } else {
            let wy = i.split('-');
            let count = dataMap[i].y;
            if (wy[0] == 52 && dataMap[53 + '-' + wy[1]]) {
                //sum 53 mongo week into 52
                count += dataMap[53 + '-' + wy[1]].count;
            }
            values.push({
                x: mongoWeekYearToWeekStartDate(i),
                y: count,
            });
        }
    });

    if (values.length < 10) {
        values = addPadding(values, 2);
    }

    return { values, max: Math.max(values.map((i) => i.y)) };
}

const PlayersAffluenceChart = ({ data = [] }) => {
    const { values, max } = chartAdapter(data);

    return (
        <ResponsiveContainer width="100%" height={350}>
            <BarChart data={values}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="x" />
                <YAxis allowDecimals={false} domain={[0, max + 5]} />
                <Bar dataKey="y" fill={PRIMARY_COLOR} />
            </BarChart>
        </ResponsiveContainer>
    );
};

export default PlayersAffluenceChart;
