import { IdcardOutlined } from '@ant-design/icons';
import { useLazyQuery } from '@apollo/client';
import { Alert, Button, Divider, Modal, message } from 'antd';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { PLAYER_PROVIDED_CARD } from '../../../apollo/query/player';
import CardDescriptions, { CardDescriptionsItem } from '../../Descriptions/CardDescription';
import IntlMessages from '../../../util/IntlMessages';
import { formatDate } from '../../../util/date';

const CardRankField = ({ label, value }) => {
    return <CardDescriptionsItem label={<IntlMessages id={`common.${label}`} />}>{value < 99999 ? value : '-'}</CardDescriptionsItem>;
};

const ProvidedPlayerCardModal = ({ open, onClose, detail }) => {
    const intl = useIntl();

    const cardData = detail?.data;

    return (
        <Modal
            centered
            destroyOnClose={true}
            title={intl.formatMessage({ id: 'common.athlete_card' })}
            open={open}
            onCancel={onClose}
            footer={null}
            width={800}
        >
            {cardData ? (
                <>
                    <CardDescriptions column={4} className="gx-mx-0">
                        <CardDescriptionsItem label={<IntlMessages id="common.type" />}>{cardData.type}</CardDescriptionsItem>
                        <CardDescriptionsItem label={<IntlMessages id="common.number" />}>{cardData.number}</CardDescriptionsItem>
                        <CardDescriptionsItem label={<IntlMessages id="common.last_name" />}>{cardData.last_name}</CardDescriptionsItem>
                        <CardDescriptionsItem label={<IntlMessages id="common.first_name" />}>{cardData.first_name}</CardDescriptionsItem>
                        <CardDescriptionsItem label={<IntlMessages id="common.gender" />}>{cardData.gender}</CardDescriptionsItem>
                        <CardDescriptionsItem label={<IntlMessages id="common.birthday" />}>{formatDate(cardData.birthday)}</CardDescriptionsItem>
                        <CardDescriptionsItem label={<IntlMessages id="events.players_company_name" />}>
                            {cardData.company_name} ({cardData.company_id})
                        </CardDescriptionsItem>
                        <CardDescriptionsItem label={<IntlMessages id="common.category" />}>{cardData.category}</CardDescriptionsItem>
                        <CardDescriptionsItem label={<IntlMessages id="common.role" />}>{cardData.role}</CardDescriptionsItem>
                        <CardDescriptionsItem label={<IntlMessages id="common.citizenship" />}>{cardData.citizenship}</CardDescriptionsItem>
                        <CardDescriptionsItem label={<IntlMessages id="common.expire_at" />}>
                            {cardData.expire_at ? formatDate(cardData.expire_at) : '-'}
                        </CardDescriptionsItem>
                    </CardDescriptions>

                    <Divider />

                    <CardDescriptions column={4} className="gx-mx-0">
                        <CardRankField label="rank_long" value={cardData.rank_long} />
                        <CardRankField label="rank_sprint" value={cardData.rank_sprint} />
                        <CardRankField label="rank_duathlon" value={cardData.rank_duathlon} />
                        <CardRankField label="rank" value={cardData.rank} />
                    </CardDescriptions>
                </>
            ) : (
                <Alert showIcon type="error" message={intl.formatMessage({ id: 'common.card_not_found' })} className="gx-text-center" />
            )}
        </Modal>
    );
};

const ProvidedPlayerCardModalButton = ({ type = 'fitri', number, title }) => {
    const intl = useIntl();
    const [detail, setDetails] = useState({
        isOpen: false,
        data: null
    });
    const [getCard, { loading, error }] = useLazyQuery(PLAYER_PROVIDED_CARD);

    if (type !== 'fitri' || !number) {
        return null;
    }

    if (error) {
        message.error(intl.formatMessage({ id: 'common.default_mutation_error' }));
    }

    return (
        <>
            <Button
                loading={loading}
                className="gx-mb-0"
                size="small"
                onClick={() => {
                    getCard({
                        variables: {
                            number,
                            type,
                        },
                    }).then((response) => {
                        setDetails({
                            isOpen: true,
                            data: response?.data?.playerProvidedCard
                        });
                    });
                }}
            >
                <IdcardOutlined />
                {title ?? intl.formatMessage({ id: 'common.view' })}
            </Button>
            <ProvidedPlayerCardModal
                open={detail.isOpen}
                detail={detail}
                onClose={() => setDetails({
                    isOpen: false,
                    data: null
                })}
            />
        </>
    );
};

export default ProvidedPlayerCardModalButton;
