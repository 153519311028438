import React, { Suspense, lazy, useContext, useEffect } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';
import PrivateCASLRoute from '../../components/PrivateCASLRoute';
import IntlMessages from '../../util/IntlMessages';
import NotFound from '../../containers/NotFound';
import { useQuery } from '@apollo/client';
import { GQL_LOCAL_ME } from '../../apollo/query/user';
import ErrorBoundary from 'antd/lib/alert/ErrorBoundary';
import { getAdminPath } from '../../util/router';
import BadResponse from '../../components/BadResponse/index';
import { useIntl } from 'react-intl';
import CircularProgress from '../../components/CircularProgress';
import Dashboard from './dashboard';
import EventRoutes from './event';
import { MenuContext } from '../../contexts/MenuContext';
import { MENU_TYPE } from '../../constants/App';
import SubEventRoutes from './subEvent';
import ManagementRoutes from './management';

const EventInsert = lazy(() => import('./events/insert'));
const OrganizationSettings = lazy(() => import('./organization/settings'));

const Admin = ({ ability }) => {
    const intl = useIntl();

    const { data, loading, error } = useQuery(GQL_LOCAL_ME);

    const { menu, setMenu } = useContext(MenuContext);

    const location = useLocation();

    useEffect(() => {
        const isOtherMenuRoute = Boolean(location.pathname.match(/^\/admin\/(event|management)(.*)/));

        if (menu.type !== MENU_TYPE.MAIN && !isOtherMenuRoute) {
            setMenu({ type: MENU_TYPE.MAIN });
        }
    }, [menu.type, location]);

    if (loading) {
        return <CircularProgress />;
    }

    if (error || !data) {
        return <BadResponse title={intl.formatMessage({ id: '500.ooops' })} subtitle={intl.formatMessage({ id: '500.something_wrong' })} />;
    }

    return (
        <ErrorBoundary>
            <Suspense fallback={<IntlMessages id="common.loading" />}>
                <Switch>
                    <PrivateCASLRoute I="manage" a="Event" exact path={[getAdminPath(), getAdminPath('dashboard')]} component={Dashboard} ability={ability} />

                    <PrivateCASLRoute I="manage" a="Event" exact path={getAdminPath('events/insert')} component={EventInsert} />

                    <PrivateCASLRoute I="manage" a="Event" path={getAdminPath('event/:id/sub-event')} component={SubEventRoutes} />

                    <PrivateCASLRoute I="manage" a="Event" path={getAdminPath('event/:id')} component={EventRoutes} />

                    <PrivateCASLRoute I="update" a="Organization" path={getAdminPath('organization/settings')} component={OrganizationSettings} />

                    <PrivateCASLRoute I="manage" a="Organization" path={getAdminPath('management')} component={ManagementRoutes} />

                    <Route path="*" component={NotFound} />
                </Switch>
            </Suspense>
        </ErrorBoundary>
    );
};

export default Admin;
