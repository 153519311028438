import { gql } from '@apollo/client';
import { MULTILANGUAGE_FRAGMENT } from '../common';

export const SUB_EVENT_CONTAINER_DATA = gql`
    query subEvent($id: String!) {
        subEvent(id: $id) {
            id
            name
            event {
                id
                name
                type
                languages {
                    iso
                    main
                }
                main_language
            }
            has_teams
            team_players
            hide_starting_list
            subscriptions_limit
            subscriptions_min_age
            subscriptions_max_age
            manual_confirmation
            certificate {
                type
            }
            predefined_fields {
                athlete_card {
                    active
                    required
                    options {
                        card_number
                        type
                        value
                        label
                        certificate_required
                        card_upload
                    }
                }
            }
            timetables {
                subscription_start
                subscription_end
                event_start
                event_end
                hide_event_date
            }
        }
    }
`;

export const SUB_EVENT_PRICES_DATA = gql`
    query subEvent($id: String!) {
        subEvent(id: $id) {
            id
            name
            event {
                id
                name
                type
                timezone
                payments {
                    fee_to_org
                    sp_fee_to_org
                    location {
                        id
                        currency {
                            symbol
                            iso
                        }
                    }
                    ks_fee {
                        fixed
                        percent
                        sp_fixed
                        sp_percent
                        min
                    }
                    providers {
                        slug
                        active
                        reset_ks_fee
                        selling_point
                    }
                }
                main_language
            }
            prices {
                is_free
                base
                variations {
                    value
                    start
                    end
                }
                additions {
                    field_id
                    options {
                        field_value
                        price_increase
                    }
                }
            }
            timetables {
                subscription_start
                subscription_end
                event_start
                event_end
            }
            priceable_fields {
                name
                label {
                    ...multilanguageFragment
                }
                required
                custom_type
                options {
                    label {
                        ...multilanguageFragment
                    }
                    value
                }
            }
        }
    }
    ${MULTILANGUAGE_FRAGMENT}
`;

export const SUB_EVENT_SUBSCRIPTION_MODULE = gql`
    query subEvent($id: String!) {
        subEvent(id: $id) {
            id
            name
            event {
                id
                name
                type
                languages {
                    iso
                    main
                }
                main_language
                products {
                    id
                    name
                    description {
                        ...multilanguageFragment
                    }
                    variants {
                        sku
                        price
                        attributes {
                            attribute_name {
                                ...multilanguageFragment
                            }
                            option_name {
                                ...multilanguageFragment
                            }
                            option_value
                        }
                    }
                }
                payments {
                    location {
                        id
                        currency {
                            symbol
                            iso
                        }
                    }
                }
            }
            predefined_fields {
                phone {
                    active
                    required
                }
                location {
                    active
                    required
                }
                athlete_card {
                    active
                    required
                    options {
                        card_number
                        type
                        value
                        label
                        certificate_required
                        card_upload
                    }
                }
                citizenship {
                    active
                    required
                }
            }
            custom_fields {
                name
                label {
                    ...multilanguageFragment
                }
                required
                custom_type
                options {
                    label {
                        ...multilanguageFragment
                    }
                    value
                }
            }
            products_id
        }
    }
    ${MULTILANGUAGE_FRAGMENT}
`;

export const SUB_EVENT_DASHBOARD = gql`
    query subEvent($id: String!, $event_id: String!) {
        subEvent(id: $id) {
            id
            name
            has_teams
            team_players
            certificate {
                type
            }
            subscriptions_limit
            manual_confirmation
            event {
                id
                name
                type
                publish {
                    state
                }
                payments {
                    location {
                        id
                        currency {
                            symbol
                            iso
                        }
                    }
                }
            }
        }
        playersAggregations(event_id: $event_id, sub_event_id: $id) {
            count
            retired
            confirmed
            paid
            total_paid
        }
        paymentsAggregations(event_id: $event_id, sub_event_id: $id) {
            transactions {
                type
                count
                total
            }
        }
        playersAffluence(event_id: $event_id, sub_event_id: $id) {
            x
            y
        }
    }
`;

export const SUB_EVENT_DESCRIPTION = gql`
    query subEventDescription($id: String!) {
        subEvent(id: $id) {
            id
            name
            event {
                id
                name
                type
                languages {
                    iso
                    main
                }
                main_language
            }
            description {
                ...multilanguageFragment
            }
        }
    }
    ${MULTILANGUAGE_FRAGMENT}
`;

export const SUB_EVENT_COMMUNICATIONS = gql`
    query subEventDescription($id: String!) {
        subEvent(id: $id) {
            id
            name
            event {
                id
                name
                type
                languages {
                    iso
                    main
                }
                main_language
            }

            communications {
                confirmation_email {
                    ...multilanguageFragment
                }
            }
        }
    }
    ${MULTILANGUAGE_FRAGMENT}
`;

export const SUB_EVENT_PLAYERS = gql`
    query subeventPlayers($sorting: SortingInput, $pagination: PaginationInput, $filter: FilterPlayersInput!) {
        eventPlayers(sorting: $sorting, pagination: $pagination, filter: $filter) {
            items {
                id
                first_name
                last_name
                email
                retired
                paid_at
                confirmed
                confirm_email
                certificate {
                    path
                    expire_at
                    upload_at
                    organization_confirm
                }
                athlete_card {
                    number
                    label
                    type
                    value
                    validation
                    file_path
                    file_upload_at
                }
                tracking {
                    number
                    assignment_mode
                    assigned_at
                }
                created_at
                deleted_at
            }
            pagination {
                page
                pages
                current_items
                total_items
                size
            }
        }
    }
`;

export const SUB_EVENT_PLAYER = gql`
    query subEventPlayers($subEventId: String!, $playerId: String!, $language: String) {
        subEvent(id: $subEventId) {
            id
            name
            certificate {
                type
            }
            event {
                id
                name
                type
                languages {
                    iso
                    main
                }
                main_language
                payments {
                    location {
                        id
                        currency {
                            symbol
                            iso
                        }
                    }
                }
                sub_events {
                    id
                    name
                }
                products {
                    id
                    name
                    description_locale
                    variants {
                        sku
                        stock
                        sold_quantity
                        available_quantity
                        attributes {
                            attribute_name_locale
                            option_name_locale
                        }
                    }
                }
            }
            custom_fields {
                name
                label {
                    ...multilanguageFragment
                }
                required
                custom_type
                options {
                    label {
                        ...multilanguageFragment
                    }
                    value
                }
            }

            tracking {
                mode
            }
            predefined_fields {
                phone {
                    active
                    required
                }
                location {
                    active
                    required
                }
                athlete_card {
                    active
                    required
                    options {
                        card_number
                        type
                        value
                        label
                        certificate_required
                        card_upload
                    }
                }
            }
        }
        player(id: $playerId) {
            id
            first_name
            last_name
            email
            birthday
            phone
            gender
            citizenship
            citizenship_label(language: $language)
            custom_fields {
                label
                value
                name
            }
            product_variants {
                sku
                stock
                price
                attributes {
                    option_name_locale
                    attribute_name_locale
                }
                product {
                    id
                    name
                }
            }
            location {
                id
                name
                parents {
                    id
                    name
                }
            }
            athlete_card {
                number
                type
                value
                label
                validation
                organization_code
                organization_name
                file_path
                file_upload_at
            }
            order {
                id
                price
                additions
                fee
                fee_to_org
                subtotal
                total
            }
            payment {
                id
                transaction {
                    type
                    total
                    paid_at
                }
            }
            certificate {
                path
                expire_at
                upload_at
                organization_confirm
            }
            communications {
                url
                type
                sent_at
            }
            sub_event {
                id
            }
            tracking {
                number
                assignment_mode
                assigned_at
            }
            team {
                id
                name
            }
            retired
            confirmed
            confirm_email
            organization_note
            created_at
            updated_at
            deleted_at
        }
    }
    ${MULTILANGUAGE_FRAGMENT}
`;

export const SUB_EVENT_ATTACHMENTS = gql`
    query subEvent($id: String!) {
        subEvent(id: $id) {
            id
            name
            event {
                id
                name
                type
                languages {
                    iso
                    main
                }
                main_language
            }
            assets(type: "attachment") {
                id
                file_name
                context_type
                url
                category
                label {
                    ...multilanguageFragment
                }
            }
        }
    }
    ${MULTILANGUAGE_FRAGMENT}
`;

export const SUB_EVENT_TRACKING_DATA = gql`
    query subEvent($id: String!) {
        subEvent(id: $id) {
            id
            name
            event {
                id
                name
                type
                languages {
                    iso
                    main
                }
                main_language
            }
            certificate {
                type
            }
            predefined_fields {
                athlete_card {
                    active
                    required
                    options {
                        card_number
                        type
                        value
                        label
                        certificate_required
                        card_upload
                    }
                }
            }
            tracking {
                mode
                starting_number
            }
        }
    }
`;

export const EXPORT_SUB_EVENT_SUBSCRIPTIONS = gql`
    query exportSubEventSubscriptions($id: String!, $type: String, $locale: String) {
        exportSubEventSubscriptions(id: $id, type: $type, locale: $locale) {
            content
            filename
            url
        }
    }
`;

export const PLAYERS_SUMMARY = gql`
    query playersSummary($sub_event_id: String!) {
        playersSummary(sub_event_id: $sub_event_id) {
            total
            confirmed
            not_confirmed
            retired
            certificate_upload
            certificate_confirm
            athlete_card_upload
            athlete_card_confirm
            should_confirm
            should_upload_athlete_card
            should_confirm_athlete_card
            should_upload_certificate
            should_confirm_certificate
        }
    }
`;
