import React, { useEffect } from 'react';
import { LexicalComposer } from '@lexical/react/LexicalComposer';
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin';
import { ContentEditable } from '@lexical/react/LexicalContentEditable';
import { HistoryPlugin } from '@lexical/react/LexicalHistoryPlugin';
import { OnChangePlugin } from '@lexical/react/LexicalOnChangePlugin';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import LexicalErrorBoundary from '@lexical/react/LexicalErrorBoundary';
import { ListPlugin } from '@lexical/react/LexicalListPlugin';
import './theme.less';
import TreeViewPlugin from './plugins/TreeViewPlugin';
import { getEditorConfig } from './config';
import ToolbarPlugin from './plugins/ToolbarPlugin';
import LinkPlugin from './plugins/LinkPlugin';
import AutoLinkPlugin from './plugins/AutoLinkPlugin';
import ClickableLinkPlugin from './plugins/ClickableLinkPlugin';
import FloatingLinkEditorPlugin from './plugins/FloatingLinkEditorPlugin';
import ImagesPlugin from './plugins/ImagesPlugin';

// Lexical React plugins are React components, which makes them
// highly composable. Furthermore, you can lazy load plugins if
// desired, so you don't pay the cost for plugins until you
// actually use them.
function AutoFocusPlugin() {
    const [editor] = useLexicalComposerContext();

    useEffect(() => {
        // Focus the editor when the effect fires!
        editor.focus();
    }, [editor]);

    return null;
}

function Placeholder({ text }) {
    return <div className="editor-placeholder">{text}</div>;
}

const LexicalEditor = ({ value, onChange, placeholder, debug = false }) => {
    const [floatingAnchorElem, setFloatingAnchorElem] = React.useState(null);

    const onRef = (_floatingAnchorElem) => {
        if (_floatingAnchorElem !== null) {
            setFloatingAnchorElem(_floatingAnchorElem);
        }
    };

    return (
        <LexicalComposer initialConfig={getEditorConfig(value)}>
            <ToolbarPlugin />
            <div className="editor-container">
                <RichTextPlugin
                    contentEditable={
                        <div ref={onRef}>
                            <ContentEditable className="editor-input" />
                        </div>
                    }
                    placeholder={<Placeholder text={placeholder} />}
                    ErrorBoundary={LexicalErrorBoundary}
                />

                <OnChangePlugin onChange={onChange} />
                <HistoryPlugin />
                <ListPlugin />
                <LinkPlugin />
                <ImagesPlugin />
                <AutoLinkPlugin />
                <ClickableLinkPlugin />
                {debug && <TreeViewPlugin />}
                <AutoFocusPlugin />
                <FloatingLinkEditorPlugin anchorElem={floatingAnchorElem} />
            </div>
        </LexicalComposer>
    );
};

export default LexicalEditor;
