import React from 'react';
import IntlMessages from '../../../../util/IntlMessages';
import { Row, Col, Card, Form, Checkbox, Descriptions, Tag } from 'antd';
import { formatCurrency, getLocaleFromMultiLanguage } from '../../../../util/string';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { getAdminPath } from '../../../../util/router';

const ProductFields = ({ products, event }) => {

    if (!products || products.length === 0) {
        return (
            <Card title={<IntlMessages id="products.products" />} className="gx-card">
                <IntlMessages id="products.no_products" />
                <br />
                <br />
                <Link to={getAdminPath(`event/${event.id}/products`)}>
                    <IntlMessages id="products.create_product" />
                </Link>
            </Card>
        );
    }

    return (
        <Card title={<IntlMessages id="products.products" />} className="gx-card">
            <Form.Item name="products">
                <Checkbox.Group style={{ width: '100%' }}>
                    {products.map((product, pIndex) => {
                        return (
                            <Row gutter={[16, 16]} key={pIndex}>
                                <Col xs={12} lg={6}>
                                    <Checkbox value={product.id}>
                                        {product.name}
                                    </Checkbox>
                                </Col>
                                <Col xs={12} lg={18}>
                                    {product.variants.map((variant, index) => {
                                        return (
                                            <Descriptions
                                                size="small"
                                                bordered
                                                key={index}
                                                title={
                                                    <span>
                                                        <IntlMessages id="products.variant" /> {index + 1}
                                                    </span>
                                                }
                                                column={1}
                                                className="gx-mb-4"
                                            >
                                                <Descriptions.Item label={<IntlMessages id="products.attributes" />}>
                                                    {variant.attributes.map((attribute, index) => {
                                                        return (
                                                            <span key={index}>
                                                                {getLocaleFromMultiLanguage(attribute.attribute_name)}{' '}
                                                                <Tag>{getLocaleFromMultiLanguage(attribute.option_value)}</Tag>
                                                            </span>
                                                        );
                                                    })}
                                                </Descriptions.Item>
                                                <Descriptions.Item label={<IntlMessages id="common.price" />}>
                                                    {formatCurrency(variant.price / 100, 2, event.payments.location?.currency.iso)}
                                                </Descriptions.Item>
                                            </Descriptions>
                                        );
                                    })}
                                </Col>
                            </Row>
                        );
                    })}
                </Checkbox.Group>
            </Form.Item>
        </Card>
    );
};

export default ProductFields;
