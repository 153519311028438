import { gql } from '@apollo/client';

export const UPDATE_SUBEVENT_SUBSCRIPTION_MODULE = gql`
    mutation updateSubscriptionModule($id: String!, $input: SubscriptionModuleInput!) {
        updateSubscriptionModule(id: $id, input: $input)
    }
`;

export const UPDATE_SUB_EVENT_PRICES = gql`
    mutation updateSubEventPrices($id: String!, $input: UpdateSubEventPricesInput!) {
        updateSubEventPrices(id: $id, input: $input)
    }
`;

export const UPDATE_PLAYER = gql`
    mutation updatePlayer($id: String!, $input: UpdatePlayerInput!) {
        updatePlayer(id: $id, input: $input)
    }
`;

export const DELETE_SUB_EVENT = gql`
    mutation deleteSubEvent($id: String!) {
        deleteSubEvent(id: $id)
    }
`;

export const DUPLICATE_SUB_EVENT = gql`
    mutation duplicateSubEvent($id: String!) {
        duplicateSubEvent(id: $id) {
            id
            name
        }
    }
`;

export const MOVE_PLAYER = gql`
    mutation movePlayer($id: String!, $sub_event_id: String!) {
        movePlayer(id: $id, sub_event_id: $sub_event_id)
    }
`;

export const PLAYER_SUBSCRIPTION_CONFIRMATIONS = gql`
    mutation subscriptionConfirmations($input: [SubScriptionConfirmationsInput]!) {
        subscriptionConfirmations(input: $input)
    }
`;

export const PLAYER_ATHLETE_CARD_VALIDATIONS = gql`
    mutation playerAthleteCardValidations($players_id: [String], $sub_event_id: String) {
        playerAthleteCardValidations(players_id: $players_id, sub_event_id: $sub_event_id)
    }
`;

export const DELETE_PLAYER_MEDICAL_CERTIFICATE = gql`
    mutation deletePlayerMedicalCertificate($id: String!) {
        deletePlayerMedicalCertificate(id: $id)
    }
`;

export const ASSIGN_PLAYERS_TRACKING_NUMBERS = gql`
    mutation assignPlayersTrackingNumbers($sub_event_id: String!) {
        assignPlayersTrackingNumbers(sub_event_id: $sub_event_id)
    }
`;
