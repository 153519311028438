import { HomeOutlined } from '@ant-design/icons';
import { useQuery } from '@apollo/client';
import React from 'react';
import { Link } from 'react-router-dom';
import { getAdminPath } from '../../util/router';
import { GQL_LOCAL_ME } from '../../apollo/query/user';
import { get } from 'lodash';

const BreadcrumbHomeLink = ({ showWorkspaceName }) => {
    const { data, loading, error } = useQuery(GQL_LOCAL_ME);

    const currentWorkspaceName = !loading && !error ? get(data, 'me.current_workspace.name', '') : '';

    return (
        <Link to={getAdminPath('dashboard')}>
            {showWorkspaceName ? (
                <>
                    <HomeOutlined className="gx-mr-1" /> {currentWorkspaceName}
                </>
            ) : (
                <HomeOutlined />
            )}
        </Link>
    );
};

BreadcrumbHomeLink.defaultProps = {
    showWorkspaceName: true,
};

export default BreadcrumbHomeLink;
