import { useMutation, useQuery } from '@apollo/client';
import { Row, Col, Skeleton, Input, PageHeader, Card, Select, message, Button, Form } from 'antd';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { defaultCatchException, defaultMutationCallback } from '../../../../apollo/callbacks';
import { UPDATE_SUB_EVENT } from '../../../../apollo/mutation/event';
import { SUB_EVENT_COMMUNICATIONS } from '../../../../apollo/query/sub-event';
import BadResponse from '../../../../components/BadResponse';
import BadResponseErrorAlert from '../../../../components/BadResponseErrorAlert';
import IntlMessages from '../../../../util/IntlMessages';
import SubEventBreadcrumbs from '../breadcrumbs';

const CommunicationsForm = ({ intl, subEvent }) => {
    const { event } = subEvent;

    const history = useHistory();
    const languages = event.languages?.map((l) => l.iso);
    const [selectedLanguage, setSelectedLanguage] = useState(event.main_language);

    const [updateSubEvent, { loading: loadingMutation }] = useMutation(UPDATE_SUB_EVENT);

    const handleOk = (values) => {
        updateSubEvent({
            variables: {
                id: subEvent.id,
                subEvent: { confirmation_email_communication: values.text },
            },
        })
            .then((response) => defaultMutationCallback(response, () => closeHandler(response.data)))
            .catch((e) => defaultCatchException(e, intl));
    };
    const handleError = () => {};
    const closeHandler = () => {
        message.success(intl.formatMessage({ id: 'common.edit_successful' }));
    };

    return (
        <Form
            onFinish={handleOk}
            onFinishFailed={handleError}
            layout="vertical"
            labelCol={{ span: 20 }}
            initialValues={{
                text: subEvent?.communications?.confirmation_email || {},
            }}
            autoComplete="off"
        >
            <Row>
                <Col xs={24}>
                    <PageHeader
                        title={
                            <span>
                                <IntlMessages id="common.communications" />
                            </span>
                        }
                        onBack={() => history.goBack()}
                        extra={[
                            <Button key="1" onClick={() => closeHandler()}>
                                <IntlMessages id="common.cancel" />
                            </Button>,
                            <Button key="2" htmlType="submit" type="primary" loading={loadingMutation}>
                                {intl.formatMessage({ id: 'common.update' }).toUpperCase()}
                            </Button>,
                        ]}
                    />
                </Col>

                <Col xs={24}>
                    <BadResponseErrorAlert />
                </Col>

                <Col xs={24} sm={24} md={24} lg={24}>
                    <Card
                        title={<IntlMessages id="common.confirmation_email" />}
                        className="gx-card"
                        extra={[
                            <Select
                                value={selectedLanguage}
                                onSelect={(value) => {
                                    setSelectedLanguage(value);
                                }}
                                style={{ width: 100 }}
                                size="small"
                            >
                                {languages.map((value, key) => {
                                    return (
                                        <Select.Option key={key} value={value}>
                                            {intl.formatMessage({ id: `common.${value}` })}
                                        </Select.Option>
                                    );
                                })}
                            </Select>,
                        ]}
                    >
                        {event.languages.map((language) => {
                            return (
                                <Form.Item
                                    style={{ display: language.iso === selectedLanguage ? 'block' : 'none' }}
                                    name={['text', language.iso]}
                                    label={intl.formatMessage({ id: `common.confirmation_email_free_text` })}
                                    tooltip={intl.formatMessage({ id: `common.confirmation_email_free_text_tip` })}
                                    wrapperCol={{ span: 16 }}
                                >
                                    <Input.TextArea rows={8} />
                                </Form.Item>
                            );
                        })}
                    </Card>
                </Col>
            </Row>
        </Form>
    );
};

const SubEventCommunications = ({ match }) => {
    const { params } = match;

    const intl = useIntl();
    const { data, loading, error } = useQuery(SUB_EVENT_COMMUNICATIONS, { variables: { id: params.id } });

    if (error) {
        return <BadResponse title={intl.formatMessage({ id: '500.ooops' })} subtitle={intl.formatMessage({ id: '500.something_wrong' })} />;
    }

    return (
        <>
            <Row>
                <Col md={24}>
                    <SubEventBreadcrumbs subEvent={data?.subEvent} section={<IntlMessages id={`common.communications`} />} />
                </Col>
            </Row>

            <Row>
                <Col xs={24}>{!loading ? <CommunicationsForm loading={loading} subEvent={data?.subEvent} intl={intl} /> : <Skeleton />}</Col>
            </Row>
        </>
    );
};

export default SubEventCommunications;
