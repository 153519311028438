const DEFAULT_ORDER = 'descend';

export const getDefaultSortOrder = (columnKey, sortState, defaultSortOrder = DEFAULT_ORDER) => {
    if (!sortState) return defaultSortOrder;

    const { key, direction } = sortState;

    if (key === columnKey) {
        return direction === 1 ? 'ascend' : 'descend';
    } else {
        return null;
    }
};
