import { makeVar } from '@apollo/client';
import { LAYOUT_TYPE_FULL, NAV_STYLE_MINI_SIDEBAR, THEME_COLOR, THEME_TYPE_LITE } from '../../constants/ThemeSetting';
import { KS_EMP_LOCALE_STORAGE_KEY } from '../../lngProvider';

const locales = {
    en: {
        locale: 'en',
        name: 'English',
        icon: 'en',
    },
    it: {
        locale: 'it',
        name: 'Italiano',
        icon: 'it',
    },
    es: {
        locale: 'es',
        name: 'Español',
        icon: 'es',
    },
    pt: {
        locale: 'pt',
        name: 'Português',
        icon: 'pt',
    },
};

//Layout settings
const initialLayoutSettings = {
    navStyle: NAV_STYLE_MINI_SIDEBAR,
    layoutType: LAYOUT_TYPE_FULL,
    themeType: THEME_TYPE_LITE,
    themeColor: THEME_COLOR,
    isDirectionRTL: false,
    locale: null,
};
export const layoutSettingsVar = makeVar(initialLayoutSettings);
//

//Global variables
const initialGlobalSettings = {
    error: '',
    loading: false,
    message: '',
    navCollapsed: false,
    sideBarCollapsed: false,
    width: window.innerWidth,
    pathname: '/',
};
export const globalSettingsVar = makeVar(initialGlobalSettings);
//

export const showLoadingProgress = (showLoading) => {
    globalSettingsVar({ ...globalSettingsVar(), loading: showLoading });
};

export const changeLocale = (locale) => {
    localStorage.setItem(KS_EMP_LOCALE_STORAGE_KEY, locale);
    layoutSettingsVar({ ...layoutSettingsVar(), locale: locales[locale] });
};
//
