import { makeVar } from '@apollo/client';

const initialServerErrors = {
    hasError: false,
    items: [],
};

export const serverErrorsVar = makeVar(initialServerErrors);

export const clearErrors = () => {
    serverErrorsVar(initialServerErrors);
};
