import { UPLOAD_MAX_FILE_SIZE_MB, UPLOAD_SUPPORTED_MIME_TYPES } from '../upload-asset.constants';
import { find } from 'lodash';

export const isFileTypeSupported = (file, mimeTypes) => {
    if (mimeTypes.includes('*') || mimeTypes.includes(file.type)) {
        return true;
    }

    return false;
};

const bytesToMB = (bytes) => {
    return Math.round(bytes / Math.pow(1024, 2), 2);
};

export const isFileTooBig = (file, maxSize) => {
    if (file && file.size && maxSize >= bytesToMB(file.size)) {
        return false;
    }

    return true;
};

export const validateFilesType = (files, mimeTypes, intl) => {
    if (!files || files.length === 0) {
        return Promise.resolve();
    }

    const fileTypeNotSupported = find(files, (file) => !isFileTypeSupported(file, mimeTypes));

    if (fileTypeNotSupported) {
        return Promise.reject(intl.formatMessage({ id: 'uploads.file_type_not_supported' }, { allowed: mimeTypes.join(', ') }));
    }

    return Promise.resolve();
};

export const validateFilesSize = (files, maxSize, intl) => {
    if (!files || files.length === 0) {
        return Promise.resolve();
    }

    const isTooBig = find(files, (file) => isFileTooBig(file, maxSize));

    if (isTooBig) {
        return Promise.reject(
            intl.formatMessage(
                {
                    id: 'uploads.file_too_big',
                },
                {
                    max_file_size: maxSize,
                }
            )
        );
    }

    return Promise.resolve();
};

export const validateFiles = async (files, { maxSize = UPLOAD_MAX_FILE_SIZE_MB, mimeTypes = UPLOAD_SUPPORTED_MIME_TYPES } = {}, intl) => {
    try {
        await Promise.all(
            validateFormFiles(
                {
                    maxSize,
                    mimeTypes,
                },
                intl
            ).map((v) => v.validator(null, files))
        );
        return null;
    } catch (error) {
        return error;
    }
};

export const validateFormFiles = ({ maxSize = UPLOAD_MAX_FILE_SIZE_MB, mimeTypes = UPLOAD_SUPPORTED_MIME_TYPES } = {}, intl) => {
    return [
        {
            validator: (_, files) => validateFilesSize(files, maxSize, intl),
        },
        {
            validator: (_, files) => validateFilesType(files, mimeTypes, intl),
        },
    ];
};
