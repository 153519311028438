import { get, keys, isEmpty } from 'lodash';
import { PAYMENTS } from '../constants/App';
import { safeRoundedNonNegativeNumber } from './number';

export const paymentSlugToInt = (slug) => {
    return get(PAYMENTS, slug.toUpperCase().replace('-', '_'), null);
};

export const paymentIntToSlug = (integer) => {
    let slug = '';
    keys(PAYMENTS).map((key) => {
        if (PAYMENTS[key] === integer) {
            slug = key;
        }
    });
    return slug.toLowerCase();
};

/**
 *
 * @param {*} paymentsConfig payments gql
 * @param {*} provider provider di pagamento
 * @returns
 */
export const getKsFees = (provider, paymentsConfig) => {
    let fees = {};

    if (provider.reset_ks_fee === true) {
        fees.percent = 0;
        fees.fixed = 0;
        fees.min = 0;
        fees.feeToOrg = get(paymentsConfig, 'sp_fee_to_org');
    } else if (provider.selling_point === true) {
        fees.percent = get(paymentsConfig, 'ks_fee.sp_percent', 0);
        fees.fixed = get(paymentsConfig, 'ks_fee.sp_fixed', 0);
        fees.min = 0;
        fees.feeToOrg = get(paymentsConfig, 'sp_fee_to_org');
    } else {
        fees.percent = get(paymentsConfig, 'ks_fee.percent', 0);
        fees.fixed = get(paymentsConfig, 'ks_fee.fixed', 0);
        fees.min = get(paymentsConfig, 'ks_fee.min', 0);
        fees.feeToOrg = get(paymentsConfig, 'fee_to_org');
    }

    return fees;
};

export const processKsFees = (provider, paymentsConfig, prices) => {
    const ksFeeConfig = getKsFees(provider, paymentsConfig);

    const basePrice = safeRoundedNonNegativeNumber(prices?.base);

    if (isEmpty(paymentsConfig.ks_fee || isEmpty(ksFeeConfig))) return 0;

    const priceWithFees = basePrice / 100;

    const minFee = ksFeeConfig.min / 100;

    let ksFee = priceWithFees * (ksFeeConfig.percent / (100 * 100)) + ksFeeConfig.fixed / 100;
    if (minFee > ksFee) ksFee = minFee;
    return { ksFee, ksFeeConfig };
};

export const processProviderPrice = (provider, paymentsConfig, prices) => {
    //non ci sono commissioni sul pagamento
    //per convenzione, mantenerle solo per visualizzare il prezzo finale
    provider = {
        ...provider,
        fee: {
            percent: 0,
            fixed: 0,
        },
    };

    let { ksFee, ksFeeConfig } = processKsFees(provider, paymentsConfig, prices);

    let providerFee = 0;

    let price = safeRoundedNonNegativeNumber(prices?.base / 100);

    if (ksFeeConfig.feeToOrg === true) {
        providerFee = price * (provider.fee.percent / (100 * 100)) + provider.fee.fixed / 100;
        price += providerFee;
    } else {
        providerFee = (price + ksFee) * (provider.fee.percent / (100 * 100)) + provider.fee.fixed / 100;
        price += ksFee + providerFee;
    }

    return { ksFee, providerFee, price };
};
