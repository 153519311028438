import moment from 'moment';
import { DATETIME_FORMAT, DATE_FORMAT } from '../constants/App';

export const formatDate = (date, outFormat = DATE_FORMAT, inFormat = null, utc = false) => {
    if (!date) return null;
    let m = null;
    if (inFormat) {
        m = moment(date, inFormat);
    } else {
        m = moment(date);
    }

    if (utc) {
        m = m.utc();
    }

    return m.format(outFormat);
};

export const formatDateTime = (date) => {
    return formatDate(date, DATETIME_FORMAT);
};
