import { useMutation, useQuery } from '@apollo/client';
import React, { useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { EVENT_PAYMENTS_REPORT } from '../../../../apollo/query/event';
import BadResponse from '../../../../components/BadResponse';
import DevButton from '../../../../components/Dev/DevButton';
import IntlMessages from '../../../../util/IntlMessages';
import {
    Statistic,
    Breadcrumb,
    Card,
    Col,
    PageHeader,
    Row,
    Tabs,
    Typography,
    Table,
    Space,
    Button,
    DatePicker,
    Form,
    InputNumber,
    Modal,
    Input,
    Tooltip,
    Divider,
} from 'antd';
import CircularProgress from '../../../../components/CircularProgress';
import BreadcrumbHomeLink from '../../../../components/BreadcrumbHomeLink';
import CardDescriptions, { CardDescriptionsItem } from '../../../../components/Descriptions/CardDescription';
import { formatCurrency } from '../../../../util/string';
import { requiredRule } from '../../../../util/form';
import { formatDate } from '../../../../util/date';
import { ADD_EXTRA_FEE, ADD_TRANSFER_TO_ORGANIZATION, DELETE_EXTRA_FEE, DELETE_TRANSFER_TO_ORGANIZATION } from '../../../../apollo/mutation/event';
import { InfoCircleOutlined } from '@ant-design/icons';
import { DATE_FORMAT, PAYMENTS } from '../../../../constants/App';
import { defaultCatchException, defaultMutationCallback } from '../../../../apollo/callbacks';
import { usePersistedState } from '../../../../hooks/usePersistedState';
import BadResponseErrorAlert from '../../../../components/BadResponseErrorAlert';
import { Can } from '../../../../acl';
import { find, get } from 'lodash';
import { useActiveBreakpoint } from '../../../../hooks/useActiveBreakpoint';

const PaymentsCountCard = ({ report, loading }) => {
    const [showDetails, setShowDetails] = useState(false);
    return (
        <Card
            className="gx-card"
            title={<IntlMessages id="common.payments_number" />}
            extra={[
                <a
                    onClick={() => {
                        setShowDetails(!showDetails);
                    }}
                >
                    {showDetails ? <IntlMessages id="common.total" /> : <IntlMessages id="common.details" />}
                </a>,
            ]}
        >
            {showDetails ? (
                <CardDescriptions column={2} className="gx-ml-0">
                    {(report.data_types || []).map((row, index) => {
                        return (
                            <CardDescriptionsItem key={index} label={<IntlMessages id={`common.payment_type_${row.type}`} />}>
                                {row.payments}
                            </CardDescriptionsItem>
                        );
                    })}
                </CardDescriptions>
            ) : (
                <Statistic value={report.payments} precision={0} loading={loading} />
            )}
        </Card>
    );
};

const PlayersCountCard = ({ report, loading }) => {
    const [showDetails, setShowDetails] = useState(false);
    return (
        <Card
            className="gx-card"
            title={
                <Tooltip title={<IntlMessages id="common.payers_players_number" />}>
                    <IntlMessages id="common.payers_players_number" /> <InfoCircleOutlined />
                </Tooltip>
            }
            extra={[
                <a
                    onClick={() => {
                        setShowDetails(!showDetails);
                    }}
                >
                    {showDetails ? <IntlMessages id="common.players" /> : <IntlMessages id="common.details" />}
                </a>,
            ]}
        >
            {showDetails ? (
                <CardDescriptions column={2} className="gx-ml-0">
                    {(report.data_types || []).map((row, index) => {
                        return (
                            <CardDescriptionsItem key={index} label={<IntlMessages id={`common.payment_type_${row.type}`} />}>
                                {row.players}
                            </CardDescriptionsItem>
                        );
                    })}
                </CardDescriptions>
            ) : (
                <Statistic value={report.players} precision={0} loading={loading} />
            )}
        </Card>
    );
};

const SubTotalCard = ({ report, loading, currency }) => {
    const [showDetails, setShowDetails] = useState(false);
    return (
        <Card
            className="gx-card"
            title={
                <Tooltip title={<IntlMessages id="common.subtotal_gain" />}>
                    <IntlMessages id="common.subtotal_gain" /> <InfoCircleOutlined />
                </Tooltip>
            }
            extra={[
                <a
                    onClick={() => {
                        setShowDetails(!showDetails);
                    }}
                >
                    {showDetails ? <IntlMessages id="common.subtotal" /> : <IntlMessages id="common.details" />}
                </a>,
            ]}
        >
            {showDetails ? (
                <CardDescriptions column={2} className="gx-ml-0">
                    {(report.data_types || []).map((row, index) => {
                        return (
                            <CardDescriptionsItem key={index} label={<IntlMessages id={`common.payment_type_${row.type}`} />}>
                                {formatCurrency(row.subtotal / 100, 2, currency.iso)}
                            </CardDescriptionsItem>
                        );
                    })}
                </CardDescriptions>
            ) : (
                <Statistic value={formatCurrency(report.subtotal / 100, 2, currency.iso)} loading={loading} />
            )}
        </Card>
    );
};

const FeesCard = ({ report, loading, currency }) => {
    const [showDetails, setShowDetails] = useState(false);
    return (
        <Card
            className="gx-card"
            title={<IntlMessages id="common.total_fees" />}
            extra={[
                <a
                    onClick={() => {
                        setShowDetails(!showDetails);
                    }}
                >
                    {showDetails ? <IntlMessages id="common.total" /> : <IntlMessages id="common.details" />}
                </a>,
            ]}
        >
            {showDetails ? (
                <CardDescriptions column={1} className="gx-ml-0">
                    <CardDescriptionsItem label={<IntlMessages id="common.ks_fees_to_players" />}>
                        {formatCurrency(report.fees.to_player / 100, 2, currency.iso)}
                    </CardDescriptionsItem>
                    <CardDescriptionsItem label={<IntlMessages id="common.ks_fees_to_org" />}>
                        {formatCurrency(report.fees.to_org / 100, 2, currency.iso)}
                    </CardDescriptionsItem>
                </CardDescriptions>
            ) : (
                <Statistic value={formatCurrency(report.fees.total / 100, 2, currency.iso)} loading={loading} />
            )}
        </Card>
    );
};

const TotalCard = ({ report, loading, currency }) => {
    const [showDetails, setShowDetails] = useState(false);
    return (
        <Card
            className="gx-card"
            title={<IntlMessages id="common.total_gain_long" />}
            extra={[
                <a
                    onClick={() => {
                        setShowDetails(!showDetails);
                    }}
                >
                    {showDetails ? <IntlMessages id="common.total" /> : <IntlMessages id="common.details" />}
                </a>,
            ]}
        >
            {showDetails ? (
                <CardDescriptions column={2} className="gx-ml-0">
                    {(report.data_types || []).map((row, index) => {
                        return (
                            <CardDescriptionsItem key={index} label={<IntlMessages id={`common.payment_type_${row.type}`} />}>
                                {formatCurrency(row.total / 100, 2, currency.iso)}
                            </CardDescriptionsItem>
                        );
                    })}
                </CardDescriptions>
            ) : (
                <Statistic value={formatCurrency(report.total / 100, 2, currency.iso)} loading={loading} />
            )}
        </Card>
    );
};

const ExtraFeeModalForm = ({ visible, onCancel, onCreate, loading }) => {
    const intl = useIntl();
    const [form] = Form.useForm();

    return (
        <Modal
            visible={visible}
            loading={loading}
            title={
                <Tooltip title={<IntlMessages id="common.extra_fees_tip" />}>
                    <IntlMessages id="common.extra_fees" />
                    &nbsp;
                    <InfoCircleOutlined />
                </Tooltip>
            }
            okText={<IntlMessages id="common.add" />}
            cancelText={<IntlMessages id="common.cancel" />}
            onCancel={onCancel}
            onOk={() => {
                form.validateFields()
                    .then((values) => {
                        form.resetFields();
                        onCreate(values);
                    })
                    .catch((info) => {
                        console.log('Validate Failed:', info);
                    });
            }}
        >
            <BadResponseErrorAlert className="gx-mb-3" />
            <Form form={form} layout="vertical">
                <Form.Item name="date" label={<IntlMessages id="common.date" />} rules={[requiredRule(intl)]}>
                    <DatePicker format={DATE_FORMAT} />
                </Form.Item>
                <Form.Item name="description" label={<IntlMessages id="common.description" />} rules={[requiredRule(intl)]}>
                    <Input />
                </Form.Item>
                <Form.Item name="amount" label={<IntlMessages id="common.amount" />} rules={[requiredRule(intl)]}>
                    <InputNumber />
                </Form.Item>
            </Form>
        </Modal>
    );
};

const TransferModalForm = ({ visible, onCancel, onCreate, loading }) => {
    const intl = useIntl();
    const [form] = Form.useForm();

    return (
        <Modal
            visible={visible}
            loading={loading}
            title={
                <Tooltip title={<IntlMessages id="common.transfers_to_org_tip" />}>
                    <IntlMessages id="common.transfers_to_org" />
                    &nbsp;
                    <InfoCircleOutlined />
                </Tooltip>
            }
            okText={<IntlMessages id="common.add" />}
            cancelText={<IntlMessages id="common.cancel" />}
            onCancel={onCancel}
            onOk={() => {
                form.validateFields()
                    .then((values) => {
                        form.resetFields();
                        onCreate(values);
                    })
                    .catch((info) => {
                        console.log('Validate Failed:', info);
                    });
            }}
        >
            <BadResponseErrorAlert className="gx-mb-3" />

            <Form form={form} layout="vertical">
                <Form.Item name="date" label={<IntlMessages id="common.date" />} rules={[requiredRule(intl)]}>
                    <DatePicker format={DATE_FORMAT} />
                </Form.Item>
                <Form.Item name="code" label={<IntlMessages id="common.transfer_code" />} rules={[requiredRule(intl)]}>
                    <Input />
                </Form.Item>
                <Form.Item name="amount" label={<IntlMessages id="common.amount" />} rules={[requiredRule(intl)]}>
                    <InputNumber />
                </Form.Item>
            </Form>
        </Modal>
    );
};

const ExtraFeesView = ({ refetch, event, currency, intl }) => {
    const [showModal, setShowModal] = useState(false);
    const [addExtraFee, { loading }] = useMutation(ADD_EXTRA_FEE);
    const [deleteExtraFee, { loading: deleteLoading }] = useMutation(DELETE_EXTRA_FEE);

    return (
        <div>
            <Space className="gx-d-flex gx-justify-content-between">
                <Typography.Text className="gx-d-block gx-text-uppercase gx-mb-3">
                    <IntlMessages id="common.extra_fees" />
                </Typography.Text>
                <Can I="manage" a="Organization">
                    <Button
                        onClick={() => {
                            setShowModal(true);
                        }}
                    >
                        <IntlMessages id="common.add" />
                    </Button>
                </Can>
            </Space>
            <Table
                columns={[
                    {
                        title: <IntlMessages id="common.date" />,
                        dataIndex: 'date',
                        align: 'center',
                        width: 150,
                        render(date) {
                            return formatDate(date);
                        },
                    },
                    {
                        title: <IntlMessages id="common.description" />,
                        dataIndex: 'description',
                    },
                    {
                        title: <IntlMessages id="common.amount" />,
                        dataIndex: 'amount',
                        align: 'right',
                        width: 150,
                        render(amount) {
                            return formatCurrency(amount / 100, 2, currency.iso);
                        },
                    },
                    {
                        title: '',
                        dataIndex: 'id',
                        width: 100,
                        align: 'center',
                        render(id) {
                            return [
                                <Can I="manage" a="Organization">
                                    <Button
                                        key="1"
                                        className="gx-btn-danger gx-mb-0"
                                        size="small"
                                        loading={deleteLoading}
                                        onClick={() => {
                                            Modal.confirm({
                                                title: intl.formatMessage({ id: 'common.delete' }),
                                                content: intl.formatMessage({ id: 'common.delete_modal_text' }),
                                                okText: intl.formatMessage({ id: 'common.delete' }),
                                                cancelText: intl.formatMessage({ id: 'common.cancel' }),
                                                onOk() {
                                                    deleteExtraFee({
                                                        variables: {
                                                            id: event.id,
                                                            fee_id: id,
                                                        },
                                                    })
                                                        .then((response) =>
                                                            defaultMutationCallback(response, () => {
                                                                refetch();
                                                            })
                                                        )
                                                        .catch((e) => defaultCatchException(e, intl));
                                                },
                                            });
                                        }}
                                    >
                                        <IntlMessages id="common.delete" />
                                    </Button>
                                </Can>,
                            ];
                        },
                    },
                ]}
                dataSource={event.payments?.extra_fees || []}
                summary={() => {
                    return (
                        <Table.Summary.Row>
                            <Table.Summary.Cell colSpan={2} align="right">
                                <IntlMessages id="common.total" />
                            </Table.Summary.Cell>
                            <Table.Summary.Cell align="right">
                                {formatCurrency(event.payments?.extra_fees?.reduce((prev, curr) => prev + curr.amount, 0) / 100, 2, currency.iso)}
                            </Table.Summary.Cell>
                            <Table.Summary.Cell />
                        </Table.Summary.Row>
                    );
                }}
            />
            <ExtraFeeModalForm
                event={event}
                visible={showModal}
                onCancel={() => {
                    setShowModal(false);
                }}
                loading={loading}
                onCreate={(values) => {
                    addExtraFee({
                        variables: {
                            id: event.id,
                            input: {
                                date: values.date,
                                description: values.description,
                                amount: parseInt(values.amount * 100),
                            },
                        },
                    })
                        .then((response) =>
                            defaultMutationCallback(response, () => {
                                refetch();
                                setShowModal(false);
                            })
                        )
                        .catch((e) => defaultCatchException(e, intl));
                }}
            />
        </div>
    );
};

const TransfersToOrganizationView = ({ refetch, event, currency, intl }) => {
    const [showModal, setShowModal] = useState(false);
    const [addTransfer, { loading }] = useMutation(ADD_TRANSFER_TO_ORGANIZATION);
    const [deleteTransfer, { loading: deleteLoading }] = useMutation(DELETE_TRANSFER_TO_ORGANIZATION);

    return (
        <div>
            <Space className="gx-d-flex gx-justify-content-between">
                <Typography.Text className="gx-d-block gx-text-uppercase gx-mb-3">
                    <IntlMessages id="common.transfers_to_org" />
                </Typography.Text>
                <Can I="manage" a="Organization">
                    <Button
                        onClick={() => {
                            setShowModal(true);
                        }}
                    >
                        <IntlMessages id="common.add" />
                    </Button>
                </Can>
            </Space>
            <Table
                columns={[
                    {
                        title: <IntlMessages id="common.date" />,
                        dataIndex: 'date',
                        align: 'center',
                        width: 150,
                        render(date) {
                            return formatDate(date);
                        },
                    },
                    {
                        title: <IntlMessages id="common.transfer_code" />,
                        dataIndex: 'code',
                    },
                    {
                        title: <IntlMessages id="common.amount" />,
                        dataIndex: 'amount',
                        align: 'right',
                        width: 150,
                        render(amount) {
                            return formatCurrency(amount / 100, 2, currency.iso);
                        },
                    },
                    {
                        title: '',
                        dataIndex: 'id',
                        width: 100,
                        align: 'center',
                        render(id) {
                            return [
                                <Can I="manage" a="Organization">
                                    <Button
                                        key="1"
                                        className="gx-btn-danger gx-mb-0"
                                        size="small"
                                        loading={deleteLoading}
                                        onClick={() => {
                                            Modal.confirm({
                                                title: intl.formatMessage({ id: 'common.delete' }),
                                                content: intl.formatMessage({ id: 'common.delete_modal_text' }),
                                                okText: intl.formatMessage({ id: 'common.delete' }),
                                                cancelText: intl.formatMessage({ id: 'common.cancel' }),
                                                onOk() {
                                                    deleteTransfer({
                                                        variables: {
                                                            id: event.id,
                                                            transfer_id: id,
                                                        },
                                                    })
                                                        .then((response) =>
                                                            defaultMutationCallback(response, () => {
                                                                refetch();
                                                            })
                                                        )
                                                        .catch((e) => defaultCatchException(e, intl));
                                                },
                                            });
                                        }}
                                    >
                                        <IntlMessages id="common.delete" />
                                    </Button>
                                </Can>,
                            ];
                        },
                    },
                ]}
                dataSource={event.payments?.transfers_to_org || []}
                summary={() => {
                    return (
                        <Table.Summary.Row>
                            <Table.Summary.Cell colSpan={2} align="right">
                                <IntlMessages id="common.total" />
                            </Table.Summary.Cell>
                            <Table.Summary.Cell align="right">
                                {formatCurrency(event.payments?.transfers_to_org?.reduce((prev, curr) => prev + curr.amount, 0) / 100, 2, currency.iso)}
                            </Table.Summary.Cell>
                            <Table.Summary.Cell />
                        </Table.Summary.Row>
                    );
                }}
            />
            <TransferModalForm
                event={event}
                visible={showModal}
                onCancel={() => {
                    setShowModal(false);
                }}
                loading={loading}
                onCreate={(values) => {
                    addTransfer({
                        variables: {
                            id: event.id,
                            input: {
                                date: values.date,
                                code: values.code,
                                amount: parseInt(values.amount * 100),
                            },
                        },
                    })
                        .then((response) =>
                            defaultMutationCallback(response, () => {
                                refetch();
                                setShowModal(false);
                            })
                        )
                        .catch((e) => defaultCatchException(e, intl));
                }}
            />
        </div>
    );
};

const PaymentReport = ({ event, currency, report }) => {
    const _formatCurrency = (value) => {
        return formatCurrency(value / 100, 2, currency.iso);
    };

    const values = useMemo(() => {
        const ticketsFees = report.fees?.total || 0;
        const extraFees = event.payments?.extra_fees?.reduce((prev, curr) => prev + curr.amount, 0);
        const totalFees = ticketsFees + extraFees;
        const transfersToOrg = event.payments?.transfers_to_org?.reduce((prev, curr) => prev + curr.amount, 0);
        const cashPayments = find(report.data_types, (item) => parseInt(item.type) === PAYMENTS.CASH);
        const cashPaymentsTotal = cashPayments?.total || 0;
        const ksGain = report.total - report.bank_payment_to_org_account - cashPaymentsTotal;
        const organizationCredit = ksGain - totalFees;
        const organizationNetCredit = organizationCredit - transfersToOrg;

        return {
            ticketsFees,
            extraFees,
            totalFees,
            transfersToOrg,
            cashPaymentsTotal,
            ksGain,
            organizationCredit,
            organizationNetCredit,
        };
    }, [report, event]);

    const getTransferFee = (amount) => {
        let transferFee = get(event, 'payments.location.transfer_fee');

        if (transferFee) {
            return transferFee;
        }

        return {
            threshold: 25000,
            fee: 100,
        };
    };

    const transferFee = getTransferFee();

    return (
        <Row>
            <Col xs={24} md={18} lg={12}>
                <dl class="gx-dl-horizontal gx-dl-payment-summary">
                    <dt>
                        <IntlMessages id="common.regular_fees_total" />
                    </dt>
                    <dd>{_formatCurrency(values.ticketsFees)}</dd>
                    <dt>
                        <IntlMessages id="common.extra_fees_total" />
                    </dt>
                    <dd>+ {_formatCurrency(values.extraFees)}</dd>
                    <dt className="gx-font-weight-bold">
                        <IntlMessages id="common.total_fees" />
                    </dt>
                    <dd className="gx-font-weight-bold">= {_formatCurrency(values.totalFees)}</dd>
                    <Divider />
                    <dt>
                        <IntlMessages id="common.total_gain" /> (<IntlMessages id="common.discounts" />)
                    </dt>
                    <dd>
                        {_formatCurrency(report.total)} <small>({_formatCurrency(report.discount)} )</small>
                    </dd>
                    <dt>
                        <IntlMessages id="common.manual_payment_gain" />
                    </dt>
                    <dd>- {_formatCurrency(values.cashPaymentsTotal)}</dd>
                    <dt>
                        <IntlMessages id="common.bank_payment_on_organization_account" />
                    </dt>
                    <dd>- {_formatCurrency(report.bank_payment_to_org_account)}</dd>
                    <dt className="gx-font-weight-bold">
                        <IntlMessages id="common.ks_total_gain" />
                    </dt>
                    <dd className="gx-font-weight-bold">= {_formatCurrency(values.ksGain)}</dd>
                    <Divider />
                    <dt>
                        <IntlMessages id="common.total_organization_credit" />
                    </dt>
                    <dd>{_formatCurrency(values.organizationCredit)}</dd>
                    <dt>
                        <IntlMessages id="common.transfers_total" />
                    </dt>
                    <dd>- {_formatCurrency(values.transfersToOrg)}</dd>
                    <dt className="gx-font-weight-bold">
                        <IntlMessages id="common.current_organization_credit_" />
                    </dt>
                    <dd className="gx-font-weight-bold">= {_formatCurrency(values.organizationNetCredit)}</dd>
                </dl>
                <Divider />

                {/* 
                TODO: Implement transfer request
                <button className="btn btn-info pull-right gx-ml-3 transfer-button"><i className="icon-wallet"></i> {<IntlMessages id="common.request_transfer" />}</button> 
                */}

                <p>
                    <small>
                        *&nbsp;
                        <IntlMessages
                            id="common.request_transfer_fees"
                            values={{
                                value: _formatCurrency(transferFee.threshold),
                                value2: _formatCurrency(transferFee.fee),
                            }}
                        />
                    </small>
                </p>
            </Col>
        </Row>
    );
};

const EventPaymentsSummary = ({ match }) => {
    const { params } = match;

    const intl = useIntl();

    const activeBreakpoint = useActiveBreakpoint();

    const [selectedTab, setSelectedTab] = usePersistedState('event.payments-summary.tab', '1');

    const { data, loading, error, refetch } = useQuery(EVENT_PAYMENTS_REPORT, {
        variables: {
            id: params.id,
        },
    });

    let event = {};
    let report = {};
    let currency = {}; //iso, symbol

    if (error) {
        return <BadResponse title={intl.formatMessage({ id: '500.ooops' })} subtitle={intl.formatMessage({ id: '500.something_wrong' })} />;
    }

    if (loading) {
        return <CircularProgress />;
    }

    event = data.event;
    report = data.paymentsReportAggregations;
    currency = event.payments?.location?.currency;

    return (
        <>
            <Row>
                <Col md={24}>
                    <Breadcrumb className="gx-pb-3">
                        <Breadcrumb.Item>
                            <BreadcrumbHomeLink />
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>{event.name}</Breadcrumb.Item>
                        <Breadcrumb.Item>
                            <IntlMessages id="common.payments_report" />
                        </Breadcrumb.Item>
                    </Breadcrumb>
                </Col>
            </Row>

            <Row>
                <Col xs={24}>
                    <PageHeader
                        title={
                            <span>
                                <IntlMessages id="common.payments_report" />
                                &nbsp;{event.name}
                            </span>
                        }
                    />
                    <Row>
                        <Col xs={24} md={8}>
                            <PaymentsCountCard report={report} loading={loading} />
                        </Col>
                        <Col xs={24} md={8}>
                            <PlayersCountCard report={report} loading={loading} />
                        </Col>
                        <Col xs={24} md={8}>
                            <SubTotalCard report={report} loading={loading} currency={currency} />
                        </Col>
                        <Col xs={24} md={8}>
                            <Card className="gx-card" title={<IntlMessages id="common.total_player_fees" />}>
                                <Statistic value={formatCurrency(report.fees?.to_player / 100, 2, currency.iso)} loading={loading} />
                            </Card>
                        </Col>
                        <Col xs={24} md={8}>
                            <FeesCard report={report} loading={loading} currency={currency} />
                        </Col>
                        <Col xs={24} md={8}>
                            <TotalCard report={report} loading={loading} currency={currency} />
                        </Col>
                    </Row>

                    <Row>
                        <Col xs={24}>
                            <Card title={<IntlMessages id="common.payments_summary" />} className="gx-card">
                                <Tabs
                                    tabPosition={activeBreakpoint === 'XS' ? 'top' : 'left'}
                                    activeKey={selectedTab}
                                    onChange={(value) => {
                                        setSelectedTab(value);
                                    }}
                                >
                                    <Tabs.TabPane key="1" tab={<IntlMessages id="common.totals" />}>
                                        <PaymentReport event={event} report={report} currency={currency} loading={loading} />
                                    </Tabs.TabPane>
                                    <Tabs.TabPane key="2" tab={<IntlMessages id="common.extra_fees" />}>
                                        <ExtraFeesView event={event} currency={currency} loading={loading} intl={intl} refetch={refetch} />
                                    </Tabs.TabPane>
                                    <Tabs.TabPane key="3" tab={<IntlMessages id="common.transfers" />}>
                                        <TransfersToOrganizationView event={event} currency={currency} loading={loading} intl={intl} refetch={refetch} />
                                    </Tabs.TabPane>
                                </Tabs>
                            </Card>
                        </Col>
                    </Row>
                </Col>
            </Row>

            <DevButton path={__filename} />
        </>
    );
};

export default EventPaymentsSummary;
