import { gql } from '@apollo/client';

export const GET_SPORTS = gql`
    query getSports($locale: String) {
        sports {
            id
            name(locale: $locale)
            keepsporting_id
        }
    }
`;
