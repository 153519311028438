import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBank, faMoneyBill, faBan, faBarcode, faUser, faCreditCard, faUnderline } from '@fortawesome/free-solid-svg-icons';
import { faPaypal, faStripe } from '@fortawesome/free-brands-svg-icons';
import { Tooltip } from 'antd';
import IntlMessages from '../../util/IntlMessages';

const paymentTypeToIcon = (type) => {
    let icon;

    switch (type) {
        case 1:
            icon = faBank;
            break;
        case 2:
            icon = faStripe;
            break;
        case 3:
            icon = faPaypal;
            break;
        case 4:
            icon = faUser;
            break;
        case 5:
            icon = faBarcode;
            break;
        case 6:
            icon = faBan;
            //icon += ' font-green-jungle';
            break;
        case 7:
            icon = faUnderline;
            break;
        case 8:
            icon = faCreditCard;
            break;
        case 9:
            icon = faMoneyBill;
            break;
        case 10:
            icon = faCreditCard;
            break;
    }

    return icon;
};

const PaymentTypeIcon = ({ transaction }) => {
    if (!transaction) {
        return null;
    }

    return (
        <Tooltip title={<IntlMessages id={`common.payment_type_${transaction.type}`} />}>
            <FontAwesomeIcon className={`gx-fs-lg ${transaction.paid_at ? 'gx-text-success' : ''} `} icon={paymentTypeToIcon(transaction.type)} />
        </Tooltip>
    );
};

export default PaymentTypeIcon;
