import React, { useContext, useEffect } from 'react';
import { MenuContext } from '../../../contexts/MenuContext';
import { MENU_TYPE } from '../../../constants/App';
import { Switch } from 'react-router-dom';
import PrivateCASLRoute from '../../../components/PrivateCASLRoute';
import { getAdminPath } from '../../../util/router';
import ManagementDashboard from './dashboard';
import Profiles from './profiles';

const ManagementRoutes = () => {
    const { menu, setMenu } = useContext(MenuContext);

    useEffect(() => {
        if (menu.type !== MENU_TYPE.MANAGEMENT) {
            setMenu({
                type: MENU_TYPE.MANAGEMENT,
            });
        }
    }, [menu.type]);

    return (
        <Switch>
            <PrivateCASLRoute
                I="manage"
                a="Organization"
                exact={true}
                path={[getAdminPath('management'), getAdminPath('management/dashboard')]}
                component={ManagementDashboard}
            />

            <PrivateCASLRoute I="manage" a="Profile" exact={true} path={getAdminPath('management/profiles')} component={Profiles} />
        </Switch>
    );
};

export default ManagementRoutes;
