import React from 'react';
import { Card, Empty } from 'antd';

const EmptyCard = (props) => {
    return (
        <Card {...props} className="gx-card">
            <Empty image={props.size === 'sm' ? Empty.PRESENTED_IMAGE_SIMPLE : Empty.PRESENTED_IMAGE_DEFAULT} />
        </Card>
    );
};

export default EmptyCard;
