import React, { useEffect, useState } from 'react';
import { Menu } from 'antd';
import { THEME_TYPE_LITE } from '../../constants/ThemeSetting';
import { Can } from '../../acl';
import {
    AlignLeftOutlined,
    AppstoreOutlined,
    FieldNumberOutlined,
    FileOutlined,
    FormOutlined,
    FundOutlined,
    HomeOutlined,
    InfoCircleOutlined,
    NotificationOutlined,
    PictureOutlined,
    PlusOutlined,
    TagOutlined,
    TagsOutlined,
    TeamOutlined,
    WalletOutlined,
} from '@ant-design/icons';
import IntlMessages from '../../util/IntlMessages';
import { Link, useLocation } from 'react-router-dom';
import { getAdminPath, getOpenedMenuByUri } from '../../util/router';
import { MENU_TYPE } from '../../constants/App';
import { globalSettingsVar } from '../../apollo/local/cache';
import { isDevelopment } from '../../util/config';

const SidebarMenu = ({ themeType, menu, drawerWrapped }) => {
    const adminPath = getAdminPath();

    const [menuSelectedKeys, setMenuSelectedKeys] = useState([]);

    const location = useLocation();

    useEffect(() => {
        setMenuSelectedKeys([getOpenedMenuByUri(location.pathname)]);
    }, [location]);

    const linkProps = drawerWrapped ? { onClick: () => globalSettingsVar({ ...globalSettingsVar(), navCollapsed: false }) } : {};

    return (
        <Menu
            selectedKeys={menuSelectedKeys}
            openKeys={menuSelectedKeys}
            onOpenChange={(openKeys) => {
                if (!openKeys || openKeys.length === 0) {
                    setMenuSelectedKeys([]);
                } else {
                    setMenuSelectedKeys(openKeys);
                }
            }}
            theme={themeType === THEME_TYPE_LITE ? 'lite' : 'dark'}
            mode="inline"
            className="gx-mt-2 gx-mt-sm-1"
            style={{ overflowX: 'hidden' }}
        >
            <Menu.Item key={`${adminPath}/dashboard`} icon={<HomeOutlined />}>
                <Can I="read" a="Event">
                    <Link to={`${adminPath}/dashboard`} {...linkProps}>
                        <span>
                            <IntlMessages id="common.events" />
                        </span>
                    </Link>
                </Can>
            </Menu.Item>

            {menu.type === MENU_TYPE.EVENT &&
                menu.event && [
                    <Menu.ItemGroup
                        key="event_group"
                        title={
                            <span className="gx-text-uppercase">
                                <IntlMessages id={`common.event`} />
                            </span>
                        }
                    >
                        <Menu.Divider key="div_1" />,
                        <Menu.SubMenu key={`${adminPath}/event/${menu.event.id}`} title={menu.event.name} icon={<AppstoreOutlined />}>
                            <Menu.Item key={`${adminPath}/event/${menu.event.id}/dashboard`} icon={<HomeOutlined />}>
                                <Link to={`${adminPath}/event/${menu.event.id}/dashboard`} {...linkProps}>
                                    <IntlMessages id="common.dashboard" />
                                </Link>
                            </Menu.Item>
                            <Menu.ItemGroup title={<IntlMessages id="common.management" />}>
                                <Menu.Item key={`${adminPath}/event/${menu.event.id}/payments`} icon={<WalletOutlined />}>
                                    <Link to={`${adminPath}/event/${menu.event.id}/payments`} {...linkProps}>
                                        <IntlMessages id="common.payments" />
                                    </Link>
                                </Menu.Item>

                                <Menu.Item key={`${adminPath}/event/${menu.event.id}/payments-summary`} icon={<FundOutlined />}>
                                    <Link to={`${adminPath}/event/${menu.event.id}/payments-summary`} {...linkProps}>
                                        <IntlMessages id="common.payments_report" />
                                    </Link>
                                </Menu.Item>
                                <Menu.Item key={`${adminPath}/event/${menu.event.id}/discounts`} icon={<TagOutlined />}>
                                    <Link to={`${adminPath}/event/${menu.event.id}/discounts`} {...linkProps}>
                                        <IntlMessages id="common.discounts" />
                                    </Link>
                                </Menu.Item>
                            </Menu.ItemGroup>
                            <Menu.ItemGroup title={<IntlMessages id="common.configuration" />}>
                                <Menu.Item key={`${adminPath}/event/${menu.event.id}/informations`} icon={<InfoCircleOutlined />}>
                                    <Link to={`${adminPath}/event/${menu.event.id}/informations`} {...linkProps}>
                                        <IntlMessages id="common.base_data" />
                                    </Link>
                                </Menu.Item>
                                <Menu.Item key={`${adminPath}/event/${menu.event.id}/description`} icon={<AlignLeftOutlined />}>
                                    <Link to={`${adminPath}/event/${menu.event.id}/description`} {...linkProps}>
                                        <IntlMessages id="common.description" />
                                    </Link>
                                </Menu.Item>
                                <Menu.Item key={`${adminPath}/event/${menu.event.id}/gallery`} icon={<PictureOutlined />}>
                                    <Link to={`${adminPath}/event/${menu.event.id}/gallery`} {...linkProps}>
                                        <IntlMessages id="common.gallery" />
                                    </Link>
                                </Menu.Item>
                                <Menu.Item key={`${adminPath}/event/${menu.event.id}/subscriptions`} icon={<FormOutlined />}>
                                    <Link to={`${adminPath}/event/${menu.event.id}/subscriptions`} {...linkProps}>
                                        <IntlMessages id="common.subscriptions" />
                                    </Link>
                                </Menu.Item>

                                <Menu.Item key={`${adminPath}/event/${menu.event.id}/products`} icon={<TagsOutlined />}>
                                    <Link to={`${adminPath}/event/${menu.event.id}/products`} {...linkProps}>
                                        <IntlMessages id="products.products" />
                                    </Link>
                                </Menu.Item>

                            </Menu.ItemGroup>
                        </Menu.SubMenu>
                    </Menu.ItemGroup>,
                    <Menu.Divider key="div_2" />,
                    <Menu.ItemGroup
                        key="sub_event_group"
                        title={
                            <span className="gx-text-uppercase">
                                <IntlMessages id={`common.events_type_${menu.event.type}`} />
                            </span>
                        }
                    >
                        {menu.event.sub_events && menu.event.sub_events.length > 0
                            ? menu.event.sub_events.map((subEvent) => {
                                const baseSubMenu = `${adminPath}/event/${menu.event.id}/sub-event/${subEvent.id}`;

                                return (
                                    <Menu.SubMenu key={baseSubMenu} title={subEvent.name} icon={<AppstoreOutlined />}>
                                        <Menu.Item key={`${baseSubMenu}/dashboard`} icon={<HomeOutlined />}>
                                            <Link to={`${baseSubMenu}/dashboard`} {...linkProps}>
                                                <IntlMessages id="common.dashboard" />
                                            </Link>
                                        </Menu.Item>
                                        <Menu.ItemGroup title={<IntlMessages id="common.management" />}>
                                            <Menu.Item key={`${baseSubMenu}/subscribers`} icon={<TeamOutlined />}>
                                                <Link to={`${baseSubMenu}/subscribers`} {...linkProps}>
                                                    <IntlMessages id="common.players" />
                                                </Link>
                                            </Menu.Item>
                                            <Menu.Item key={`${baseSubMenu}/tracking`} icon={<FieldNumberOutlined />}>
                                                <Link to={`${baseSubMenu}/tracking`} {...linkProps}>
                                                    <IntlMessages id="common.tracking" />
                                                </Link>
                                            </Menu.Item>
                                        </Menu.ItemGroup>
                                        <Menu.ItemGroup title={<IntlMessages id="common.configuration" />}>
                                            <Menu.Item key={`${baseSubMenu}/informations`} icon={<InfoCircleOutlined />}>
                                                <Link to={`${baseSubMenu}/informations`} {...linkProps}>
                                                    <IntlMessages id="common.base_data" />
                                                </Link>
                                            </Menu.Item>

                                            <Menu.Item key={`${baseSubMenu}/description`} icon={<AlignLeftOutlined />}>
                                                <Link to={`${baseSubMenu}/description`} {...linkProps}>
                                                    <IntlMessages id="common.description" />
                                                </Link>
                                            </Menu.Item>

                                            <Menu.Item key={`${baseSubMenu}/prices`} icon={<TagsOutlined />}>
                                                <Link to={`${baseSubMenu}/prices`} {...linkProps}>
                                                    <IntlMessages id="common.prices" />
                                                </Link>
                                            </Menu.Item>
                                            <Menu.Item key={`${baseSubMenu}/attachments`} icon={<FileOutlined />}>
                                                <Link to={`${baseSubMenu}/attachments`} {...linkProps}>
                                                    <IntlMessages id="common.attachments" />
                                                </Link>
                                            </Menu.Item>
                                            <Menu.Item key={`${baseSubMenu}/subscription-module`} icon={<FormOutlined />}>
                                                <Link to={`${baseSubMenu}/subscription-module`} {...linkProps}>
                                                    <IntlMessages id="common.subscription_module" />
                                                </Link>
                                            </Menu.Item>
                                            <Menu.Item key={`${baseSubMenu}/communications`} icon={<NotificationOutlined />}>
                                                <Link to={`${baseSubMenu}/communications`} {...linkProps}>
                                                    <IntlMessages id="common.communications" />
                                                </Link>
                                            </Menu.Item>
                                        </Menu.ItemGroup>
                                    </Menu.SubMenu>
                                );
                            })
                            : null}
                        <Menu.Divider key={`add_sub_event_divider`} />,
                        <Menu.Item key={`${adminPath}/event/${menu.event.id}/sub-event/insert`} icon={<PlusOutlined />}>
                            <Link to={`${adminPath}/event/${menu.event.id}/sub-event/insert`} {...linkProps}>
                                <IntlMessages id="common.add" />
                            </Link>
                        </Menu.Item>
                    </Menu.ItemGroup>,
                ]}
        </Menu>
    );
};

export default SidebarMenu;
