import React, { useEffect } from 'react';
import { EVENT_CONTAINER_DATA } from '../../../../apollo/query/event';
import BadResponse from '../../../../components/BadResponse';
import CircularProgress from '../../../../components/CircularProgress';
import { useQuery } from '@apollo/client';
import { Breadcrumb, Col, PageHeader, Row, Space, Table } from 'antd';
import { CheckCircleOutlined, CloseCircleOutlined, EditOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { useIntl } from 'react-intl';
import IntlMessages from '../../../../util/IntlMessages';
import BreadcrumbHomeLink from '../../../../components/BreadcrumbHomeLink';
import { useActiveBreakpoint } from '../../../../hooks/useActiveBreakpoint';
import { Link } from 'react-router-dom';
import DevButton from '../../../../components/Dev/DevButton';
import { PAGINATE_PRODUCTS } from '../../../../apollo/query/product';
import { paginationOptions } from '../../../../constants/PaginationOptions';
import { usePersistedState } from '../../../../hooks/usePersistedState';
import { getAdminPath } from '../../../../util/router';
import { formatDate } from '../../../../util/date';
import { SUCCESS_COLOR } from '../../../../constants/ThemeSetting';

const usePaginatedProductsQuery = (eventId) => {

    const [paginationState, setPaginationState] = usePersistedState(`event.${eventId}.productsPagination`, { current: 1, pageSize: 10 });

    const { data, loading, error, previousData } = useQuery(PAGINATE_PRODUCTS, {
        variables: {
            filter: {
                event_id: eventId,
            },
            pagination: {
                page: paginationState.current,
                limit: paginationState.pageSize,
            },
        },
    });

    //reuse previous data while loading
    let tableData = previousData
        ? previousData.products
        : {
              items: [],
              pagination: {},
          };

    useEffect(() => {
        if (data && data.products) {
            const totalPages = data.products.pagination?.pages;
            const currentPage = data.products.pagination?.page;
            if (currentPage > totalPages) {
                setPaginationState({ ...paginationState, current: 1 });
            }
        }
    }, [data]);

    if (!loading && data) {
        tableData = data.products;
    }

    return {
        tableData,
        loading,
        error,
        paginationState,
        setPaginationState,
    };
};

const getColumns = ({ event }) => {
    return [
        {
            title: <IntlMessages id="common.active" />,
            dataIndex: 'active',
            key: 'active',
            align: 'center',
            width: 100,
            render: (active) => {
                return active ? <CheckCircleOutlined style={{ color: SUCCESS_COLOR }} /> : <CloseCircleOutlined />;
            },
        },
        {
            title: <IntlMessages id="common.name" />,
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: <IntlMessages id="products.variants" />,
            dataIndex: 'variants',
            key: 'variants',
            width: 100,
            align: 'right',
            render: (variants) => {
                return variants.length
            },
        },
        {
            title: <IntlMessages id="common.createdAt" />,
            dataIndex: 'created_at',
            width: 120,
            key: 'created_at',
            render(date) {
                return formatDate(date);
            },
        },
        {
            title: '',
            dataIndex: 'id',
            align: 'center',
            width: 140,
            render(id) {
                return [
                    <Link key="update-product" to={getAdminPath(`event/${event.id}/products/${id}/update`)} className="ant-btn ant-btn-primary gx-mb-0">
                        <EditOutlined />
                    </Link>,
                ];
            },
        },
    ];
};

const EventProductsTable = ({ event, intl }) => {
    const { tableData, loading, error, setPaginationState } = usePaginatedProductsQuery(event.id);

    if (error) {
        return <BadResponse title={intl.formatMessage({ id: '500.ooops' })} subtitle={intl.formatMessage({ id: '500.something_wrong' })} />;
    }

    return (
        <Table
            rowKey="id"
            loading={loading}
            dataSource={tableData.items}
            columns={getColumns({ event })}
            pagination={{
                ...paginationOptions,
                total: tableData.pagination.total_items,
                current: tableData.pagination.page,
                pageSize: tableData.pagination.limit,
                onChange: (current, pageSize) => {
                    setPaginationState({ current, pageSize });
                },
            }}
        />
    );
};

const EventProducts = ({ match }) => {
    const { params } = match;

    const intl = useIntl();

    const activeBreakpoint = useActiveBreakpoint();

    const { data, loading, error } = useQuery(EVENT_CONTAINER_DATA, {
        variables: {
            id: params.id,
        },
    });

    let event = {};

    if (error) {
        return <BadResponse title={intl.formatMessage({ id: '500.ooops' })} subtitle={intl.formatMessage({ id: '500.something_wrong' })} />;
    }

    if (loading) {
        return <CircularProgress />;
    }

    event = data.event;

    return (
        <>
            <Row>
                <Col md={24}>
                    <Breadcrumb className="gx-pb-3">
                        <Breadcrumb.Item>
                            <BreadcrumbHomeLink />
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>{event.name}</Breadcrumb.Item>
                        <Breadcrumb.Item>
                            <IntlMessages id="products.products" />
                        </Breadcrumb.Item>
                    </Breadcrumb>
                </Col>
            </Row>

            <Row>
                <Col xs={24}>
                    <PageHeader
                        title={
                            <span>
                                <IntlMessages id="products.products" />
                                &nbsp;{event.name}
                            </span>
                        }
                        extra={
                            <Space>
                                <Link to={`/admin/event/${event.id}/products/create`} className="ant-btn ant-btn-primary">
                                    <PlusCircleOutlined className="gx-mr-2" />
                                    <IntlMessages id="common.add" />
                                </Link>
                            </Space>
                        }
                    />
                </Col>
            </Row>
            <EventProductsTable activeBreakpoint={activeBreakpoint} event={data?.event} intl={intl} />
            <DevButton path={__filename} />
        </>
    );
};

export default EventProducts;
