export const AssetContextType = {
    EVENT_ATTACHMENT: 'EVENT_ATTACHMENT',
    EVENT_IMAGE_BIG: 'EVENT_IMAGE_BIG',
    EVENT_IMAGE_SQUARE: 'EVENT_IMAGE_SQUARE',
    EVENT_DESCRIPTION_IMAGE: 'EVENT_DESCRIPTION_IMAGE',
};

export const imageTypeProps = {
    [AssetContextType.EVENT_IMAGE_BIG]: {
        quantity: 1,
        aspect: 2,
        placeholder: require('../assets/images/event-image-big.jpg').default,
        style: {},
        recommendedSize: [1200, 600],
    },
    [AssetContextType.EVENT_IMAGE_SQUARE]: {
        quantity: 0.8,
        aspect: 1,
        placeholder: require('../assets/images/event-image-square.jpg').default,
        style: { maxWidth: 300, margin: '0 auto' },
        recommendedSize: [250, 250],
    },
};
