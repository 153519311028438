import React from 'react';
import { Link } from 'react-router-dom';
import { NAV_STYLE_DRAWER, NAV_STYLE_FIXED, NAV_STYLE_MINI_SIDEBAR, TAB_SIZE, THEME_TYPE_LITE } from '../../constants/ThemeSetting';

import { layoutSettingsVar, globalSettingsVar } from '../../apollo/local/cache';
import { useReactiveVar } from '@apollo/client';
import { getAdminPath } from '../../util/router';
import { Button } from 'antd';
import { CloseOutlined } from '@ant-design/icons';

const SidebarLogo = ({ sidebarCollapsed, setSidebarCollapsed, drawerWrapped }) => {
    let { width } = useReactiveVar(globalSettingsVar);
    let { navStyle, themeType } = useReactiveVar(layoutSettingsVar);

    if (width < TAB_SIZE && navStyle === NAV_STYLE_FIXED) {
        navStyle = NAV_STYLE_DRAWER;
    }

    const logoStyle = {
        userSelect: 'none',
        WebkitUserSelect: 'none',
        MozUserSelect: 'none',
        OUserSelect: 'none',
        khtmlUserSelect: 'none',
    };

    return (
        <div className={`gx-layout-sider-header ${drawerWrapped ? 'gx-justify-content-between' : ''}`}>
            {navStyle === NAV_STYLE_FIXED || navStyle === NAV_STYLE_MINI_SIDEBAR ? (
                <div className="gx-linebar">
                    <i
                        className={`gx-icon-btn icon icon-${!sidebarCollapsed ? 'menu-unfold' : 'menu-fold'} ${
                            themeType !== THEME_TYPE_LITE ? 'gx-text-white' : ''
                        }`}
                        onClick={(e) => {
                            e.stopPropagation();
                            setSidebarCollapsed(!sidebarCollapsed);
                        }}
                    />
                </div>
            ) : null}

            <Link to={getAdminPath('dashboard')} className="gx-site-logo gx-sidebar-logo">
                <img alt="Keepsporting" src="/assets/images/keepsporting_logo.png" style={logoStyle} />
            </Link>

            {drawerWrapped ? (
                <Button
                    style={{
                        marginTop: 15,
                        fontSize: 20,
                        marginTight: 0,
                    }}
                    className="gx-float-right gx-d-inline-block"
                    type="link"
                    onClick={() => {
                        globalSettingsVar({ ...globalSettingsVar(), navCollapsed: false });
                    }}
                >
                    <CloseOutlined />
                </Button>
            ) : null}
        </div>
    );
};

export default SidebarLogo;
