import { useMutation, useQuery } from '@apollo/client';
import React from 'react';
import { useIntl } from 'react-intl';
import BadResponse from '@/components/BadResponse';
import CircularProgress from '@/components/CircularProgress';
import { Row, Col, Form, Button, PageHeader, Card, Input, InputNumber, Select, Alert, DatePicker, Divider, Checkbox } from 'antd';
import IntlMessages from '@/util/IntlMessages';
import { useHistory } from 'react-router-dom';
import { getAdminPath, pushNextOrFallback } from '@/util/router';
import { UPDATE_SUB_EVENT } from '@/apollo/mutation/event';
import BadResponseErrorAlert from '@/components/BadResponseErrorAlert';
import { defaultCatchException, defaultMutationCallback } from '@/apollo/callbacks';
import { DATETIME_FORMAT, MEDICAL_CERTIFICATE } from '@/constants/App';
import { SUB_EVENT_CONTAINER_DATA } from '@/apollo/query/sub-event';
import moment from 'moment';
import SubEventBreadcrumbs from '../breadcrumbs';

const UpdateSubEventForm = ({ subEvent, intl }) => {
    const history = useHistory();

    const [updateSubEvent, { loading }] = useMutation(UPDATE_SUB_EVENT);

    const handleOk = (values) => {
        updateSubEvent({
            variables: {
                id: subEvent.id,
                subEvent: values,
            },
        })
            .then((response) => defaultMutationCallback(response, () => closeHandler(response.data, values)))
            .catch((e) => defaultCatchException(e, intl));
    };

    const handleError = (error) => {
        console.log('Error', error);
    };

    const closeHandler = ({ updateSubEvent } = {}, values) => {
        if (updateSubEvent) {
            pushNextOrFallback(history, getAdminPath(`event/${subEvent.event.id}/sub-event/${updateSubEvent}/dashboard`));
        } else {
            history.push(getAdminPath(`event/${subEvent.event.id}/dashboard`));
        }
    };

    return (
        <>
            <Row>
                <Col md={24}>
                    <SubEventBreadcrumbs subEvent={subEvent} section={<IntlMessages id={`common.update_type_${subEvent.event.type}`} />} />
                </Col>
            </Row>
            <Form
                onFinish={handleOk}
                onFinishFailed={handleError}
                layout="vertical"
                labelCol={{ span: 20 }}
                initialValues={{
                    name: subEvent.name,
                    has_teams: subEvent.has_teams,
                    team_players: subEvent.team_players,
                    hide_starting_list: subEvent.hide_starting_list,
                    manual_confirmation: subEvent.manual_confirmation,
                    medical_certificate_type: subEvent.certificate.type,
                    subscription_start: moment(subEvent.timetables.subscription_start),
                    subscription_end: moment(subEvent.timetables.subscription_end),
                    event_start: moment(subEvent.timetables.event_start),
                    event_end: moment(subEvent.timetables.event_end),
                    subscriptions_limit: subEvent.subscriptions_limit,
                    subscriptions_min_age: subEvent.subscriptions_min_age,
                    subscriptions_max_age: subEvent.subscriptions_max_age,
                    hide_event_date: subEvent.timetables?.hide_event_date || false,
                }}
                autoComplete="off"
            >
                <Row>
                    <Col xs={24}>
                        <PageHeader
                            title={<IntlMessages id={`common.update_type_${subEvent.event.type}`} />}
                            onBack={() => history.goBack()}
                            extra={[
                                <Button key="1" onClick={() => closeHandler()}>
                                    <IntlMessages id="common.cancel" />
                                </Button>,
                                <Button key="2" htmlType="submit" type="primary" loading={loading}>
                                    {intl.formatMessage({ id: 'common.update' }).toUpperCase()}
                                </Button>,
                            ]}
                        />
                    </Col>
                    <Col xs={24}>
                        <BadResponseErrorAlert />
                    </Col>
                    <Col xs={24} md={12}>
                        <Card
                            className="gx-card"
                            title={intl.formatMessage({
                                id: 'common.general_data',
                            })}
                        >
                            <Row>
                                <Col xs={20}>
                                    <Form.Item
                                        label={intl.formatMessage({
                                            id: 'common.sub_event_name',
                                        })}
                                        name="name"
                                        rules={[
                                            {
                                                required: true,
                                                message: intl.formatMessage({
                                                    id: 'common.field_required',
                                                }),
                                            },
                                            {
                                                min: 5,
                                                message: intl.formatMessage(
                                                    {
                                                        id: 'common.validation_min_chars',
                                                    },
                                                    {
                                                        min: 5,
                                                    }
                                                ),
                                            },
                                            {
                                                max: 125,
                                                message: intl.formatMessage(
                                                    {
                                                        id: 'common.validation_max_chars',
                                                    },
                                                    {
                                                        max: 125,
                                                    }
                                                ),
                                            },
                                        ]}
                                    >
                                        <Input showCount />
                                    </Form.Item>
                                </Col>

                                <Col xs={20}>
                                    <Form.Item
                                        label={intl.formatMessage({
                                            id: 'common.has_teams',
                                        })}
                                        name="has_teams"
                                        rules={[
                                            {
                                                required: false,
                                                message: intl.formatMessage({
                                                    id: 'common.field_required',
                                                }),
                                            },
                                        ]}
                                    >
                                        <Select disabled={true}>
                                            <Select.Option value={true}>
                                                <IntlMessages id="common.yes" />
                                            </Select.Option>
                                            <Select.Option value={false}>
                                                <IntlMessages id="common.no" />
                                            </Select.Option>
                                        </Select>
                                    </Form.Item>
                                </Col>

                                <Form.Item noStyle shouldUpdate={(prevValues, currentValues) => prevValues.has_teams !== currentValues.has_teams}>
                                    {({ getFieldValue }) =>
                                        getFieldValue('has_teams') === true ? (
                                            <Col xs={20}>
                                                <Form.Item
                                                    label={intl.formatMessage({
                                                        id: 'common.team_components_number',
                                                    })}
                                                    name="team_players"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: intl.formatMessage({
                                                                id: 'common.field_required',
                                                            }),
                                                        },
                                                    ]}
                                                >
                                                    <InputNumber disabled={true} style={{ width: '100%' }} min={2} max={10} />
                                                </Form.Item>
                                            </Col>
                                        ) : null
                                    }
                                </Form.Item>

                                <Alert
                                    showIcon={true}
                                    type="warning"
                                    message={<IntlMessages id="common.create_team_help" />}
                                    className="gx-mt-3 gx-ml-3 gx-text-danger"
                                />
                            </Row>
                        </Card>
                        <Card className="gx-card" title={<IntlMessages id="common.medical_certificate" />}>
                            <Row>
                                <Col xs={20}>
                                    <Form.Item name="medical_certificate_type" label={<IntlMessages id="common.type" />}>
                                        <Select>
                                            <Select.Option value={MEDICAL_CERTIFICATE.TYPE.NONE}>
                                                <IntlMessages id="common.none" />
                                            </Select.Option>
                                            <Select.Option value={MEDICAL_CERTIFICATE.TYPE.HEALTH}>
                                                <IntlMessages id="common.certificate_health" />
                                            </Select.Option>
                                            <Select.Option value={MEDICAL_CERTIFICATE.TYPE.COMPETITIVE}>
                                                <IntlMessages id="common.certificate_competitive" />
                                            </Select.Option>
                                        </Select>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                    <Col xs={24} md={12}>
                        <Card className="gx-card" title={<IntlMessages id="common.subscriptions" />}>
                            <Row>
                                <Col xs={20}>
                                    <Form.Item
                                        label={intl.formatMessage({
                                            id: 'common.subscriptions_limit',
                                        })}
                                        tooltip={intl.formatMessage({
                                            id: 'common.subscriptions_limit_tooltip_text',
                                        })}
                                        name="subscriptions_limit"
                                        rules={[
                                            {
                                                required: false,
                                                message: intl.formatMessage({
                                                    id: 'common.field_required',
                                                }),
                                            },
                                        ]}
                                    >
                                        <InputNumber style={{ width: '100%' }} />
                                    </Form.Item>
                                </Col>
                                <Col xs={20}>
                                    <Form.Item
                                        label={intl.formatMessage({
                                            id: 'common.hide_players_from_starting_list',
                                        })}
                                        name="hide_starting_list"
                                        rules={[
                                            {
                                                required: false,
                                                message: intl.formatMessage({
                                                    id: 'common.field_required',
                                                }),
                                            },
                                        ]}
                                    >
                                        <Select>
                                            <Select.Option value={true}>
                                                <IntlMessages id="common.yes" />
                                            </Select.Option>
                                            <Select.Option value={false}>
                                                <IntlMessages id="common.no" />
                                            </Select.Option>
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col xs={20}>
                                    <Form.Item
                                        label={intl.formatMessage({
                                            id: 'common.manual_confirmation',
                                        })}
                                        name="manual_confirmation"
                                        tooltip={intl.formatMessage({
                                            id: 'common.manual_confirmation_tooltip_text',
                                        })}
                                        rules={[
                                            {
                                                required: false,
                                                message: intl.formatMessage({
                                                    id: 'common.field_required',
                                                }),
                                            },
                                        ]}
                                    >
                                        <Select>
                                            <Select.Option value={true}>
                                                <IntlMessages id="common.yes" />
                                            </Select.Option>
                                            <Select.Option value={false}>
                                                <IntlMessages id="common.no" />
                                            </Select.Option>
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col xs={20}>
                                    <Form.Item
                                        label={intl.formatMessage({
                                            id: 'common.min_age',
                                        })}
                                        name="subscriptions_min_age"
                                    >
                                        <InputNumber style={{ width: '100%' }} />
                                    </Form.Item>
                                </Col>

                                <Col xs={20}>
                                    <Form.Item
                                        label={intl.formatMessage({
                                            id: 'common.max_age',
                                        })}
                                        name="subscriptions_max_age"
                                    >
                                        <InputNumber style={{ width: '100%' }} />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Card>
                        <Card className="gx-card" title={<IntlMessages id="common.timetables" />}>
                            <Row>
                                <Col xs={20} lg={12}>
                                    <Form.Item
                                        name="subscription_start"
                                        label={intl.formatMessage({
                                            id: 'common.race_start_subscription',
                                        })}
                                        rules={[
                                            {
                                                required: true,
                                                message: intl.formatMessage({
                                                    id: 'common.field_required',
                                                }),
                                            },
                                            ({ getFieldValue }) => ({
                                                validator(_, value) {
                                                    const subscriptionEnd = getFieldValue('subscription_end');
                                                    const eventStart = getFieldValue('event_start');

                                                    if (!subscriptionEnd || !eventStart) {
                                                        return Promise.resolve();
                                                    }

                                                    if (value.isAfter(subscriptionEnd)) {
                                                        return Promise.reject(
                                                            intl.formatMessage({
                                                                id: 'common.subscription_end_must_be_after_subscription_start',
                                                            })
                                                        );
                                                    }

                                                    return Promise.resolve();
                                                },
                                            }),
                                        ]}
                                    >
                                        <DatePicker allowClear={false} showTime={true} format={DATETIME_FORMAT} />
                                    </Form.Item>
                                </Col>
                                <Col xs={20} lg={12}>
                                    <Form.Item
                                        name="subscription_end"
                                        label={intl.formatMessage({
                                            id: 'common.race_end_subscription',
                                        })}
                                        rules={[
                                            {
                                                required: true,
                                                message: intl.formatMessage({
                                                    id: 'common.field_required',
                                                }),
                                            },
                                            ({ getFieldValue }) => ({
                                                validator(_, value) {
                                                    const eventStart = getFieldValue('event_start');

                                                    if (eventStart && value.isAfter(eventStart)) {
                                                        return Promise.reject(
                                                            intl.formatMessage({
                                                                id: `common.date_start_must_be_after_subscription_end_type_${subEvent.event.type}`,
                                                            })
                                                        );
                                                    }

                                                    return Promise.resolve();
                                                },
                                            }),
                                        ]}
                                    >
                                        <DatePicker allowClear={false} showTime={true} format={DATETIME_FORMAT} />
                                    </Form.Item>
                                </Col>
                                <Divider />
                                <Col xs={20} lg={12}>
                                    <Form.Item
                                        name="event_start"
                                        label={intl.formatMessage({
                                            id: `common.start_datetime_type_${subEvent.event.type}`,
                                        })}
                                        rules={[
                                            {
                                                required: true,
                                                message: intl.formatMessage({
                                                    id: 'common.field_required',
                                                }),
                                            },
                                            ({ getFieldValue }) => ({
                                                validator(_, value) {
                                                    const eventEnd = getFieldValue('event_end');

                                                    if (eventEnd && value.isAfter(eventEnd)) {
                                                        return Promise.reject(
                                                            intl.formatMessage({
                                                                id: 'common.race_end_must_be_after_race_start',
                                                            })
                                                        );
                                                    }

                                                    return Promise.resolve();
                                                },
                                            }),
                                        ]}
                                    >
                                        <DatePicker allowClear={false} showTime={true} format={DATETIME_FORMAT} />
                                    </Form.Item>
                                </Col>
                                <Col xs={20} lg={12}>
                                    <Form.Item
                                        name="event_end"
                                        label={intl.formatMessage({
                                            id: `common.end_datetime_type_${subEvent.event.type}`,
                                        })}
                                        rules={[
                                            {
                                                required: true,
                                                message: intl.formatMessage({
                                                    id: 'common.field_required',
                                                }),
                                            },
                                        ]}
                                    >
                                        <DatePicker allowClear={false} showTime={true} format={DATETIME_FORMAT} />
                                    </Form.Item>
                                </Col>

                                <Divider />
                                <Col xs={20}>
                                    <Form.Item
                                        label={intl.formatMessage({
                                            id: 'events.hide_frontend_date',
                                        })}
                                        name="hide_event_date"
                                        valuePropName="checked"
                                    >
                                        <Checkbox />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                </Row>
            </Form>
        </>
    );
};

const UpdateSubEvent = ({ match }) => {
    const intl = useIntl();

    const { data, loading, error } = useQuery(SUB_EVENT_CONTAINER_DATA, { variables: { id: match.params.id } });

    if (loading) {
        return <CircularProgress />;
    }

    if (error) {
        return <BadResponse title={intl.formatMessage({ id: '500.ooops' })} subtitle={intl.formatMessage({ id: '500.something_wrong' })} />;
    }

    return <UpdateSubEventForm subEvent={data.subEvent} intl={intl} />;
};

export default UpdateSubEvent;
