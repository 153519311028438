import React, { useEffect } from 'react';
import {
    CheckCircleOutlined,
    CloseCircleOutlined,
    EditOutlined,
    EyeOutlined,
    FileExcelOutlined,
    MailOutlined,
    RocketFilled,
    SaveOutlined,
} from '@ant-design/icons';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { PageHeader, Space, Form, Row, Col, Card, Table, Input, Button, Collapse, Badge, Tooltip, message, Tabs, Select, InputNumber } from 'antd';
import { useIntl } from 'react-intl';
import BadResponse from '../../../../components/BadResponse';
import { paginationOptions } from '../../../../constants/PaginationOptions';
import { usePersistedState } from '../../../../hooks/usePersistedState';
import { formatDateTime } from '../../../../util/date';
import IntlMessages from '../../../../util/IntlMessages';
import { useDebounce } from 'use-debounce';
import { debounceSearchFilterDelay } from '../../../../util/config';
import CircularProgress from '../../../../components/CircularProgress';
import { SUB_EVENT_PLAYERS, EXPORT_SUB_EVENT_SUBSCRIPTIONS, SUB_EVENT_TRACKING_DATA } from '../../../../apollo/query/sub-event';
import { Link, Redirect, useHistory } from 'react-router-dom';
import { getAdminPath } from '../../../../util/router';
import DevButton from '../../../../components/Dev/DevButton';
import SubEventBreadcrumbs from '../breadcrumbs';
import { countActiveFilters } from '../../../../util/object';
import { PRIMARY_COLOR } from '../../../../constants/ThemeSetting';
import { MEDICAL_CERTIFICATE, TRACKING_MODE } from '../../../../constants/App';
import DropDownCheckboxFilter from '../../../../components/DropdownCheckboxFilter';
import { UPDATE_SUB_EVENT } from '../../../../apollo/mutation/event';
import { values } from 'lodash';
import { defaultCatchException, defaultMutationHandler } from '../../../../apollo/callbacks';
import { getDefaultSortOrder } from '../../../../util/antd';
import { Can } from '../../../../acl';
import { ASSIGN_PLAYERS_TRACKING_NUMBERS } from '../../../../apollo/mutation/sub-event';
import { useActiveBreakpoint } from '../../../../hooks/useActiveBreakpoint';
import { includes } from 'lodash';
import { downloadBase64File } from '../../../../util/file';

const getColumns = ({ subEvent, currentURL }, { sortingState }) => {
    const columns = [
        {
            title: <IntlMessages id="common.name" />,
            dataIndex: ['first_name'],
            width: 225,
            fixed: 'left',
            render(value, row) {
                return row.retired || row.deleted_at ? (
                    <span className="gx-text-strikethrough">
                        {value} {row.last_name}
                    </span>
                ) : (
                    <>
                        {value} {row.last_name}
                    </>
                );
            },
        },
        {
            title: <IntlMessages id="common.bib_number" />,
            dataIndex: ['tracking', 'number'],
            align: 'center',
            width: 150,
            sorter: true,
            defaultSortOrder: getDefaultSortOrder('traking.number', sortingState),
            sortDirections: ['descend', 'ascend', 'descend'],
            render(value, row) {
                return row.retired || row.deleted_at ? <span className="gx-text-strikethrough">{value}</span> : <span>{value}</span>;
            },
        },
        {
            title: <IntlMessages id="common.confirm" />,
            align: 'center',
            dataIndex: ['confirmed'],
            width: 100,
            render(value, row) {
                return value ? (
                    <Space>
                        <Tooltip title={<IntlMessages id="common.player_confirmed" />}>
                            <CheckCircleOutlined className="gx-text-success" />
                        </Tooltip>
                        {row.confirm_email ? (
                            <Tooltip title={<IntlMessages id="common.confirmation_email_sent" />}>
                                <MailOutlined />
                            </Tooltip>
                        ) : null}
                    </Space>
                ) : (
                    <Tooltip title={<IntlMessages id="common.player_not_confirmed" />}>
                        <CloseCircleOutlined className="gx-text-danger" />
                    </Tooltip>
                );
            },
        },
        {
            title: <IntlMessages id="common.createdAt" />,
            dataIndex: 'created_at',
            width: 200,
            align: 'center',
            render(value) {
                return formatDateTime(value);
            },
        },
        {
            title: '',
            dataIndex: 'id',
            align: 'center',
            width: 200,
            responsive: ['sm'],
            render(id) {
                return [
                    <Link
                        key="view-athlete"
                        to={getAdminPath(`event/${subEvent.event.id}/sub-event/${subEvent.id}/subscriber/view/${id}?next=${encodeURIComponent(currentURL)}`)}
                        className="ant-btn gx-mb-0"
                    >
                        <EyeOutlined />
                    </Link>,
                    <Link
                        key="update-athlete"
                        to={getAdminPath(`event/${subEvent.event.id}/sub-event/${subEvent.id}/subscriber/update/${id}?next=${encodeURIComponent(currentURL)}`)}
                        className="ant-btn ant-btn-primary gx-mb-0"
                    >
                        <EditOutlined />
                    </Link>,
                ];
            },
        },
    ];

    return columns;
};

const getFilterButtonsSpaceProps = (activeBreakpoint) => {
    if (includes(['XS'], activeBreakpoint)) {
        return {
            direction: 'vertical',
            size: 'small',
            className: 'gx-mt-2',
        };
    } else {
        return {
            direction: 'horizontal',
            size: 'large',
        };
    }
};

const EventPlayersTrackingTable = ({ subEvent, currentURL, intl }) => {
    const DEFAULT_FILTERS = { term: '', confirmed: [], retired: [] };
    const DEFAULT_PAGINATION = { current: 1, pageSize: 10 };

    const activeBreakpoint = useActiveBreakpoint();
    const history = useHistory();

    const { event } = subEvent;

    const [filters, setFilters] = usePersistedState(`subEvent.${subEvent.id}.playersTrackingFilters`, DEFAULT_FILTERS);
    const [paginationState, setPaginationState] = usePersistedState(`subEvent.${subEvent.id}.playersTrackingPagination`, DEFAULT_PAGINATION);
    const [sortingState, setSortingState] = usePersistedState(`subEvent.${subEvent.id}.playersTrackingSorting`, { key: 'tracking.number', direction: -1 });
    //const [selectedRowKeys, setSelectedRowKeys] = useState([]);

    const updateFilters = (key, value) => {
        if (paginationState.current !== 1) {
            setPaginationState({ ...paginationState, current: 1 });
        }
        setFilters({ ...filters, [key]: value });
    };

    const [debouncedTerm] = useDebounce(filters?.term, debounceSearchFilterDelay);

    const { data, loading, error, previousData, refetch } = useQuery(SUB_EVENT_PLAYERS, {
        variables: {
            filter: {
                event_id: event.id,
                sub_event_id: subEvent.id,
                ...filters,
                term: debouncedTerm,
            },
            pagination: {
                page: paginationState.current,
                limit: paginationState.pageSize,
            },
            sorting: sortingState,
        },
        onError: (error) => {
            setFilters(DEFAULT_FILTERS);
        },
    });

    useEffect(() => {
        if (data && data.eventPlayers) {
            const totalPages = data.eventPlayers.pagination?.pages;
            const currentPage = data.eventPlayers.pagination?.page;
            if (currentPage > totalPages) {
                setPaginationState({ ...paginationState, current: 1 });
            }
        }
    }, [data]);

    if (error) {
        return <BadResponse title={intl.formatMessage({ id: '500.ooops' })} subtitle={intl.formatMessage({ id: '500.something_wrong' })} />;
    }

    //reuse previous data while loading
    let tableData = previousData
        ? previousData.eventPlayers
        : {
              items: [],
              pagination: {},
          };

    if (!loading && data) {
        tableData = data.eventPlayers;
    }

    return (
        <Row>
            <Col xs={24}>
                <Collapse className="gx-panel gx-mb-3" defaultActiveKey={countActiveFilters(filters) > 0 ? ['1'] : null}>
                    <Collapse.Panel
                        header={
                            <Space className="gx-w-100 gx-justify-content-between">
                                <div className="">
                                    <IntlMessages id="common.filters" />
                                    <Badge className="gx-ml-2" count={countActiveFilters(filters, 0)} style={{ backgroundColor: PRIMARY_COLOR }} />
                                </div>
                            </Space>
                        }
                        size="small"
                        className="gx-panel"
                        key="1"
                        bordered={false}
                        extra={[
                            <Button
                                size="small"
                                key="reset-btn"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    setFilters(DEFAULT_FILTERS);
                                    setPaginationState(DEFAULT_PAGINATION);
                                }}
                                className="gx-mb-0"
                            >
                                <CloseCircleOutlined className="gx-pr-1" />
                                <IntlMessages id="common.clear_filters" />
                            </Button>,
                        ]}
                    >
                        <Row>
                            <Col xs={24} md={24} lg={10} xl={8}>
                                <Input.Search
                                    style={{ minWidth: 300 }}
                                    value={filters?.term}
                                    onChange={(e) => {
                                        const { value } = e.target;
                                        updateFilters('term', value);
                                    }}
                                    placeholder={`${intl.formatMessage({ id: 'common.name' })}, ${intl.formatMessage({ id: 'common.email' })}`}
                                />
                            </Col>
                            <Col xs={24} md={24} lg={12} style={{ maxWidth: '100%' }}>
                                <Space {...getFilterButtonsSpaceProps(activeBreakpoint)}>
                                    <DropDownCheckboxFilter
                                        label={<IntlMessages id="common.confirm_subscription" />}
                                        options={[
                                            { label: <IntlMessages id="common.player_confirmed" />, value: true },
                                            { label: <IntlMessages id="common.player_not_confirmed" />, value: false },
                                        ]}
                                        values={filters?.confirmed}
                                        onChange={(values) => {
                                            updateFilters('confirmed', values);
                                        }}
                                    />

                                    {subEvent.certificate.type !== MEDICAL_CERTIFICATE.TYPE.NONE && (
                                        <DropDownCheckboxFilter
                                            label={<IntlMessages id="common.medical_certificate" />}
                                            options={[
                                                { label: <IntlMessages id="common.confirmed" />, value: true },
                                                { label: <IntlMessages id="common.not_confirmed" />, value: false },
                                            ]}
                                            values={filters?.certificate_confirmed}
                                            onChange={(values) => {
                                                updateFilters('certificate_confirmed', values);
                                            }}
                                        />
                                    )}

                                    {subEvent.predefined_fields?.athlete_card && subEvent.predefined_fields?.athlete_card.active && (
                                        <DropDownCheckboxFilter
                                            label={<IntlMessages id="common.athlete_card" />}
                                            options={[
                                                { label: <IntlMessages id={`common.fidal_validation_state_1`} />, value: 1 },
                                                { label: <IntlMessages id={`common.fidal_validation_state_2`} />, value: 2 },
                                                { label: <IntlMessages id={`common.fidal_validation_state_3`} />, value: 3 },
                                            ]}
                                            values={filters?.athlete_card_confirmed}
                                            onChange={(values) => {
                                                updateFilters('athlete_card_confirmed', values);
                                            }}
                                        />
                                    )}

                                    <DropDownCheckboxFilter
                                        label={<IntlMessages id="common.retired" />}
                                        options={[
                                            { label: <IntlMessages id="common.yes" />, value: true },
                                            //{ label: <IntlMessages id="common.no" />, value: false },
                                        ]}
                                        values={filters?.retired}
                                        onChange={(values) => {
                                            updateFilters('retired', values);
                                        }}
                                    />
                                </Space>
                            </Col>
                        </Row>
                    </Collapse.Panel>
                </Collapse>
            </Col>

            <Col xs={24}>
                <Card className="gx-card">
                    <Table
                        rowKey="id"
                        scroll={{ x: 600 }}
                        loading={loading}
                        dataSource={tableData.items}
                        columns={getColumns({ subEvent, currentURL }, { sortingState })}
                        onChange={(pagination, filters, sorter, { action }) => {
                            if (action === 'sort') {
                                setSortingState({ key: sorter.field?.join('.'), direction: sorter.order === 'ascend' ? 1 : -1 });
                            }
                        }}
                        onRow={(row) => {
                            return {
                                onClick: (e) => {
                                    if (includes(['XS'], activeBreakpoint)) {
                                        const uri = getAdminPath(
                                            `event/${subEvent.event.id}/sub-event/${subEvent.id}/subscriber/view/${row.id}?next=${encodeURIComponent(
                                                currentURL
                                            )}`
                                        );
                                        return history.push(uri);
                                    }
                                },
                            };
                        }}
                        pagination={{
                            ...paginationOptions,
                            total: tableData.pagination.total_items,
                            current: tableData.pagination.page,
                            pageSize: tableData.pagination.limit,
                            onChange: (current, pageSize) => {
                                setPaginationState({ current, pageSize });
                            },
                        }}
                    />
                </Card>
            </Col>
        </Row>
    );
};

/**
 * TODO sostituire con starting list
 * @param {*} param0
 * @returns
 */
const ExportSubscriptionsButton = ({ subEvent }) => {
    const intl = useIntl();

    const [exportSubscriptions, { loading }] = useLazyQuery(EXPORT_SUB_EVENT_SUBSCRIPTIONS, {
        fetchPolicy: 'no-cache',
    });

    return (
        <Button
            loading={loading}
            onClick={() => {
                exportSubscriptions({
                    variables: {
                        id: subEvent.id,
                    },
                })
                    .then(({ data }) => {
                        if (data?.exportSubEventSubscriptions) {
                            const { filename, content } = data.exportSubEventSubscriptions;
                            downloadBase64File(filename, content);
                        } else {
                            message.error(intl.formatMessage({ id: 'common.default_mutation_error' }));
                        }
                    })
                    .catch((e) => defaultCatchException(e, intl));
            }}
        >
            <FileExcelOutlined /> <IntlMessages id="common.export_xls" />
        </Button>
    );
};

const EventPlayersTrackingSettings = ({ subEvent, refetch }) => {
    const intl = useIntl();

    const [assignPlayersTrackingNumbers, { loading: loadingAssignNumbers }] = useMutation(ASSIGN_PLAYERS_TRACKING_NUMBERS);

    const [updateSubEvent, { loading, error }] = useMutation(UPDATE_SUB_EVENT, {
        onError: (error) => {
            message.error(error.message);
        },
    });

    const [form] = Form.useForm();

    const handleSave = (values) => {
        updateSubEvent({
            variables: {
                id: subEvent.id,
                subEvent: {
                    tracking_mode: values.mode,
                    tracking_starting_number: values.starting_number || null,
                },
            },
        })
            .then(
                defaultMutationHandler({
                    successMessage: intl.formatMessage({ id: 'common.edit_successful' }),
                    errorMessage: intl.formatMessage({ id: 'common.default_mutation_error' }),
                })
            )
            .catch((e) => defaultCatchException(e, intl));
    };

    const onAssignPlayersTrackingNumbers = (subEventId) => {
        assignPlayersTrackingNumbers({
            variables: {
                sub_event_id: subEventId,
            },
        })
            .then(
                defaultMutationHandler({
                    errorMessage: intl.formatMessage({ id: 'common.default_mutation_error' }),
                    successCallback: (data) => {
                        message.success(`Assegnati ${data.assignPlayersTrackingNumbers} pettorali`);
                        refetch();
                    },
                })
            )
            .catch((e) => defaultCatchException(e, intl));
    };

    return (
        <Row>
            <Col xs={24} md={12}>
                <Form form={form} onFinish={handleSave} initialValues={subEvent.tracking} layout="vertical">
                    <Card
                        title={<IntlMessages id="common.tracking_configuration" />}
                        className="gx-card"
                        extra={
                            <Button size="small" loading={loading} type="primary" htmlType="submit" icon={<SaveOutlined className="gx-pr-1" />}>
                                <IntlMessages id="common.save" />
                            </Button>
                        }
                    >
                        <Form.Item name="mode" label={<IntlMessages id="common.tracking_mode" />}>
                            <Select>
                                {values(TRACKING_MODE).map((key) => (
                                    <Select.Option key={key} value={key}>
                                        <IntlMessages id={`tracking.${key.toLowerCase()}`} />
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>

                        <Form.Item noStyle shouldUpdate={(prevValues, currentValues) => prevValues.mode !== currentValues.mode}>
                            {({ getFieldValue }) =>
                                getFieldValue('mode') === TRACKING_MODE.SUBSCRIPTION_CONFIRM ? (
                                    <Form.Item
                                        label={intl.formatMessage({
                                            id: 'common.starting_bib_number',
                                        })}
                                        name="starting_number"
                                        rules={[
                                            {
                                                required: true,
                                                message: intl.formatMessage({
                                                    id: 'common.field_required',
                                                }),
                                            },
                                        ]}
                                    >
                                        <InputNumber style={{ width: '40%' }} min={1} />
                                    </Form.Item>
                                ) : null
                            }
                        </Form.Item>
                    </Card>
                </Form>
            </Col>
            <Can I="manage" a="Organization">
                <Col xs={24} md={12}>
                    <Card
                        className="gx-card gx-bg-amber-light"
                        title={
                            <span>
                                <IntlMessages id="common.utils" /> <IntlMessages id="common.admin" />
                            </span>
                        }
                    >
                        <Button
                            loading={loadingAssignNumbers}
                            onClick={() => {
                                onAssignPlayersTrackingNumbers(subEvent.id);
                            }}
                            icon={<RocketFilled />}
                            danger={true}
                        >
                            Assegnazione massiva pettorali
                        </Button>
                    </Card>
                </Col>
            </Can>
        </Row>
    );
};

const SubEventTrackingPlayers = ({ match }) => {
    const { params } = match;

    const intl = useIntl();

    const { data, loading, error, refetch } = useQuery(SUB_EVENT_TRACKING_DATA, {
        variables: {
            id: params.id,
        },
    });

    if (error) {
        return <BadResponse title={intl.formatMessage({ id: '500.ooops' })} subtitle={intl.formatMessage({ id: '500.something_wrong' })} />;
    }

    if (loading) {
        return <CircularProgress />;
    }

    if (!data || !data.subEvent) {
        return <Redirect to={getAdminPath(`event/${params.event_id}/sub-event/${params.id}`)} />;
    }

    const { subEvent } = data;

    return (
        <>
            <Row>
                <Col md={24}>
                    <SubEventBreadcrumbs subEvent={subEvent} section={<IntlMessages id={`common.players`} />} />
                </Col>
            </Row>

            <Row>
                <Col xs={24}>
                    <PageHeader
                        title={
                            <span>
                                <IntlMessages id="common.tracking" />
                                :&nbsp;{subEvent.name}
                            </span>
                        }
                        extra={
                            [
                                // <ExportSubscriptionsButton subEvent={subEvent} />
                            ]
                        }
                    />
                </Col>
            </Row>
            <Tabs>
                <Tabs.TabPane tab={<IntlMessages id="common.subscribers_table" />} key="1">
                    <EventPlayersTrackingTable subEvent={data?.subEvent} intl={intl} currentURL={match.url} />
                </Tabs.TabPane>
                <Tabs.TabPane tab={<IntlMessages id="common.settings" />} key="2">
                    <EventPlayersTrackingSettings subEvent={data?.subEvent} refetch={refetch} />
                </Tabs.TabPane>
            </Tabs>
            <DevButton path={__filename} />
        </>
    );
};

export default SubEventTrackingPlayers;
