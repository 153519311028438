import { Popconfirm, message } from 'antd';
import React, { useState } from 'react';
import IntlMessages from '../../util/IntlMessages';
import { LoadingOutlined, PrinterOutlined, ExportOutlined } from '@ant-design/icons';
import { downloadBase64File, openDocumentWindow } from '../../util/file';
import { useApolloClient } from '@apollo/client';

export const PrintButton = ({ query, context, title, filename = null, buttonContent = null, buttonClassName = '', children = null }) => {
    const client = useApolloClient();

    const [printing, setPrinting] = useState(false);

    const onPrint = (download) => {
        setPrinting(true);

        client
            .query(query)
            .then(({ errors, data }) => {
                setPrinting(false);

                const dataContext = data[context];

                if (errors || !dataContext || !dataContext.content) {
                    message.error('Si è verificato nella stampa del documento. Riprova più tardi.');
                    return;
                }
                if (download) {
                    downloadBase64File(dataContext.filename || filename, dataContext.content);
                } else {
                    openDocumentWindow(dataContext, { title: title });
                }
            })
            .catch((err) => {
                console.log('err', err);
                message.error('Si è verificato nella stampa del documento. Riprova più tardi.');
                setPrinting(false);
            });
    };

    return (
        <Popconfirm
            className="gx-text-right"
            title={<IntlMessages id="common.show_print_alert" />}
            onConfirm={() => {
                onPrint(false);
            }}
            onCancel={() => {
                onPrint(true);
            }}
            okText={<IntlMessages id="common.open" />}
            cancelText={<IntlMessages id="common.download" />}
        >
            {children ? (
                children
            ) : (
                <a className={`gx-btn gx-btn-default ${buttonClassName}`}>
                    {printing ? (
                        <LoadingOutlined spin />
                    ) : buttonContent ? (
                        buttonContent
                    ) : (
                        <>
                            <PrinterOutlined />
                            &nbsp; <IntlMessages id="common.print" />
                        </>
                    )}
                </a>
            )}
        </Popconfirm>
    );
};

export const ExportButton = ({ query, context, filename = null, buttonContent = null, buttonClassName = '' }) => {
    const client = useApolloClient();

    const [exporting, setExporting] = useState(false);

    const onExport = () => {
        setExporting(true);

        client
            .query(query)
            .then(({ errors, data }) => {
                setExporting(false);

                const dataContext = data[context];

                if (errors || !dataContext || !dataContext.content) {
                    message.error("Si è verificato un errore durante l'esportazione del documento. Riprova più tardi.");
                    return;
                }

                downloadBase64File(dataContext.filename || filename, dataContext.content);
            })
            .catch((err) => {
                console.log('err', err);
                message.error("Si è verificato un errore durante l'esportazione del documento. Riprova più tardi.");
                setExporting(false);
            });
    };

    return (
        <a className={`gx-btn gx-btn-default ${buttonClassName}`} onClick={onExport}>
            {exporting ? (
                <LoadingOutlined spin />
            ) : buttonContent ? (
                buttonContent
            ) : (
                <>
                    <ExportOutlined />
                    &nbsp; <IntlMessages id="common.export" />
                </>
            )}
        </a>
    );
};
