import React, { useContext } from 'react';
import { Avatar, Typography, Popover, Divider } from 'antd';
import { Link } from 'react-router-dom';
import CustomScrollbars from 'util/CustomScrollbars';
import SidebarLogo from './SidebarLogo';
import { SECONDARY_COLOR } from '../../constants/ThemeSetting';
import IntlMessages from '../../util/IntlMessages';
import { layoutSettingsVar } from '../../apollo/local/cache';
import { useReactiveVar } from '@apollo/client';
import { injectIntl } from 'react-intl';
import { getAdminPath, getProfileImage } from '../../util/router';
import { getInitials } from '../../util/string';
import { HomeOutlined, SettingOutlined } from '@ant-design/icons';
import SidebarMenu from './Menu';
import SidebarManagementMenu from './ManagementMenu';
import { MenuContext } from '../../contexts/MenuContext';
import { MENU_TYPE } from '../../constants/App';

const OrganizationSidebarMenu = () => {
    return (
        <ul className="gx-user-popover gx-w-100">
            <li>
                <Link to={getAdminPath('organization/settings')}>
                    <SettingOutlined className="gx-pt-1" /> <IntlMessages id="common.settings" />
                </Link>
            </li>
            <li>
                <Divider />
            </li>
            <li>
                <a target="_blank" className="gx-d-block" href={process.env.REACT_APP_FRONTEND_URL} rel="noreferrer">
                    <HomeOutlined className="gx-pr-1" /> <IntlMessages id="common.homepage" />
                </a>
            </li>
        </ul>
    );
};

const SidebarContent = ({ sidebarCollapsed, setSidebarCollapsed, user, drawerWrapped }) => {
    const { themeType } = useReactiveVar(layoutSettingsVar);

    const { menu } = useContext(MenuContext);

    //Selected menu item highlight
    //const pathname = window.location.pathname;
    //var selectedKey = "";

    // const menuKeys = [
    //    `${adminPath}/dashboard`,
    //    `${adminPath}/reservations`,
    //    `${adminPath}/reports`,
    //    `${adminPath}/services`,
    //    `${adminPath}/workspaces`,
    //    `${adminPath}/employees`,
    //    `${adminPath}/customers`,
    //    `${adminPath}/users`,
    //    `${adminPath}/assets`,
    //    `${adminPath}/organizations`,
    //    `${adminPath}/privacy-policy`,
    // ];

    // if (pathname === getAdminPath() || pathname === getAdminPath() + "/") {
    //    selectedKey = menuKeys[1];
    // } else {
    //    menuKeys.forEach((key) => {
    //       if (pathname === key || startsWith(pathname, key + "/")) {
    //          selectedKey = key;
    //          return;
    //       }
    //    });
    // }

    if (menu.type === MENU_TYPE.MANAGEMENT) {
        return (
            <>
                <SidebarLogo sidebarCollapsed={sidebarCollapsed} setSidebarCollapsed={setSidebarCollapsed} drawerWrapped={drawerWrapped} />
                <div className="gx-sidebar-content gx-mt-4">
                    <CustomScrollbars className="gx-layout-sider-scrollbar">
                        <SidebarManagementMenu themeType={themeType} menu={menu} drawerWrapped={drawerWrapped} />
                    </CustomScrollbars>
                </div>
            </>
        );
    } else {
        return (
            <>
                <SidebarLogo sidebarCollapsed={sidebarCollapsed} setSidebarCollapsed={setSidebarCollapsed} drawerWrapped={drawerWrapped} />
                <div className={`gx-sidebar-notifications`}>
                    <Popover
                        className="gx-d-block"
                        placement="bottomLeft"
                        content={
                            <div style={{ minWidth: 200 }}>
                                <OrganizationSidebarMenu organization={user.current_organization} />
                            </div>
                        }
                        trigger="click"
                    >
                        <div className="gx-flex-row gx-align-items-center gx-mb-4 gx-avatar-row">
                            <Avatar
                                className="gx-size-40 _gx-pointer gx-mr-3"
                                style={{ minWidth: 40, backgroundColor: SECONDARY_COLOR }}
                                src={getProfileImage(user.current_organization.keepsporting_id)}
                            >
                                {getInitials(user.current_organization.name, 2).toUpperCase()}
                            </Avatar>
                            <Typography.Text ellipsis={true} style={{ maxWidth: '60%' }} className="gx-avatar-name">
                                {user.current_organization.name}
                            </Typography.Text>
                        </div>
                    </Popover>
                </div>
                <div className="gx-sidebar-content">
                    <CustomScrollbars className="gx-layout-sider-scrollbar">
                        <SidebarMenu themeType={themeType} menu={menu} drawerWrapped={drawerWrapped} />
                    </CustomScrollbars>
                </div>
            </>
        );
    }
};

SidebarContent.propTypes = {};

export default injectIntl(SidebarContent);
