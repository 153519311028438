import React, { useState } from 'react';
import { Card, Form, Typography, Input, Select, DatePicker, Divider, Empty, Checkbox } from 'antd';
import IntlMessages from '../../../../util/IntlMessages';
import { CUSTOM_FIELD_TYPES, DATE_FORMAT } from '../../../../constants/App';
import moment from 'moment';
import { some, get, includes } from 'lodash';
import { PlusOutlined } from '@ant-design/icons';
import CountrySelect from '../../../../components/LocationSelect/CountrySelect';

const Label = ({ required, id = 'common.field', text = '' }) => {
    return (
        <>
            {text ? text : <IntlMessages id={id} />}
            {required ? <span className="gx-text-danger"> *</span> : null}
        </>
    );
};

const PredefinedFieldsPreview = ({ formValues }) => {
    if (!formValues || !formValues.predefined || !some(formValues.predefined, (p) => p && p.active)) {
        return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />;
    }

    const { predefined } = formValues;

    return (
        <>
            {predefined.phone.active && (
                <Form.Item label={<Label id="common.phone" required={predefined.phone.required} />}>
                    <Input.Group compact>
                        <div style={{ width: '30%' }}>
                            <PlusOutlined className="gx-d-inline-block" style={{ width: '30%' }} />
                            <Input style={{ width: '70%' }} className="gx-d-inline-block" type="text" />
                        </div>
                        <div style={{ width: '70%' }}>
                            <Input type="text" />
                        </div>
                    </Input.Group>
                </Form.Item>
            )}
            {predefined.location.active && (
                <Form.Item label={<Label id="common.residence_location" required={predefined.location.required} />}>
                    <Input />
                </Form.Item>
            )}
            {predefined.citizenship?.active && (
                <Form.Item label={<Label id="common.citizenship_country" required={predefined.citizenship?.required} />}>
                    <CountrySelect />
                </Form.Item>
            )}
            {predefined.athlete_card.active && (
                <Form.Item label={<Label id="common.athlete_card" required={predefined.athlete_card.required} />}>
                    <Select defaultValue={''}>
                        <Select.Option value="">
                            (<IntlMessages id="common.empty" />)
                        </Select.Option>
                        {predefined.athlete_card.options
                            ?.filter((o) => Boolean(o && o.label))
                            .map((option, index) => {
                                return (
                                    <Select.Option key={index} value={index}>
                                        {option.label}
                                    </Select.Option>
                                );
                            })}
                    </Select>
                </Form.Item>
            )}
        </>
    );
};

const CustomFieldPreview = ({ field, selectedLanguage }) => {
    const label = get(field, `label.${selectedLanguage}`, '');

    switch (field.custom_type) {
        case CUSTOM_FIELD_TYPES.INPUT_TEXT:
            return (
                <Form.Item label={<Label text={label} required={field.required} />}>
                    <Input />
                </Form.Item>
            );
        case CUSTOM_FIELD_TYPES.TEXTAREA:
            return (
                <Form.Item label={<Label text={label} required={field.required} />}>
                    <Input.TextArea />
                </Form.Item>
            );
        case CUSTOM_FIELD_TYPES.CHECKBOX:
            return (
                <Form.Item>
                    <Checkbox>
                        <Label text={label} required={field.required} />
                    </Checkbox>
                </Form.Item>
            );
        case CUSTOM_FIELD_TYPES.SELECT:
            return (
                <Form.Item label={<Label text={label} required={field.required} />}>
                    <Select defaultValue="">
                        <Select.Option value="">
                            (<IntlMessages id="common.empty" />)
                        </Select.Option>
                        {field.options?.map((option, index) => {
                            const optionLabel = get(option, `label.${selectedLanguage}`, '');

                            return <Select.Option key={index}>{optionLabel}</Select.Option>;
                        })}
                    </Select>
                </Form.Item>
            );
        default:
            return null;
    }
};

const CustomFieldsPreview = ({ formValues, selectedLanguage }) => {
    if (!formValues || !formValues.customs || formValues.customs.length === 0) {
        return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />;
    }

    return (
        <div>
            {formValues.customs.map((customField, index) => {
                return <CustomFieldPreview field={customField} key={index} selectedLanguage={selectedLanguage} />;
            })}
        </div>
    );
};

const ProductFieldsPreview = ({ event, formValues, selectedLanguage }) => {
    if (!formValues || !formValues.products || formValues.products.length === 0) {
        return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />;
    }

    const getVariantName = (variant) => {
        return variant.attributes.map((attribute) => {

            const attributeName = get(attribute, `attribute_name.${selectedLanguage}`, '');
            const attributeValue = get(attribute, `option_name.${selectedLanguage}`, '');

            return <span>{attributeName}: {attributeValue}</span>;
        });
    }

    return (
        <div>
            {event.products.filter(p => includes(formValues.products, p.id)).map((product, index) => {
                const productDescription = get(product, `description.${selectedLanguage}`, '')
                return (
                    <Form.Item label={product.name} key={index} tooltip={productDescription}>
                        <Select defaultValue={null}>
                            <Select.Option value={null}>
                                (<IntlMessages id="common.empty" />)
                            </Select.Option>
                            {product.variants.map((variant, index) => {
                                return (
                                    <Select.Option key={index} value={variant.sku}>
                                        {getVariantName(variant)}
                                    </Select.Option>
                                );
                            })}
                        </Select>
                    </Form.Item>
                );
            })}
        </div>
    );

}

const SubscriptionModulePreview = ({ event, formValues, selectedLanguage }) => {
    const [pickerMode, setPickerMode] = useState('year');

    return (
        <Form layout="vertical">
            <Card className="gx-card" title={<IntlMessages id="common.preview" />}>
                <Typography.Paragraph className="gx-text-uppercase">
                    <IntlMessages id="common.default_fields" />
                </Typography.Paragraph>

                <Form.Item label={<Label id="common.first_name" required={true} />}>
                    <Input />
                </Form.Item>

                <Form.Item label={<Label id="common.last_name" required={true} />}>
                    <Input />
                </Form.Item>

                <Form.Item label={<Label id="common.email" required={true} />}>
                    <Input />
                </Form.Item>

                <Form.Item label={<Label id="common.gender" required={true} />}>
                    <Select>
                        <Select.Option>
                            <IntlMessages id="common.male" />
                        </Select.Option>
                        <Select.Option>
                            <IntlMessages id="common.female" />
                        </Select.Option>
                    </Select>
                </Form.Item>

                <Form.Item rules={[{ required: true }]} label={<Label id="common.birthday" required={true} />}>
                    <DatePicker
                        disabledDate={(current) => {
                            return current && current > moment().endOf('day');
                        }}
                        defaultPickerValue={moment().add(-20, 'years')}
                        format={DATE_FORMAT}
                        style={{ borderRadius: 0 }}
                        className="gx-w-100"
                        onChange={(value) => {
                            if (value === null) {
                                setPickerMode('year');
                            }
                        }}
                        mode={pickerMode}
                        onPanelChange={(value, mode) => {
                            setPickerMode(mode);
                        }}
                        showToday={false}
                    />
                </Form.Item>

                <Divider />

                <Typography.Paragraph className="gx-text-uppercase">
                    <IntlMessages id="common.predefined_fields" />
                </Typography.Paragraph>

                <PredefinedFieldsPreview formValues={formValues} />

                <Divider />

                <Typography.Paragraph className="gx-text-uppercase">
                    <IntlMessages id="common.custom_fields" />
                </Typography.Paragraph>

                <CustomFieldsPreview formValues={formValues} selectedLanguage={selectedLanguage} />

                <Divider />

                <Typography.Paragraph className="gx-text-uppercase">
                    <IntlMessages id="products.products" />
                </Typography.Paragraph>

                <ProductFieldsPreview event={event} formValues={formValues} selectedLanguage={selectedLanguage} />
            </Card>
        </Form>
    );
};

export default SubscriptionModulePreview;
