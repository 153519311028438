const lexicalTheme = {
    ltr: 'ltr',
    rtl: 'rtl',
    placeholder: 'editor-placeholder',
    paragraph: 'editor-paragraph',
    text: {
        // bold: 'PlaygroundEditorTheme__textBold',
        // code: 'PlaygroundEditorTheme__textCode',
        // italic: 'PlaygroundEditorTheme__textItalic',
        strikethrough: 'gx-text-strikethrough',
        // subscript: 'PlaygroundEditorTheme__textSubscript',
        // superscript: 'PlaygroundEditorTheme__textSuperscript',
        underline: 'gx-text-underline',
        //underlineStrikethrough: 'PlaygroundEditorTheme__textUnderlineStrikethrough',
    },
};

export default lexicalTheme;
