import { useQuery } from '@apollo/client';
import { message } from 'antd';
import React, { useEffect, useContext } from 'react';
import { Switch } from 'react-router-dom';
import { Redirect } from 'react-router-dom/cjs/react-router-dom.min';
import { EVENT_CONTAINER_DATA } from '../../../apollo/query/event';
import PrivateCASLRoute from '../../../components/PrivateCASLRoute';
import { MENU_TYPE } from '../../../constants/App';
import { MenuContext } from '../../../contexts/MenuContext';
import { getAdminPath } from '../../../util/router';
import EventDashboard from './dashboard';
import EventDescription from './description';
import EventGallery from './gallery';
import EventInformations from './informations';
import EventSubscriptions from './subscriptions';
import EventPayments from './payments';
import EventDiscounts from './discounts';
import ViewEventPayment from './payments/view';
import EventPaymentsSummary from './payments-summary';
import NotFound from '../../../containers/NotFound';
import { useIntl } from 'react-intl';
import EventProducts from './products';
import CreateEventProduct from './products/create';
import UpdateEventProduct from './products/update';

const EventRoutes = ({ event, loading }) => {
    const { menu, setMenu } = useContext(MenuContext);

    useEffect(() => {
        if (menu.type !== MENU_TYPE.EVENT || menu.event?.id !== event?.id) {
            setMenu({
                type: MENU_TYPE.EVENT,
                event: event,
            });
        }
    }, [menu.type, loading]);

    return (
        <div>
            <Switch>
                <PrivateCASLRoute
                    I="manage"
                    a="Event"
                    exact={true}
                    path={[getAdminPath('event/:id'), getAdminPath('event/:id/dashboard')]}
                    component={EventDashboard}
                />

                <PrivateCASLRoute I="manage" a="Event" exact={true} path={getAdminPath('event/:id/payments')} component={EventPayments} />

                <PrivateCASLRoute I="manage" a="Event" exact={true} path={getAdminPath('event/:id/payments-summary')} component={EventPaymentsSummary} />

                <PrivateCASLRoute I="manage" a="Event" exact={true} path={getAdminPath('event/:id/discounts')} component={EventDiscounts} />

                <PrivateCASLRoute I="manage" a="Event" exact={false} path={getAdminPath('event/:event_id/payment/view/:id')} component={ViewEventPayment} />

                <PrivateCASLRoute I="manage" a="Event" exact={true} path={getAdminPath('event/:id/informations')} component={EventInformations} />

                <PrivateCASLRoute I="manage" a="Event" exact={true} path={getAdminPath('event/:id/description')} component={EventDescription} />

                <PrivateCASLRoute I="manage" a="Event" exact={true} path={getAdminPath('event/:id/gallery')} component={EventGallery} />

                <PrivateCASLRoute I="manage" a="Event" exact={true} path={getAdminPath('event/:id/subscriptions')} component={EventSubscriptions} />

                <PrivateCASLRoute I="manage" a="Event" exact={true} path={getAdminPath('event/:id/products')} component={EventProducts} />

                <PrivateCASLRoute I="manage" a="Event" exact={true} path={getAdminPath('event/:id/products/create')} component={CreateEventProduct} />

                <PrivateCASLRoute
                    I="manage"
                    a="Event"
                    exact={false}
                    path={getAdminPath('event/:id/products/:product_id/update')}
                    component={UpdateEventProduct}
                />
            </Switch>
        </div>
    );
};

const EventContainer = ({ match }) => {
    const { params } = match;

    const intl = useIntl();

    let event = null;

    const { data, loading, error } = useQuery(EVENT_CONTAINER_DATA, { variables: { id: params.id } });

    if (data) {
        event = data.event;
    }

    if (error) {
        return <Redirect to="/admin" />;
    }

    if (!event && !loading) {
        return (
            <NotFound
                intl={intl}
                subTitle={intl.formatMessage({
                    id: 'errors.event_not_found',
                })}
                redirectToURL={getAdminPath()}
            />
        );
    }

    return <EventRoutes loading={loading} event={event} />;
};

export default EventContainer;
