import React from 'react';
import { EVENT } from '../../constants/App';
import { Avatar, Tooltip } from 'antd';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { DANGER_COLOR, SUCCESS_COLOR } from '../../constants/ThemeSetting';
import IntlMessages from '../../util/IntlMessages';

export const EventPublicationIcon = ({ event }) => {
    switch (event.publish.state) {
        case EVENT.PUBLISH_STATE.NOT_PUBLISHED:
            return (
                <Tooltip title={<IntlMessages id="events.not_published" />}>
                    <Avatar icon={<CloseOutlined />} style={{ backgroundColor: DANGER_COLOR }} />
                </Tooltip>
            );
        case EVENT.PUBLISH_STATE.PUBLISHED:
            return (
                <Tooltip title={<IntlMessages id="events.published" />}>
                    <Avatar icon={<CheckOutlined />} style={{ backgroundColor: SUCCESS_COLOR }} />
                </Tooltip>
            );
        default:
            return <Avatar src={event.avatar} />;
    }
};
