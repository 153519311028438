import { gql } from '@apollo/client';

export const CREATE_PRODUCT = gql`
    mutation createProduct($input: CreateProductInput!) {
        createProduct(input: $input)
    }
`;

export const UPDATE_PRODUCT = gql`
    mutation updateProduct($input: UpdateProductInput!) {
        updateProduct(input: $input)
    }
`;

export const DELETE_PRODUCT = gql`
    mutation deleteProduct($id: String!) {
        deleteProduct(id: $id)
    }
`