import React, { createContext, useState } from 'react';
import { MENU_TYPE } from '../constants/App';

export const MenuContext = createContext(null);

export const MenuProvider = ({ children }) => {
    const [menu, setMenu] = useState({ type: MENU_TYPE.MAIN });

    return <MenuContext.Provider value={{ menu, setMenu }}>{children}</MenuContext.Provider>;
};
