import { useMutation, useQuery } from '@apollo/client';
import { Button, Col, PageHeader, Row, Form, Tabs, Select, message } from 'antd';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { defaultCatchException, defaultMutationCallback } from '../../../../apollo/callbacks';
import { UPDATE_SUBEVENT_SUBSCRIPTION_MODULE } from '../../../../apollo/mutation/sub-event';
import { SUB_EVENT_SUBSCRIPTION_MODULE } from '../../../../apollo/query/sub-event';
import BadResponse from '../../../../components/BadResponse';
import BadResponseErrorAlert from '../../../../components/BadResponseErrorAlert';
import CircularProgress from '../../../../components/CircularProgress';
import DevButton from '../../../../components/Dev/DevButton';
import IntlMessages from '../../../../util/IntlMessages';
import { cleanObjectDeep } from '../../../../util/object';
import SubEventBreadcrumbs from '../breadcrumbs';
import CustomFields from './customFields';
import PredefinedFields from './predefinedFields';
import SubscriptionModulePreview from './preview';
import { CUSTOM_FIELD_TYPES } from '../../../../constants/App';
import ProductFields from './productFields';

const SubscriptionModule = ({ subEvent, intl }) => {
    const { event } = subEvent;

    //const [form] = Form.useForm();
    const [updateSubscriptionModule, { loading }] = useMutation(UPDATE_SUBEVENT_SUBSCRIPTION_MODULE);

    const history = useHistory();

    const getInitialVelues = (subEvent) => {
        const { predefined_fields: predefined, custom_fields: customs, products_id: products } = subEvent;

        return { predefined, customs, products };
    };

    const initialValues = getInitialVelues(subEvent);

    const [formValues, setFormValues] = useState(initialValues);
    const [activeTab, setActiveTab] = useState(null);
    const languages = event.languages?.map((l) => l.iso);
    const [selectedLanguage, setSelectedLanguage] = useState(event.main_language);

    const closeHandler = () => {
        message.success(intl.formatMessage({ id: 'common.edit_successful' }));
    };

    const onFinish = (values) => {

        if (!values.customs) {
            values.customs = [];
        }

        values = cleanObjectDeep(values);

        values.customs = values.customs.map((custom) => {
            if (custom.custom_type !== CUSTOM_FIELD_TYPES.SELECT && custom.options) {
                custom.options = [];
            }
            return custom;
        });

        updateSubscriptionModule({
            variables: {
                id: subEvent.id,
                input: values,
            },
        })
            .then((response) => defaultMutationCallback(response, () => closeHandler(response.data)))
            .catch((e) => defaultCatchException(e, intl));
    };

    return (
        <>
            <Row>
                <Col md={24}>
                    <SubEventBreadcrumbs subEvent={subEvent} section={<IntlMessages id={`common.update_type_${subEvent.event.type}`} />} />
                </Col>
            </Row>
            <Form
                layout="vertical"
                labelCol={{ span: 20 }}
                onFinish={onFinish}
                initialValues={initialValues}
                onValuesChange={(changedValues, allValues) => {
                    setFormValues(allValues);
                }}
            >
                <Row className="gx-row-form-child">
                    <Col xs={24}>
                        <PageHeader
                            title={<IntlMessages id="common.add_custom_fields" />}
                            onBack={() => history.goBack()}
                            extra={[
                                // <Button key="1" onClick={() => { }}>
                                //     <IntlMessages id="common.cancel" />
                                // </Button>,
                                <Button key="2" htmlType="submit" type="primary" loading={loading}>
                                    {intl.formatMessage({ id: 'common.update' }).toUpperCase()}
                                </Button>,
                            ]}
                        />
                    </Col>
                    <Col xs={24}>
                        <BadResponseErrorAlert />
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={16}>
                        <Tabs
                            defaultActiveKey="1"
                            onChange={(value) => {
                                setActiveTab(value);
                            }}
                            tabBarExtraContent={
                                activeTab === '2' ? (
                                    <Select
                                        value={selectedLanguage}
                                        onSelect={(value) => {
                                            setSelectedLanguage(value);
                                        }}
                                        style={{ width: 100 }}
                                        size="small"
                                    >
                                        {languages.map((value, key) => {
                                            return (
                                                <Select.Option key={key} value={value}>
                                                    {intl.formatMessage({ id: `common.${value}` })}
                                                </Select.Option>
                                            );
                                        })}
                                    </Select>
                                ) : null
                            }
                        >
                            <Tabs.TabPane forceRender={true} tab={intl.formatMessage({ id: 'common.predefined_fields' })} key="1">
                                <PredefinedFields intl={intl} />
                            </Tabs.TabPane>

                            <Tabs.TabPane forceRender={true} tab={intl.formatMessage({ id: 'common.custom_fields' })} key="2">
                                <CustomFields intl={intl} values={formValues?.customs} selectedLanguage={selectedLanguage} />
                            </Tabs.TabPane>

                            <Tabs.TabPane forceRender={true} tab={intl.formatMessage({ id: 'products.products' })} key="3">
                                <ProductFields products={event.products} event={event} />
                            </Tabs.TabPane>

                        </Tabs>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={8}>
                        <SubscriptionModulePreview formValues={formValues} selectedLanguage={selectedLanguage} event={event}/>
                    </Col>
                </Row>
            </Form>
        </>
    );
};

const SubscriptionModuleContainer = ({ match }) => {
    const intl = useIntl();

    const { data, loading, error } = useQuery(SUB_EVENT_SUBSCRIPTION_MODULE, { variables: { id: match.params.id } });

    if (loading) {
        return <CircularProgress />;
    }

    if (error) {
        return <BadResponse title={intl.formatMessage({ id: '500.ooops' })} subtitle={intl.formatMessage({ id: '500.something_wrong' })} />;
    }

    if (!data?.subEvent) {
        return <BadResponse title={intl.formatMessage({ id: '404.ooops' })} subtitle={intl.formatMessage({ id: '404.something_wrong' })} />;
    }

    return (
        <div>
            <SubscriptionModule subEvent={data.subEvent} intl={intl} />
            <DevButton path={__filename} />
        </div>
    );
};

export default SubscriptionModuleContainer;
