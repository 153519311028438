import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { $wrapNodeInElement, mergeRegister } from '@lexical/utils';
import {
    $createParagraphNode,
    $createRangeSelection,
    $getSelection,
    $insertNodes,
    $isNodeSelection,
    $isRootOrShadowRoot,
    $setSelection,
    COMMAND_PRIORITY_EDITOR,
    COMMAND_PRIORITY_HIGH,
    COMMAND_PRIORITY_LOW,
    createCommand,
    DRAGOVER_COMMAND,
    DRAGSTART_COMMAND,
    DROP_COMMAND,
} from 'lexical';
import { useEffect, useRef, useState } from 'react';
import * as React from 'react';
import { $createImageNode, $isImageNode, ImageNode } from '../../nodes/ImageNode';
import Button from '../../ui/Button';
import { DialogActions, DialogButtonsList } from '../../ui/Dialog';
import FileInput from '../../ui/FileInput';
import TextInput from '../../ui/TextInput';
import { CAN_USE_DOM } from '../../utils/canUseDOM';
import IntlMessages from '../../../../util/IntlMessages';
import { validateFiles } from '../../../UploadAsset/validators';
import { UPLOAD_MAX_FILE_SIZE_MB } from '../../../UploadAsset/upload-asset.constants';
import { useIntl } from 'react-intl';
import { Divider, Upload, message } from 'antd';
import { useS3Upload } from '../../../../hooks/useS3Upload';
import { AssetContextType } from '../../../../constants/Asset';
import { defaultCatchException, defaultMutationCallback } from '../../../../apollo/callbacks';
import { InboxOutlined, LoadingOutlined } from '@ant-design/icons';
import { useRouteMatch } from 'react-router-dom';

const getDOMSelection = (targetWindow) => (CAN_USE_DOM ? (targetWindow || window).getSelection() : null);

export const INSERT_IMAGE_COMMAND = createCommand('INSERT_IMAGE_COMMAND');

export function InsertImageUriDialogBody({ onClick }) {
    const [src, setSrc] = useState('');
    const [altText, setAltText] = useState('');

    const isDisabled = src === '';

    return (
        <>
            <TextInput label="URL" placeholder="https://..." onChange={setSrc} value={src} data-test-id="image-modal-url-input" />
            <TextInput
                label={<IntlMessages id="common.description" />}
                placeholder=""
                onChange={setAltText}
                value={altText}
                data-test-id="image-modal-alt-text-input"
            />
            <DialogActions>
                <Button data-test-id="image-modal-confirm-btn" disabled={isDisabled} onClick={() => onClick({ altText, src })}>
                    <IntlMessages id="common.confirm" />
                </Button>
            </DialogActions>
        </>
    );
}

export function InsertImageUploadedDialogBody({ onClick }) {
    const [src, setSrc] = useState('');
    const [altText, setAltText] = useState('');

    const isDisabled = src === '';

    const loadImage = (files) => {
        const reader = new FileReader();
        reader.onload = function () {
            if (typeof reader.result === 'string') {
                setSrc(reader.result);
            }
            return '';
        };
        if (files !== null) {
            reader.readAsDataURL(files[0]);
        }
    };

    return (
        <>
            <FileInput label={<IntlMessages id="common.upload" />} onChange={loadImage} accept="image/*" data-test-id="image-modal-file-upload" />
            <TextInput
                label={<IntlMessages id="common.description" />}
                placeholder=""
                onChange={setAltText}
                value={altText}
                data-test-id="image-modal-alt-text-input"
            />
            <DialogActions>
                <Button data-test-id="image-modal-file-upload-btn" disabled={isDisabled} onClick={() => onClick({ altText, src })}>
                    <IntlMessages id="common.confirm" />
                </Button>
            </DialogActions>
        </>
    );
}

export function AntdInsertImageUploadedDialogBody({ onClick }) {
    const intl = useIntl();

    const routeMatch = useRouteMatch();

    const eventId = routeMatch?.params.id;

    const [loading, setLoading] = useState(false);

    const [uploadAssets] = useS3Upload(
        AssetContextType.EVENT_DESCRIPTION_IMAGE,
        {
            id: eventId,
            name: 'event',
        },
        false
    );

    const [filesList, setFilesList] = useState([]);
    const [validationResult, setValidationResult] = useState({ errors: null, warnings: null });
    const [src, setSrc] = useState('');
    const [altText, setAltText] = useState('');

    const isDisabled = src === '';

    const acceptedFiles = ['image/png', 'image/jpeg', 'image/gif', 'image/jpg'];

    const onChange = (files, successCallback) => {
        if (!eventId) {
            message.error('Event id not found');
            return;
        }

        setLoading(true);
        uploadAssets(files)
            .then((data) => {
                setLoading(false);
                defaultMutationCallback(data, () => {
                    if (data && data.length > 0) {
                        setSrc(data[0]?.createAsset?.url);
                        successCallback();
                    } else {
                        message.error(intl.formatMessage({ id: 'common.file_upload_error' }));
                    }
                });
            })
            .catch((error) => {
                console.log('uploadAssets ERROR', error);
                defaultCatchException(error, intl);
                setLoading(false);
            });
    };

    return (
        <>
            <Upload.Dragger
                className="lexical-editor-image-uploader"
                accept={acceptedFiles.join(',')}
                multiple={false}
                fileList={filesList}
                beforeUpload={async (file, files) => {
                    const newFilesList = [...filesList, ...files];

                    setFilesList(newFilesList);

                    let warnings = null;

                    const errors = await validateFiles(
                        files,
                        {
                            maxSize: UPLOAD_MAX_FILE_SIZE_MB,
                            mimeTypes: acceptedFiles,
                        },
                        intl
                    );

                    // if (!allowMultipleFiles) {
                    //     warnings = await validateImageSize(first(files), imageSize, intl)
                    // }

                    setValidationResult({
                        errors: errors,
                        warnings: warnings,
                    });

                    if (errors) return false;

                    onChange(newFilesList, () => {
                        message.success(intl.formatMessage({ id: 'common.operation_successful' }));
                        //setFilesList([]);
                    });
                    return true;
                }}
            >
                <p className="ant-upload-drag-icon">{loading ? <LoadingOutlined /> : <InboxOutlined />}</p>

                <p className="ant-upload-text">{<IntlMessages id="uploads.dragger_upload_file" />}</p>
                <p className="ant-upload-hint">
                    <IntlMessages id="uploads.file_upload_help" values={{ mime: 'jpeg, png', size: `${UPLOAD_MAX_FILE_SIZE_MB}MB` }} />
                </p>

                {validationResult?.errors && <p className="gx-mt-3 gx-text-danger">{validationResult.errors}</p>}
                {validationResult?.warnings && <p className="gx-mt-3 gx-text-warning">{validationResult.warnings}</p>}
            </Upload.Dragger>

            <Divider />

            <TextInput
                label={<IntlMessages id="common.description" />}
                placeholder=""
                onChange={setAltText}
                value={altText}
                data-test-id="image-modal-alt-text-input"
            />
            <DialogActions>
                <Button data-test-id="image-modal-file-upload-btn" disabled={isDisabled} onClick={() => onClick({ altText, src })}>
                    <IntlMessages id="common.confirm" />
                </Button>
            </DialogActions>
        </>
    );
}

export function InsertImageDialog({ activeEditor, onClose }) {
    const [mode, setMode] = useState(null);
    const hasModifier = useRef(false);

    useEffect(() => {
        hasModifier.current = false;
        const handler = (e) => {
            hasModifier.current = e.altKey;
        };
        document.addEventListener('keydown', handler);
        return () => {
            document.removeEventListener('keydown', handler);
        };
    }, [activeEditor]);

    const onClick = (payload) => {
        activeEditor.dispatchCommand(INSERT_IMAGE_COMMAND, payload);
        onClose();
    };

    return (
        <>
            {!mode && (
                <DialogButtonsList>
                    <Button data-test-id="image-modal-option-url" onClick={() => setMode('url')}>
                        URL
                    </Button>
                    <Button data-test-id="image-modal-option-file" onClick={() => setMode('file')}>
                        File
                    </Button>
                </DialogButtonsList>
            )}
            {mode === 'url' && <InsertImageUriDialogBody onClick={onClick} />}
            {mode === 'file' && <AntdInsertImageUploadedDialogBody onClick={onClick} />}
        </>
    );
}

export default function ImagesPlugin({ captionsEnabled }) {
    const [editor] = useLexicalComposerContext();

    useEffect(() => {
        if (!editor.hasNodes([ImageNode])) {
            throw new Error('ImagesPlugin: ImageNode not registered on editor');
        }

        return mergeRegister(
            editor.registerCommand(
                INSERT_IMAGE_COMMAND,
                (payload) => {
                    const imageNode = $createImageNode(payload);
                    $insertNodes([imageNode]);
                    if ($isRootOrShadowRoot(imageNode.getParentOrThrow())) {
                        $wrapNodeInElement(imageNode, $createParagraphNode).selectEnd();
                    }

                    return true;
                },
                COMMAND_PRIORITY_EDITOR
            ),
            editor.registerCommand(
                DRAGSTART_COMMAND,
                (event) => {
                    return onDragStart(event);
                },
                COMMAND_PRIORITY_HIGH
            ),
            editor.registerCommand(
                DRAGOVER_COMMAND,
                (event) => {
                    return onDragover(event);
                },
                COMMAND_PRIORITY_LOW
            ),
            editor.registerCommand(
                DROP_COMMAND,
                (event) => {
                    return onDrop(event, editor);
                },
                COMMAND_PRIORITY_HIGH
            )
        );
    }, [captionsEnabled, editor]);

    return null;
}

const TRANSPARENT_IMAGE = 'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7';
const img = document.createElement('img');
img.src = TRANSPARENT_IMAGE;

function onDragStart(event) {
    const node = getImageNodeInSelection();
    if (!node) {
        return false;
    }
    const dataTransfer = event.dataTransfer;
    if (!dataTransfer) {
        return false;
    }
    dataTransfer.setData('text/plain', '_');
    dataTransfer.setDragImage(img, 0, 0);
    dataTransfer.setData(
        'application/x-lexical-drag',
        JSON.stringify({
            data: {
                altText: node.__altText,
                caption: node.__caption,
                height: node.__height,
                key: node.getKey(),
                maxWidth: node.__maxWidth,
                showCaption: node.__showCaption,
                src: node.__src,
                width: node.__width,
            },
            type: 'image',
        })
    );

    return true;
}

function onDragover(event) {
    const node = getImageNodeInSelection();
    if (!node) {
        return false;
    }
    if (!canDropImage(event)) {
        event.preventDefault();
    }
    return true;
}

function onDrop(event, editor) {
    const node = getImageNodeInSelection();
    if (!node) {
        return false;
    }
    const data = getDragImageData(event);
    if (!data) {
        return false;
    }
    event.preventDefault();
    if (canDropImage(event)) {
        const range = getDragSelection(event);
        node.remove();
        const rangeSelection = $createRangeSelection();
        if (range !== null && range !== undefined) {
            rangeSelection.applyDOMRange(range);
        }
        $setSelection(rangeSelection);
        editor.dispatchCommand(INSERT_IMAGE_COMMAND, data);
    }
    return true;
}

function getImageNodeInSelection() {
    const selection = $getSelection();
    if (!$isNodeSelection(selection)) {
        return null;
    }
    const nodes = selection.getNodes();
    const node = nodes[0];
    return $isImageNode(node) ? node : null;
}

function getDragImageData(event) {
    const dragData = event.dataTransfer?.getData('application/x-lexical-drag');
    if (!dragData) {
        return null;
    }
    const { type, data } = JSON.parse(dragData);
    if (type !== 'image') {
        return null;
    }

    return data;
}

function canDropImage(event) {
    const target = event.target;
    return !!(
        target &&
        target instanceof HTMLElement &&
        !target.closest('code, span.editor-image') &&
        target.parentElement &&
        target.parentElement.closest('div.ContentEditable__root')
    );
}

function getDragSelection(event) {
    let range;
    const target = event.target;
    const targetWindow = target == null ? null : target.nodeType === 9 ? target.defaultView : target.ownerDocument.defaultView;
    const domSelection = getDOMSelection(targetWindow);
    if (document.caretRangeFromPoint) {
        range = document.caretRangeFromPoint(event.clientX, event.clientY);
    } else if (event.rangeParent && domSelection !== null) {
        domSelection.collapse(event.rangeParent, event.rangeOffset || 0);
        range = domSelection.getRangeAt(0);
    } else {
        throw Error(`Cannot get the selection when dragging`);
    }

    return range;
}
