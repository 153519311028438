import { DeleteOutlined, ExclamationCircleOutlined, FileImageOutlined, InboxOutlined, LoadingOutlined } from '@ant-design/icons';
import { useMutation, useQuery } from '@apollo/client';
import { Modal, Row, Col, Breadcrumb, Skeleton, PageHeader, Tabs, Card, Upload, Image as ImageTag } from 'antd';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { EVENT_GALLERY } from '../../../../apollo/query/event';
import BadResponse from '../../../../components/BadResponse';
import BreadcrumbHomeLink from '../../../../components/BreadcrumbHomeLink';
import IntlMessages from '../../../../util/IntlMessages';
import ImgCrop from 'antd-img-crop';
import { validateFiles } from '../../../../components/UploadAsset/validators';
import { first, find } from 'lodash';
import { UPLOAD_MAX_FILE_SIZE_MB } from '../../../../components/UploadAsset/upload-asset.constants';
import { useS3Upload } from '../../../../hooks/useS3Upload';
import { AssetContextType, imageTypeProps } from '../../../../constants/Asset';
import { defaultCatchException, defaultMutationCallback } from '../../../../apollo/callbacks';
import { GQL_ASSET_DELETE } from '../../../../apollo/mutation/assets';
import DevButton from '../../../../components/Dev/DevButton';
import { useActiveBreakpoint } from '../../../../hooks/useActiveBreakpoint';

const validateImageSize = async (file, validationSize, intl) => {
    const reader = new FileReader();

    reader.readAsDataURL(file);

    const size = await new Promise((resolve, reject) => {
        reader.onload = function (e) {
            const image = new Image();

            image.src = e.target.result;

            image.onload = () => {
                resolve([image.width, image.height]);
            };

            image.onerror = (error) => reject(error);
        };
    });

    if (size[0] < validationSize[0] || size[1] < validationSize[1]) {
        return intl.formatMessage(
            {
                id: 'uploads.image_reccomended_size_warning',
            },
            {
                size: `${validationSize[0]}X${validationSize[1]} px`,
                upload_size: `${size[0]}X${size[1]} px`,
            }
        );
    }
    return null;
};

const GalleryUpload = ({ accept, loading, allowMultipleFiles, onChange, crop, imageSize = [1200, 600] }) => {
    const intl = useIntl();

    const [filesList, setFilesList] = useState([]);
    const [validationResult, setValidationResult] = useState({ errors: null, warnings: null });

    return (
        <>
            <ImgCrop
                rotate={crop.rotate}
                quality={crop.quality}
                aspect={crop.aspect}
                modalTitle={intl.formatMessage({ id: 'common.event_image' })}
                modalWidth={860}
            >
                <Upload.Dragger
                    accept={accept}
                    multiple={allowMultipleFiles}
                    fileList={filesList}
                    beforeUpload={async (file, files) => {
                        const newFilesList = [...filesList, ...files];

                        setFilesList(newFilesList);

                        let warnings = null;

                        const errors = await validateFiles(
                            files,
                            {
                                maxSize: UPLOAD_MAX_FILE_SIZE_MB,
                                mimeTypes: ['image/png', 'image/jpeg', 'image/gif', 'image/jpg'],
                            },
                            intl
                        );

                        if (!allowMultipleFiles) {
                            warnings = await validateImageSize(first(files), imageSize, intl);
                        }

                        setValidationResult({
                            errors: errors,
                            warnings: warnings,
                        });

                        if (errors) return false;

                        onChange(newFilesList, () => {
                            setFilesList([]);
                        });
                        return true;
                    }}
                >
                    <p className="ant-upload-drag-icon">{loading ? <LoadingOutlined /> : <InboxOutlined />}</p>

                    <p className="ant-upload-text">{<IntlMessages id="uploads.dragger_upload_file" />}</p>
                    <p className="ant-upload-hint">
                        <IntlMessages id="uploads.file_upload_help" values={{ mime: 'jpeg, png', size: `${UPLOAD_MAX_FILE_SIZE_MB}MB` }} />
                        <br />
                        {imageSize && imageSize.length > 0 && (
                            <IntlMessages id="uploads.image_reccomended_size_info" values={{ size: `${imageSize[0]}x${imageSize[1]}px` }} />
                        )}
                    </p>

                    {validationResult?.errors && <p className="gx-mt-3 gx-text-danger">{validationResult.errors}</p>}
                    {validationResult?.warnings && <p className="gx-mt-3 gx-text-warning">{validationResult.warnings}</p>}
                </Upload.Dragger>
            </ImgCrop>
        </>
    );
};

GalleryUpload.defaultProps = {
    allowMultipleFiles: false,
    accept: '*',
    fileList: [],
    loading: false,
    crop: {
        quality: 0.8,
        aspect: 1,
        rotate: true,
    },
};

const EventGalleryTab = ({ event, onDelete, type, refetch, intl }) => {
    const [loading, setLoading] = useState(false);

    const [uploadAssets] = useS3Upload(
        type,
        {
            id: event.id,
            name: 'event',
        },
        true
    );

    const [previewVisible, setPreviewVisible] = useState(false);

    const imageProps = imageTypeProps[type];

    const image = find(event.assets, (asset) => {
        return asset.context_type === type;
    });

    const onChange = (files, successCallback) => {
        setLoading(true);
        uploadAssets(files)
            .then((data) => {
                setLoading(false);
                defaultMutationCallback(data, () => {
                    refetch();
                    successCallback();
                });
            })
            .catch((error) => {
                console.log('uploadAssets ERROR', error);
                defaultCatchException(error, intl);
                setLoading(false);
            });
    };

    return (
        <Row>
            <Col xs={24} md={16} className="gx-text-center">
                <Card
                    cover={
                        image ? (
                            <ImageTag
                                preview={{
                                    visible: previewVisible,
                                    src: image?.url,
                                    onVisibleChange: (value) => {
                                        setPreviewVisible(value);
                                    },
                                }}
                                style={{
                                    borderRadius: 0,
                                    ...imageProps.style,
                                }}
                                src={image?.url}
                            />
                        ) : (
                            <img
                                style={{
                                    borderRadius: 0,
                                    ...imageProps.style,
                                }}
                                src={imageProps.placeholder}
                            />
                        )
                    }
                    actions={
                        image
                            ? [
                                  <FileImageOutlined
                                      onClick={() => {
                                          setPreviewVisible(true);
                                      }}
                                      key="preview"
                                  />,
                                  <DeleteOutlined
                                      onClick={() => {
                                          onDelete(image);
                                      }}
                                      key="delete"
                                  />,
                              ]
                            : []
                    }
                >
                    <Card.Meta
                    //title="Card title"
                    //description="This is the description"
                    />
                </Card>
            </Col>
            <Col xs={24} md={8}>
                <GalleryUpload
                    loading={loading}
                    allowMultipleFiles={false}
                    crop={{
                        quality: imageProps.quality,
                        aspect: imageProps.aspect,
                    }}
                    type={type}
                    onChange={onChange}
                    imageSize={imageProps.recommendedSize}
                />
            </Col>
        </Row>
    );
};

const EventGalleryEditor = ({ event, refetch, intl }) => {
    const [deleteAsset] = useMutation(GQL_ASSET_DELETE);

    const activeBreakpoint = useActiveBreakpoint();

    const onDelete = (image) => {
        Modal.confirm({
            title: intl.formatMessage({ id: 'common.warning' }),
            icon: <ExclamationCircleOutlined />,
            content: intl.formatMessage({
                id: 'common.ask_delete_image_asset',
            }),
            okText: intl.formatMessage({ id: 'common.delete' }),
            cancelText: intl.formatMessage({ id: 'common.no' }),
            okType: 'danger',
            onOk() {
                deleteAsset({ variables: { id: image.id } })
                    .then(() => {
                        refetch();
                    })
                    .catch((e) => {
                        defaultCatchException(e, intl);
                    });
            },
        });
    };

    return (
        <Row>
            <Col xs={24}>
                <PageHeader
                    title={
                        <span>
                            <IntlMessages id="common.gallery" />
                        </span>
                    }
                />
            </Col>

            <Col xs={24}>
                <Card title={<IntlMessages id="common.event_images" />} className="gx-card">
                    <Tabs tabPosition={activeBreakpoint === 'XS' ? 'top' : 'left'}>
                        <Tabs.TabPane tab={intl.formatMessage({ id: 'common.asset_image_big' })} key="1">
                            <EventGalleryTab type={AssetContextType.EVENT_IMAGE_BIG} event={event} onDelete={onDelete} refetch={refetch} intl={intl} />
                        </Tabs.TabPane>
                        <Tabs.TabPane tab={intl.formatMessage({ id: 'common.asset_image_square' })} key="2">
                            <EventGalleryTab type={AssetContextType.EVENT_IMAGE_SQUARE} event={event} onDelete={onDelete} refetch={refetch} intl={intl} />
                        </Tabs.TabPane>
                    </Tabs>
                </Card>
            </Col>
        </Row>
    );
};

const EventGallery = ({ match }) => {
    const { params } = match;

    const intl = useIntl();
    const { data, loading, error, refetch } = useQuery(EVENT_GALLERY, { variables: { id: params.id }, fetchPolicy: 'no-cache' });

    if (error) {
        return <BadResponse title={intl.formatMessage({ id: '500.ooops' })} subtitle={intl.formatMessage({ id: '500.something_wrong' })} />;
    }

    return (
        <>
            <Row>
                <Col md={24}>
                    <Breadcrumb className="gx-pb-3">
                        <Breadcrumb.Item>
                            <BreadcrumbHomeLink />
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>{data?.event.name}</Breadcrumb.Item>
                        <Breadcrumb.Item>
                            <IntlMessages id="common.event_images" />
                        </Breadcrumb.Item>
                    </Breadcrumb>
                </Col>
            </Row>

            <Row>
                <Col xs={24}>{!loading ? <EventGalleryEditor loading={loading} event={data?.event} intl={intl} refetch={refetch} /> : <Skeleton />}</Col>
            </Row>
            <DevButton path={__filename} />
        </>
    );
};

export default EventGallery;
