import React, { useState } from 'react';
import { Input, Form, Dropdown, Space, Tag } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { isArray } from 'lodash';
import IntlMessages from '../../util/IntlMessages';

const LanguageMenu = ({ eventLanguages, selectedLanguage, setSelectedLanguage }) => {
    const items = eventLanguages.map((language) => {
        return {
            key: language.iso,
            label: (
                <a
                    onClick={() => {
                        setSelectedLanguage(language.iso);
                    }}
                >
                    <IntlMessages id={`common.${language.iso}`} />
                </a>
            ),
        };
    });

    if (eventLanguages.length === 1) {
        return (
            <Tag className="gx-mb-0">
                <IntlMessages id={`common.${selectedLanguage}`} />
            </Tag>
        );
    }

    return (
        <Dropdown
            menu={{
                items,
            }}
        >
            <a onClick={(e) => e.preventDefault()}>
                <Space>
                    <IntlMessages id={`common.${selectedLanguage}`} />
                    <DownOutlined />
                </Space>
            </a>
        </Dropdown>
    );
};

const MultilanguageFormInput = ({ formItemProps, input = null, eventLanguages = [] }) => {
    const [selectedLanguage, setSelectedLanguage] = useState(eventLanguages.find((language) => language.main)?.iso);

    if (!input) {
        input = <Input />;
    }

    const getFieldName = (language) => {
        if (isArray(formItemProps.name)) {
            return [...formItemProps.name, language];
        } else {
            return [formItemProps.name, language];
        }
    };

    return eventLanguages.map((language) => {
        return (
            <Form.Item
                {...formItemProps}
                className="gx-form-label-w-100"
                name={getFieldName(language.iso)}
                key={language.iso}
                label={
                    <Space className="gx-justify-content-between gx-w-100">
                        <span>{formItemProps.label}</span>
                        <LanguageMenu eventLanguages={eventLanguages} selectedLanguage={selectedLanguage} setSelectedLanguage={setSelectedLanguage} />
                    </Space>
                }
            >
                {input}
            </Form.Item>
        );
    });
};

export default MultilanguageFormInput;
