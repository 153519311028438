import { gql } from '@apollo/client';

export const PLAYER_PROVIDED_CARD = gql`
    query playerProvidedCard($number: String!, $type: String!) {
        playerProvidedCard(number: $number, type: $type) {
            last_name
            first_name
            category
            birthday
            number
            type
            expire_at
            role
            company_id
            citizenship
            gender
            rank_long
            rank_sprint
            rank_duathlon
            rank
            company_name
            updated_at
        }
    }
`;
