import { useMutation, useQuery } from '@apollo/client';
import React from 'react';
import { useIntl } from 'react-intl';
import BadResponse from '@/components/BadResponse';
import CircularProgress from '@/components/CircularProgress';
import { Row, Col, Form, Button, PageHeader, Card, InputNumber, DatePicker, message, Typography } from 'antd';
import IntlMessages from '@/util/IntlMessages';
import { useHistory } from 'react-router-dom';
import BadResponseErrorAlert from '@/components/BadResponseErrorAlert';
import { defaultCatchException, defaultMutationCallback } from '@/apollo/callbacks';
import { DATETIME_FORMAT } from '@/constants/App';
import moment from 'moment';
import SubEventBreadcrumbs from '../breadcrumbs';
import { SUB_EVENT_PRICES_DATA } from '../../../../apollo/query/sub-event';
import { UPDATE_SUB_EVENT_PRICES } from '../../../../apollo/mutation/sub-event';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import { formatCurrency, getLocaleFromMultiLanguage } from '../../../../util/string';
import { keys } from 'lodash';
import { floatToInt, intToFloat } from '../../../../util/number';
import { formatDate } from '../../../../util/date';
import { PRIMARY_COLOR } from '../../../../constants/ThemeSetting';
import PriceCommissions from './commissions';

const getPriceChartData = ({ prices, timetables }) => {
    let data = [];

    if (!timetables || !prices) {
        return { serie: data, min: 0, max: 0 };
    }

    const { variations } = prices;

    const startDate = moment(timetables.subscription_start);
    const endDate = moment(timetables.subscription_end);
    let basePrice = prices.base / 100;

    let max = basePrice;
    let min = basePrice;

    function addToData(mDate, price) {
        data.push({ x: formatDate(mDate), y: price });
    }

    while (startDate.isBefore(endDate)) {
        let price = basePrice;

        for (let i = 0; i < variations.length; i++) {
            let varStart = moment(variations[i].start);
            let varEnd = moment(variations[i].end);
            if (startDate.isBetween(varStart, varEnd, 'days', '[]')) {
                price = variations[i].value / 100;
            }
        }

        if (price > max) {
            max = price;
        }

        if (price < min) {
            min = price;
        }

        addToData(startDate, price);

        startDate.add(1, 'days');
    }

    return { data, min: min, max: max };
};

const PriceTooltip = ({ active, payload, label, currency }) => {
    if (active && payload && payload.length) {
        const [point] = payload;
        return (
            <div className="gx-bg-light-grey gx-px-2 gx-py-2 gx-border gx-border-primary">
                <strong>{label}</strong>
                <br />
                <span>{formatCurrency(point.value, 2, currency.iso)}</span>
            </div>
        );
    }
    return null;
};

const getXAxisDomainPadding = ({ min, max }, padding = 0.2) => {
    let padValue = (max - min) * padding;
    padValue = Math.ceil(padValue / 10) * 10;
    return [min - padValue, max + padValue];
};

const PriceChart = ({ subEvent, currency }) => {
    const { data, min, max } = getPriceChartData(subEvent);

    return (
        <ResponsiveContainer width="100%" height={300}>
            <LineChart
                data={data}
                margin={{
                    top: 5,
                    right: 30,
                    left: 20,
                    bottom: 5,
                }}
            >
                <CartesianGrid strokeDasharray="10 10" />
                <XAxis dataKey="x" />
                <YAxis
                    domain={getXAxisDomainPadding({ min, max }, 0.2)}
                    dataKey="y"
                    tickFormatter={(value) => {
                        return formatCurrency(value, 2, currency.iso);
                    }}
                />
                <Tooltip content={<PriceTooltip currency={currency} />} />
                <Line type="monotone" dataKey="y" stroke={PRIMARY_COLOR} activeDot={{ r: 8 }} />
            </LineChart>
        </ResponsiveContainer>
    );
};

const formToMutation = (values) => {
    const { base, variations, additions } = values;
    return {
        base: floatToInt(base),
        additions: keys(additions).map((fieldName) => {
            return {
                id: fieldName,
                values: keys(additions[fieldName]).map((optionValue) => {
                    return {
                        id: optionValue,
                        value: floatToInt(additions[fieldName][optionValue]),
                    };
                }),
            };
        }),
        variations:
            variations?.map((variation) => {
                return {
                    ...variation,
                    value: floatToInt(variation.value),
                };
            }) || [],
    };
};

const queryToForm = (prices) => {
    let additionObj = {};

    prices?.additions.forEach((addition) => {
        additionObj[addition.field_id] = {};
        addition.options.forEach((option) => {
            additionObj[addition.field_id][option.field_value] = intToFloat(option.price_increase) || 0;
        });
    });

    return {
        base: prices?.base !== null ? intToFloat(prices?.base) : null,
        variations: prices?.variations.map((variation) => {
            return {
                start: moment(variation.start),
                end: moment(variation.end),
                value: intToFloat(variation.value),
            };
        }),
        additions: additionObj,
    };
};

const PricesSubEventForm = ({ subEvent, refetch, intl }) => {
    const history = useHistory();

    const [updateSubEventPrices, { loading: loadingMutation }] = useMutation(UPDATE_SUB_EVENT_PRICES);

    const closeHandler = ({ updateSubEvent } = {}, values) => {
        message.success(intl.formatMessage({ id: 'common.edit_successful' }));
        refetch();
    };

    const handleOk = (values) => {
        const input = formToMutation(values);

        updateSubEventPrices({
            variables: {
                id: subEvent.id,
                input: input,
            },
        })
            .then((response) => defaultMutationCallback(response, () => closeHandler(response.data, values)))
            .catch((e) => defaultCatchException(e, intl));
    };

    const handleError = (error) => {
        console.log('Error', error);
    };

    const { event, prices } = subEvent;

    const currency = event.payments?.location.currency;

    return (
        <>
            <Row>
                <Col md={24}>
                    <SubEventBreadcrumbs subEvent={subEvent} section={<IntlMessages id={`common.update_type_${subEvent.event.type}`} />} />
                </Col>
            </Row>
            <Form
                onFinish={handleOk}
                onFinishFailed={handleError}
                layout="vertical"
                labelCol={{ span: 20 }}
                initialValues={queryToForm(prices)}
                autoComplete="off"
            >
                <Row>
                    <Col xs={24}>
                        <PageHeader
                            title={<IntlMessages id={`common.prices`} />}
                            onBack={() => history.goBack()}
                            extra={[
                                <Button key="1" onClick={() => closeHandler()}>
                                    <IntlMessages id="common.cancel" />
                                </Button>,
                                <Button key="2" htmlType="submit" type="primary" loading={loadingMutation}>
                                    {intl.formatMessage({ id: 'common.update' }).toUpperCase()}
                                </Button>,
                            ]}
                        />
                    </Col>
                    <Col xs={24}>
                        <BadResponseErrorAlert />
                    </Col>
                    <Col xs={24} lg={16}>
                        <Card className="gx-card" title={<IntlMessages id="common.athlete_base_price" />}>
                            <PriceChart subEvent={subEvent} currency={currency} />
                        </Card>
                        <Card className="gx-card" title={<IntlMessages id="common.prices" />}>
                            <Form.Item
                                name="base"
                                label={<IntlMessages id="common.base_price" />}
                                rules={[
                                    {
                                        required: true,
                                        message: intl.formatMessage({ id: 'common.field_required' }),
                                    },
                                ]}
                            >
                                <InputNumber addonAfter={currency.symbol} style={{ minWidth: 200 }} />
                            </Form.Item>

                            <Form.List name="variations" className="gx-bg-red">
                                {(fields, { add, remove }) => (
                                    <>
                                        <Typography.Title level={5}>
                                            <IntlMessages id="common.temporal_price_variations" />
                                            &nbsp;
                                            <small>
                                                (<IntlMessages id="common.timezone_of" values={{ timezone: event.timezone }} />)
                                            </small>
                                        </Typography.Title>
                                        {fields.map(({ key, name }) => {
                                            return (
                                                <Row key={key} className="gx-bg-light-grey gx-py-4 gx-mb-2">
                                                    <Col xs={24} md={6}>
                                                        <Form.Item
                                                            name={[name, 'value']}
                                                            label={<IntlMessages id="common.price" />}
                                                            tooltip={<IntlMessages id="common.price_variation_tooltip" />}
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message: intl.formatMessage({ id: 'common.field_required' }),
                                                                },
                                                            ]}
                                                        >
                                                            <InputNumber addonAfter={currency.symbol} />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col xs={24} md={6}>
                                                        <Form.Item
                                                            name={[name, 'start']}
                                                            label={<IntlMessages id="common.price_variation_date_start" />}
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message: intl.formatMessage({ id: 'common.field_required' }),
                                                                },
                                                            ]}
                                                        >
                                                            <DatePicker showTime={{ format: 'HH:mm' }} format={DATETIME_FORMAT} />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col xs={24} md={6}>
                                                        <Form.Item
                                                            name={[name, 'end']}
                                                            label={<IntlMessages id="common.price_variation_date_end" />}
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message: intl.formatMessage({ id: 'common.field_required' }),
                                                                },
                                                                ({ getFieldValue }) => ({
                                                                    validator(_, value) {
                                                                        const priceStart = getFieldValue(['variations', name, 'start']);

                                                                        if (priceStart && value.isBefore(priceStart)) {
                                                                            return Promise.reject(
                                                                                intl.formatMessage({
                                                                                    id: `common.date_start_must_be_after_end`,
                                                                                })
                                                                            );
                                                                        }

                                                                        return Promise.resolve();
                                                                    },
                                                                }),
                                                            ]}
                                                        >
                                                            <DatePicker showTime={{ format: 'HH:mm' }} format={DATETIME_FORMAT} />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col xs={24} md={6} className="gx-text-right">
                                                        <MinusCircleOutlined
                                                            style={{
                                                                top: 20,
                                                                position: 'relative',
                                                            }}
                                                            className="gx-text-danger"
                                                            onClick={() => {
                                                                remove(name);
                                                            }}
                                                        />
                                                    </Col>
                                                </Row>
                                            );
                                        })}
                                        <Form.Item>
                                            <Button
                                                style={{ minWidth: 250 }}
                                                type="dashed"
                                                onClick={() =>
                                                    add({
                                                        value: null,
                                                        start: moment().startOf('day'),
                                                        end: moment().add(10, 'days').endOf('day'),
                                                    })
                                                }
                                                icon={<PlusOutlined />}
                                            >
                                                <IntlMessages id="common.add" />
                                            </Button>
                                        </Form.Item>
                                    </>
                                )}
                            </Form.List>
                        </Card>
                        <Card
                            className="gx-card"
                            title={
                                <span>
                                    <IntlMessages id="common.options" />
                                    <small className="gx-ml-2">
                                        <IntlMessages id="common.price_options_description" />
                                    </small>
                                </span>
                            }
                        >
                            <div className="gx-bg-light-grey gx-py-4 gx-mb-2">
                                <Row>
                                    <Col span={24}>
                                        <Typography.Title level={5}>
                                            <IntlMessages id="common.gender" />
                                        </Typography.Title>
                                    </Col>
                                    <Col span={8}>
                                        <Form.Item name={['additions', 'gender', 'm']} label={<IntlMessages id="common.male" />}>
                                            <InputNumber addonAfter={currency.symbol} />
                                        </Form.Item>
                                    </Col>
                                    <Col span={8}>
                                        <Form.Item name={['additions', 'gender', 'f']} label={<IntlMessages id="common.female" />}>
                                            <InputNumber addonAfter={currency.symbol} />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </div>
                            {subEvent.priceable_fields.map((field, index) => {
                                return (
                                    <div key={index} className="gx-bg-light-grey gx-py-4 gx-mb-2">
                                        <Row>
                                            <Col span={24}>
                                                <Typography.Title level={5}>{getLocaleFromMultiLanguage(field.label, event.main_language)}</Typography.Title>
                                            </Col>
                                            {field.options.map((option, index) => {
                                                return (
                                                    <Col key={index} span={8}>
                                                        <Form.Item
                                                            name={['additions', field.name, option.value]}
                                                            label={getLocaleFromMultiLanguage(option.label, event.main_language)}
                                                        >
                                                            <InputNumber addonAfter={currency.symbol} />
                                                        </Form.Item>
                                                    </Col>
                                                );
                                            })}
                                        </Row>
                                    </div>
                                );
                            })}
                        </Card>
                    </Col>
                    <Col xs={24} lg={8}>
                        <PriceCommissions event={event} prices={prices} currency={currency} />
                    </Col>
                </Row>
            </Form>
        </>
    );
};

const SubEventPrices = ({ match }) => {
    const intl = useIntl();

    const { data, loading, error, refetch } = useQuery(SUB_EVENT_PRICES_DATA, { variables: { id: match.params.id }, fetchPolicy: 'no-cache' });

    if (loading) {
        return <CircularProgress />;
    }

    if (error) {
        return <BadResponse title={intl.formatMessage({ id: '500.ooops' })} subtitle={intl.formatMessage({ id: '500.something_wrong' })} />;
    }

    return <PricesSubEventForm subEvent={data.subEvent} intl={intl} refetch={refetch} />;
};

export default SubEventPrices;
