import React, { useState, useEffect } from 'react';
import { Select } from 'antd';
import { useDebouncedCallback } from 'use-debounce/lib';
import { uniq, map, truncate } from 'lodash';
import './flags.css';

const { Option } = Select;

const getLocationLabel = (response) => {
    let label = response.name;

    if (response.parents) {
        let parentsName = map(response.parents, 'name');

        //add
        parentsName.unshift(label);
        parentsName = uniq(parentsName);

        label = parentsName.join(', ');
    }

    return truncate(label, { length: 150 });
};

const LocationSelect = ({ value, onChange, onSelect, country, placeType = 'town', language = 'it', ...rest }) => {
    const [locations, setLocations] = useState([]);
    const [loading, isLoading] = useState(false);

    useEffect(() => {
        if (value) {
            fetchDataById({ id: value, language }, onRequestCallback);
        }
    }, []);

    const fetchData = ({ term, placeType, language = 'it', country }, callback) => {
        let placeTypeInt;
        switch (placeType) {
            case 'town': //town,city only
                placeTypeInt = 0;
                break;
            case 'all':
                placeTypeInt = 1;
                break;
            case 'country':
                placeTypeInt = 12;
                break;
            default:
                placeTypeInt = 0;
                break;
        }

        const request = {
            method: 'POST',
            body: JSON.stringify({
                term: term,
                page: 1,
                size: 40,
                place: placeTypeInt,
                country: country,
            }),
            headers: {
                Authorization: `Basic ${process.env.REACT_APP_AUTH_BASIC}`,
                'Content-Type': 'application/json',
            },
        };

        processRequest(`${process.env.REACT_APP_DATA_URL}/api/location?locale=${language}`, request, callback);
    };

    const fetchDataById = ({ id, language }, callback) => {
        const request = {
            method: 'GET',
            headers: {
                Authorization: `Basic ${process.env.REACT_APP_AUTH_BASIC}`,
                'Content-Type': 'application/json',
            },
        };

        const url = `${process.env.REACT_APP_DATA_URL}/api/location/${id}?locale=${language}`;
        processRequest(url, request, callback);
    };

    const processRequest = (url, request, callback) => {
        isLoading(true);
        fetch(url, request)
            .then((response) => {
                return response.json();
            })
            .then((json) => {
                isLoading(false);
                let locations = [];
                if (json.results && json.results.length > 0) {
                    locations = map(json.results, (r) => {
                        return {
                            value: r.woeid,
                            label: getLocationLabel(r),
                            country: r.country,
                            coords: r.coords,
                        };
                    });
                } else {
                    locations.push({
                        value: json.woeid,
                        label: getLocationLabel(json),
                        country: json.country,
                        coords: json.coords,
                    });
                }
                callback(locations);
            })
            .catch((ex) => {
                isLoading(false);
                callback(null, ex);
            });
    };

    const onRequestCallback = (data, error) => {
        if (!error) {
            setLocations(data);
        }
    };

    const debouncedFilterCallback = useDebouncedCallback((value) => fetchData({ term: value, country, placeType, language }, onRequestCallback), 500);

    const handleSelect = (value, event) => onSelect(value, event);

    const handleChange = (value) => {
        if (!value) {
            onSelect(null);
        }
    };

    return (
        <Select
            loading={loading}
            value={!loading ? value : null}
            showSearch={true}
            defaultActiveFirstOption={false}
            allowClear={true}
            showArrow={true}
            filterOption={false}
            onSearch={(value) => debouncedFilterCallback(value)}
            onSelect={(value, event) => handleSelect(value, event)}
            onChange={handleChange}
            notFoundContent={null}
            {...rest}
        >
            {locations.map((o) => {
                return (
                    <Option key={o.value} value={o.value} option={o}>
                        <i className={`${o.country ? `list-flag flag-${o.country.toLowerCase()}` : ''}`}></i>
                        {o.label}
                    </Option>
                );
            })}
        </Select>
    );
};
export default LocationSelect;
