import React from 'react';
import { Result } from 'antd';
import { injectIntl } from 'react-intl';
import { CodeTwoTone } from '@ant-design/icons';
import IntlMessages from '../util/IntlMessages';

const UnderMaintenance = ({ intl }) => {
    return (
        <div className="gx-app-login-wrap">
            <div className="gx-app-login-container gx-app-login-container-frontend">
                <div className="gx-app-logo gx-app-logo-frontend">
                    <img alt="" src="/assets/images/keepsporting_logo.png" />
                </div>
                <Result
                    icon={<CodeTwoTone twoToneColor="#4FA0CA" spin={false} />}
                    title={<IntlMessages id="maintenance.under_maintenance_title" />}
                    subTitle={<IntlMessages id="maintenance.under_maintenance_subtitle" />}
                />
            </div>
        </div>
    );
};

export default injectIntl(UnderMaintenance);
