import React from 'react';
import { Select, Skeleton, message } from 'antd';
import { useQuery } from '@apollo/client';
import { GET_SPORTS } from '../../apollo/query/sport';
import { useIntl } from 'react-intl';
import { getSafeISOLanguage } from '../../lngProvider';

const SportSelect = ({ value, onSelect, ...rest }) => {
    const intl = useIntl();

    const { data, loading } = useQuery(GET_SPORTS, {
        onError: (error) => {
            message.error(intl.formatMessage({ id: 'errors.fetch_error' }));
        },
        fetchPolicy: 'cache-first',
        variables: {
            locale: getSafeISOLanguage(intl.locale),
        },
    });

    if (loading) {
        return <Skeleton.Input active={true} className="gx-w-100" />;
    }

    let { sports } = data;

    return (
        <Select
            value={value}
            onSelect={onSelect}
            showSearch={true}
            filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
            filterSort={(optionA, optionB) => optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())}
            {...rest}
        >
            {[...sports]
                .sort((a, b) => {
                    return a.name.localeCompare(b.name);
                })
                .map((sport) => {
                    return <Select.Option key={sport.keepsporting_id}>{sport.name}</Select.Option>;
                })}
        </Select>
    );
};
export default SportSelect;
