import React, { useState, useEffect } from 'react';
import { Select } from 'antd';
import { map } from 'lodash';
import './flags.css';
import { getSafeISOLanguage } from '../../lngProvider';
import { useIntl } from 'react-intl';

const getCountryLabel = (response, language) => {
    if (!response) return '-';

    if (response[`name_${language}`]) {
        return response[`name_${language}`];
    }

    return response.name;
};

const CountrySelect = ({ value, onChange, onSelect, ...rest }) => {
    const intl = useIntl();

    const locale = getSafeISOLanguage(intl.locale);

    const [locations, setLocations] = useState([]);
    const [loading, isLoading] = useState(false);

    useEffect(() => {
        fetchData(onRequestCallback);
    }, []);

    const processRequest = (url, request, callback) => {
        isLoading(true);
        fetch(url, request)
            .then((response) => {
                return response.json();
            })
            .then((json) => {
                isLoading(false);
                let locations = [];
                if (json.results && json.results.length > 0) {
                    locations = map(json.results, (r) => {
                        return {
                            value: r.country,
                            label: getCountryLabel(r, locale),
                            country: r.country,
                            coords: r.coords,
                        };
                    }).sort((a, b) => {
                        const aLabel = a.label?.toLowerCase() || '';
                        const bLabel = b.label?.toLowerCase() || '';
                        return aLabel.localeCompare(bLabel);
                    });
                }
                callback(locations);
            })
            .catch((ex) => {
                isLoading(false);
                callback(null, ex);
            });
    };

    const fetchData = (callback) => {
        const request = {
            method: 'GET',
            headers: {
                Authorization: `Basic ${process.env.REACT_APP_AUTH_BASIC}`,
                'Content-Type': 'application/json',
            },
        };

        processRequest(`${process.env.REACT_APP_DATA_URL}/api/location/countries`, request, callback);
    };

    const onRequestCallback = (data, error) => {
        if (!error) {
            setLocations(data);
        }
    };

    const handleSelect = (value, event) => onSelect(value, event);

    const handleChange = (value) => {
        if (!value) {
            onSelect(null);
        }
    };

    return (
        <Select
            loading={loading}
            value={!loading ? value : null}
            defaultActiveFirstOption={false}
            allowClear={true}
            showArrow={true}
            showSearch={true}
            filterOption={(input, option) => option.option?.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
            onSelect={(value, event) => handleSelect(value, event)}
            onChange={handleChange}
            notFoundContent={null}
            {...rest}
        >
            {locations.map((o) => {
                return (
                    <Select.Option key={o.value} value={o.value} option={o}>
                        <i className={`${o.country ? `list-flag flag-${o.country.toLowerCase()}` : ''}`}></i>
                        {o.label}
                    </Select.Option>
                );
            })}
        </Select>
    );
};

export default CountrySelect;
