export const requiredRule = (intl) => {
    return {
        required: true,
        message: intl.formatMessage({
            id: 'common.field_required',
        }),
    };
};

export const emailRule = (intl) => {
    return {
        type: 'email',
        message: intl.formatMessage({
            id: 'common.field_email',
        }),
    };
};
