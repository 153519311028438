import React, { useEffect, useState } from 'react';
import { CheckCircleOutlined, CloseCircleOutlined, EyeOutlined, FileExcelOutlined, MailOutlined, UnorderedListOutlined } from '@ant-design/icons';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { PageHeader, Breadcrumb, Row, Col, Card, Table, Input, Button, Space, Collapse, Badge, Dropdown, Tag, Tooltip, Modal, message, Drawer } from 'antd';
import { useIntl } from 'react-intl';
import { EVENT_CONTAINER_DATA, EVENT_PAYMENTS, EXPORT_EVENT_PAYMENTS } from '../../../../apollo/query/event';
import BadResponse from '../../../../components/BadResponse';
import BreadcrumbHomeLink from '../../../../components/BreadcrumbHomeLink';
import PaymentTypeIcon from '../../../../components/Payment/PaymentTypeIcon';
import { paginationOptions } from '../../../../constants/PaginationOptions';
import { usePersistedState } from '../../../../hooks/usePersistedState';
import { formatDateTime } from '../../../../util/date';
import IntlMessages from '../../../../util/IntlMessages';
import { formatCurrency } from '../../../../util/string';
import { useDebounce } from 'use-debounce';
import { debounceSearchFilterDelay } from '../../../../util/config';
import CircularProgress from '../../../../components/CircularProgress';
import DevButton from '../../../../components/Dev/DevButton';
import { Link, useHistory } from 'react-router-dom';
import { getAdminPath } from '../../../../util/router';
import DropDownCheckboxFilter from '../../../../components/DropdownCheckboxFilter';
import { countActiveFilters } from '../../../../util/object';
import { PRIMARY_COLOR } from '../../../../constants/ThemeSetting';
import { PAYMENTS } from '../../../../constants/App';
import { keys, includes } from 'lodash';
import { getDefaultSortOrder } from '../../../../util/antd';
import { SUBSCRIPTION_PAYMENT_REMINDERS } from '../../../../apollo/mutation/event';
import { defaultCatchException, defaultMutationHandler } from '../../../../apollo/callbacks';
import { getSafeISOLanguage } from '../../../../lngProvider';
import PaymentsSummary from './components/summary';
import { useActiveBreakpoint } from '../../../../hooks/useActiveBreakpoint';

const getColumns = ({ event, sortingState }, history) => {
    return [
        {
            title: <IntlMessages id="common.name" />,
            dataIndex: ['payer', 'full_name'],
            render(fullName, row) {
                if (row.deleted_at) {
                    return (
                        <span className="gx-text-strikethrough">
                            {fullName}
                            <br />
                            <Tag className="gx-bg-warning">
                                <IntlMessages id={`common.${row.order_state}`} />
                            </Tag>
                        </span>
                    );
                }
                return fullName;
            },
        },
        {
            title: <IntlMessages id="common.email" />,
            dataIndex: ['payer', 'email'],
            render(email, row) {
                if (row.deleted_at) {
                    return <span className="gx-text-strikethrough">{email}</span>;
                }
                return email;
            },
        },
        {
            title: <IntlMessages id="common.paid" />,
            className: 'gx-text-center',
            dataIndex: ['transaction', 'paid_at'],
            render(paidAt) {
                return paidAt ? (
                    <Tooltip
                        title={
                            <span>
                                <IntlMessages id="common.paid_at" />
                                :&nbsp;{formatDateTime(paidAt)}
                            </span>
                        }
                    >
                        <CheckCircleOutlined className="gx-text-success gx-fs-lg" />
                    </Tooltip>
                ) : (
                    <CloseCircleOutlined className="gx-fs-lg" />
                );
            },
        },
        {
            title: <IntlMessages id="common.type" />,
            className: 'gx-text-center',
            dataIndex: ['transaction'],
            render(transaction) {
                return <PaymentTypeIcon transaction={transaction} />;
            },
        },
        {
            title: <IntlMessages id="common.players" />,
            dataIndex: 'players',
            render(value) {
                const players = value.slice(0, 4).map((player) => {
                    return (
                        <Tag.CheckableTag className="gx-border-grey">
                            <Link
                                className="gx-p-1"
                                to={getAdminPath(`event/${event.id}/sub-event/${player.sub_event_id}/subscriber/view/${player.id}`)}
                                key={player.id}
                            >
                                {player.full_name}
                            </Link>
                        </Tag.CheckableTag>
                    );
                });

                if (value.length > 4) {
                    players.push(<span key="more">...</span>);
                }

                return players;
            },
        },
        {
            title: <IntlMessages id="common.price" />,
            dataIndex: ['transaction', 'total'],
            className: 'gx-text-right',
            render(value, row) {
                return formatCurrency(value / 100, 2, row.paymentLocation?.currency.iso);
            },
        },
        {
            title: <IntlMessages id="common.createdAt" />,
            dataIndex: 'created_at',
            align: 'center',
            sorter: true,
            defaultSortOrder: getDefaultSortOrder('created_at', sortingState),
            sortDirections: ['descend', 'ascend', 'descend'],
            render(value) {
                return formatDateTime(value);
            },
        },
        {
            title: '',
            dataIndex: 'id',
            align: 'center',
            fixed: 'right',
            width: 75,
            responsive: ['lg'],
            render(id) {
                return [
                    <a
                        key="view-payment"
                        onClick={(e) => {
                            e.stopPropagation();
                            history.push(getAdminPath(`event/${event.id}/payment/view/${id}`));
                        }}
                        className="ant-btn gx-mb-0"
                    >
                        <EyeOutlined />
                    </a>,
                ];
            },
        },
    ];
};

const getDropdownActionMenu = ({ onClickRemindPayment }) => {
    return [
        {
            key: '1',
            label: (
                <a onClick={onClickRemindPayment}>
                    <MailOutlined />
                    &nbsp;
                    <IntlMessages id="common.remainder_email" />
                </a>
            ),
        },
    ];
};

const getFilterButtonsSpaceProps = (activeBreakpoint) => {
    if (activeBreakpoint === 'XS') {
        return {
            direction: 'vertical',
            size: 'small',
            className: 'gx-mt-2',
        };
    } else {
        return {
            direction: 'horizontal',
            size: 'large',
        };
    }
};

const EventPaymentsTable = ({ event, intl, activeBreakpoint }) => {
    const DEFAULT_FILTERS = { term: '' };

    const history = useHistory();

    const [filters, setFilters] = usePersistedState('eventPayments', DEFAULT_FILTERS);
    const [paginationState, setPaginationState] = usePersistedState('eventPaymentsPagination', { current: 1, pageSize: 10 });
    const [sortingState, setSortingState] = usePersistedState('eventPaymentsSorting', { key: 'created_at', direction: -1 });
    const [selectedRowKeys, setSelectedRowKeys] = React.useState([]);

    const updateFilters = (key, value) => {
        if (paginationState.current !== 1) {
            setPaginationState({ ...paginationState, current: 1 });
        }
        setFilters({ ...filters, [key]: value });
    };

    const [debouncedTerm] = useDebounce(filters?.term, debounceSearchFilterDelay);

    const [subscriptionPaymentReminders, { loading: confirmationLoading }] = useMutation(SUBSCRIPTION_PAYMENT_REMINDERS);

    const { data, loading, error, previousData, refetch } = useQuery(EVENT_PAYMENTS, {
        variables: {
            filter: {
                event_id: event.id,
                ...filters,
                term: debouncedTerm,
            },
            pagination: {
                page: paginationState.current,
                limit: paginationState.pageSize,
            },
            sorting: sortingState,
        },
        onError: (error) => {
            setFilters(DEFAULT_FILTERS);
        },
    });

    useEffect(() => {
        if (data && data.eventPayments) {
            const totalPages = data.eventPayments.pagination?.pages;
            const currentPage = data.eventPayments.pagination?.page;
            if (currentPage > totalPages) {
                setPaginationState({ ...paginationState, current: 1 });
            }
        }
    }, [data]);

    if (error) {
        return <BadResponse title={intl.formatMessage({ id: '500.ooops' })} subtitle={intl.formatMessage({ id: '500.something_wrong' })} />;
    }

    const onRemindPayment = () => {
        Modal.confirm({
            title: intl.formatMessage({ id: 'common.payment_remainder_modal_title' }),
            content: intl.formatMessage({ id: 'common.payment_remainder_modal_text_plural' }, { count: selectedRowKeys.length }),
            okText: intl.formatMessage({ id: 'common.confirm' }),
            cancelText: intl.formatMessage({ id: 'common.cancel' }),
            okButtonProps: {
                loading: confirmationLoading,
            },
            onOk: () => {
                subscriptionPaymentReminders({
                    variables: {
                        input: selectedRowKeys,
                    },
                })
                    .then(
                        defaultMutationHandler({
                            successCallback: ({ subscriptionPaymentReminders }) => {
                                message.success(
                                    intl.formatMessage({ id: 'common.multi_remainder_email_sent_plural' }, { count: subscriptionPaymentReminders })
                                );
                                setSelectedRowKeys([]);
                                refetch();
                            },
                            errorMessage: intl.formatMessage({ id: 'common.default_mutation_error' }),
                        })
                    )
                    .catch((e) => defaultCatchException(e, intl));
            },
        });
    };

    //reuse previous data while loading
    let tableData = previousData
        ? previousData.eventPayments
        : {
              items: [],
              pagination: {},
          };

    if (!loading && data) {
        tableData = data.eventPayments;
    }

    return (
        <Row>
            <Col xs={24}>
                <Collapse className="gx-panel gx-mb-3" defaultActiveKey={countActiveFilters(filters) > 0 ? ['1'] : null}>
                    <Collapse.Panel
                        header={
                            <Space className="gx-w-100 gx-justify-content-between">
                                <div className="">
                                    <IntlMessages id="common.filters" />
                                    <Badge className="gx-ml-2" count={countActiveFilters(filters, 0)} style={{ backgroundColor: PRIMARY_COLOR }} />
                                </div>
                            </Space>
                        }
                        size="small"
                        className="gx-panel"
                        key="1"
                        bordered={false}
                        extra={[
                            <Button
                                size="small"
                                key="reset-btn"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    setFilters(DEFAULT_FILTERS);
                                }}
                                className="gx-mb-0"
                            >
                                <CloseCircleOutlined className="gx-pr-1" />
                                <IntlMessages id="common.clear_filters" />
                            </Button>,
                        ]}
                    >
                        <Row>
                            <Col xs={24} md={24} lg={10} xl={8}>
                                <Input.Search
                                    value={filters?.term}
                                    onChange={(e) => {
                                        const { value } = e.target;
                                        updateFilters('term', value);
                                    }}
                                    style={{ minWidth: 300 }}
                                    placeholder={`${intl.formatMessage({ id: 'common.name' })}, ${intl.formatMessage({
                                        id: 'common.email',
                                    })}, ${intl.formatMessage({ id: 'common.bank_code' })}`}
                                />
                            </Col>
                            <Col xs={24} md={24} lg={6} style={{ maxWidth: '100%' }}>
                                <Space {...getFilterButtonsSpaceProps(activeBreakpoint)}>
                                    <DropDownCheckboxFilter
                                        label={<IntlMessages id="common.payment" />}
                                        options={[
                                            { label: <IntlMessages id="common.has_paid" />, value: true },
                                            { label: <IntlMessages id="common.not_paid" />, value: false },
                                        ]}
                                        values={filters?.paid}
                                        onChange={(values) => {
                                            updateFilters('paid', values);
                                        }}
                                    />

                                    <DropDownCheckboxFilter
                                        label={<IntlMessages id="common.payment_type" />}
                                        options={keys(PAYMENTS).map((key) => {
                                            return { label: <IntlMessages id={`common.payment_type_${PAYMENTS[key]}`} />, value: PAYMENTS[key] };
                                        })}
                                        values={filters?.payment_types}
                                        onChange={(values) => {
                                            updateFilters('payment_types', values);
                                        }}
                                    />

                                    <DropDownCheckboxFilter
                                        label={<IntlMessages id="common.deleted" />}
                                        options={[{ label: <IntlMessages id="common.yes" />, value: true }]}
                                        values={filters?.deleted}
                                        onChange={(values) => {
                                            updateFilters('deleted', values);
                                        }}
                                    />
                                </Space>
                            </Col>
                        </Row>
                    </Collapse.Panel>
                </Collapse>
            </Col>

            <Col xs={24}>
                <Card
                    title={[
                        <Dropdown
                            key="actions"
                            menu={{
                                items: getDropdownActionMenu({
                                    onClickRemindPayment: onRemindPayment,
                                }),
                            }}
                            trigger={'click'}
                            placement="bottom"
                            arrow
                        >
                            <Button className="gx-mb-0" disabled={selectedRowKeys.length === 0}>
                                <IntlMessages id="common.actions_on_selected" />
                                &nbsp;({selectedRowKeys.length})
                            </Button>
                        </Dropdown>,
                    ]}
                    className="gx-card"
                >
                    <Table
                        scroll={{ x: 1300 }}
                        rowKey="id"
                        rowSelection={{
                            type: 'checkbox',
                            selectedRowKeys,
                            onChange: (selectedRowKeys, selectedRows) => {
                                setSelectedRowKeys(selectedRowKeys);
                            },
                        }}
                        onChange={(pagination, filters, sorter, { action }) => {
                            if (action === 'sort') {
                                setSortingState({ key: sorter.field, direction: sorter.order === 'ascend' ? 1 : -1 });
                            }
                        }}
                        onRow={(row) => {
                            return {
                                onClick: (e) => {
                                    return history.push(getAdminPath(`event/${event.id}/payment/view/${row.id}`));
                                },
                            };
                        }}
                        loading={loading}
                        dataSource={tableData.items}
                        columns={getColumns({ event, sortingState }, history)}
                        pagination={{
                            ...paginationOptions,
                            total: tableData.pagination.total_items,
                            current: tableData.pagination.page,
                            pageSize: tableData.pagination.limit,
                            onChange: (current, pageSize) => {
                                setPaginationState({ current, pageSize });
                            },
                        }}
                    />
                </Card>
            </Col>
        </Row>
    );
};

const ExportPaymentsButton = ({ event }) => {
    const intl = useIntl();

    const [exportPayments, { loading }] = useLazyQuery(EXPORT_EVENT_PAYMENTS, {
        fetchPolicy: 'no-cache',
    });

    return (
        <Button
            loading={loading}
            onClick={() => {
                exportPayments({
                    variables: {
                        id: event.id,
                        locale: getSafeISOLanguage(intl.locale),
                    },
                })
                    .then(({ data }) => {
                        if (data?.exportPayments) {
                            const link = document.createElement('a');
                            link.href = data.exportPayments;
                            link.download = true;
                            link.click();
                            link.remove();
                        } else {
                            message.error(intl.formatMessage({ id: 'common.default_mutation_error' }));
                        }
                    })
                    .catch((e) => defaultCatchException(e, intl));
            }}
        >
            <FileExcelOutlined /> <IntlMessages id="common.export_xls" />
        </Button>
    );
};

const EventPayments = ({ match }) => {
    const { params } = match;

    const intl = useIntl();

    const activeBreakpoint = useActiveBreakpoint();

    const [showPaymentsSummary, setShowPaymentsSummary] = useState(false);

    const { data, loading, error } = useQuery(EVENT_CONTAINER_DATA, {
        variables: {
            id: params.id,
        },
    });

    let event = {};

    if (error) {
        return <BadResponse title={intl.formatMessage({ id: '500.ooops' })} subtitle={intl.formatMessage({ id: '500.something_wrong' })} />;
    }

    if (loading) {
        return <CircularProgress />;
    }

    event = data.event;

    return (
        <>
            <Row>
                <Col md={24}>
                    <Breadcrumb className="gx-pb-3">
                        <Breadcrumb.Item>
                            <BreadcrumbHomeLink />
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>{event.name}</Breadcrumb.Item>
                        <Breadcrumb.Item>
                            <IntlMessages id="common.payments" />
                        </Breadcrumb.Item>
                    </Breadcrumb>
                </Col>
            </Row>

            <Row>
                <Col xs={24}>
                    <PageHeader
                        title={
                            <span>
                                <IntlMessages id="common.payments" />
                                &nbsp;{event.name}
                            </span>
                        }
                        extra={
                            <Space>
                                <ExportPaymentsButton event={event} />
                                <Button
                                    icon={<UnorderedListOutlined className="gx-mr-2" />}
                                    type="primary"
                                    onClick={() => {
                                        setShowPaymentsSummary(true);
                                    }}
                                >
                                    <IntlMessages id="common.payments_summary" />
                                </Button>
                            </Space>
                        }
                    />
                </Col>
            </Row>
            <EventPaymentsTable activeBreakpoint={activeBreakpoint} event={data?.event} intl={intl} />
            <Drawer
                title={<IntlMessages id="common.payments_summary" />}
                placement="right"
                width={!includes(['XS', 'SM', 'MD'], activeBreakpoint) ? 800 : null}
                closable={true}
                onClose={() => {
                    setShowPaymentsSummary(false);
                }}
                open={showPaymentsSummary}
            >
                <PaymentsSummary event={event} intl={intl} />
            </Drawer>
            <DevButton path={__filename} />
        </>
    );
};

export default EventPayments;
